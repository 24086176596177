/* eslint-disable */

import type { ExpensesLang } from "../types/ExpensesLang";
import { translateTempImpl } from "../TranslatableLanguage";

export function ExpensesLangDef(): ExpensesLang {
  return {
    translateTemp: (key: string, neutral: string, svSE?: string, enGB?: string) => translateTempImpl(key, neutral, svSE, enGB),
    
    Accepted: "Accepted",
    ActiveClaims: "Active claims",
    AddExtraPage: "Add extra page",
    AFewMoreDetails_message: "We just need a few more details to get you set up with Expenses",
    AllAccounts: "All accounts",
    AllEmployeesConnected: "All employees are connected.",
    AllExpenses: "All Expense claims",
    AllExpensesForCompany_message: "This is where you will find all of the expenses for your company",
    AlmostThere_heading: "Almost there!",
    Amount: "Amount",
    AmountInSek_label: "Amount in GBP",
    Approved_status: "Approved",
    AreYourSureUndoBookkeeping_text: "Are you sure you want to undo the accounting for ",
    BankAccountNo: "Bank account no.",
    BBAPaymentOption_Description: "Automatically do the payments and all the follow up recording. You just need to sign off on the payments.",
    BookkeptAs: "Recorded as",
    BookkeptBy: "Recorded by",
    CanRequestPermissionEmployerAdmin: "You can request permission from your employer or admin.",
    ChangePermissions: "Change your permissions",
    ClaimDate: "Claim date",
    Claimer: "Claimer",
    Claims: "Claims",
    CompanyCards_Description: "Company cards are credit cards where the company pay the bill and have the liability. Expenses on these cards are not paid out to the employee, which is why they are handled differently.",
    CompanyCards_Title: "Company cards",
    Completed: "Completed",
    ConfirmDelete_heading: "Confirm delete",
    ConfirmDelete_message: "Are you sure you want to delete your expense?",
    Connect_AddEmployeeAction: "Create employee",
    Connect_Info: "To be able to do Expense claims a person needs to have a user (a login) in this company and be connected to an employee. They also need the permission to upload expenses. Note: We call it employees here but it could also be board members or other persons connected to the company who often need reimbursements.",
    Connected: "Connected",
    ConvertToExpense: "Move receipt to expenses",
    ConvertToExpense_Blocked_BankConnection: "This receipt has a bank connection and can't be moved to expenses",
    ConvertToExpense_Blocked_Employees: "You have no employee to move the receipt to",
    ConvertToExpense_Blocked_Expense: "This is already an expense can't be moved to expenses",
    ConvertToExpense_Blocked_Recorded: "This receipt is recorded and can't be moved to expenses",
    ConvertToExpense_Blocked_SupplierInvoice: "This receipt is connected to a supplier invoice and can't be moved to expenses",
    ConvertToExpense_Description: "By moving this receipt to expenses, it will first be placed with your expenses. If it belongs to another employee, you can transfer it afterwards.",
    ConvertToReceipt_Action: "Move to the company's receipts",
    ConvertToReceipt_Confirm: "Yes, move it",
    ConvertToReceipt_Description: "This will move this expense claim to the company's receipts instead. It will no longer be reimbursable to you.",
    CreateEmployee_action: "Create employee",
    CreateExpense_heading: "Create expense",
    CreateExpense_SaveAsDraft: "Do later",
    CreateNewEmployee_action: "New employee",
    CreateNewExpense_text: "Create a new expense by submitting a receipt. No more forgotten receipts!",
    Date: "Date",
    DaysOverdue: "days overdue",
    Delete_DisabledActionDescription: "Expenses that are recorded or scheduled for payment must be deleted from those places first",
    DescribeYourReceipt: "Describe your receipt",
    Description_label: "Description",
    Details: "Details",
    Disbursement_CancelPayment: "Cancel payment",
    Disbursement_DeleteInfo: "If you need to change any of these expenses or their bookkeeping you first need to undo the bookkeeping of this payment and the planning of it.",
    Disbursement_PaymentMethod: "Payment method",
    Disbursement_ScheduledBy: "Scheduled by {0}",
    Disbursements_GoAndSign: "Sign payments",
    Disbursements_NeedSign: "These payments needs to be signed",
    Disbursements_PaymentsTitles: "{0} payments",
    Disbursements_SignedMessage: "These payments are signed and will be paid",
    DueIn: "Due in",
    DueToday: "Due today",
    EditExpense_action: "Edit",
    EditExpenseScene_ExpenseAccount_Label: "How did you pay for this?",
    EditUser: "Edit user",
    Employee: "Employee",
    Employees: "Employees",
    Expense: "Expense",
    Expense_DeleteInfo: "If you need to change this expense or the recording of it you need to undo the payment first. Often it's easier to record an adjustment manually.",
    Expense_Form_EmployeeForExpense: "Employee this is for",
    ExpenseAcconts_Active: "Active",
    ExpenseAcconts_AddAccount: "Add card",
    ExpenseAcconts_DeleteAccount_Description: "If expense claims are connected to the card it will be archived. Otherwise it will be permanently removed.",
    ExpenseAcconts_Title: "My cards",
    ExpenseAcconts_Type: "Type",
    ExpenseAccount_Deactivate: "Deactivate",
    ExpenseAccount_Label: "Paid with",
    ExpenseAccounts_Active: "Active",
    ExpenseAccounts_CompanyCC: "Company credit card",
    ExpenseAccounts_CompanyCC_Description: "For credit cards where the company pays the bill.",
    ExpenseAccounts_CompanyDC: "Company debit card",
    ExpenseAccounts_Deactivated: "Deactivated",
    ExpenseAccounts_DefaultTitle: "Own money",
    ExpenseAccounts_Information: "If you make purchases for the company with different cards adding these here will allow you to filter your expenses card, making it easier to verify that you cowered all your expenses. For company credit cards, these are administrated under settings by an expense admin.",
    ExpenseAccounts_PrivateCard: "Private card",
    ExpenseAccounts_PrivateCard_Description: "For private cards, or any other account where the company should reimburse you.",
    ExpenseAccounts_Reactivate: "Reactivate",
    ExpenseDate_label: "Expense date",
    ExpenseDetails: "Expense details",
    ExpensePayment: "Payment",
    ExpensePermissions_heading: "Expense permissions",
    Expenses_heading: "Expenses",
    Expenses_Planned: "Planned",
    Expenses_text: "expenses",
    ExpensesAwaitingAttest_heading: "Events awaiting approval",
    ExpensesAwaitingPayment_heading: "Expenses to pay",
    ExpensesDrafts_heading: "Drafts",
    ExpenseSettingsTitle: "Set earliest bookkeeping date for expenses",
    ExpensesPayments_heading: "Expense payments",
    FilterEmployees_All: "All employees",
    FilterExpenseStatus_All: "Any status",
    FilterExpenseStatus_Pending: "Pending",
    forPayment: "for payment",
    GoToMyExpenses_action: "Go to my expenses",
    HmmNoExpensesSystem: "Hmm, no expenses seem to be registered in the system. First, let's try adding an expense.",
    ImDone: "I'm done",
    InviteEmployee_action: "Invite employee",
    InviteEmployees_action: "Invite employees",
    InviteEmployeesExpenses: "Invite employees to expenses",
    InvitePending: "Invite pending",
    ManagePeople: "Manage people",
    ManageRights_action: "Manage rights",
    ManualPaymentOption: "Manual payment",
    ManualPaymentOption_Description: "You need pay these outside of Bokio and then record the payments.",
    ManualPaymentOption_SwitchToTeller_Description: "If you want to pay these automatically you can cancel these payments and reschedule them.",
    MatchingXOfYExpensesWithATotalOfZ: "Matching {0} of {1} expense claims. Total sum: {2}",
    MinBookkeepingDate_HelpText: "If this date is set, all expenses with a date before it will be recorded on this date. This is useful to avoid recording expenses on closed periods, if they were uploaded too late.",
    MustBeEmployee: "You must be an employee to be able to create expenses.",
    MyExpenses: "My expense claims",
    NewExpense_action: "New expense claim",
    NoAccessExpensesPayments: "You don't have access to make expense payments.",
    NoAccessViewAllExpenses: "You don't have access to view all expenses.",
    NoActiveExpenses: "No active expenses right now.",
    NoEmployees: "No employees.",
    NoExpensesEmptyState_message: "Have an expense you want to claim? Upload your receipts here to get started",
    NoExpensesScheduled: "No expenses are currently scheduled for payment",
    NoExpensesWaitingSchedule_empty: "No expenses are currently waiting to be scheduled",
    NoPaidExpenses_empty: "There are no paid expenses to see yet.",
    NoPermissionUpload: "You don't have permission to upload expenses yet.",
    NoResultsChangeFilters: "No results. Try changing the filters.",
    NotApproved_heading: "Not approved",
    NotEditable_Info: "This expense can no longer be edited.",
    NoUsersWithoutEmployee: "There are no users without employee.",
    NowEmployerApprove: "Now your employer needs to approve it and then schedule it for payment.",
    NowExpensesBookkeep: "Now the expense needs to be recorded and scheduled for payment.",
    Onboarding_EmptyState_NotAdmin_CompanyOnboarded_message: "Oops, it looks like expenses has not been activated for your user. Please contact your administrator to enable it.",
    Onboarding_EmptyState_NotAdmin_message: "Oops, it looks like expenses has not been activated for your company. Please contact your administrator to enable it.",
    Onboarding_ForWho_Heading: "Which companies should use Expense claims?",
    Onboarding_ForWho_Who: "Expense claims in Bokio is for all companies with employees or where multiple persons might do purschases for the company.",
    Onboarding_ForWho_WhoNot: "Sole traders usually don't need to use Expense claims. If you are the owner the only one making purchases for the company it's easier to handle this in the regular bookkeeping flow.",
    Onboarding_GetStarted_Heading: "How to get started?",
    Onboarding_GetStarted_Text: "The first thing you will have to do is to setup who can do expense claims and who can administrate them. Click the button below to go there are and get started.",
    Onboarding_Intro: "When an employee make a purchase with their own money there has traditionally been a lot of boring work both for the employee and the admin to handle this. With Bokios Expense claims the employee can just snap a photo of the receipt on the go, fill in some quick info on the purchase and then they are done. The admin then get these expense claims organized and ready to record. After that they can quickly reimburse all employees with a single button click.",
    Paid: "Paid",
    PaymentMehtod: "Automatic payment",
    PaymentScheduled_status: "Payment scheduled",
    ReadingReceipt_loading: "Reading receipt",
    RegisterAndBookkeep_action: "Register and record",
    RegisterBookkeepPayment: "Register and record payment",
    RegisterPayment_action: "Record payment",
    Rejected_status: "Rejected",
    Schedule: "Schedule",
    ScheduledPaymentDate_DatePickerLabel: "Scheduled payment date",
    ScheduledPayments_Description: "These payments have to be paid by you manually.",
    ScheduledPayments_Heading: "Upcoming manual payments",
    ScheduleForPayment_Manual_Action: "Schedule {0} for manual payment",
    ScheduleForPayment_Teller_Action: "Pay {0} with Bokio Business Account",
    SeePaidOrRejected: "expenses to see paid or unapproved expenses.",
    SendInvites_action: "Send {0} invites",
    SettingsPage_MinDate_Label: "Earliest date to record expenses on",
    SettingsPage_Title: "Expense settings",
    SpecifyEmailBeforeInviting: "Specify email",
    StartUsingExpenses_heading: "Expense claims - Simplify employee purchases",
    Status: "Status",
    SubmitExpense_action: "Submit expense",
    SubmitExpense_editAction: "Edit expense",
    SubmitForBookkeeping_action: "Submit for accounting",
    SuccessEditExpense: "You have successfully edited an expense!",
    SuccessExpense: "You have succesfully submitted an expense!",
    TellerScheduledPayments_Description: "These are scheduled to be paid with Bokio Business Account.",
    TellerScheduledPayments_Heading: "Upcoming automatic payments",
    temp: "test",
    Today: "Today",
    TotalPayment: "Total payment",
    TotalSum: "Total sum",
    Unattended_status: "Awaiting approval",
    UndoBookkeeping_action: "Undo accounting",
    UndoExpensePayment_MovedToOtherTab_text: "It will be moved to the {0} section under the {1} tab.",
    Upload_FileTypeRestrictions: "(.jpeg .png .pdf only)",
    Upload_Instruction_Desktop: "Drag an image here or click to browse for a file",
    Upload_Instruction_Mobile: "Tap here to take a photo or upload a receipt",
    Upload_PdfFileTooLarge: "The file you are trying to upload is too large. We currently only support pdf files up to 4 MiB",
    Upload_ProcessingImage: "Processing image",
    Upload_UploadingExpense: "Uploading expense",
    UploadAnother: "Upload another",
    UploadExpense_action: "Upload expense",
    UploadFirstExpense_action: "Upload first expense",
    UserAccount: "User Account",
    Users_Unconnected_Info: "The following users are connected to the company but not to any user. If you want to enable Expense claims for these you can either create a new employee and connect these. Or if the employee is already in the list above you can click on that to connect these.",
    UsersWithoutEmployees: "Users Without Employees",
    ViewReceipt_action: "View receipt",
    WaitingToAttestBookeep_action: "Open in Things to do",
    WaitingToAttestBookeep_info: "{0} expense claims are waiting to be attested or recorded.",
    WhenThereAreExpenses: "When there are expenses to be scheduled and paid they’ll end up here.",
    XOfNClaimsSelected: "{0} of {1} claims selected",
    YesDelete_action: "Yes, delete",
    YoureAboutToRegisterBookkeep_text: "You’re about to register and record a payment of {0} for {1}.",
  };
}