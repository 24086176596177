/* eslint-disable */

import type { GeneralLang } from "../types/GeneralLang";
import { translateTempImpl } from "../TranslatableLanguage";

export function GeneralLangDef(): GeneralLang {
  return {
    translateTemp: (key: string, neutral: string, svSE?: string, enGB?: string) => translateTempImpl(key, neutral, svSE, enGB),
    
    _OR: "or",
    Accept: "Accept",
    Account: "Account",
    AccountedPage_ShowVerifications_label: "Showing {0} of {1} journal entries",
    AccountedPage_Sum_Tooltip: "The sum is the total of the credit/debit",
    AccountingApplication: "Accounting",
    AccountingCheck_CheckVerifications_Intro: "This reports helps you find faulty bookkeeping by looking att the different patterns of verifications the company have.",
    AccountingCheck_CheckVerifications_Title: "Check for faulty verifications",
    AccountingCheck_LedgerReconcile_Intro: "This reports helps you to quickly find any day the ledger doesn't match an external list, like a bank or tax account",
    AccountingCheck_LedgerReconcile_SelectionTitle: "Reconcile account {0} from {1} to {2}",
    AccountingCheck_LedgerReconcile_Title: "Find bookkeeping errors",
    AccountingCheck_Title: "Check accounting",
    Accounts: "Accounts",
    AccountSelector_Placeholder: "Number or name",
    AccountSync_ManualBookkeepingWarning: "We strongly advise that you only record on synced accounts from {Transactions to record}.",
    AccountSync_ManualBookkeepingWarning_Title: "Recording on a synced account",
    Action_Heading: "Action",
    Actions: "Actions",
    Activate: "Activate",
    ActivationFailed: "Activation failed",
    Active: "Active",
    Add: "Add",
    Add_Account: "Add account",
    AddMaterial: "Add material",
    AddNew: "Add new",
    AddNewRow: "Add new row",
    Address_City: "City",
    Address_Co: "C/o",
    Address_Country: "Country",
    Address_PostalCode: "Postcode",
    Address_Street: "Address line 1",
    Address_Street2: "Address line 2",
    AddrPosSetField: "Address position",
    AdvancedSettings: "Advanced settings",
    All: "all",
    AllItems: "All items",
    AllowBypassLabel: "I understand the risks and want to record anyway",
    AllRemoved: "All removed",
    AllTypes: "All types",
    Amount: "Amount",
    AmountExclVAT: "Amount (Excl. VAT)",
    AmountIn_Currency: "Amount in {currency}",
    AmountInclVAT: "Amount (Incl. VAT)",
    AmountWithLocalCurrency: "£{0}",
    And: "and",
    ApartmentNumber: "Apartment number",
    Apply: "Apply",
    ApplyFilters: "Apply filters",
    April: "April",
    AreYouSureDelete: "Are you sure you want to delete this?",
    AreYouSureYouWantToContinue: "Are you sure you want to continue?",
    Ascending_label: "Ascending",
    Assets: "Assets",
    Assigned_accountants: "Assigned accountants",
    August: "August",
    AuthenticateCompanyError: "Unable to verify your company. Please try again later.",
    AwaitingApproval: "Awaiting approval",
    Back: "Back",
    BankAccount: "Bank Account",
    BankDemoData_Completed_Title: "Test transactions generated",
    BankDemoData_CompletedCta: "Open To do list",
    BankDemoData_CompletedText: "17 rows have been added. This is how simple it will be for you if you connect your bank to Bokio. You can now record your test transactions by going to the To do page",
    BankDemoData_CTA: "Generate test transactions",
    BankDemoData_Text: "You can quickly test how accounting works in Bokio by generating test transactions that you can record. The transaction will be added to account 1930. If you want to avoid importing your transactions manually in the future, you can connect your bank to Bokio. With bank connection, the transactions are automatically synchronised daily from your bank.",
    BankDemoData_Title: "Explore accounting by generating some test transactions",
    BankDetails: "Bank details",
    BankID: "BankID",
    BankID_Footer: "{Read more about BankID} {or} {cancel}",
    BankId_NotOpening: "BankID not opening?",
    BankId_Open: "Open BankID",
    BankID_OpenOnOtherDevice: "Open BankID on other device",
    BankID_OpenOnThisDevice: "Open BankID on this device",
    BankID_Start: "Start your Mobile BankID",
    BankID_Trouble: "Having trouble logging in with Mobile BankID?",
    BankId_UserMessage_NotSupportedByUserApp: "Update your BankID client",
    BankId_UserMessage_RFA1: "Start your BankID app.",
    BankId_UserMessage_RFA1_QR: "1. Open the BankID app on another device\n1. Tap the **Scan QR Code** button\n1. Point the camera at the QR code below",
    BankId_UserMessage_RFA13: "Trying to start your BankID app.",
    BankId_UserMessage_RFA15A: "Searching for BankID:s, it may take a little while …\nIf a few seconds have passed and still no BankID has been found, you probably don’t have a BankID which can be used for this identification/signing on this computer. If you have a BankID card, please insert it into your card reader. If you don’t have a BankID you can get one from your bank.",
    BankId_UserMessage_RFA15B: "Searching for BankID, it may take a little while …\nIf a few seconds have passed and still no BankID has been found, you probably don’t have a BankID which can be used for this identification/signing on this device. If you don’t have a BankID you can get one from your bank.",
    BankId_UserMessage_RFA16: "The BankID you are trying to use is blocked or too old. Please use another BankID or get a new one from your bank.",
    BankId_UserMessage_RFA17A: "The BankID app couldn’t be found on your computer or mobile device. Please install it and get a BankID from your bank. Install the app from your app store or [https://install.bankid.com](https://install.bankid.com).",
    BankId_UserMessage_RFA17B: "Failed to scan the QR code. Start the BankID app and scan the QR code. Check that the BankID app is up to date. If you don't have the BankID app, you need to install it and get a BankID from your bank. Install the app from your app store or [https://install.bankid.com](https://install.bankid.com).",
    BankId_UserMessage_RFA21: "Identification or signing in progress.",
    BankId_UserMessage_RFA22: "Unknown error. Please try again.",
    BankId_UserMessage_RFA3: "Action cancelled. **Please try again.**",
    BankId_UserMessage_RFA6: "Action cancelled.",
    BankId_UserMessage_RFA8: "The BankID app is not responding. Please check that it’s started and that you have internet access. If you don’t have a valid BankID you can get one from your bank. **Try again.**",
    BankId_UserMessage_RFA9: "Enter your security code in the BankID app and select Identify or Sign.",
    BankRulesRating_Feedback_Description: "Any thoughts that could help us improve your experience using bank rules?",
    BankRulesRating_Rate_Title: "How was your experience using bank rules?",
    BankTransaction: "Bank transaction",
    BBA_AddPaymentsOrTransfers: "Any payments or transfers that can be signed will show up here and you can sign all payments together",
    BBA_AvailableBalance: "Available balance",
    BBA_CreatePaymentOrTransfer: "Create new payment / transfers",
    BBA_NoPaymentsOrTransfers: "No payments or transfers to sign",
    BBA_PaymentsToSign: "Payments to sign",
    BBA_TopBarButton_NoticePopoverContent_QuickAccess: "Now you can quickly access transactions, payments and sign payments from here.",
    BBA_ViewPayments: "View payments",
    BBA_ViewTransactions: "View transactions",
    Beta: "Beta",
    BetaFeedback: "Beta feedback",
    Billing: "Billing",
    Billing_CurrentPeriod_Description: "Based on your current utilization. All costs for this period will be billed on {0}. Total sum might change. Billing details are updated nightly",
    Billing_CurrentPeriod_Heading: "Draft invoice for current period",
    Billing_FullPriceList: "See full price list here",
    Billing_FullPriceList_Link: "https://www.bokio.co.uk/pricing/",
    Billing_InvoiceDetails_Heading: "Your invoice details",
    Billing_Invoices_EmptyState: "When you sign up for paid services in Bokio, your invoices will end up here.",
    Billing_Item: "Item",
    Billing_OverdueError_Description: "We have not recieved any payment for the invoice and it is now overdue. Please pay now to make sure your services are not cancelled.",
    Billing_OverdueError_Heading: "The invoice for {0} (#{1}) is overdue",
    Billing_PreviousInvoices_Heading: "Previous invoices",
    Billing_Receipt_PdfName: "Bokio_Receipt_{0}.pdf",
    Billing_Receipts: "Receipts",
    Billing_ReceiptsHistory_Heading: "Receipts history",
    Billing_Reports_FromAndIncluding: "From",
    Billing_Reports_Heading: "Preliminary functionality - Billing reports",
    Billing_Reports_Subtitle: "Get reports for for billed costs.",
    Billing_Reports_Tab: "Reports",
    Billing_Settings: "Billing Settings",
    Billing_Settings_AddPaymentMethod: "Add payment method",
    Billing_Settings_NotACustomer: "Here you will be able to change payment methods after the company has signed up for paid services in Bokio.",
    Billing_Settings_UpdatePaymentMethod: "Update payment method",
    Billing_Settings_UpdatePaymentMethodNotice: "When updating your card details a payment attempt will be performed on any unpaid invoices",
    Billing_Status_Credited: "Credited",
    Billing_Status_CurrentPeriod: "Current period",
    Billing_Status_NoInvoice: "No invoice",
    Billing_Status_Overdue: "Overdue",
    Billing_Status_Paid: "Paid",
    Billing_Status_Pending: "Pending",
    Billing_Status_ReadyToPay: "Ready to pay",
    Billing_TypeName_Default: "Bokio Receipt",
    Billing_TypeName_EarlyBird: "Price plan purchase",
    Billing_TypeName_MonthlyInvoice: "Bokio Receipt",
    Billing_TypeName_PlanPurchase: "Price plan purchase",
    Billing_TypeName_PlanUpgrade: "Price plan upgrade",
    Billing_TypeName_Refund: "Refund",
    Billing_Voucher_Success_CTA: "Pick a plan",
    Billing_Voucher_Success_Heading: "The discount {0} was activated!",
    Billing_Voucher_Success_Text: "Complete the plan purchase before {0} to apply the discount.",
    Billing_Voucher_Title: "Activate discount",
    Billing_YourInvoiceFor: "Your invoice for {0}",
    Blocked: "Blocked",
    BokioEntrepreneurs_description: "Bokio entrepreneurs help each other",
    BokioEntrepreneurs_label: "Facebook group",
    BokioFacebookCommunityUrl: "https://www.facebook.com/groups/221717978755079/",
    BokioHelpBaseUrl: "https://www.bokio.co.uk/help",
    BokioPartner_description: "We will refer you to one of our accounting partners",
    BokioPartner_label: "Find an accountant",
    BokioPartnerProgrammeUrl: "https://www.bokio.co.uk/bokio-partner-programme/",
    BokioPartnerUrl: "https://www.bokio.co.uk/find-an-accountant/",
    BokioPlan_Name_Balance: "Balance",
    BokioPlan_Name_Business: "Business",
    BokioPlan_Name_Premium: "Premium",
    BokioPlan_Name_PremiumPlus: "Premium Plus",
    BokioPlan_Offer_Status: "Offer",
    BokioPlan_SelectPlan: "View plans",
    BokioPlanLockBadge_PlaidBankFeed_Tooltip: "You can only connect one account on our {Free} plan. You need the {Balance} or {Business} plan to connect multiple accounts.",
    BokioPlanLockBadge_PlaidBankFeed_Tooltip_Trial: "After the trial period expires, you can only connect one account on our {Free} plan. You need the {Balance} or {Business} plan to connect multiple accounts.",
    BokioPlanLockBadge_Text_RequiresPlanX: "Requires the {0} plan",
    BokioPlanLockBadge_Text_Trial: "Free during your trial",
    BokioPlanLockBadge_ToolTip_NoPermission: "Your administrator needs to upgrade the plan.",
    BokioPlanLockBadge_ToolTip_RequiresPlanX_Locked: "This feature requires the {0} plan.",
    BokioPlanLockBadge_ToolTip_RequiresPlanX_Trial: "This feature requires the {0} plan after your trial ends.",
    BokioPlanLockBadge_ToolTip_RequiresPlanX_WithFeatureName_Trial: "{0} requires the {1} plan after your trial ends.",
    BokioPlanLockPromotionBanner_InvoiceReportText: "Upgrade your plan to {0} to view invoice reports.",
    BokioPlanLockPromotionBanner_InvoiceReportText_Trial: "Upgrade your plan to {0} to access invoice reports after the trial period ends.",
    BokioPlanLockPromotionBanner_QuoteText: "Upgrade your plan to {0} to publish new quotes.",
    BokioPlanLockPromotionBanner_QuoteText_Trial: "Upgrade your plan to {0} to publish new quotes after the trial periods ends.",
    BokioPlanLockPromotionBanner_RecurringInvoice: "Upgrade your plan to {0} to use recurring invoices.",
    BokioPlanLockPromotionBanner_RecurringInvoice_Trial: "Upgrade your plan to {0} to use recurring invoices when trial periods ends.",
    BokioPlanLockPromotionBanner_RotRutText: "Upgrade your plan to {0} to publish invoices and quotes with Rot/Rut.",
    BokioPlanLockPromotionBanner_RotRutText_Trial: "Upgrade your plan to {0} to publish invoices and quotes with Rot/Rut after the trial periods ends.",
    BokioPlanLockPromotionBanner_StripeText: "Upgrade your plan to {0} to get card payments by stripe.",
    BokioPlanLockPromotionBanner_StripeText_Trial: "Upgrade your plan to {0} to get card payments by stripe after the trial periods ends.",
    BokioPlanLockPromotionBanner_ZettleIntegrationText: "Upgrade your plan to {0} to get automatic recording from Zettle.",
    BokioPlanLockPromotionBanner_ZettleIntegrationText_Trial: "Upgrade your plan to {0} to keep automatic recording from Zettle after trial periods ends.",
    BokioSupportMail: "support@bokio.co.uk",
    Bookkeep_BookkeepTaxes: "Record taxes",
    Bookkeep_NoTaxes: "There was no taxes for this period so we will not generate any verifications. Click on record transaction to complete the period.",
    Bookkeeping: "Bookkeeping",
    BookkeepingRating_Feedback_Description: "Any thoughts that could help us improve your bookkeeping experience?",
    BookkeepingRating_Feedback_Placeholder: "Write your feedback here",
    BookkeepingRating_Rate_Title: "How was your bookkeeping experience?",
    BrowserNotSupported: "Your browser or version of browser is not supported by Bokio. Please upgrade to a newer version or use another [supported browser](bokio-help:/BrowserSupport).",
    Campaign: "Campaign",
    Cancel: "Cancel",
    CancelPlanConfirmationModal_ActiveBbaAccount: "You cannot cancel your plan while you have an active Bokio Business Account. ",
    CancelPlanConfirmationModal_ActiveBbaOnboarding_link: "To cancel your subscription, please cancel your [pending Bokio Business Account application]({0}).",
    CancelPlanConfirmationModal_CancelCancellation: "No, keep plan",
    CancelPlanConfirmationModal_CancelPlan: "Cancel plan",
    CancelPlanConfirmationModal_ConfirmCancellation: "Yes, confirm cancellation",
    CancelPlanConfirmationModal_DeleteYourBBA: "Delete your Bokio Business Account here.",
    CancelPlanConfirmationModal_DownloadData: "You can download your data here.",
    CancelPlanConfirmationModal_ModalContent: "Are you sure you want to cancel your plan now? Your plan will be valid through {0} and after that you will no longer have access to any of Bokios services or your data with this company. ",
    Card: "Card",
    CashbackOverview_Heading: "Status for cashback",
    CashbackOverview_HideBanner: "Hide cashback",
    CashbackOverview_PremiumText: "You would have earned {0} over the past 30 days if you had activated cashback by upgrading to Premium Plus.",
    CashbackOverview_ShowBanner: "Show cashback",
    Category: "Category",
    CESRating_Complete_Description: "If you have additional feedback or questions you are welcome to contact us at [support@bokio.co.uk](mailto:support@bokio.co.uk).",
    CESRating_Complete_Title: "Thank you for the feedback!",
    CESRating_Feedback_Title: "Give us feedback",
    Change: "Change",
    Changed: "Changed",
    ChangeLanguage_action: "Change language",
    ChangeRegion: "Change region",
    ChartOfAccounts_Custom: "Custom",
    ChartOfAccounts_CustomName: "Custom name",
    Checkout_BokioStartFreeMonths: "({0} free months)",
    Checkout_Discount: "Discount {0}",
    ChooseAnotherCompany: "Choose another company",
    ChooseMatch: "Choose match",
    ChooseNewPassword: "Choose new password",
    ChooseOne: "Choose one",
    ChoosePassword: "Choose password",
    Claims_AccountingSettings: "Accounting settings",
    Claims_AdminUsers: "Users",
    Claims_Attest: "Approve",
    Claims_Bookkeep: "Record",
    Claims_Closures: "Year-end closures",
    Claims_CompanySettings: "Company settings",
    Claims_Description_AccountingSettings: "The user can change the accounting settings",
    Claims_Description_AdminUsers: "Allow the user to manage the members of the organisation",
    Claims_Description_Attest: "Allows the user to approve receipts",
    Claims_Description_Bookkeep: "Allow the user to record",
    Claims_Description_Closures: "Allows the user to see and create year-end closures",
    Claims_Description_CompanySettings: "The user can change the company settings",
    Claims_Description_Disbursements: "The user can schedule expense reimbursements",
    Claims_Description_ImportantDates: "Allow the user to manage important dates",
    Claims_Description_Inbox: "The user can change settings for whom may submit receipts",
    Claims_Description_Invoices: "Allow the user to see, create and send invoices and quotes",
    Claims_Description_InvoiceSettings: "The user can change invoice and quote settings",
    Claims_Description_Reports: "Allows the user to see reports",
    Claims_Description_Salaries: "Allows the user to see and create salaries and salary reports",
    Claims_Description_SalarySettings: "The user can change salary settings",
    Claims_Description_SupplierInvoices: "The user can manage suppliers and supplier invoices",
    Claims_Description_UploadBank: "Allows the user to perform imports from bank",
    Claims_Description_UploadExpense: "Allow the user to upload expenses",
    Claims_Description_UploadReceipt: "Allow the user to upload receipts",
    Claims_Description_ViewBookkeeping: "The user can see details about the accounting",
    Claims_Disbursements: "Payments",
    Claims_HasUser: "Viewer",
    Claims_ImportantDates: "Important dates",
    Claims_Inbox: "Inbox",
    Claims_Invoices: "Invoices and quotes",
    Claims_InvoiceSettings: "Invoice and quote settings",
    Claims_Reports: "Reports",
    Claims_Salaries: "Salaries",
    Claims_SalarySettings: "Salary settings",
    Claims_SupplierInvoices: "Supplier invoices",
    Claims_UploadBank: "Upload from bank",
    Claims_UploadExpense: "Upload",
    Claims_UploadReceipt: "Upload receipts",
    Claims_ViewBookkeeping: "View accounting",
    Clear: "Clear",
    ClearFilters: "Clear filters",
    ClearingNumber: "Sort code",
    Close: "Close",
    Closed: "Closed",
    Code: "Code",
    ComingSoon: "Coming soon",
    CommandPalette_SwitchCompany: "Switch company",
    CommandSearch_GoTo_Prefix: "Go to",
    CommandSearch_NoSearchResultFound: "Nothing found...",
    CommandSearch_SearchInvoices: "Search for invoices",
    CommandSearch_SearchSupplierInvoices: "Search for supplier invoices",
    Comments_1Comment: "1 Comment",
    Comments_AddNewComment: "Add new comment",
    Comments_AddYourCommentWithMention: "Type your comment here, use @ to mention",
    Comments_ManyComments: "{0} Comments",
    Comments_ShowNewestFirst: "Show newest first",
    Comments_ShowOldestFirst: "Show oldest first",
    Company: "Company",
    CompanyAccessDeniedPage_Content: "You don't have access to display this page.\nEither there isn't a company on this web address or you don't have access to the specific company.",
    CompanyChoose_Heading: "Choose company",
    CompanyCommandPalette_BookkeepWithTemplate: "Template to record",
    CompanyCommandPalette_CaptionInstruction: "'Enter' chooses highlighted action ('Ctrl'/'Cmd' + 'Enter' to open in new tab). Press 'Esc' to cancel.",
    CompanyCommandPalette_ManualBookkeeping: "Manual recording",
    CompanyCommandPalette_SearchActions: "Search commands",
    CompanyCommandPalette_SearchInvoices: "Invoice search",
    CompanyCommandPalette_SearchSupplierInvoices: "Supplier invoice search",
    CompanyDataStorageSettingsNotice_Cta: "Manage storage of data",
    CompanyDataStorageSettingsNotice_Message: "With our storage service, you have the opportunity to access your data if you subscribe at a later stage. If you want to store your data with Bokio, you must accept the terms of the storage service by October 1, 2024 at the latest. In case you do not subscribe or choose to store your data, it will be permanently deleted after October 1.",
    CompanyDataStorageSettingsNotice_Title: "Do you want to store your data in Bokio?",
    CompanyDetails: "Company details",
    CompanyInformation: "Company information",
    CompanyList_CreateATestCompany: "Create a test company",
    CompanyList_SearchField_NoResults: "No results for \"{0}\"",
    CompanyList_SearchField_Placeholder: "Search companies",
    CompanyName: "Business/trading name",
    CompanySearchFetch: "Get company info",
    CompanyStartedAt: "Company started at",
    CompanyType: "Business type",
    CompanyUserRole_AccountOwner_Label: "Company owner",
    CompanyUserRole_ChangeOwner_ButtonText: "Change owner",
    CompanyUserRole_ChangeOwner_ModalHeading: "Change company owner",
    CompanyUserRole_ChangeWarning_Heading: "Warning!",
    CompanyUserRole_ChangeWarning_Text: "Only the company owner can change the owner. Once you have changed the owner only the new owner can change the ownership.",
    CompanyUserRole_CurrentOwnerIs_Label: "The company is owned by",
    CompanyUserRole_NoCurrentOwner_Label: "No user is registered as owner",
    CompanyVertical: "Industry",
    Completed_Heading: "Completed",
    Confirm: "Confirm",
    ConfirmAndComplete: "Confirm and complete",
    ConfirmAndDelete: "Confirm and delete",
    ConfirmChanges: "Confirm changes",
    ConfirmDeleteCommentMessage: "Are you sure you want to delete this comment?",
    ConfirmDeleteTaskMessage: "Are you sure you want to delete this task?",
    ConfirmDeleteX: "Delete {0}?",
    ConfirmDeleteX_Title: "Confirm delete {0}",
    ConfirmOrder: "Confirm order",
    ConfirmPassword: "Confirm password",
    ConfirmSubscription: "Confirm subscription",
    ConfirmYourDetails: "Confirm your details",
    Congratulations: "Congratulations!",
    Connect_another: "Connect another",
    ContactAdministratorToFixProblem: "You don't have permission to change this. Please contact your company administrator.",
    ContactInformation: "Contact information",
    ContactPerson: "Contact person",
    ContactSupport_action: "Contact support",
    ContactSupportAgency: "Practice contact support",
    Continue: "Continue",
    CookieWarning: "We are storing information on your device that is required for Bokio to work. Read more in our [Cookie policy](https://www.bokio.co.uk/legal-privacy/cookie-policy/). We would also like to store marketing and analytics cookies to help us make Bokio better. Can we do that?",
    CookieWarning_Accept: "Yes, allow all cookies",
    Copied_label: "Copied",
    Copy: "Copy",
    CopyAndPasteExactFormat: "Copy and paste (exact format)",
    CorrectPersonalNumber: "and be a correct/valid personal number",
    CountrySubdivision: "Country",
    Create_Custom_Todo: "Create a new task",
    create_test_company: "Create test company",
    CreateCompany: "Create new company",
    Created: "Created",
    CreatedBy: "Created by",
    CreatedOn: "Created on",
    CreatedOnX: "Created on {0}",
    CreateInvoice: "Create invoice",
    CreateNew: "Create new",
    Credit: "Credit",
    Csv_ExportAll_Button: "Export all",
    Currency: "Currency",
    CurrentChoicePostFix: " (current)",
    CurrentFiscalYear: "Current fiscal year",
    CurrentPassword: "Current password",
    Custom_Selection: "Custom selection",
    Custom_Todo_DueDate: "Due date (optional)",
    Custom_Todo_Info_Message: "Do not include any sensitive information in the task",
    CustomerNumber: "Customer number",
    CustomPeriod: "Custom interval",
    CustomTodo_Completed_EmptyState: "No completed tasks to show. ",
    CustomTodo_Edit: "Edit task",
    CustomTodo_EmptyState_Text: "Create your own reminders or other to-do items.",
    CustomTodo_EmptyState_Title: "No tasks to show",
    CustomTodos_CompletedTasks: "Completed tasks",
    DataSecurity: "Data security and legal",
    DataSecurityPageUrl: "https://www.bokio.co.uk/legal-privacy",
    Date: "Date",
    DateFilter_AllDates: "All dates",
    DateFilter_From: "From {0}",
    DateFilter_Range: "From {0} to {1}",
    DateFilter_Reset: "Clear dates",
    DateFilter_To: "To {0}",
    DateIntervalBetweenTwoDates: "{first date} to {end date}",
    DateRange_Custom_label: "Custom period",
    DateRange_Last12Months_label: "Last 12 months",
    DateRange_Last30Days_label: "Last 30 days",
    DateRange_Last4Months_label: "Last 4 months",
    DateRange_Last7Days_label: "Last 7 days",
    DateRange_LastMonth_label: "Last month",
    DateRange_LastWeek_label: "Last week",
    DateRange_LastXDays_label: "Last {0} days",
    day: "day",
    DayPicker_ShortcutDown_label: "Shortcut: down arrow",
    DayPicker_ShortcutUp_label: "Shortcut: up arrow",
    days: "days",
    DDR_Accumulated: "Total value",
    DDR_AddMetric: "Add metric",
    DDR_Children: "Show break down",
    DDR_Comparison: "Compare to",
    DDR_ComparisonLabel: "Previous",
    DDR_ExploreNextLevel: "Explore this in more detail",
    DDR_FailedToGetComparisonOptions: "Failed to load periods",
    DDR_Insights: "Get more insights",
    DDR_Level_AdministrationCosts: "Administration costs",
    DDR_Level_CostOfGoods: "Cost of goods",
    DDR_Level_Costs: "Costs",
    DDR_Level_DepreciationCosts: "Depreciations",
    DDR_Level_Income: "Incomes",
    DDR_Level_NetRevenue: "Net revenue",
    DDR_Level_OfficeCosts: "Office costs",
    DDR_Level_OtherCosts: "Other costs",
    DDR_Level_OtherIncome: "Other income",
    DDR_Level_Result: "Result",
    DDR_Level_SalaryCosts: "Salary costs",
    DDR_Level_SalesCosts: "Sales costs",
    DDR_LineChart: "Linechart",
    DDR_NoEarlierPeriods: "There are no earlier periods to compare to",
    DDR_NotAccumulated: "Period values",
    DDR_NotYearMonthOrQuarter: "You can only compare when you have a month, quarter or year selected",
    DDR_OtherLabel: "Include Other",
    DDR_PageTitle: "Result analysis",
    DDR_Period: "Period to analyse",
    DDR_RemoveMetric: "Remove metric",
    DDR_SelectTags: "Select tags to compare",
    DDR_Table: "Table",
    Debit: "Debit",
    December: "December",
    Decline: "Decline",
    Declined_Nekad: "Declined",
    DecreaseTrust: "Unsafe emails are not allowed",
    Delete: "Delete",
    DeleteAccount: "Delete account",
    DeleteSelected: "Delete selected",
    Deleting: "Deleting",
    Descending_label: "Descending",
    Description: "Description",
    DeselectAll: "Deselect all",
    Destroyed: "Destroyed",
    Details: "Details",
    DirectorSetup_AccountsOfficeReferenceValidationError: "The Accounts Office Reference number isn't valid, it should be in the format 123AB12345678, please double check and enter the correct Accounts Office Reference",
    DirectorSetup_EmployerPayeValidationError: "The Employer PAYE Reference number isn't valid, it should be in the format 123/A45678, please double check and enter the correct Employer PAYE Reference",
    DirectorSetup_NINOValidationError: "The National Insurance number isn't valid, it should be in the format AB123456C, please double check and enter the correct National Insurance number",
    DirectorSetup_TaxCodeNotSupportedError: "We currently do not support this Tax code, so you will need to use a different software",
    DirectorSetup_TaxCodeValidationError: "The tax code isn't valid, please double check and enter the correct tax code",
    DirectorsPayrollRating_Feedback_Description: "Any thoughts that could help us improve your director's payroll experience?",
    DirectorsPayrollRating_Rate_Title: "How was your director's payroll experience?",
    Dismiss: "Dismiss",
    DismissTodoItemFeedback_TextInput_Title: "Why was this todo item not interesting for you? (optional)",
    DoesNotMatch: "Doesn't match",
    Done: "Done",
    Download: "Download",
    Download_Excel: "Export to Excel",
    DownloadBankgiroFile: "Get Bankgiro file",
    DownloadData: "Download data",
    DownloadPdf: "Download pdf",
    DownloadSelected: "Download selected",
    Draft: "Draft",
    DraftVerification_Todo_SectionTitle: "Scheduled journal entries",
    DraftVerification_TodoPreview_Action: "Preview",
    DueDate: "Due date",
    ECommerce: "Ecommerce",
    Edit: "Edit",
    EditDescription_Placeholder: "Type your description here",
    EditInCompanySettings: "Edit in company settings",
    EditMore: "Edit more",
    EditUploadModal_SaveAndCreateSupplierInvoice_Action: "Save and create supplier invoice",
    EditUploadModal_SaveAndRecord_Action: "Save and record",
    EditUploadModal_SaveAndShowVerification_Action: "Save and view journal entry ",
    EInvoice_VerifyCompany: "Verify your company",
    EInvoice_VerifyCompanyMessage: "You need to verify your company with BankID before you register for e-invoice",
    Email: "Email",
    Email_Bounced: "Could not deliver",
    Email_Clicked: "Clicked",
    Email_Delivered: "Delivered",
    Email_Opened: "Email opened",
    Email_Sent: "Sent",
    Enddate: "End date",
    EnterEmail: "Enter email",
    EntityType: "Entity type",
    EntityType_Organization: "Organisation",
    EntityType_PrivatePerson: "Consumer",
    EquityAndLiabilities: "Equity and liabilities",
    Error_General: "An error occurred please contact &lt;a href=\"mailto:support@bokio.co.uk\"&gt;support@bokio.co.uk&lt;/a&gt;",
    Error_Notification_Reference: "Reference: ",
    ErrorCode_InMessageToSupport: "Please let this error code be included in the message: ",
    ErrorMessage_GenericWithErrorCode: "Something went wrong. Please try again. If the problem persists, you can {0} and include this error code: {1} in your message.",
    ErrorMessage_GenericWithErrorCode_ContactSupportPart: "contact our support",
    ErrorPage_CTA_Reload: "Take me home",
    ErrorPage_Footer: "Still having problems? Contact our {support team}",
    ErrorPage_Message: "We encountered a problem with this page.\n\nThe issue has been logged and our developers are working on a solution.",
    ErrorPage_Title: "Oh no! We are terribly sorry!",
    Etc_abbreviation: "etc.",
    ExcelFileAndFileEnding: "Excel file (.xlsx)",
    ExchangeRate: "Exchange rate",
    ExpenseClaims: "Expense claims",
    Expired: "Expired",
    Export: "Export",
    ExportAll_Articles_Button: "Export all items",
    ExportAll_Customers_Button: "Export all customers",
    ExportAll_Employees_Button: "Export all employees",
    ExportAll_Invoices_Button: "Export all invoices",
    Failed: "Failed",
    FailedPayment_Bar_Text: "There are failed subscription payments, [see more details]({0}). This should be fixed to make sure the company isn't blocked.",
    FBE_AccumulatedDifference: "Accumulated difference",
    FBE_DayDifference: "Day difference",
    FBE_Excel_Info: "The .xlsx file must have separate columns for the date, description and amount.",
    FBE_HideGoodDays: "Hide days without a difference",
    FBE_InfoText: "For instructions see {0}. This is an early version and we appreciate both positive and negative {1} so we can help you find any errors quicker.",
    FBE_Period_BookkkeepingAccount_Label: "Bookkeeping account to reconcile",
    FBE_Period_ToReview_Info: "The period you select in this step needs to match the period of the comparison data that you will select in the next step. For example, if you export an excel file from your bank for the period 15th of March to 31st of March that should be the period you select in this step.",
    FBE_Period_ToReview_Title: "Period to reconcile",
    FBE_Promotion: "Struggling to reconcile your bookkeeping? Try our new function to find bookkeeping errors. See \"Find bookkeeping errors\" in the \"...\" menu to try it out.",
    FBE_Status_LooksGood: "Looks good",
    FBE_Status_NeedsReview: "Needs review",
    FBE_Summary_Days: "{0} days",
    FBE_Summary_Error_Description: "You should now look through all days marked as 'Needs review', to see if journal entries need to be added, removed or edited. The specific day of journal entries may not always agree, so you can use the Accumulative difference to see if the total journal entries to date agree.",
    FBE_Summary_Error_Title: "We found days where your bookkeeping doesn't match the input",
    FBE_Summary_Success_Description: "This is a good indicator that you recorded all events for this account.",
    FBE_Summary_Success_Title: "All days match the input! Good work!",
    FBE_TransactionFromAccount: "Journal entries",
    FBE_TransactionFromInput: "Transactions from input",
    FBE_ViewExpense: "View expense",
    FBE_ViewExpensePayment: "View payment",
    FBE_ViewSupplierInvoice: "View supplier invoice",
    FeatureFeedbackText: "We would love to get your feedback so we can make this feature even better.",
    FeatureInterestInterestedFeedbackModal_HelpUsGetBetterAboutX_Description: "We always love to hear your feedback! If you want to share your thoughts about {0}, you are welcome to let us know so we can create a software that meets your needs.",
    FeatureInterestInterestedFeedbackModal_ThankYouForShowingInterextInX_Description_1: "These features aren’t available yet. We’re working on being able to offer {0} later this year.",
    FeatureInterestInterestedFeedbackModal_ThankYouForShowingInterextInX_Description_2: "Thanks to your participation we have more information about how we should design Bokio in the future. As a thank you, we promise to contact you when {0} is released so you will be first to get the new features.",
    FeatureInterestInterestedFeedbackModal_ThankYouForShowingInterextInX_Header: "Thank you for showing interest in {0}",
    FeatureToggle_ActivateNow_Action: "Activate now",
    FeatureToggle_VacationTracking_Name: "Vacation balance tracking",
    FeatureToggles_AskingFeedback: "Please provide us with more details on why you want to disable {0}?",
    FeatureToggles_CantDisable: "This feature can't be disabled without contacting suppport",
    FeatureToggles_Disable: "Deactivate",
    FeatureToggles_DisableFeature: "Disable {0}?",
    FeatureToggles_Enable: "Activate",
    FeatureToggles_Enabled: "Activated",
    FeatureToggles_FeatureIsDependantOfOtherFeatures: "Other preview features have to be activated before this feature can be activated",
    FeatureToggles_Title: "Preview features",
    FeatureToggles_TryOutOtherNewFeatures_button: "Try out our other {preview features}",
    February: "February",
    Feedback: "Feedback",
    FieldCanNotEmpty: "{name} can not be empty",
    FieldMustBeInteger: "Must be a whole number",
    FieldMustBeNumber: "Must be a valid number",
    FieldMustBePositiveInteger: "Must be a positive whole number",
    FieldMustContainNoExternalLink: "External links are not allowed in the {0}",
    FieldNotCorrectFormat: "{name} is not in a correct format",
    FieldNumberFromZeroToHundred: "{0} has to be between 0 and 100",
    FileName: "File name",
    Files_Choose: "Choose files",
    FilesTooLarge: "These files are too large.",
    FileType: "File type",
    Filter_ShowDecimals: "Show decimals",
    Filters_Filters_heading: "Filters",
    Filters_GroupVerifications_action: "Group by connections",
    Filters_ShowInactiveVerifications_action: "Show inactive",
    FindAgencyPage_ManualLink: "Add details manually",
    FindAgencyPage_PageDescription: "To import your practice details from Companies House, please enter the **organisation number or name of the practice** you are working with.",
    FindAgencyPage_PageTitle: "Find your practice",
    FindAgencyPage_SearchForOrgNumberOrName_Label: "Name or org number of practice",
    FindAgencyPage_SkipText: "Couldn’t find your practice? {0}",
    FindBookkeepingErrors_ComparisonText_Description: "Comparison data needs to be in a format like this",
    FindBookkeepingErrors_CopyPaste_FormatExample: "18/12/2020 2000.00 ExampleText\n19/12/2020 -100.00",
    FindBookkeepingErrors_Dates_Description: "Must be in British format (dd/MM/yyyy) or ISO format (yyyy-MM-dd)",
    FindBookkeepingErrors_Dates_Key: "Dates",
    FindBookkeepingErrors_FailedToParse_ErrorText: "Could not parse input",
    FindBookkeepingErrors_Numbers_Description: "Must be without spaces and commas. Use \".\" as a decimal separator. No decimals works too",
    FindBookkeepingErrors_Numbers_Key: "Numbers",
    FindBookkeepingErrors_TextField_Label: "Paste you comparison data",
    FindOutMore_action: "Find out more",
    FirstName: "First name",
    FirstNames: "First name(s)",
    FiscalYear: "Fiscal year",
    FixedAssets_Menu: "Fixed assets",
    FixNow: "Fix now",
    Forbidden: "Access denied",
    ForgotPassword: "Forgot password?",
    Free: "Free",
    Friday: "Friday",
    From: "From",
    Fullname: "Full name",
    FullYear: "Full year",
    GeneralAppError_Content: "Try again after some time or {contact support}.",
    GeneralAppError_Title: "Something went wrong",
    GeneralFeedback_WriteYourMessageHere_text: "Write your message here",
    GetStarted: "Get started",
    GiveFeedback: "Give feedback",
    GoBack: "Go back",
    GoToSettings: "Go to settings",
    GroupBy: "Group by",
    Help_Action: "Help",
    Help_ChooseACategory: "Choose a category",
    Help_ChooseAnArea: "Choose an area",
    Help_ChooseASubCategory: "Choose a sub-category",
    Help_DescribeWhat: "Describe what you need help with",
    Help_HowCanWeHelpYou: "How can we help you?",
    Help_Search: "Search",
    Help_SendingMessage: "Sending message...",
    Help_Type: "I want to",
    Help_Type_Feedback: "Give feedback",
    Help_Type_Question: "Ask a question",
    Help_Type_Technical: "Report an issue",
    Help_UploadAttachments: "Attach files in your message to us",
    help_us_to_improve: "Help us to improve",
    Help_WhatIsYourQuestion: "What do you want to contact as about?",
    HelpCenter_ArticleError: "We failed to load this help page",
    HelpCenter_label: "Help center",
    HelpConfirmationHeading: "Message sent!",
    HelpConfirmationText: "Thank you for contacting us! We will answer to your registered email address soon.",
    HelpEmptyResult1: "Sorry, we couldn't find any help items matching your search.",
    HelpEmptyResult2: "You can post a question on our {Facebook community help page} , or contact our support for more assistance.",
    HelpSendMessage: "Send message",
    HelpTitle: "What can we help you with?",
    Here: "here",
    Hi: "Hi",
    HiName: "Hi {0}",
    hour: "hour",
    hours: "hours",
    Ignore: "Ignore",
    IllegalExpression: "Illegal expression",
    Import: "Import",
    ImportantDate: "Important dates",
    ImportantDate_Action_HideCategory: "Hide all similar tasks",
    ImportantDate_Action_HideImportantDate: "Hide task",
    ImportantDate_Action_MarkAsDone: "Mark as done",
    ImportantDate_Action_MarkAsNotDone: "Mark as not done",
    ImportantDate_Action_SetInProgress: "In Progress",
    ImportantDate_Action_UndoInProgress: "Undo 'In Progress'",
    ImportantDate_Action_Unhide: "Unhide task",
    ImportantDate_Action_UnhideCategory: "Unhide all similar tasks",
    ImportantDate_Label_Completed: "Completed",
    ImportantDate_ReadMoreAtSkatteverket: "Read more",
    ImportantDate_RefersToPeriod: "Refers to period {0} to {1}",
    ImportantDates_Feedback_Title: "Do you have any feedback on important dates?",
    ImportantDates_Onboarding_CheckCompanyDetails_message: "We need you to check the following details for {0}",
    ImportantDates_Onboarding_heading: "You're one step closer to important dates!",
    ImportantDates_Onboarding_PleasecContactSupport_message: "If you believe these settings are already correct, then please contact support.",
    ImportantDates_Onboarding_UpdateCompanySettings: "Update company settings",
    ImportArticles: "Import items",
    ImportCustomers: "Import customers",
    ImportEmployees: "Import employees",
    Inactivate: "Inactivate",
    Inactive: "Inactive",
    Inbox: "Inbox",
    Inbox_Limitation_BannerText: "You need Balance or Business to be able to import new messages.",
    Included: "Included",
    IncreaseTrust: "Unsafe emails are allowed",
    informationNeededForCompany: "The following information is needed for this company before proceeding.",
    informationNeededForEmployee: "The following information is needed for this employee before proceeding.",
    InProgress: "In progress",
    InputField_FillInCharacters_placeholder: "Fill in {0} characters",
    InputField_FillInDigits_placeholder: "Fill in {0} digits",
    Integration_Singular: "Integration",
    Integrations_iZettle_Title: "Record sales automatically - {Zettle}",
    Integrations_Stripe_Description: "",
    Integrations_Stripe_Title: "",
    InvalidCode: "Invalid code",
    InvalidPassword: "Invalid password",
    InviteAndComplete: "Invite and complete",
    Invoice: "Invoice",
    InvoiceApplication: "Invoicing",
    Invoices: "Invoices",
    InvoicingRating_Feedback_Confirmation: "Would you like to tell us more about your experience using invoices in Bokio? Book a time slot for an interview [here](https://calendly.com/bokio-sales/interview-with-bokio).",
    InvoicingRating_Feedback_Description: "Any thoughts that could help us improve your invoicing experience?",
    InvoicingRating_Rate_Title: "How was your invoicing experience?",
    IsRequiredError: "is mandatory",
    Item: "Item",
    ItemNotFound: "Not found",
    January: "January",
    JournalPage_Sum_Heading: "Debit/Credit",
    July: "July",
    JumpTo: "Jump to",
    June: "June",
    KeepMeLoggedIn: "Keep me logged in",
    LanguageEnglish: "English",
    LanguageSwedish: "Swedish",
    LastName: "Last name",
    LastUpdated: "Last updated",
    LearnMore: "Learn more",
    LeavePagePrompt: "Do you really want to leave this page? Your changes will be lost",
    Left: "Left",
    LinkToMobileApp: "https://www.bokio.co.uk/help/getting-started/create-account/does-bokio-have-a-mobile-app/",
    Loading: "Loading",
    LoadingImage: "Loading image",
    LoadMore: "Load more",
    LogIn: "Log in",
    Logo_heading: "Logo",
    LogoAction: "Upload logo",
    LogoChange: "Change logo",
    LogotypeAltText: "Bokio logotype",
    LogOut: "Log out",
    LoyaltyProgram_BronzeTier: "Bronze tier",
    LoyaltyProgram_GoldTier: "Gold tier",
    LoyaltyProgram_LoyaltyProgram: "Loyalty program",
    LoyaltyProgram_RegisterInterest: "Register interest",
    LoyaltyProgram_RegisterInterest_SuccessHeading: "Thanks for your interest!",
    LoyaltyProgram_RegisterInterest_SuccessMessage: "An account manager will be in touch as soon as possible",
    LoyaltyProgram_RegisterInterestEnquiry: "Register Agency Partner Interest",
    LoyaltyProgram_SilverTier: "Silver tier",
    LoyaltyProgram_StatusInactive: "Inactive",
    LoyaltyProgram_StatusPending: "Pending",
    LoyaltyProgram_ViewTiers: "View Tiers",
    LoyaltyProgram_ViewTiersLink: "https://www.bokio.co.uk/bokio-partner-programme/",
    MailLinker_Campaign_Preamble: "These companies connected to {0} have access to this campaign.",
    MailLinker_NoCompanies: "There is no active campaign for any of companies connected to {0}. The campaign might have expired.",
    Manage: "Manage",
    ManuallyRecorded: "Manually recorded",
    March: "March",
    MarkAsManuallyRecorded: "Mark as manually recorded",
    Matches: "Matches",
    MatchManually: "Match manually",
    MatchP: "Best match",
    Materials: "Receipts or invoices",
    MaximumFileSize: "Maximum file size allowed is {0} MB per file.",
    May: "May",
    MaybeLater: "Maybe later",
    MentionsTextArea_caption: "{0} will recieve a notification",
    MentionsTextArea_Placeholder: "Use @ to mention",
    Menu_Accounted: "Journals",
    Menu_AccountingServices: "Accounting",
    Menu_AccountsReceivable: "Accounts receivable",
    Menu_AdditionalServices: "Additional services",
    Menu_AgedCreditors: "Aged creditors",
    Menu_Articles: "Items",
    Menu_BalanceReport: "Balance sheet report",
    Menu_Bank: "Bank feeds",
    Menu_BankAndTaxAccounts: "Bank accounts",
    Menu_Closures: "Year-end closures",
    Menu_CurrentCompany: "Selected company",
    Menu_Customers: "Customers",
    Menu_DailyTakings: "Daily takings",
    Menu_Directors_Payroll: "Directors payroll",
    Menu_Employees: "Employees",
    Menu_Expenses_All: "All expense claims",
    Menu_Expenses_MyExpenses: "My expense claims",
    Menu_Expenses_Payments: "Payments",
    Menu_HireAccountant: "Hire an Accountant",
    Menu_Invoices: "Invoices",
    Menu_InvoicesReport: "Invoices report",
    Menu_Ledger: "General ledger",
    Menu_MyPayslips: "My payslips",
    Menu_Offers: "Offers",
    Menu_Payments: "Payments",
    Menu_Profile: "Profile",
    Menu_Quotes: "Quotes",
    Menu_ResultReport: "Profit and loss report",
    Menu_RotRutErrands: "Rot/Rut cases",
    Menu_Salaries: "Salaries",
    Menu_SalaryOverview: "Salaries",
    Menu_SalaryReports: "Salary reports",
    Menu_Sales: "Sales",
    Menu_SupplierInvoices_Invoices: "Supplier invoices",
    Menu_Suppliers: "Suppliers",
    Menu_TagReport: "Tag report",
    Menu_Transactions: "Transactions to record",
    Menu_Vacation: "Vacation",
    Menu_VatReport: "VAT returns",
    MenuButton_Upload_CTA: "Create new",
    MenuCurrentPlan_1DayLeftOfAccess: " day left before your access is lost",
    MenuCurrentPlan_1DayLeftOfTrial: " day left of your free trial",
    MenuCurrentPlan_PricePlan_Title: "Plan",
    MenuCurrentPlan_XDaysLeftOfAccess: " days left before your plan expires",
    MenuCurrentPlan_XDaysLeftOfTrial: " days left of your free trial",
    MenuItem_Accounts: "Accounts",
    MenuWhatsNew_label: "What's new",
    MenuWhatsNew_ReleaseNotesLink: "https://www.bokio.co.uk/release-notes/",
    MenuWhatsNew_ReleaseNotesLink_Agency: "https://www.bokio.co.uk/release-notes/?id=agency/",
    MenuWhatsNew_ReleaseNotesLink_Company: "https://www.bokio.co.uk/release-notes/?id=company/",
    Message: "Message",
    MessageOptional: "Message (optional)",
    Monday: "Monday",
    Month: "Month",
    MoreInformation: "More information",
    MoreThan: "More than {0}",
    Multiple: "Multiple",
    MyAccount: "My account",
    Name: "Name",
    Nbr: "No.",
    NeedsReconnection: "Needs reconnection",
    New: "New",
    NewUpdate_Text: "A new version of Bokio is available",
    Next: "Next",
    NextStep: "Next step",
    NFilesSelected: "{0} files selected",
    NFilesWillBeDeleted: "{0} files will be deleted",
    No: "No",
    No_Continue_CTA: "No, continue",
    NoAccount: "No account",
    NoCancel: "No, cancel",
    None: "None",
    NoPermissions_Message: "You don’t have permission to access this page. Please report this issue to Bokio support team.",
    NoSearchResults: "No search results",
    NoSearchResults_filter_message: "Hmm, we didn't find any results. Let's try a different search",
    NotActivated: "Not activated",
    NotDone: "Not done",
    NoThanks: "No thanks",
    NothingToShow: "There's nothing to show here at the moment",
    Notification_TargetEmail: "Email",
    Notification_TargetMobile: "Push",
    NotificationFlyout_Earlier: "Earlier",
    NotificationFlyout_MarkAllAsRead: "Mark all as read",
    NotificationFlyout_Notifications: "Notifications",
    NotificationFlyout_Recent: "Recent",
    NotificationFlyout_UnreadNotifications_P: "unread notifications",
    NotificationFlyout_UnreadNotifications_S: "unread notification",
    NotificationsEmptyState_Content: "All your new notifications will be shown here.",
    NotificationsEmptyState_Title: "No notifications yet",
    NotSet: "Not set",
    NotSpecified: "Not specified",
    November: "November",
    NPS_Heading: "How likely are you recommend {0} to your friends?",
    NPS_Label_Good: "What do you like with Bokio?",
    NPS_Label_Improve: "How can we improve?",
    NPS_Message_1To4: "Thanks for your feedback! How can we improve to give you a better experience?",
    NPS_Message_5To8: "Thanks for your feedback! How do you think we can improve?",
    NPS_Message_9To10: "Awesome, thank you! Would you help us and tell what makes you happy with Bokio?",
    NPS_NotAtAll: "Not at all likely",
    NPS_NotThisTime: "Not this time",
    NPS_Review_Description: "We’re so glad that you’re happy with us! Would you mind giving us a review on Trustpilot? It only takes a minute and we’d greatly appreciate it.",
    NPS_Review_Title: "Thanks for your feedback!",
    NPS_ReviewOnTrustpilot: "Review on Trustpilot",
    NPS_ReviewOnTrustpilot_Link: "https://www.trustpilot.com/evaluate/bokio.co.uk",
    NPS_SendFeedback: "Send feedback",
    NPS_VeryLikley: "Extremely likely",
    October: "October",
    OK: "OK",
    OkGotIt_action: "Ok, got it",
    OnboardingChecklist_Active_BBA_Button: "Get started",
    OnboardingChecklist_Active_BBA_heading: "Open business account",
    OnboardingChecklist_Active_BBA_text: "Banking service fully integrated in Bokio for easy bookkeeping, more control and convenience.",
    OnboardingChecklist_App_Heading: "Download the mobile app",
    OnboardingChecklist_App_Text: "Avoid losing track of your receipts by capturing them on the go with our mobile app",
    OnboardingChecklist_CC_Text: "Set up your company and start your trial",
    OnboardingChecklist_Checklist_intro: "Here you can find tips on things that are relevant to get started with for your company",
    OnboardingChecklist_Connect_to_bank_button: "Connect to bank",
    OnboardingChecklist_Connect_To_Bank_Heading: "Automate bank transactions",
    OnboardingChecklist_Connect_To_Bank_Text: "Reduce mistakes and automate your bookkeeping by connecting Bokio to your bank",
    OnboardingChecklist_Continue: "Continue",
    OnboardingChecklist_Create_Invoice_Button: "Create an invoice",
    OnboardingChecklist_Create_Invoice_Heading: "Try out invoicing",
    OnboardingChecklist_Create_Invoice_Text: "Explore invoicing in Bokio - add details, publish, register payment and record",
    OnboardingChecklist_Create_Payroll_Button: "Explore now",
    OnboardingChecklist_Create_Payroll_Heading: "Explore Bokios salary solution",
    OnboardingChecklist_Create_Payroll_Text: "Test the solution in Bokio for handling your employees salaries.",
    OnboardingChecklist_Create_your_company_button: "Get started",
    OnboardingChecklist_Create_your_company_Heading: "New company in Bokio",
    OnboardingChecklist_Download_the_mobile_app_button: "Download the mobile app",
    OnboardingChecklist_Import_Heading: "Import previous bookkeeping",
    OnboardingChecklist_Import_previous_bookkeeping_button: "Start import wizard",
    OnboardingChecklist_Import_Text: "Follow the guide on previous bookkeeping and take the steps it recommends",
    OnboardingChecklist_Important_Dates_Button: "Adjust dates",
    OnboardingChecklist_Important_Dates_heading: "Your important dates",
    OnboardingChecklist_Important_Dates_text: "Hide a task in your important dates list that is not relevant for you. Use the icon with three dots.",
    OnboardingChecklist_Mobile_App_Heading: "Bokio on-the-go",
    OnboardingChecklist_MobileText: "Do your banking and capture receipts and invoices on the go with our mobile app.\n- Quickly capture receipts and invoices for your purchases\n- Sign and create payments for Bokio Business Account\n- See bank transactions for Bokio Business Account or other connected banks\n- Get notifcations for purchases with your Bokio card\n- Manage your expenses ",
    OnboardingChecklist_Pending_Status: "Pending",
    OnboardingChecklist_Record_Receipt_Or_Expense_Button: "Record now",
    OnboardingChecklist_Record_Receipt_Or_Expense_Heading: "Record a receipt or expense",
    OnboardingChecklist_Record_Receipt_Or_Expense_Text: "Test Bokio’s templates by recording costs and expenses.",
    OnboardingChecklist_Remove_item_heading: "Are you sure?",
    OnboardingChecklist_Remove_item_text: "You are about to remove a task from the list. You can easily find the task again if you select 'show hidden tasks' under the icon with three dots.",
    Open: "Open",
    Open_CTA: "Open",
    OpenInNewTab: "Open in new tab",
    OptionalDescription: "Optional description",
    Or: "Or",
    Order_label: "Order",
    OrderFailed: "Order failed",
    OrderOngoing: "Ongoing order",
    OrgNumber: "Company number",
    Other: "Other",
    Overview: "Overview",
    PageNotFoundHeading: "The page could not be found,",
    PageNotFoundText: "&lt;a href=\"/\"&gt;Go to start page&lt;/a&gt; to continue your work.",
    PageOf: "of",
    Pages: "pages",
    Pagination_ItemsPerPage: "Items per page",
    Paid: "Paid",
    Password: "Password",
    PasswordChanged: "Password was changed",
    PasswordConfirmMismatch: "The password confirm didn't match",
    Payment: "Payment",
    PaymentDate: "Payment date",
    PaymentFailedTitle: "Last payment failure reason from Stripe:",
    PaymentFile_BankAccount_FailedToParse: "Could not parse",
    PaymentFile_BgFileInfo: "Bankgiro files can be used to quickly uploaded many payments to your bank. But you must buy that extra service from your bank before they work.",
    PaymentFile_GenerateFile: "Generate file",
    PaymentFile_InvalidAccountNumber: "Invalid account number: You have to have a correct account number to create the file",
    PaymentFile_InvalidBankgiro: "Invalid Bankgiro: You have to have a correct Bankgiro to create the file",
    PaymentFile_ItemsSelected: "Include {0} payments with a total of {1}",
    PaymentMethod: "Payment method",
    Payments: "Payments",
    Payroll: "Payroll",
    Period: "Period",
    PeriodEnd: "Period end",
    PeriodStart: "Period start",
    Permissions: "Permissions",
    Personal_Number: "Personal number",
    PersonalSettings: "Personal settings",
    PhoneNumber: "Phone",
    PhoneNumber_CountryCode: "Country code",
    PickFromUploads: "Pick from uploads",
    Plans_NoPermission: "You don't have permission to change the plans for this company. ",
    Plans_PayingAgencyInfo: "You automatically get {0} because you are a customer of {1}. There is no need for you to pick a plan.",
    please_wait: "Please Wait",
    PleaseContact_OurSupport: "Please contact our support",
    PleaseContactSupport_action: "Please contact support",
    PleaseHelpUsWithSomeSettings: "Please help us with some settings",
    PleaseTryAgainLaterOrContactSupport: "Please try again later or {contact support}.",
    PMatch: "{0} match",
    PMatch_NoData: "No data",
    PMatch_NoData_Explanation: "There was no date or amount predicted or manually added for this receipt so it could not be matched",
    PMatchText: "Receipt date match {0}, Sum match {1}",
    PoweredByInEnglish: "Powered by",
    PP_DowngradeBlock_ContactSupport: "Please contact support if you want to change your payment interval",
    Previous: "Previous",
    PriceExclVAT: "Price (Excl. VAT)",
    PricePlan: "Plan",
    PricePlan_CurrentPlan: "Current plan",
    PricePlan_EndsOn: "Ends on {0}",
    PricePlan_MenuText: "You have access to all bookkeeping features for free during the trial",
    PricePlan_ReactivatePlan: "Reactivate your plan",
    PricePlan_ReadMoreAboutBusinessAccount: "Read more about Bokio Business Account",
    PricePlan_ReadMoreAboutBusinessAccount_Link: "https://www.bokio.se/foretagskonto/",
    PricePlan_SelectPlan: "Select plan",
    PricePlan_TrialUntil: "until {0}",
    PricePlan_Upgrade: "Upgrade",
    PricePlan_ViewPlans: "See our plans",
    PricePlan_WithBusinessAccount: "With business account",
    PricePlan_WithoutBusinessAccount: "Without business account",
    PriceplanCheckoutModal_SubscriptionPeriod: "Subscription period: {0} to {1}",
    PricePlanTrial: "Free trial",
    Print: "Print",
    Priority: "Priority",
    PrivacyPolicyLink_Text: "Privacy policy",
    Processing: "Processing",
    Quantity: "Quantity",
    Quarter: "Quarter",
    QuarterPrefix: "Q",
    QuotingRating_Feedback_Description: "Any thoughts that could help us improve your experience using quotes?",
    QuotingRating_Rate_Title: "How was your experience using quotes?",
    Rating_Bad: "Bad",
    Rating_Excellent: "Excellent",
    Rating_Good: "Good",
    Rating_Okay: "Okay",
    Rating_VeryBad: "Very bad",
    ReadMore: "Read more",
    ReadMoreHere: "Read more {here}.",
    ReadyToOrder: "Ready to order",
    Receipt: "Receipt",
    ReceiptUpload_FileTypeNotSupported: "File type is not supported. Select a .png, .pdf or .jpeg file.",
    ReceiptUpload_PDFTooBig: "File is too large. Maximum file size is 4 MB",
    ReceiptZoomInShortcut: "Ctrl + Mouse wheel",
    RecentLogin_Action: "Verify",
    RecentLogin_Description: "Because you are about to do a sensitive update you need to verify your account before you can continue.",
    RecentLogin_Header: "Verify your identity",
    Recommended: "Recommended",
    Reconcile_CompareTo_CopyAndPaste_Text: "Compare this account against data you copy and paste. This is flexible but harder to get right",
    Reconcile_CompareTo_Expenes_Text: "Compare this account against expenses. This helps you find any mismatch between the account and the expenses in Bokio",
    Reconcile_CompareTo_FileOption_Text: "You can upload an Excel file (.xslx) that contain the data. The data has to be in the first sheet and must contain a date, a text and a sum.",
    Reconcile_CompareTo_SupplierInvoices_Text: "Compare this account against supplier invoices. This helps you find any mismatch between the account and the supplier invoices in Bokio",
    Reconcile_CompareTo_TaxAccount_Text: "Compare to the data we have fetched from your integration with Skatteverket. We did fetch data further back than what we added as rows to record to let you reconcile.",
    Reconnect: "Reconnect",
    ReconnectWith: "Reconnect with",
    Record: "Record",
    Recorded: "Recorded",
    RecurringInvoices: "Recurring Invoices",
    Refresh: "Refresh",
    Refund: "Refund",
    Refunds: "Refunds",
    RegisterInterest_action: "Register interest",
    Rejected: "Rejected",
    Remove: "Remove",
    Remove_Account: "Remove account",
    RemoveAll: "Remove all",
    Removed: "Removed",
    RemoveReminder_action: "Remove reminder",
    Reports: "Reports",
    RequiredInformation: "* Required information",
    Reset_Action: "Reset",
    ResetFilters_Action: "Reset filters",
    ResetToDefault: "Reset to default",
    Resubmit_action: "Update and resubmit",
    Right: "Right",
    Role: "Role",
    Rotating: "Rotating",
    SalaryApplication: "Salary",
    Saturday: "Saturday",
    Save: "Save",
    SaveAnyway: "Save anyway",
    SaveAsDraft: "Save as draft",
    SaveChanges: "Save changes",
    Saved: "Saved",
    Savings: "Savings",
    Scheduled: "Scheduled",
    Search: "Search",
    Searching: "Searching...",
    SearchTags: "Search tags",
    Select: "Select",
    SelectAccount: "Select account",
    SelectAll: "Select all",
    SelectColumns: "Select columns",
    SelectPeriodOrRange: "Select period or range",
    SelectTags: "Select tags",
    SelectType: "Select type",
    Send: "Send",
    SendAgain: "Send again",
    SendFeedback: "Send feedback",
    September: "September",
    Settings: "Settings",
    Share: "Share",
    ShouldBeOfTheFormat: "Should be in the format",
    Show_N_Hits: "Show hits ({0})",
    ShowAll: "Show all",
    ShowMoreContent: "Show more",
    ShowXResults: "Show {0} results",
    ShowXVerifications_action: "Show {0} journal entries",
    Sie: "SIE",
    Skatteverket: "Skatteverket",
    Skip: "Skip",
    SkipForLater: "Skip for later",
    SomethingWentWrong_TryAgainOrContactSupport: "Sorry, something went wrong. Please try after some time, or {contact support}",
    SomethingWentWrongWeAreSorry: "Something went wrong. We are sorry!",
    Sort: "Sort",
    SortBy: "Sort by",
    StaffAndSalaries_label: "Staff and salaries",
    Startdate: "Start date",
    Status: "Status",
    StepXofY: "Step {0} of {1}",
    Subject: "Subject",
    Submit: "Submit",
    SuccessModal_Understand: "I understand",
    Sum: "Sum",
    Summary: "Summary",
    SummerCampaign_GetStarted: "Get started with Bokio Business",
    Sunday: "Sunday",
    Supplier: "Supplier",
    SupplierInvoice: "Supplier invoice",
    SuppliersPurchases_label: "Suppliers and purchases",
    Support_AwaitingSupport_status: "Awaiting response",
    Support_InformationNeeded_status: "Information needed",
    Support_SupportReceived_status: "Response received",
    SupportMessage_Active: "Active",
    SupportMessage_EmptyActive: "Great! There are currently no active support messages for you. Any new messages will show up in your todo.",
    SupportMessage_EmptyResolved: "There are currently no resolved support messages",
    SupportMessage_Reactivate: "Reactive",
    SupportMessage_Resolve: "Resolve",
    SupportMessage_Resolved: "Resolved",
    SupportMessages_Title: "Support messages",
    SupportMessages_Todo_GoTo: "Open message",
    Tags_AddLink: "Add new tags",
    Tags_AddTag: "Add tag",
    Tags_AddTagsTitle: "Add tag(s)",
    Tags_CompareGroup: "Compare tags in this group",
    Tags_DisableBulkTagging: "Disable bulk tagging",
    Tags_EmptyState: "Tags allow you to get a better control of your finances. To get started, add a new tag group such as projects, locations, people and add tags to each group. Once this has been set up you will be able filter your reports based on the tags.",
    Tags_EmptyState_Title: "No active tags",
    Tags_EnableBulkTagging: "Enable bulk tagging",
    Tags_FailedTag_DuplicateTag: "This tag was already added",
    Tags_FailedTag_Error: "Unknown error when adding tag",
    Tags_FailedTag_WeightError: "The total of the tags in this group was over 100%. This limit is to ensure useful reports.",
    Tags_FailedToTagVerification: "Failed to tag {0}",
    Tags_Feedback: "Tags is a new feature that we are actively working on improving. If you have any feedback please let us know",
    Tags_FilterBy: "Filter by",
    Tags_Heading: "Tags",
    Tags_LearnMore: "Learn more about tags",
    Tags_PercentageLabel: "Percentage of total",
    Tags_Report: "Tag report",
    Tags_RowModeMessage: "* Can only tag on row level because of earlier tags",
    Tags_Save_FailMessage: "We could not apply {0} tag(s).",
    Tags_Save_PartialFailMessage: "You have successfully added {0} tag(s). We could not apply {1} tag(s).",
    Tags_Save_SuccessMessage: "You have successfully added {0} tag(s)",
    Tags_Selector_Placeholder: "Search by prefix and name",
    Tags_SelectTagLabel: "Select a tag",
    Tags_SetupLink: "Set up tags to get more granular reports",
    Tags_SplitBy: "Split by",
    Tags_StopTagging: "Stop tagging",
    Tags_TagSettings: "Tag settings",
    Tags_Untagged: "Untagged",
    Tags_VerificationModeMessage: "* Can only tag the whole verification because of earlier tags",
    Task: "Task",
    Tasks: "Tasks",
    TaxAccount: "Tax account",
    TaxAccountBumper: "If you connect your tax account to Bokio you don't have to record the taxes manually anymore.",
    TaxAccountBumper_CTA: "Enable tax account sync",
    TaxAccountSync_Connect: "Add Skattekonto connection",
    TaxAccountSync_ConnectError_AlreadyConnectedBookkeepingAccount: "You have another bank account connected to bookkeeping account 1630 so we can't connect the tax account sync. Please try again after fixing that.",
    TaxAccountSync_ConnectError_InvalidOrgNumber: "The organisation number looks incorrect",
    TaxAccountSync_ConnectSuccess_Text: "You have now activated the tax account sync. When you are about to record things you can retrieve the transactions quick and easy. You do this by clicking on the Tax account from the bank list or in Transactions to record.",
    TaxAccountSync_ConnectSuccess_Title: "Tax account sync is now activated",
    TaxAccountSync_DateToSyncFrom: "Date to sync from",
    TaxAccountSync_DateToSyncFrom_ExtraInfo: "This date should be set so it doesn't overlap with manual imports for the tax account. Currently we don't allow you to change this. If you manually import tax account transaction make sure to import up to this date before connecting.",
    TaxAccountSync_FetchTransactions: "Fetch transactions",
    TaxAccountSync_FetchTransactions_ApprovalInfo: "When you sync you will be sent to Skatteverket to approve that we read your Skattekonto. We will only fetch transactions for this company.",
    TaxAccountSync_FetchTransactions_Success: "Fetched {0} new transaction(s)",
    TaxAccountSync_FetchTransactions_SuccessNoTransactions: "You tax account was already up to date",
    TaxAccountSync_ReasonToConnect: "Connect to your Skattekonto to quickly fetch all tax transactions so you get accurate information and save time when recording.",
    TaxAccountSync_StatusMessage: "Last synced the tax account {0}",
    TaxAccountSync_StatusMessage_Empty: "Sync your tax account for the first time",
    TaxesSalary: "Taxes: Salary",
    TaxesVat: "Taxes: VAT",
    TaxPeriod_NoEuTradeDeadlineInfo: "If you haven't sold to EU countries during the period the deadline is {0}",
    TaxPeriod_NoTaxes: "There was no taxes for this period",
    TaxPeriod_RecordedTaxes: "Recorded taxes",
    TaxPeriod_TaxDeductionDate: "Date the taxes where deducted",
    TaxPeriod_TaxPaymentInfoText: "You need to pay {0} to Bankgiro {1} (Skatteverket) with OCR {2} before {3}. With Bokio Business Account, you can pay directly from this page and the transaction is automatically recorded. Otherwise, you pay from your bank and the recording is easiest to handle from Transaction to record.",
    TaxPeriod_WaitingForTaxAccountText: "Great job and keep up the good work! As you have tax account sync enabled the recording will appear on {0}.",
    Terminated: "Terminated",
    Terms_Accept_Action: "Accept terms",
    Terms_AllowMarketing_Label: "I agree that Bokio may use my personal data for targeted marketing through the advertising platforms Google (Google Ads and Display and Video 360), Linkedin and Meta (Facebook and Instagram). Your consent helps us to offer more relevant advertisements and to exclude existing users from advertising. More information can be found in our privacy policy",
    Terms_CurrentTerms_Text: "These are the agreements that are currently active for Bokio. You can still be under another version of the agreements if you haven't approved this version yet.",
    Terms_CurrentTerms_Title: "Current terms, version {0}",
    Terms_EULA: "general terms and conditions",
    Terms_ProcessorAgreement: "privacy policy",
    Terms_ReadAndAccept_EulaOnly_Label: "I have read and accept the {0}",
    Terms_ReadAndAccept_Label: "I have read and accept both the {0} and the {1}",
    Terms_UpdatedTerms: "Updated terms",
    TestCompany_Label: "Test company",
    TestCompany_ThisActionIsNotAvailable: "Unfortunately this can't be tested in a test company. You will have to create a real company to continue with this.",
    TestCompanyTopBar_Description: "Feel free to explore Bokio, record journal entries and create invoices!",
    TestCompanyTopBar_Header: "This is a test company",
    TestCompanyTopBar_SetUp_Action: "Set up your real company",
    ThankForYourFeedback: "Thank you for your feedback",
    ThankYou: "Thank you",
    ThingsToDo_label: "Things to do",
    Thursday: "Thursday",
    Time_DaysAgo_P: "{0} days ago",
    Time_DaysAgo_S: "{0} day ago",
    Time_EditedXTimeAgo: "Edited {0}",
    TIMEAGO_AGO: "ago",
    TIMEAGO_DAY: "a day",
    TIMEAGO_DAYS: "%d days",
    TIMEAGO_HOUR: "an hour",
    TIMEAGO_HOURS: "%d hours",
    TIMEAGO_IN: "in",
    TIMEAGO_MINUTE: "a minute",
    TIMEAGO_MINUTES: "%d minutes",
    TIMEAGO_MONTH: "a month",
    TIMEAGO_MONTHS: "%d months",
    TIMEAGO_SECONDS: "less than a minute",
    TIMEAGO_YEAR: "a year",
    TIMEAGO_YEARS: "%d years",
    TimeGranularity: "Granularity",
    Tip: "Tip",
    Title: "Title",
    To: "To",
    Today: "Today",
    Todo: "To do",
    Todo_Action_Approve: "Approve",
    Todo_Action_Attest: "Approve",
    Todo_Action_Bookkeep: "Record",
    Todo_Action_BookkeepPayment: "Record payment",
    Todo_Action_Complete: "I finished this",
    Todo_Action_GoToFiscalYear: "Go to fiscal year",
    Todo_Action_MatchPayment: "Match payment",
    Todo_Action_NotInterested: "I'm not interested",
    Todo_Action_Postpone: "Remind me later",
    Todo_Action_RegisterPayment: "Register payment",
    Todo_Action_Reject: "Reject",
    Todo_Action_SchedulePayment: "Schedule payment",
    Todo_Action_Upload: "Record via uploads",
    Todo_Action_YesApprove: "Yes, approve",
    Todo_Action_YesConnect: "Yes, connect",
    Todo_Action_YesReject: "Yes, reject",
    Todo_ApproveConfirmation_Title: "Are you sure you want to approve this receipt?",
    Todo_Closure_GoTo: "Go to closure",
    Todo_ClosureUpcomingDescription: "Complete and submit your year-end report and income declaration 2",
    Todo_ConfirmConnectVerification_Text: "Do you want to connect {0} to this imported bank row? We will connect these so you will be able to verify that all bank rows have a journal entry.",
    Todo_ConfirmConnectVerification_Title: "Connect to journal entry",
    Todo_ConfirmDelete_Title: "Are you sure you want to delete?",
    Todo_ConfirmDeleteAutoVerificationRows_Text_P: "{0} bank rows will be deleted.",
    Todo_ConfirmDeleteAutoVerificationRows_Text_S: "One bank row will be deleted.",
    Todo_ConfirmDeleteExpenses_Text_S: "One expense will be deleted",
    Todo_ConfirmDeleteReceipts_Text_P: "{0} receipts will be deleted.",
    Todo_ConfirmDeleteReceipts_Text_S: "One receipt will be deleted.",
    Todo_ConfirmDeleteSupplierInvoices_Text_S: "One supplier invoice will be deleted",
    ToDo_DailyTakingsRow_Title: "You have events from Zettle that needs to be handled",
    Todo_Date_DueInDays_P: "Due in {0} days",
    Todo_Date_DueInDays_S: "Due tomorrow",
    Todo_Date_DueToday: "Due today",
    Todo_Date_OverdueDays_P: "{0} days overdue",
    Todo_Date_OverdueDays_S: "1 day overdue",
    Todo_DisableMatchPayment: "Disable matching",
    Todo_DisableMatchPayment_Text: "Disable matching for this imported bank row. The bank row will be moved as unmatched to Record receipt or invoice.",
    Todo_DoNow_EmptyState_Message: "Started something new? Create a task or reminder here.",
    Todo_DoNow_EmptyState_Title: "Good job, you’re up to date!",
    Todo_Feedback_Title: "Do you have any feedback on Things to do?",
    Todo_GoToExpenses: "Go to expenses",
    Todo_GoToInboxMessage: "Open inbox message",
    Todo_GoToInvoice: "Go to invoice",
    Todo_GoToInvoiceDraft: "Go to draft invoice",
    ToDo_GoToIZettleIntegration: "Go to Zettle integration",
    Todo_GoToPayroll: "Go to payroll",
    Todo_GoToRecurringInvoice: "Go to recurring invoice",
    Todo_GoToSupplierInvoice: "Go to supplier invoice",
    Todo_GotoVatReport: "Go to VAT report",
    ToDo_ImportantDates_heading: "Important dates",
    ToDo_ImportantDates_ShowCompleted: "Show completed dates",
    ToDo_ImportantDates_ShowHidden: "Show hidden dates",
    Todo_Mark_as_done: "Mark as done",
    Todo_Match_MultipleMatchesFound: "{0} {1} matches found",
    Todo_Match_SingleBankRowMatch: "Match found with bank row {0}",
    Todo_Match_SingleInvoiceMatch: "Match found with {0}",
    Todo_Match_SingleSalaryIncomeTaxMatch: "Matches income taxes for the period {0}",
    Todo_Match_SingleSalarySocialFeesMatch: "Matches social fees for the period {0}",
    Todo_Match_SingleSupplierInvoiceMatch: "Match found with supplier invoice {0}",
    Todo_Match_SingleVatTaxesMatch: "Matches vat deduction for the period {0}",
    Todo_Match_SingleVerificaitonMatch: "Match found with journal entry {0}",
    Todo_MatchModal_LoadEarlier: "Load earlier matches",
    Todo_MatchModal_LoadLater: "Load later matches",
    Todo_MatchModal_ManualMatchDateRange: "Showing {0} results between {1} and {2}",
    Todo_MatchModal_ManualMatchDateRangeSingular: "Showing {0} result between {1} and {2}",
    Todo_MatchModal_ManualMatchingPrompt: "Can't find what you are looking for?",
    Todo_MatchModal_ManualMatchingSubHeading: "If none of the above are correct you can load more potential matches.",
    Todo_MatchModal_MatchHeading: "{0} suggested matches found for the above {1}",
    Todo_MatchModal_NoManualMatchesFound: "No related matches found between {0} and {1}",
    Todo_MatchModal_SingularMatchHeading: "{0} suggested match found for the above {1}",
    Todo_Now: "To do now",
    Todo_Now_Page_Title: "Now",
    Todo_PrelTax_AdjustSums: "Adjust sums",
    Todo_PrelTax_ManualPayment: "Manual payment",
    Todo_PrelTax_NoSetupForYear: "You have not setup preliminary taxes for {0} yet. Click here to do it.",
    Todo_PrelTax_Paid: "Paid",
    Todo_PrelTax_PaymentDate: "Payment date",
    Todo_PrelTax_PaymentDetails: "Payment details",
    Todo_PrelTax_RegisterPayment: "Register payment",
    Todo_PrelTax_RemovePayment: "Remove payment",
    Todo_PrelTax_RemovePaymentConfirmation: "Are you sure you want to remove this payment?",
    Todo_PrelTax_SetupTaxesModal_MonthlyAmount: "Amount to pay per month",
    Todo_PrelTax_SetupTaxesModal_Paragraph1: "Enter what you have received from skatteverket so we can calculate how much you should pay together with other taxes and fees during the year.",
    Todo_PrelTax_SetupTaxesModal_Paragraph2: "You can find your decision at {0} under ",
    Todo_PrelTax_SetupTaxesModal_Paragraph2Italic: "Visa uppgifter om deklarationsobjektet.",
    Todo_PrelTax_SetupTaxesModal_SkatteverketLink: "https://sso.skatteverket.se/so/epd_web/visa_deklarationsobjekt",
    Todo_PrelTax_SetupTaxesModal_Title: "Adjust preliminary taxes payments",
    Todo_PrelTax_Title: "Preliminary Taxes",
    Todo_PrelTax_TotalSum: "Total sum",
    Todo_PrelTaxStatus_NothingToPay: "Nothing to pay",
    Todo_PrelTaxStatus_Overdue: "Overdue",
    Todo_PrelTaxStatus_Overpaid: "Overpaid",
    Todo_PrelTaxStatus_Paid: "Paid",
    Todo_PrelTaxStatus_Scheduled: "Scheduled",
    Todo_PrelTaxStatus_Underpaid: "Underpaid",
    Todo_PrelTaxStatus_Unknown: "Unknown",
    Todo_PrelTaxStatus_Unpaid: "Unpaid",
    Todo_RejectConfirmation_Title: "Are you sure you want to reject this receipt?",
    Todo_ReorderSections: "Reorder sections",
    Todo_Section_Approve: "Approve",
    Todo_Section_AssignedTasks: "Assigned tasks",
    Todo_Section_Bank: "Bank",
    Todo_Section_Bookkeep: "Record",
    Todo_Section_BookkeepPayment: "Record payment",
    Todo_Section_CompanyOnboarding: "Get started with Bokio",
    Todo_Section_CompleteClosure: "Complete closure",
    Todo_Section_GetPayment: "Get paid for",
    Todo_Section_MyTasks: "My tasks",
    Todo_Section_NewPaymentMethods: "New Payment Methods",
    Todo_Section_Pay: "Things to pay",
    Todo_Section_Promotions: "Recommendations based on your company",
    Todo_Section_Review: "Review",
    Todo_Section_SchedulePayment: "Schedule payment",
    Todo_Section_SupportMessages: "Support messages",
    Todo_SectionGroup_ActivateCard: "Activate your card",
    Todo_SectionGroup_Bank: "Bank feeds",
    Todo_SectionGroup_BankPricePlan: "Upgrade your plan",
    Todo_SectionGroup_Closures: "Closures",
    Todo_SectionGroup_DailyPayments: "Manage your daily payments",
    Todo_SectionGroup_DepositMoney: "Deposit money",
    Todo_SectionGroup_EasyAccounting: "Easy to use accounting",
    Todo_SectionGroup_Expenses: "Expenses",
    Todo_SectionGroup_GetAccountant: "Get help from an accountant",
    Todo_SectionGroup_Invoices: "Invoices",
    Todo_SectionGroup_ManageBankConnection: "Manage bank connection",
    Todo_SectionGroup_ManageExpenses: "Manage expenses",
    Todo_SectionGroup_ManageSalaries: "Manage salaries",
    Todo_SectionGroup_ManageSwish: "Manage Swish",
    Todo_SectionGroup_Material: "Receipt or invoice",
    Todo_SectionGroup_OrderCard: "Order your card",
    Todo_SectionGroup_Salary: "Salary",
    Todo_SectionGroup_Savings: "Savings alert",
    Todo_SectionGroup_SendProfessionalInvoices: "Send professional invoices",
    Todo_SectionGroup_SupplierInvoices: "Supplier invoices",
    Todo_SectionGroup_TrackBills: "Track your bills",
    Todo_SectionGroup_Vacation: "Vacation",
    Todo_SectionGroup_Vat: "VAT",
    Todo_Settings_Page_Title: "Things to do settings",
    Todo_Summary_BankTransactions_P: "You have {0} bank transactions to record",
    Todo_Summary_BankTransactions_S: "You have one bank transaction to record",
    Todo_Upcoming: "To do upcoming",
    Todo_Upcoming_EmptyState_Message: "Want to plan something? Create an invoice or upload receipts for the upcoming period.",
    Todo_Upcoming_EmptyState_Title: "You have no upcoming todos.",
    Todo_Upcoming_Page_Title: "Upcoming",
    Todo_WaitingForSomeoneElse: "Waiting for someone else",
    TOM_CTA: "Upgrade now",
    TOM_Heading: "Thanks for trying out Bokio, your trial has now ended",
    TOM_Heading_OnPlan: "Your plan is now active!",
    TOM_Text: "Select a plan to keep using Bokio services",
    TOM_Text_OnPlan: "Your trial has ended and the price plan {0} has been activated on your company.",
    Total: "Total",
    TotalExclVAT: "Total (Excl VAT)",
    TotalInclVAT: "Total (Incl. VAT)",
    TOTP_VerifyCode_Action: "Verify code",
    TrialBalanceExcel: "Trial balance (Excel)",
    TrialExtendedModal_CTA: "Continue with trial",
    TrialExtendedModal_Heading: "We have now extended your trial with one month extra!",
    TrialExtendedModal_Message: "Continue to explore our features by yourself from the Overview page or [schedule a free consultation](https://calendly.com/bokiokomigang/kom-igang-med-bokio?month=2023-05&amp;date=2023-06-08) with us to get some extra help to get started.",
    TryAgain: "Try again",
    TTR_RuleSuggestion_Link: "Show details or edit rule",
    TTR_RuleSuggestion_Multiple: "Always recommend the templates [{0}] when the message {1} '{2}'",
    TTR_RuleSuggestion_Single: "Always recommend {0} when the message {1} '{2}'",
    Tuesday: "",
    Type: "Type",
    Uncategorised: "Uncategorised",
    Undo: "Undo",
    UndoBookkeepingForTaxes: "Undo accounting for taxes",
    UndoManuallyRecorded: "Undo manually recorded",
    Unknown: "Unknown",
    Upcoming: "Upcoming",
    Update: "Update",
    Updated: "Updated",
    UpgradePlan_CTA: "Upgrade plan",
    UpgradeToX: "Upgrade to {0}",
    Upload: "Upload",
    Upload_DontHaveAReceiptYet: "Don’t have your receipts in Bokio yet? No worries!",
    UploadedBy: "Uploaded by",
    UploadedByX: "Uploaded by: {0}",
    UploadedOn: "Uploaded on",
    UploadImageTypesRestriction: "(.jpeg .png only)",
    Uploads: "Uploads",
    User_Action_Connect: "Connect",
    User_Action_Connect_To: "Connect user to {0}",
    User_Action_Invite: "Invite",
    User_Label_SelectUserToConnect: "User to connect",
    User_Message_NotConnectedMessage: "{0} is NOT connected to a user. Connect a user if you want {0} to be able to login. This is needed to use Expenses.",
    User_Message_TheEmployeeIsConnectedTo: "{0} is connected to the user {1}",
    User_Option_InviteNewUser: "Invite new user",
    UserPermission_BokioSupportUser: "Bokio Support",
    Users: "Users",
    Validation_Action_DoLater_Required: "Can be filled in later",
    Validation_Action_DoNow_Required: "Must be filled in",
    Validation_Action_FixErrors: "Fix issues",
    Validation_AllowedCharacters: "Allowed characters in {0} is a-zA-Z0-9åäöÅÄÖ,.-",
    Validation_AtLeastOnInList: "Must be at least one item in list",
    Validation_ContainsWhitespace: "Cannot contain spaces",
    Validation_DateGreaterThanOrEqual_Must: "Pick a date no earlier than {0}",
    Validation_DateIsNotOneOf_Must: "Date is not supported",
    Validation_DateLessThan_Must: "Pick a date before {0}",
    Validation_DateLessThanOrEqual: "Pick a date no later than {0}",
    Validation_Equal: "Has to be {0}",
    Validation_GreaterThan_Must: "Enter a number larger than {0}",
    Validation_GreaterThanOrEqual_Must: "Enter to a number which is at least {0}",
    Validation_IncorrectEUVATFormat: "VAT number does not match the format for {0}: [{1}]",
    Validation_Invoices_PleaseSelectACustomer_text: "Please select a customer to invoice",
    Validation_Invoices_SelectOneItemRow_text: "Please add at least one item row to invoice",
    Validation_IsInvalid: "{0} is invalid",
    Validation_IsNotAlphanumeric: "May only contain numbers and letters.",
    Validation_IsNotSupportedCurrency: "{fieldName} must be a supported payment currency",
    Validation_ItemIsMissing_S_text: "{0} is missing",
    Validation_ItemsAreMissing_P_text: "{0} are missing",
    Validation_MaxLength: "Must have at most {0} characters",
    Validation_MinLength: "Must have at least {0} characters",
    Validation_MustBeInCurrency: "Must be in {0}",
    Validation_NeedToBeReviewed_P_text: "{0} need to be reviewed",
    Validation_NonExistingDate: "Change to a date that exists",
    Validation_NonZero_Must: "Value must not be 0",
    Validation_NotContainOnlySpaces: "{fieldName} must not contain only spaces",
    Validation_NotEqualTo_Must: "Value must not be {0}",
    Validation_NotInList: "Value {0} already exists",
    Validation_OrgNumber_NotValid: "The organisation number is not valid",
    Validation_RangeLength: "Must be {0} to {1} characters",
    Validation_SmallerThanOrEqual_Must: "Enter a number which is less than or equal to {0}",
    Validation_Summary_DoLater_Title_P: "You can fix these {0} issues later.",
    Validation_Summary_DoLater_Title_S: "You can fix this one issue later.",
    Validation_Summary_DoNow_Title_P: "After you fix these {0} issues you can save.",
    Validation_Summary_DoNow_Title_S: "Almost there! Just fix this one issue.",
    Validation_ValidEmail: "Change to a valid email",
    Validation_Zero_Must: "Value must be zero",
    VATCheck_MappingId_label: "Mapping ID",
    VatNumber: "VAT number",
    VATPeriodClosed_Warning: "Consider opening the VAT period or recording this journal entry at the start of the next open VAT period.",
    VATPeriodClosed_Warning_Title: "Recording on a closed VAT period",
    VATPeriodStarted_Warning_Title: "Recording on a reconciled VAT period",
    VatSetting_ExternalVatProvider: "Registered for VAT with HMRC",
    VatSetting_FixedQuarterlyFeb: "Once per quarter (Feb,May,Aug,Nov)",
    VatSetting_FixedQuarterlyJan: "Once per quarter (Jan,Apr,Jul,Oct)",
    VatSetting_FixedQuarterlyMar: "Once per quarter (Mar,Jun,Sep,Dec)",
    VatSetting_FixedYearlyApr: "Once per year (Apr)",
    VatSetting_FixedYearlyAug: "Once per year (Aug)",
    VatSetting_FixedYearlyDec: "Once per year (Dec)",
    VatSetting_FixedYearlyFeb: "Once per year (Feb)",
    VatSetting_FixedYearlyJan: "Once per year (Jan)",
    VatSetting_FixedYearlyJul: "Once per year (Jul)",
    VatSetting_FixedYearlyJun: "Once per year (Jun)",
    VatSetting_FixedYearlyMar: "Once per year (Mar)",
    VatSetting_FixedYearlyMay: "Once per year (May)",
    VatSetting_FixedYearlyNov: "Once per year (Nov)",
    VatSetting_FixedYearlyOct: "Once per year (Oct)",
    VatSetting_FixedYearlySep: "Once per year (Sep)",
    VatSetting_Monthly: "Every month",
    VatSetting_NotRegisteredForVat: "Not registered for VAT",
    VatSetting_NotSet: "Not set",
    VatSetting_Quarterly: "Once per quarter",
    VatSetting_Yearly: "Once per year (Dec)",
    VerificationCode_TOTP: "Verification code (from 2FA app)",
    VerifyYourCompanyModal_Message: "Please verify your company with BankID",
    VerifyYourCompanyModal_Title: "Verify your company",
    View: "View",
    ViewAll: "View all",
    ViewDetails: "View details",
    ViewInTodo_ButtonText: "View in todo",
    ViewSettings: "View Settings",
    Visit_Backoffice: "Visit Bokio Agency",
    Voucher_Flow_ActivateOnCompanyCTA: "Activate the discount on your company",
    Voucher_Flow_CreateAccountCTA: "Create a new account with the discount",
    Voucher_Flow_CreateCompanyCTA: "Create a new company with the discount",
    Voucher_Flow_CreateDiscountInformation: "The discount will be applied when you purchase a plan after creating the company. The purchase needs to be completed while the discount is still active.",
    Voucher_Flow_Description: "To activate the discount on the correct account we need a little bit of information. You apply the discount to more than one company by returning here again.",
    Voucher_Flow_DoesTheCompanyExists: "Do the company already exist in Bokio?",
    Voucher_Flow_DoYouHaveAnAccount: "Do you already have a Bokio login?",
    Voucher_Flow_Title: "Activate discount",
    WantFeatureFeedback: "This is a new feature you are using, to be able to improve and make it even better we would love to get your feedback.",
    Wednesday: "Wednesday",
    Week: "Week",
    WeekPrefix: "W",
    WelcomeMessage_Viktor_CEO: "CEO",
    WelcomeMessage_Viktor_Name: "Viktor Stensson",
    WelcomeToBokio: "Welcome to Bokio",
    WhatsNext: "What's next?",
    Yes: "Yes",
    Yes_Cancel_CTA: "Yes, cancel",
    YesDelete: "Yes, delete",
    YesGoBack: "Yes, go back",
    YesNoFeedback_IsBankRulesHelpful: "Is bank rules helpful for you?",
    YesNoFeedback_WasThisInformationHelpful: "Was this information helpful?",
    YesRemove: "Yes, remove",
    YouAreUsingBokioForCountryX: "You are using Bokio for {0}",
    Your: "Your",
    YourAccount: "Your account",
    YourChangesHaveBeenSaved: "Your changes have been saved!",
    YourContactEmail: "Your contact email",
    YourName: "Your name",
  };
}