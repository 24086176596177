/* eslint-disable */

import type { BankLang } from "../types/BankLang";
import { translateTempImpl } from "../TranslatableLanguage";

export function BankLangDef(): BankLang {
  return {
    translateTemp: (key: string, neutral: string, svSE?: string, enGB?: string) => translateTempImpl(key, neutral, svSE, enGB),
    
    AccountBalance: "Account balance",
    AccountDetails: "Account details",
    AccountDetailsModal_Description_Bankgiro: "To transfer money to this account, use your dedicated Bankgiro if your bank requires OCR number, use {0}.",
    AccountDetailsModal_Description_SeClearingAccount: "To transfer money to this account, use your dedicated bank account number.",
    AccountDetailsModal_Description2: "Please allow 1-3 business days for the transfer to end up in your account. If you have any questions or need help, please ",
    AccountDetailsModal_ScanQrCode: "Scan this QR with your bank app",
    AccountNumber: "Account number",
    AccountOwner: "Account owner",
    ActivateAndRenewYourNewCard: "Activate your renewed card",
    ActivateBokioBusinessAccount: "Apply for Bokio Business Account",
    ActivateBokioBusinessAccountToStartUsingCards: "Activate Bokio Business Account to start using cards in Bokio!",
    ActivateBusinessAccount_Modal_AdditionalChecksNotice: "Read through and make sure everything below is correct.",
    ActivateBusinessAccount_Modal_Description: "We need to verify that the information about you and your company is correct. Open your BankID app for authentication.",
    ActivateBusinessAccount_Modal_NotShared: "I am the only one who has access to my account with username {0}.",
    ActivateBusinessAccount_Modal_OwnerOfEmail: "I acknowledge that I’m the owner of {0} and my personal number is {1}.",
    ActivateBusinessAccount_Modal_RealCompany: "{0} with organisation number {1} is not a test company.",
    ActivateBusinessAccount_Modal_RealCompany_Tooltip: "After activating Bokio Business Account on this company you won't be able to activate it again for organization {0}",
    ActivateCard: "Activate card",
    ActivatedOn: "Activated on",
    ActivateInternationalPayments: "Activate International Payments",
    ActivateWithBankId: "Activate with BankID",
    ActivateYourAccount: "Activate your account",
    ActivateYourCard: "Activate your card",
    ActiveBusinessCards: "active business cards",
    ActiveBusinessCards_P: "active business cards",
    ActiveBusinessCards_S: "active business card",
    AddBeneficiary: "Add beneficiary",
    AddPayment_AddPayment: "Add payment",
    AddPayment_CategoryOrConsumerIsNotSupported: "This payment category or consumer is not supported",
    AddPayment_CountryAndCurrency: "Country and currency",
    AddPayment_PaymentMode: "Payment mode",
    AddPayment_PaymentNumber: "{0} number",
    AddReceiver: "Add receiver",
    AddSender: "Add sender",
    AllowContactless: "Allow contactless",
    ApplyToBokioBusinessAccountModal_Description: "To be able to connect **{0}**, you must first apply for a **Bokio Business Account**.\n\nWith Bokio Business Account, you get an integrated bank account, business card and other banking services that allow you to pay suppliers and employees, get paid from customers, manage transactions to Skatteverket and much more, directly in Bokio. The business account is included in your price plan.",
    ApplyToBokioBusinessAccountModal_Title: "Bokio Business Account is needed for {0}",
    ApprovedBy: "Approved by",
    AtmWithdrawals: "ATM withdrawals",
    Audit_MultipleWarnings: "This payment have multiple issues",
    AvailableAmount: "Available amount",
    AvailableBalance: "Available balance",
    Bank_PageTitle: "Bank accounts",
    BankAccount: "Bank account",
    BankAccount_Accounts: "Accounts",
    BankAccountDetails: "Bank account details",
    BankAccountType: "Bank account type",
    BankAccountType_Credit: "Credit card",
    BankAccountType_Depository: "Depository",
    BankAccountType_Depot: "Depot",
    BankAccountType_Investment: "Investment",
    BankAccountType_Loan: "Loan",
    BankAccountType_Other: "Unknown",
    BankAccountType_Saving: "Saving",
    BankConnect_AvailableBanks: "See available banks",
    BankConsent_Accepted: "Accepted",
    BankConsent_ConsentStatus: "Status",
    BankConsent_Description: "The entity concerned, which I represent, (the \"Company\"), hereby instructs Svea Bank AB (\"Svea\") to, on an on-going basis, share information about the Company’s bank accounts held with Svea (including payment and transactional data) with Bokio AB (\"Bokio\") (the \"Instruction\").\n\nThis Instruction is valid from the date hereof, until it is revoked through Svea having received a notice in writing sent by a duly authorised representative of the Company.\n\nA revocation of this Instruction may affect Bokio’s ability to provide certain services that are integrated into Bokio’s bookkeeping services to the Company. For example, Bokio may not be able to provide services for:\n- adding payments to supplier invoices, salaries or expenses.\n- automatic recording of transactions.\n- adding a receipt to payments.\n\nBy selecting \"Yes\" below (i) Svea is instructed as set out in this Instruction, (ii) the Company confirms that it has taken part of and accepts all terms of this Instruction, and (iii) I certify that I have all necessary power and authority to represent the Company.",
    BankConsent_GiveConsent_RadioLabel: "Yes - I confirm that I leave the Assignment to Svea",
    BankConsent_Revoke_Warning: "Caution: A revocation of this Instruction may affect Bokio’s ability to provide certain services that are integrated into Bokio’s bookkeeping services.",
    BankConsent_RevokeConsent_RadioLabel: "No - I am not leaving the assignment to Svea. I am aware that this means that Bokio cannot provide all services.",
    BankConsent_Revoked: "Revoked",
    BankgiroNumber: "Bankgiro number",
    BankgiroNumberInInboxMessage: "If you have a bankgiro number it has been sent by Svea Bank to your ",
    BankId_Cancelled_ErrorMessage: "BankID authentication was cancelled",
    BankIdPolling_CancelledByUser: "The bank id signing was cancelled",
    BankIdPolling_Rejected: "The bank id signing failed",
    BankIdPolling_UnHandledPollStatus: "An unhandled error occured, try again or contact support",
    BankInbox_DownloadAttachment: "Download attachment",
    BankInbox_EmptyStateMessage: "Here you will receive messages regarding your bank account",
    BankInbox_MarkAsRead: "Mark as read",
    BankInbox_Title: "Bank inbox",
    BankPaymentRecipientInfo_ClearingNumberToolTip_InputField: "Clearing number is the identifier to which bank and branch an account belongs (usually written before the account number, except for Nordea personal accounts where it's not used at all).",
    BankPreregistrations_Application: "Application",
    BankPreregistrations_ApplicationInProgressNotice_Title: "You have an application in progress. You need to submit it before you can publish another one.",
    BankPreregistrations_ApplicationType: "Type of application",
    BankPreregistrations_Apply: "Apply",
    BankPreregistrations_CheckCompleted_Cta: "Update status",
    BankPreregistrations_CheckCompleted_Info: "You have {0} application(s) in review. Check if they can be completed.",
    BankPreregistrations_CompleteApplication: "Finish application",
    BankPreregistrations_EntityType: "Organisation or person?",
    BankPreregistrations_EntityType_Organisation: "Organisation",
    BankPreregistrations_EntityType_PrivatePerson: "Private person",
    BankPreregistrations_HowToFix_Application: "If the information in the application is wrong you need to first cancel this application and then send a new one with the correct information",
    BankPreregistrations_HowToFix_SupplierOrRecipient: "If the Supplier/Recipient is wrong you can edit these from the payment flow so it matches the application",
    BankPreregistrations_HowToFix_Title: "How to fix so they match",
    BankPreregistrations_IbanAlreadyRegistered: "This Iban was already registered",
    BankPreregistrations_Matches_Heading: "Matching recipients",
    BankPreregistrations_MustSyncFirst: "The applications are out of sync. Before you delete this you must complete any pending applications.",
    BankPreregistrations_ReceiverInfo: "Accounts that you can send international payments to",
    BankPreregistrations_RegisterCreditor: "Register receiver",
    BankPreregistrations_Title: "International receivers/senders",
    BankPreregistrations_WaitingForAml: "Need answers",
    BankPreregistrations_WaitingForSvea: "In review",
    BankReconnectWarning: "Your bank connection to {0} is expired. Please reconnect it to continue fetching transactions.",
    BBA_BankTransactions_OutOfSync_NoticeContent: "If you do not consent, you won't be able to fully use the product and its features will be limited. This means Bokio cannot access your transactions to show you more details.",
    BBA_BankTransactions_OutOfSync_NoticeTitle: "Bokio Business Account transactions are out of sync!",
    Bba_ContactSupport: "Please {contact support} if you have any questions.",
    BBA_NewBank_AccountNumber_Text: "New bank account",
    Bba_OrderMastercard: "Order Mastercard",
    BBA_SveaOnboarding_Consent_Header: "Sharing of account information",
    BBA_SveaOnboarding_ContactDetails_Description: "Please enter the name and telephone number of the contact person. The data will be used by Svea Bank and in accordance with their privacy policy.",
    BBA_SveaOnboarding_ContactDetails_Header: "Contact details",
    BbaBanner_Campaign_StartBonusDescription: "start bonus",
    BbaBannerFeatures_AvailableCountries: "available countries",
    BbaBannerFeatures_Bankgiro: "Bankgiro",
    BbaBannerFeatures_Businesscard: "Business card",
    BbaBannerFeatures_CapitalAccount: "Capital account",
    BbaBannerFeatures_CashBack: "{0}% cashback on card purchases and {1}% on account balance {2}",
    BbaBannerFeatures_CashBack_PricePlan: "(for {0})",
    BbaBannerFeatures_OutgoingInternationalPayments: "International outgoing payments {0}",
    BbaBannerFeatures_SeeCountries: "(see {0})",
    BbaBannerFeatures_Swish: "Swish Business {0}",
    BbaBannerFeatures_SwishFee: "(free monthly fee {0} in 2024)",
    BbaBannerFeatures_ThisIsBba: "This is Bokio Business Account:",
    BbaBannerFeatures_TransactionAccount: "Transaction account",
    BbaBannerFeatures_Upcoming: "Upcoming:",
    BbaMigrationChooseBookkeeping_AccountActivated_NoticeDescription: "Please select the bookkeeping/payment account you want to connect to your new bank account.",
    BbaMigrationChooseBookkeeping_AccountActivated_NoticeTitle: "Your Bokio Business Account has been activated",
    BbaMigrationChooseBookkeepingAccount_Description: "Since you are changing bank, we recommend that you use a different account than the one you used for your previous business account. Using for example 1932 instead of 1930 doesn't matter at all for your bookkeeping, the account works the same as 1930. The only difference is the final digit.",
    BbaNotAvailableInFreePlanModal_Content_1: "In connection with the update of our pricing plans, Bokio Business Accounts are no longer included as a free feature. To retain your business account, you need to upgrade to {Premium}.",
    BbaNotAvailableInFreePlanModal_Content_2: "If you do not wish to upgrade, you must clear your business account by {31 January, 2023}. After that, the business account will be blocked and no longer usable. If you have money left on your Bokio Business Account after 31 January and Bokio needs to handle the payment of the balance, Bokio reserves the right to pay amounts exceeding 100 SEK to your tax account. Amounts less than 100 SEK will not be paid out. {Read more here}.",
    BbaNotAvailableInFreePlanModal_Title: "Upgrade your plan to keep your Bokio Business Account",
    BBAOnboarding_SveaConfirmAndSignYourAgreement_BoxDescription: "Download and read through the agreement before you sign. To keep your account secure, we will require you to always log in with BankID when you have verified your company details.",
    BBAOnboarding_SveaConfirmAndSignYourAgreement_BoxTitle: "Confirm and sign your agreement",
    BBAOnboarding_SveaConfirmAndSignYourAgreement_TermsAndConditions: "I have read and understand the terms and conditions.",
    BbaOnboardingPopoverContent_Description: "Bokio Business Account makes it easy to get paid, pay and will automatically record your invoices and salaries directly to Bokio. However, you need to activate it before you can use it for your business.",
    BbaPromotionVideoEmbedPath: "https://www.youtube.com/embed/HA4gZEgqILM?rel=0&amp;enablejsapi=1",
    Bic: "Bic",
    BillingOfService: "Billing of service",
    BlockCard: "Block card",
    BlockedColumnName_AccountNumber: "Account number",
    BokioBusinessAccount: "Bokio Business Account",
    BokioBusinessAccount_Svea_Activate_Button: "Start application",
    BokioBusinessAccount_Svea_Activate_ComplianceCaption: "Bokio Business account is provided by Svea Bank AB (publ). In connection with your application for a Bokio Business Account, your personal data will be shared with Svea Bank and a credit check will be carried out. Svea Bank is the personal data controller for the personal data that is collected and used when providing the Bokio Company account.",
    BokioBusinessAccount_Svea_Activate_Step1_Description: "Check your details and choose whether you would like to include bankgiro or not.",
    BokioBusinessAccount_Svea_Activate_Step1Migration_Description: "Check your details.",
    BokioBusinessAccount_Svea_Activate_Step2_Description: "Enter contact details and sign your agreement with BankID.",
    BokioBusinessAccount_Svea_Activate_Step3_Description: "Answer questions about you and your company.",
    BokioBusinessAccount_Svea_Activate_Step4_Description: "If you have chosen to include a bankgiro, you now have the option to transfer an existing one or get a new one.",
    BokioBusinessAccount_Svea_Activate_Step4Migration_Description: "Once the application has been approved, you can activate your account. The money will be automatically transferred within 1-2 banking days.",
    BokioBusinessAccount_Svea_Activate_Step5_Description: "Once your application is approved by Svea you can connect the business account to a bookkeeping/payment account.",
    BokioBusinessAccount_Svea_Activate_Step5Migration_Description: "Connect your business account to a new bookkeeping/payment account.",
    BokioBusinessAccountMigrationScene_AccessDenied: "Upgrading Bokio Business Account can only be done by the user that can sign payments in current Bokio Business Account",
    BokioBusinessAccountMigrationScene_PageTitle: "Upgrade Bokio Business Account",
    BokioBusinessAccountSvea_Activation_Description: "Applying for a business account in Bokio is quick and easy with no paperwork or start-up costs. All you have to do is click on the button below and then follow these steps:",
    BokioBusinessAccountSvea_Migration_ComplianceCaption: "When your application to upgrade your Bokio Business account has been approved, your current Bokio Business Account will be terminated, as well as your agreement with Bokio AB regarding financial services.",
    BokioBusinessAccountSvea_Migration_Description: "To get access to the new services in Bokio Business Account you need to make a new application for your company. You will keep your bankgiro number (it will be transfered automatically) and when your application has been approved, the balance on your current business account will be transferred to the new bank account.",
    BokioBusinessAccountSvea_Migration_FirstStepBlock_Title: "Application for Bokio Business Account:",
    BokioBusinessAccountSvea_Migration_SecondStepBlock_Title: "Activate and transfer money to your new Bokio Business Account:",
    BokioBusinessAccountSveaReadyToOnboardBlock_Description: "With Bokio Business Account, you get an integrated bank account, business card and other banking services that allow you to manage your finances as easily as your bookkeeping. Pay suppliers and employees, get paid from customers, manage transactions to Skatteverket and much more, directly in Bokio. **Bokio Business Account is included in your price plan.**\n",
    BokioBusinessAccountSveaReadyToOnboardBlock_Header_Badge_Text: "New: Swish Business",
    BokioBusinessAccountSveaReadyToOnboardBlock_StartedByAnotherUser: "Another user has already started the activation of Bokio Business Account",
    BokioBusinessAccountSveaReadyToOnboardBlock_Title: "Apply for Bokio Business Account",
    BokioCard: "Bokio card",
    BokioCards: "Bokio cards",
    Cancelled: "Cancelled",
    CancelOnboarding_ConfirmModal_Description: "Are you sure you want to cancel the current Bokio Business Account setup?",
    CancelOnboarding_ConfirmModal_LeaveFeedback: "Please let us know why you want to cancel your Bokio Business Account setup (optional)",
    CancelOnboarding_ConfirmModal_Title: "Cancel Bokio Business Account setup",
    CancelOrder: "Cancel order",
    CancelPayment: "Cancel payment",
    CancelPayment_ConfirmMessage_ConfirmationModal: "Are you sure you want to cancel the payment you selected?",
    CancelSetup: "Cancel setup",
    Card: "Card",
    Card_ActivateAndRenew: "Activate and renew",
    Card_InRenewal_NoticeInfo: "Your new card is on the way! You can activate and renew when it is delivered to you. Not received yet? {Contact support}",
    Card_Mask: "Card - xxxx {0}",
    Card_ReplaceCard_ConfirmModalDone: "Your card is now replaced with the new card. Your card order is placed and will be delivered to your provided address within 5-7 working days.",
    CardDeliveredTo: "Card delivered to",
    CardDeliveryPrompt_Description_1: "Card will usually be delivered within 2 weeks.",
    CardDeliveryPrompt_Description_1_Renewal: "Your new card is on the way! You can activate your renewed card when it is delieverd to you.",
    CardDeliveryPrompt_Description_2: "Not received yet?",
    CardDetails: "Card details",
    CardDetailsActivateSection_CardHoldersPhoneNumber_InputFieldLabel: "Card holder’s phone number",
    CardDetailsActivateSection_CardHoldersPhoneNumber_InputFieldToolTip: "Phone number will be connected to the card and PIN code will be sent to this as SMS.",
    CardDetailsActivateSection_LastFourDigitsOfCard_InputFieldLabel: "Enter last 4 digits of the card you received",
    CardDetailsSecuritySettingsSection_ContactSupportDescription: "Want to know more about using your card? Do you need to order a new one? Read more about your Bokio card in our {help guide}. If you need more help, please {contact support}.",
    CardHolderName: "Card holder name",
    CardIsAlreadyOrdered: "The card is already ordered.",
    CardLimitCurrencyWeek: "Card limit ({0}/week)",
    CardListScene_CannotOrderCardBecauseActiveUserIsNotTheOnboardingUser_EmptyState: "Currently only the user who signed up the company for Bokio Business Account can order cards. We will add support for multiple users soon.",
    CardOrder_Success_Info: "Your card order is placed and will be delivered to your civil registration address within 2 weeks.",
    Cards_CardDelivery: "Card will be delivered to:",
    CardStatus: "Card status",
    CardWillBeDeliveredTo: "Card will be delivered to",
    Category: "Category",
    ChangePinCode: "Change PIN code",
    ChangeReceipt: "Change receipt",
    ChooseAccountAndRecipient: "Select account and recipient",
    ChooseBookkeepingAccount_AccountWillBeCreatedLater: "The account {0} will be created when your business account is activated. You can then view and change settings for the account under Settings and Payment accounts.",
    ChooseBookkeepingAccount_Description: "If 1930 is in use we will recommend another account, like 1932. Using 1932 instead of 1930 doesn't matter at all for you or your bookkeeping, the account works the same as 1930. The only difference is the final digit.",
    ChooseCardName: "Choose name to be printed on the card",
    ChooseCompanyName: "Choose company name",
    ChooseOrCreateNewRecipient: "Choose or create new recipient",
    CompanyCard: "Company card",
    CompanyName: "Company name",
    CompanyToggle: "Turn on to get your company name printed on the card.",
    CompletedTransactions: "Completed transactions",
    ConfirmCardActivationModal_Success_Description: "Your card is now activated and ready to use.",
    ConfirmPaymentCancelation: "Confirm payment cancellation",
    ConfirmTerminateCard: "Confirm terminate card",
    ConfirmWithBankId: "Confirm with BankID",
    ConnectAccount: "Connect account",
    ConnectedAccount: "Connected account",
    ConnectToBankBlock_Description: "Our bank connection allows you to keep your bookkeeping up to date. Save time and get a great overview directly in Bokio. All transactions are automatically fetched to your To do list, ready to be recorded in just a few clicks.",
    ConnectToBankBlock_Perk1: "Secure connection to your bank",
    ConnectToBankBlock_Perk2: "Record multiple transactions at once",
    ConnectToBankBlock_Perk3: "Create custom rules for how to record transactions",
    ConnectToBankBlock_Perk4: "Reduce mistakes and never miss any transactions",
    ConnectToBankBlock_Perk5: "Unlimited number of account connections",
    ConnectToBankBlock_ReadMore: "Read more about bank connection",
    ConnectToBankBlock_ReadMore_Link: "https://www.bokio.se/hjalp/bokio-foretagskonto-bankkoppling/bankkoppling/automatisk-bankkoppling/",
    ConnectToBankBlock_UserCountBadge: "{0} users have connected their bank",
    ConnectYourAccount: "Choose payment account",
    ConnectYourNewBokioBusinessAccount_Heading: "Connect your Bokio Business Account",
    ConnectYourNewBokioBusinessAccount_WithoutConsent: "Caution: You did not give consent to Bokio to store your transaction data. Therefore, you will experience a less efficient bookkeeping process that lacks integrations and automations.",
    ConnectYourNewBokioBusinessAccount_WithoutConsent_Paragraph: "Caution: A revocation of this Instruction may affect Bokio’s ability to provide certain services that are integrated into Bokio’s bookkeeping services.",
    ContinueActivation: "Continue activation",
    ContinueBbaKycApplication_TodoGroupName: "Manage business account",
    ContinueWithBankID: "Continue with BankID",
    CopyAccountNumber: "Copy account number",
    CopyClearingNumber: "Copy clearing number",
    CopyIban: "Copy IBAN",
    CountryOfCitizenship: "Country of citizenship",
    CountryOfResidence: "Country of residence",
    CreateInternationalPaymentForm_Bic_InputField: "BIC (Swift code)",
    CreateInternationalPaymentForm_BIC_RadioField: "BIC (Formerly Swift address)",
    CreateInternationalPaymentForm_BicToolTip_InputField: "The receiving banks identification code (previously called Swift address). The code shows which bank your payment is for. Enter the BIC code if you know it and click Ok. If you do not know it, click Search BIC code. BIC codes are mandatory for foreign payments.",
    CreateInternationalPaymentForm_EnterAmount_InputField: "Enter amount ({0})",
    CreateInternationalPaymentForm_IbanToolTip_InputField: "The IBAN, or International Bank Account Number, is an international method for writing bank account numbers that is used for SEPA payments to foreign countries.",
    CreateInternationalPaymentForm_RecipientDetails_BoxContentTitle: "Recipient details",
    CreatePaymentScene_AddSignAndPay_Button: "Add, sign and pay",
    CreatePaymentScene_AddToPayment_Button: "Add payment",
    CreatePaymentScene_BankgiroPlusgiro_RadioField: "Bankgiro/Plusgiro",
    CreatePaymentScene_CountryAndCurrency_BoxContentTitle: "Country and currency",
    CreatePaymentScene_MoreCountryCommingSoon: "More countries coming later.",
    CreatePaymentScene_PageTitle: "New payment or transfer",
    CreatePaymentScene_ReceivingCountry_SelectField: "Receiver's country",
    CreatePaymentScene_SaveThisRecipient: "Save this recipient for later use",
    CreatePaymentScene_SaveThisSupplier: "Save this supplier",
    CreatePaymentSuccess_SupplierInvoicePaymentAdded: "Your supplier invoice payment is ready to be signed.",
    CreatePaymentSuccess_SupplierInvoicePaymentScheduled: "Your supplier invoice payment has been scheduled successfully.",
    CreatePaymentWithNewRecipient: "Create payment with new recipient",
    CreateSwedishPaymentForm_giroNumber_InputField: "Bankgiro/Plusgiro number",
    CreateSwedishPaymentForm_MessageToolTip_InputField: "Sometimes 'message' is used instead of OCR numbers. It can consist of the customer, reference or order number. Ensure that the recipient can link the payment to your company using the message.",
    CreateSwedishPaymentForm_OcrNumberToolTip_InputField: "OCR numbers consist of a maximum of 25 digits and contain payment information.",
    CreateSwedishPaymentForm_ReferenceForReceiver_InputField: "Reference for receiver",
    CustomiseCard: "Customise card",
    DeleteBeneficiary: "Delete beneficiary",
    DeleteRecipient: "Delete recipient",
    DeleteRecipient_ConfirmMessage: "Are you sure you want to delete this recipient?",
    EditBeneficiary: "Edit beneficiary",
    EnableBanking_BankAccountConnectionRow_NeedsReconnection: "Needs reconnection",
    EnableBanking_BankAccountSelectModal_Description: "Select the bank accounts that you wish to connect to Bokio.",
    EnableBanking_BankAccountSelectModal_Title: "Select bank account(s)",
    EnableBanking_MigrationNotice_Content: "Bokio now uses the Enable Banking authentication service instead of Klarna. The bank connection works just like before, but with the advantage that we now support more banks than before.",
    EnableBanking_MigrationNotice_Title: "We are replacing Klarna with Enable Banking",
    EnableBanking_SelectBankModal_ContinueButtonText: "Continue authentication",
    EnableBanking_SelectBankModal_PsuType_Business: "Business account",
    EnableBanking_SelectBankModal_PsuType_Personal: "Personal account",
    EnableBanking_SelectBankModal_SelectBank: "Select bank",
    EnableBanking_SelectBankModal_SelectBankPlaceholder: "Search for your bank",
    EnableBanking_SelectBankModal_SelectPsuType: "Account type",
    EnableBanking_SelectBankModal_TermsPreview: "We use the Enable Banking online service as an authentication service. Their service enables a secure and stable connection with your bank.",
    EnableBanking_SelectBankModal_Title: "Connect bank",
    EnableBankingBankAccountSelectionModal_ConfirmCancelModal_Description: "If you cancel now, no changes will be saved. This means that your bank will not be connected to Bokio.",
    EnableBankingBankAccountSelectionModal_ConfirmCancelModal_Title: "Are you sure you want to cancel?",
    ExportBankStatementAsPdfForTerminatedBba: "Export bank statement for terminated Bokio Business Account",
    ExportDetailsAsPdf: "Export details as PDF",
    ExportStatement_Description: "Will be exported as PDF for the selected date range.",
    ExportStatementAsPdf: "Export statement as PDF",
    ExportStatementPdfFileName: "Bokio Business Account.pdf",
    Faq_Bba_Link: "https://www.bokio.se/hjalp/bokio-foretagskonto-bankkoppling/bokio-foretagskonto/vanliga-fragor-om-bokio-foretagskonto/",
    GetStartedWithBokioBusinessAccount: "Get started with Bokio Business Account",
    GoToPayments: "Go to payments",
    GotoSveaBank_WithoutConsent_ButtonText: "Go to Svea Bank",
    GoToTransactions: "Go to transactions",
    GroupPayment_ErrorInPayment_Description: "One or more payments have errors",
    GroupPayment_ErrorInPayment_Title: "Payment error",
    IBAN: "IBAN",
    InitiateBankIDSign: "Initiate BankID sign",
    InternationalPayments: "International payments",
    InternationalPayments_BillingOfService_Description: "Read more about costs for international payments on",
    InternationalPurchases: "International purchases",
    InvalidSavedRecipientWarning: "The selected {0} has invalid information. Please edit before continuing.",
    KlarnaConnectionKnownIssueException_CanNotSyncForSEB_WillContactUserWhenFixed_Description: "Automatic import from SEB does not work at the moment. Klarna and SEB are working to solve this as soon as possible. We will reach out to you as soon as it works again. If you have any questions please {contact our support}.",
    KlarnaConnectionKnownIssueException_CanNotSyncForSEB_WillContactUserWhenFixed_Title: "Automatic Import from SEB is currently not working due to technical problems",
    KYCNext: "Once your KYC is reviewed and ready to go, you can continue from here by connecting your bookkeeping account to your new Bokio Business Account. Want to be prepared for the next step? Read more about {how to choose bookkeeping account}.",
    KYCReviewStatus: "KYC review status",
    KYCReviewStatus_Description_ApprovedCanConnectBba: "Your application is approved. Please connect your Bokio Business Account.",
    KYCReviewStatus_Processing: "Processing",
    KYCSubmitted: "Thank you for submitting your KYC.",
    LinkedPhoneNumber: "Linked phone number",
    LoadReservedTransactions: "Show reserved transactions",
    LogoutModal_Description: "Your Secure connection to Bokio business account is about to timeout.\nWould you like to remain logged in?",
    LogoutModal_TimeRemaining: "Time remaning: {0}",
    LogoutModal_Title: "Session timeout",
    MakePayments: "Make payments",
    ManageCards: "Manage cards",
    ManualBankImport: "Manual bank import",
    ManualPayment: "Manual payment",
    MirChangeSignatoryScene_PageTitle: "Change payment signatory of Bokio Business Account",
    MirChangeSignatoryScene_Success: "Contact the support and we will help you change the payment signatory of Bokio Business Account.",
    MirOnboarding_AccountDetails_BoxDescription: "For now, you will be the only one with rights to sign payments and order cards in your Business Account.",
    MirOnboarding_AdditionalQuestionsKYC_StepName: "Additional questions (KYC)",
    MirOnboarding_AgreeUsingBankIdForFutureLogin_Checkbox: "I understand I will have to use BankID for every login after my company details is verified.",
    MirOnboarding_AllSignatoriesMustBeAtLeast18YearsOld_Error: "All signatories must be at least 18 years old.",
    MirOnboarding_AlreadyOnboarded: "Company with organisation number {0} is already signed up for Bokio Business Account. If this is an issue please contact support.",
    MirOnboarding_AlreadyUsingDifferentPersonalId: "You have signed up for Bokio Business Account using a different personal number in the past. Make sure you are using your own personal number when signing up for Bokio Business Account.",
    MirOnboarding_AuthenticateWithBankId: "Authenticate with BankID",
    MirOnboarding_ChooseYearlyTurnOver_SelectFieldLabel: "Choose yearly turnover",
    MirOnboarding_ConfirmAndSign_StepName: "Confirm and sign",
    MirOnboarding_ConfirmAndSignYourAgreement_BoxDescription: "Download and read through the agreement before you sign. To keep your account secure, we will require you to always log in with BankID when you have verified your company details.",
    MirOnboarding_ConfirmAndSignYourAgreement_BoxDescription_Success: "You have successfully signed the agreement.",
    MirOnboarding_ConfirmAndSignYourAgreement_BoxTitle: "Confirm and sign your agreement",
    MirOnboarding_ConfirmAndSignYourAgreement_InviteOthersToSignTheAgreement: "Invite others to sign the agreement",
    MirOnboarding_ConfirmAndSignYourAgreement_InviteOthersToSignTheAgreement_Description_AfterActiveUserSigned: "Initiate other signatories to sign the agreement. They will need to confirm their identity with BankID.",
    MirOnboarding_ConfirmAndSignYourAgreement_InviteOthersToSignTheAgreement_Description_BeforeActiveUserSigned: "You need to sign the agreement before you can initiate others to sign.",
    MirOnboarding_EditCompanySettings: "Edit company settings",
    MirOnboarding_FailedToCreateMirOrganisation_Error: "Failed to create organisation",
    MirOnboarding_FailedToSaveKyc_Error: "Failed to save kyc data",
    MirOnboarding_GoToCancelActication: "Go to cancel activation",
    MirOnboarding_InvalidPlan_Free: "The free plan is currently not available due to high demand. Please choose another plan to continue.",
    MirOnboarding_IsInvalidCompanyInfo_Error: "Your company information doesn't match the official records. You need to update it.",
    MirOnboarding_IsInvalidOrganisationNumber_Error: "Your organisation number doesn't match the official records. You need to update it. If you recently started your company, it usually takes a day before the information is updated in Bolagsverket. Try again tomorrow, and {contact support} if it still doesn't work.",
    MirOnboarding_IsNotSignatory_Error: "You are not allowed to sign on behalf of this company and can therefore not verify the company details",
    MirOnboarding_Kyc_SubmitCompanyDetails: "Submit company details",
    MirOnboarding_KycBokioBusinessAccountUsage_BoxDescription: "Select all options that applies to your business.",
    MirOnboarding_KycBokioBusinessAccountUsage_BoxTitle: "How will your company use Bokio Business Account?",
    MirOnboarding_KycCashSalesPercentageAnswer_From0To25: "Less than 25%",
    MirOnboarding_KycCashSalesPercentageAnswer_From26To50: "26% to 50%",
    MirOnboarding_KycCashSalesPercentageAnswer_From51To75: "51% to 75%",
    MirOnboarding_KycCashSalesPercentageAnswer_From76To100: "76% to 100%",
    MirOnboarding_KycCashSalesPercentageQuestion: "How big part of your sales is in cash?",
    MirOnboarding_KycFrequencyAnswer_AFewTimesAYear: "A few times a year",
    MirOnboarding_KycFrequencyAnswer_EveryDay: "Every day",
    MirOnboarding_KycFrequencyAnswer_EveryMonth: "Every month",
    MirOnboarding_KycFrequencyAnswer_EveryWeek: "Every week",
    MirOnboarding_KycFrequencyAnswer_LessOften: "Less often",
    MirOnboarding_KycGeneralQuestions_BoxDescription: "We need to know you and your company a little better before you start using our services. Please answer the questions below truthfully.",
    MirOnboarding_KycGeneralQuestions_BoxTitle: "General questions",
    MirOnboarding_KycQuestion_CountryOfResidenceOrCitizenshipNotSweden: "Is your country of residence and/or citizenship outside of Sweden?",
    MirOnboarding_KycQuestion_ForeignTransactions: "Are you planning to use the account for foreign transactions?",
    MirOnboarding_KycQuestion_Frequency: "How often will you make deposits/withdrawals?",
    MirOnboarding_KycQuestion_InWhichCountries: "In which countries?",
    MirOnboarding_KycQuestion_InWhichCountry: "In which country?",
    MirOnboarding_KycQuestion_IsHandlingCash: "Is your company handling cash?",
    MirOnboarding_KycQuestion_IsLiableToPayTaxInTheUS: "Are you liable to pay taxes in the United States?",
    MirOnboarding_KycQuestion_IsPEP: "Are you a politcally exposed person?",
    MirOnboarding_KycQuestion_IsResidenceForTaxPurposesNotSweden: "Is your residence for tax purposes outside of Sweden?",
    MirOnboarding_KycQuestion_IsSellingOutsideEU: "Is your company selling goods or services outside of EU/EEA?",
    MirOnboarding_KycQuestion_Turnover: "What is your yearly turnover?",
    MirOnboarding_KycQuestionsAboutBeneficiaries_BoxTitle: "Additional questions about the beneficiaries of your company",
    MirOnboarding_KycTurnoverAnswer_From3MTo30MSek: "3 to 30 million SEK",
    MirOnboarding_KycTurnoverAnswer_LessThan3MSek: "Less than 3 million SEK",
    MirOnboarding_KycTurnoverAnswer_MoreThan30MSek: "More than 30 million SEK",
    MirOnboarding_KycTurnoverAnswer_NewCompany: "New company",
    MirOnboarding_MissingAddress_Error: "Missing company address",
    MirOnboarding_MissingContactInfo_Error: "Missing contact person information",
    MirOnboarding_MultipleSignatories_Error: "We currently don't support companies that have multiple signatories, e.g. the board. Please come back later when we have added support for this.",
    MirOnboarding_NoBeneficialOwner_Error: "Company has no beneficial owner registered",
    MirOnboarding_OnboardingCompleted: "Onboarding Completed",
    MirOnboarding_OrganisationTypeNotSupported_Error: "We don't currently support this company type but are constantly working to increase the support. Please check back later when we support more types.",
    MirOnboarding_PageTitle: "Activate Bokio Business Account",
    MirOnboarding_QuestionsAbout: "Questions about",
    MirOnboarding_ReadTermsAndConditions_Checkbox: "I have read and understand the terms and conditions.",
    MirOnboarding_ReviewDetails_StepName: "Review details",
    MirOnboarding_ReviewDetailsStepBenefits_Title: "Your Bokio Business Account includes:",
    MirOnboarding_ReviewDetailsStepNotSupported_1: "Swedish account number and IBAN number",
    MirOnboarding_ReviewDetailsStepNotSupported_2: "Receive international payments",
    MirOnboarding_ReviewDetailsStepNotSupported_3: "Autogiro",
    MirOnboarding_ReviewDetailsStepNotSupported_4: "Withdrawals from your company tax account (Skatteverket)",
    MirOnboarding_ReviewDetailsStepNotSupported_Title: "Not supported right now: {how does this affect me?}",
    MirOnboarding_ReviewDetailsStepRequirePaidPlan_1: "Use Bokio's mobile app to pay your suppliers, view transactions and get push notification",
    MirOnboarding_ReviewDetailsStepRequirePaidPlan_2: "Automatic recording of invoice events",
    MirOnboarding_ReviewDetailsStepRequirePaidPlan_3: "Pay salaries and expenses directly from Bokio account",
    MirOnboarding_ReviewDetailsStepRequirePaidPlan_Title: "Requires Balance or Business price plan:",
    MirOnboarding_SelectSignatory_BoxDescription: "Please pick this carefully because you can't change this choice.",
    MirOnboarding_SelectSignatory_BoxTitle: "Who should sign on behalf of the company?",
    MirOnboarding_SelectSignatory_PreDescription: "To verify your company details you need to select signatories, accept terms and conditions and fill in additional information (KYC). You will be able to start using your Business Account when your company details are verified.",
    MirOnboarding_Signatories_Status_Waiting: "Waiting",
    MirOnboarding_TermsAndConditions_FileName: "Terms and conditions for Bokio payment services",
    MirOnboardingPrompt_CompleteTheSignUp: "Complete setup",
    MirOnboardingPrompt_CompleteTheSignUpWithBankId: "Complete setup with BankID",
    MirOnboardingPrompt_NoRemainingBankGiros_AllOut: "We are currently getting a high demand for Bokio Business Account and are closed for more sign-ups.",
    MirOnboardingPrompt_NoRemainingBankGiros_FreePlan_Dec202022: "Currently {Bokio Business Account} is only available for our {Premium} and {Pro} plans due to high demand. {Upgrade your plan} to get going immediately.",
    MirOnboardingPrompt_ProcessingYourAccount_StateDescription_1: "Your KYC is under review and we will notify you once your Bokio Business Account has been set up and is ready to use.",
    MirOnboardingPrompt_ProcessingYourAccount_StateDescription_2: "{Please contact support} if you have any questions.",
    NameOnCard: "Name on card",
    NoCardsToShow: "No cards to show",
    NoPaymentsToShow: "No payments to show.",
    NoPaymentsToShow_WhenFiltersApplied: "No payments to show based on the filters you applied.",
    NoRecipientsFound: "No recipients found",
    NoReservedTransactions: "Zero reserved transactions",
    NormalSveaOnboarding_ContinueNotice_Description: "Continue your application where you left off. If you are waiting for feedback from Svea, you can open and view the latest status here.",
    NormalSveaOnboarding_ContinueNotice_Title: "You have started an application for a Bokio Business Account",
    NoTransactionsTowShow_WhenFiltersApplied: "No transactions to show based on the filters you applied.",
    NotSigned: "Not signed",
    NumberOfPayments: "No. of payments",
    OCR: "OCR",
    OffboardingBBA_Acknowledgment_LosingBG: "I understand that, by continuing I’ll lose my allocated Bankgiro number.",
    OffboardingBBA_Acknowledgment_NoExpectedTransactions: "I acknowledge that I’m not expecting any customer invoice payments to this account.",
    OffboardingBBA_CanNotBeUndone_Warning: "Warning: This cannot be undone",
    OffboardingBBA_Footnote_ChargingIncomingPayments: "Bokio reserves the right to take a consultancy fee to sort out incoming transactions after termination.",
    OffboardingBBA_Footnote_OnlyBBANotice: "Note if this is your only Bokio Business Account: Terminating this account will change the way you login back to email and password + 2FA (if enabled).",
    OffboardingBBA_InvoicesModal_FixInvoices: "These are the list of upcoming or active invoices which use the Bokio Business Account as payment method. You need to register a payment or change the payment method for those invoices to continue terminating the Bokio Business Account.",
    OffboardingBBA_NoActiveBusinessAccount: "You don't have any active Bokio Business Account.",
    OffboardingBBA_Requirements_NoActiveCard: "Terminate debit card",
    OffboardingBBA_Requirements_NoActiveInvoices_Action: "View invoices",
    OffboardingBBA_Requirements_NoActiveInvoices_Description: "No upcoming/active invoices with this payment details",
    OffboardingBBA_Requirements_NoActivePayments: "No active payments in scheduled or ongoing state",
    OffboardingBBA_Requirements_NoPendingTransactions: "No reserved transactions",
    OffboardingBBA_Requirements_Title: "Requirements to terminate Bokio Business Account",
    OffboardingBBA_Requirements_UpdatedInvoiceSettings: "Update invoice payment details",
    OffboardingBBA_Requirements_UpdatedInvoiceSettingsCaption: "Note: Already published invoices will still have the old Bankgiro number on them. Wait for them to be paid, or provide your customer with the new payment details.",
    OffboardingBBA_Requirements_ZeroBalance: "Account balance should be zero",
    OffboardingBBA_TerminateAccount: "Terminate Bokio Business Account",
    OffboardingBBA_TerminationCompleted_Title: "Business account terminated",
    OffboardingBBA_TerminationConfirmModal_GiveFeedback: "Please let us know why you want to terminate Bokio Business Account (optional).",
    OffboardingBBA_TerminationConfirmModal_PlanNotDowngraded: "You can still use the other features of your current plan.",
    OffboardingBBA_TerminationConfirmModal_Success: "You have terminated your Bokio Business Account. Remember, you can activate Bokio Business Account again at any time.",
    OffboardingBBA_TerminationConfirmModal_Title: "Confirm termination",
    OnboardingStepsCompletedModal_Menu_BankAndTaxAccounts_Description: "This is where you can see all your Bokio Business Account transactions.",
    OnboardingStepsCompletedModal_Menu_Payments_Description: "A dedicated place where you can manage and sign all your outgoing payments like supplier invoices, salaries and manual payments.",
    OnboardingStepsCompletedModal_YourBokioBusinessAccountIsActive: "Your Bokio Business Account is active and ready to use. You got a lot more things in Bokio to explore now.",
    OnePayment: "1 payment",
    OnlinePurchases: "Online purchases",
    OpenApplication: "Open application",
    OrderCard: "Order card",
    OrderPlacedOn: "Order placed on",
    OtherSettings: "Other settings",
    OwnNote: "Own note",
    Payment_AntiMoneyLaundering_Message: "Bokio has to meet all the requirements of the Anti-Money Laundering, including monitoring transactions and understanding them as well as our customers’ behaviour. It is possible that we need to ask questions about one or several transactions on your Bokio Business Account. Therefore, it is important that you have documents for each of the transactions. In some cases, we will not be able to let a transaction through until we have contacted you. Always make sure to have your contact information up-to-date.",
    Payment_AntiMoneyLaundering_TitleInfo: "Bokio has to meet all the requirements of the Anti-Money Laundering.",
    Payment_AntiMoneyLaundering_TitleWarning: "Make sure you have documents for your transactions.",
    PaymentAdded: "Payment added",
    PaymentCancelled: "Payment cancelled",
    PaymentCenter_MultipleStatuses: "Multiple Statuses",
    PaymentDetails: "Payment details",
    PaymentId: "Payment ID",
    PaymentMatching_BannerCTA: "Match now",
    PaymentMatching_BannerTitle: "There are {0} international payment(s) that needs matching",
    PaymentMatching_ConnectConfirmText: "Unfortunately we are not able to undo this so please make sure these belong together",
    PaymentMatching_ConnectConfirmTitle: "Are you sure these belong together?",
    PaymentMatching_ConnectCTA: "Match to transaction",
    PaymentMatching_Description: "At the moment we are not getting all the information we need to automatically match the transactions with the payment that created them. To make sure you can make international payments while we wait for that to implemented you have to match these yourself. This step will be removed as soon as we can.",
    PaymentMatching_IsNotTextMatch: "Message not matching",
    PaymentMatching_IsTextMatch: "Message matches",
    PaymentMatching_PossiblePayment: "Payments that can be matched to this transaction",
    PaymentMatching_PossiblePaymentsInfo: "We have placed the payments we think match best based on text and date first. Pick the one that belongs together with this transaction.",
    PaymentMatching_Title: "Match international payments",
    PaymentMatching_TransactionsToMatch: "Transactions that need to be matched",
    PaymentNotSigned: "Payment not signed",
    PaymentOnTheWay: "Payment on the way",
    PaymentReason: "Payment reason",
    PaymentRejected: "Payment rejected",
    PaymentsCenter_AvailableAfterPayment_Summary: "Available after payments",
    PaymentsCenter_Error_Status: "Error",
    PaymentsCenter_HidePayment: "Hide this payment",
    PaymentsCenter_HidePayments: "Hide these payments",
    PaymentsCenter_NewPayment_Button: "New payment",
    PaymentsCenter_Paid_Status: "Paid",
    PaymentsCenter_PastDateInformation: "Date passed, sign and pay to pay on the earliest possible day.",
    PaymentsCenter_Payments_Title: "Payments",
    PaymentsCenter_ReadyToSign_Status: "Ready to sign",
    PaymentsCenter_Rejected_Reason: "The payment was rejected. Please make sure that the Bankgiro, PlusGiro or account number you’ve entered is correct or that your account has sufficient funds.",
    PaymentsCenter_Rejected_Status: "Rejected",
    PaymentsCenter_Scheduled_Status: "Scheduled",
    PaymentsCenter_ShowCancelledAndRejectedPayments_CheckBox: "Show cancelled and hidden",
    PaymentsCenter_ShowingPayments_Text: "Showing {0} out of {1} payment rows",
    PaymentsCenter_ShowPaidPayments_CheckBox: "Show paid",
    PaymentsCenter_SignAndPay_Button: "Sign and pay",
    PaymentsCenter_Transfering_Status: "On the way",
    PaymentsCenter_UnhidePayment: "Unhide this payment",
    PaymentsCenter_UnhidePayments: "Unhide these payments",
    PaymentsCenter_UpcomingPayments_Summary: "Upcoming payments",
    PaymentScheduled: "Payment scheduled",
    PaymentTo: "Payment to",
    PaymentType_AccountTransfer: "Transfer",
    PaymentType_Bankgiro: "Bankgiro",
    PaymentType_Bic: "BIC",
    PaymentType_PlusGiro: "PlusGiro",
    PaymentType_Sepa: "SEPA",
    PlaceOrder: "Place order",
    Plaid_ChangeToProduction_Modal_Description: "We’re moving to a better, more reliable Plaid bank feed connection!",
    Plaid_ChangeToProduction_Modal_Failed_Desc: "We could not connect your bank. Try again later",
    Plaid_ChangeToProduction_Modal_Instruction_1: "Connect your bank again",
    Plaid_ChangeToProduction_Modal_Instruction_2: "Check that the same bookkeeping/payment account as the previous connection is chosen",
    Plaid_ChangeToProduction_Modal_Instruction_3: "Enter your \"Get transactions from\" date as the date of the last transaction fetched, which can be seen from the bank feeds page",
    Plaid_ChangeToProduction_Modal_Instruction_4: "Save your changes",
    Plaid_ChangeToProduction_Modal_Instruction_Title: "You will need to:",
    Plaid_ChangeToProduction_Modal_Title: "Upgrade your bank feed connection",
    PlusgiroNumber: "PlusGiro number",
    PreparingYourAccount: "Preparing your account",
    Preview: "Preview",
    PrintCompanyNameOnCard: "Print company name on card",
    PurchaseSettings: "Purchase settings",
    ReadAbout_HowToTerminateBBA: "Read about how to terminate your old Bokio Business Account here.",
    Readmore_InternationalPayments: "Read more about international payments",
    ReadMoreAbout_AutomatedBankFeeds: "Read more about automated bank feeds here.",
    ReadMoreAboutHowToChooseBookkeepingAccount: "Read more about how to choose bookkeeping account",
    ReadyToBeActivated: "Ready to be activated",
    ReceiveEInvoiceRating_Feedback_Description: "Do you have any feedback that can help us improve your experience with receiving e-Invoice?",
    ReceiveEInvoiceRating_Rating_Title: "How was your experience with receiving e-Invoice?",
    ReceivePayments: "Receive payments",
    Receiver: "Receiver",
    ReceiverInfo: "Receiver information",
    ReceiverPayableStatus_Message_CountryNotSupported: "We currently don't support payments to this country",
    ReceiverPayableStatus_Message_CurrencyNotSupported: "You have to change the currency for this payment",
    ReceiverPayableStatus_Message_InternationalPaymentsNotEnabled: "It seems you haven't applied for international payments yet. To make payments to international accounts, please apply for this service.",
    ReceiverPayableStatus_Message_InternationalReceiverInformationNotMatching: "This IBAN has been reviewed but the other contact details differ. You either need to send this recipient for review again or update the saved recipient.",
    ReceiverPayableStatus_Message_InternationalReceiverNotRegistered: "There was no registered recipient with exactly this IBAN number. Due to money laundering regulatations you need to to send this recipient for review before you can make a payment.",
    ReceiverPayableStatus_Message_InternationalReceiverRegisterationPending: "Your application for this recipient is currently pending. You'll be able to send payments once the application is approved.",
    ReceiverPayableStatus_Message_SepaNotSupported: "The recipinent is set to SEPA payments but that is not supported.",
    ReceiverPayableStatus_Title_CountryNotSupported: "Country not supported",
    ReceiverPayableStatus_Title_CurrencyNotSupported: "Currency not supported",
    ReceiverPayableStatus_Title_InternationalPaymentsNotEnabled: "Apply for international payments",
    ReceiverPayableStatus_Title_InternationalReceiverInformationNotMatching: "Recipient information is not matching",
    ReceiverPayableStatus_Title_InternationalReceiverNotRegistered: "This recipient is not yet registered",
    ReceiverPayableStatus_Title_InternationalReceiverRegisterationPending: "Recipient application pending",
    ReceiverPayableStatus_Title_SepaNotSupported: "The recipient needs to be converted to SWIFT payments",
    ReceiversBankDetails: "Reciever's bank details",
    RecipientName: "Recipient name",
    Reference: "Reference",
    RejectedBy: "Rejected by",
    Replace: "Replace",
    ReplaceCard: "Replace card",
    RequiresSigningWithMobileBankId: "Requires signing with Mobile BankID",
    ResendPin: "Resend PIN",
    ReservedTransaction: "Reserved transaction",
    ReservedTransactions: "Reserved transactions",
    ResetAddress: "Reset address",
    ResumeApplication: "Resume the application",
    ResumeOngoingApplicationNotice_Description: "You can only have one ongoing application running at the same time. Right now you have an application for {product} that must be completed or canceled in order for you to apply for {product2}.",
    ReverseNameOrder: "Reverse name order",
    SearchRecipientPlaceholder: "Search recipient or create new",
    SearchSenderPlaceholder: "Choose bank account",
    SecuritySettings: "Security settings",
    SelectCountries: "Select countries",
    SelectCountry: "Select country",
    SelectPaymentAccount: "Select payment account",
    Sender: "Sender",
    SenderInformation: "Sender information",
    ShowingOfTotalRecipients: "Showing {0} of {1} recipients",
    ShowPaymentDetails: "Show payment details",
    SignAndPayModal_NoRights: "You don't have rights to sign payments for this company.",
    SignAndPayModal_TotalSelectedPayments: "Total ({0} {1}){ - multiple currencies}",
    SignAndPayModalError: "One or more payments failed to complete, please try again.",
    SignAndPayModalError_RowErrors: "One or more payments have errors. Please fix to continue or uncheck them and sign the other payments",
    SignAndPayModalErrorTitle: "Payment failed",
    SignAndPayModalSuccess: "All your payment(s) selected were successfully signed and scheduled.",
    SignAndPayModalTitle: "Sign and pay",
    SignAndPayRow_AddToPay_Button: "Add to pay",
    SignAndPayRow_MoveForLater_Button: "Pay later",
    Signed: "Signed",
    SignWithBankID: "Sign with BankID",
    StartApplication: "Start application",
    Svea_Application_Sent_Title: "Your application has been sent",
    Svea_InternationalPaymentsOnboarding_OrderUnderReview_Description: "Your application will now be reviewed by Svea Bank. The waiting time to receive a response depends on what is reviewed in your particular application. When your application has been reviewed, you will be notified of this.",
    Svea_InternationalPaymentsOnboarding_Success_Description: "Before you can send money abroad, you need to pre-register receivers.",
    Svea_SavingsAccountOnboarding_MainSceneTitle: "Open bank account",
    Svea_SwishOnboarding_AlreadyHasSwish: "You already have Swish on your transaction account.",
    Svea_SwishOnboarding_ChooseAccount_Description: "Select the bank account to which you want to receive your Swish payments. The account must be a transaction account.",
    Svea_SwishOnboarding_ChooseAccount_SelectPlaceholder: "Välj ett transaktionskonto",
    Svea_SwishOnboarding_ChooseAccount_Title: "Select bank account",
    Svea_SwishOnboarding_ConnectNumber_Description: "Choose whether you want to create a new Swish number or connect an existing one from another bank. If you are going to connect an existing Swish number, make sure that the organisation number matches what is currently connected to that Swish number.",
    Svea_SwishOnboarding_ConnectNumber_ExistingNumberOption: "Connect existing Swish number from another bank",
    Svea_SwishOnboarding_ConnectNumber_Info: "Once connected, all payments sent to your Swish number will be received by the transaction account **{0}** which has the bank account number **{1}**.",
    Svea_SwishOnboarding_ConnectNumber_NewNumberOption: "Create new number",
    Svea_SwishOnboarding_ConnectNumber_SwishNumber_Error: "Must be a valid Swish number that starts with 123 followed by seven digits (123 xxx xx xx)",
    Svea_SwishOnboarding_ConnectNumber_SwishNumber_Label: "Swish number",
    Svea_SwishOnboarding_ConnectNumber_SwishNumber_Placeholder: "Type existing Swish number",
    Svea_SwishOnboarding_ConnectNumber_Title: "Connect Swish number",
    Svea_SwishOnboarding_ErrorNotice: "**Swish Business could not be connected**\n\nPlease check that all information is correct and try again. If the problem persists, you are welcome to [contact our support](bokio-support:Bank).\n\n- **Swish number**: Check that you entered the correct Swish number.\n- **Existing number at Svea Bank**: If you have a Swish number connected to Svea Bank, you must first terminate your Swish agreement with them. Once you've done that, Swish reserves your number for 180 days, which gives you time to connect the number to your Bokio Business Account.\n- **Organisation number**: Check that your organisation number is correct. The organisation number must be the same as the one currently connected with the Swish number.",
    Svea_SwishOnboarding_ReviewAndSign_Description: "Review and check that your information is correct. Once you have signed with BankID, you can start receiving payments via Swish.",
    Svea_SwishOnboarding_ReviewAndSign_NoticeTitle_ExistingNumber: "Connect existing Swish number",
    Svea_SwishOnboarding_ReviewAndSign_NoticeTitle_NewNumber: "Connect new Swish number",
    Svea_SwishOnboarding_ReviewAndSign_Summary_BankAccount: "Connected bank account",
    Svea_SwishOnboarding_ReviewAndSign_Summary_CompanyName: "Company name",
    Svea_SwishOnboarding_ReviewAndSign_Summary_MonthlyFee: "Monthly fee",
    Svea_SwishOnboarding_ReviewAndSign_Summary_Orgnumber: "Org. nr",
    Svea_SwishOnboarding_ReviewAndSign_Summary_Price: "Transaction fee",
    Svea_SwishOnboarding_ReviewAndSign_Title: "Review and sign",
    SveaActivationBlock_Title: "Apply and get started with Bokio Business Account",
    SveaAddProductInternationalPayments_NoUserPermission_NoticeDescription: "To activate International Payments you need to have administration rights in Bokio.",
    SveaBBA_ActivationStatus_NonConsentNotice_Title: "Bokio Business Account activation status",
    SveaBBA_ApplicationApproved_Header: "Application review status",
    SveaBba_ApplicationRejected_Notice: "If you want to get in touch with Svea regarding your application, you can email them at {hej.bank@svea.com}. Feel free to {contact our support} if you need more help.",
    SveaBba_ApplicationRejected_NoticeDetails: "**Premium business admin even without Bokio Business Account**\n\nSvea Bank has made the assessment that they unfortunately cannot offer the business account you applied for. We are of course very sorry about this and would therefore like to help you further with how you can continue to use Bokio's other services available within your Premium plan. \n\nA good alternative to Bokio Business Account is to choose another banking solution and then connect it to Bokio via **automated bank feeds**. The transactions are automatically fetched daily from your bank, you don't have to manage them manually and can record quickly and easily from the Transactions to record page and in your To do.",
    SveaBBA_ApplicationReviewStatus_Header: "Application review status",
    SveaBBA_ApplicationStatus_Details: "Your application is under review. In the meantime, you can continue to use your current business account as usual. When your application has been approved you will be able to continue and activate your new business account. If you want to get in touch with Svea regarding your application, you can email them at {hej.bank@svea.com}. Feel free to {contact our support} if you need more help.",
    SveaBBA_ApplicationUnderReviewStatus_Details: "You have provided information that Svea Bank must review manually in order to proceed with your application. The waiting time for a response depends on what is being reviewed in your specific application. In some cases, it can take up to a few weeks for the review to be completed. Svea is working as fast as they can and we thank you for your patience. Please {contact our support} if you need help.",
    SveaBBA_ApplicationUnderReviewStatus_Title: "The information you have provided is reviewed by Svea",
    SveaBBA_Migration_SepaWarning: "If you have any saved SEPA suppliers/recipients we will migrate their payment method to Swift when you onboard to international payments. This is so that you need to do less work to make future payments to these.",
    SveaBBA_OptionalServices_BankGiro_Info: "Do you have an existing bankgiro number that you would like to transfer to your Bokio Business account? (If not, we will create a new one for you)",
    SveaBBA_OptionalServices_BankGiro_InputLabel: "Enter your existing Bankgiro number",
    SveaBBA_Stepper1: "Review details",
    SveaBBA_Stepper2: "Confirm and sign",
    SveaBBA_Stepper3: "General questions",
    SveaBBA_Stepper4: "Optional services",
    SveaBBA_Stepper4_BbaMigration: "Activate account",
    SveaBBA_Stepper5: "Connect account",
    SveaBBA_WhatsNext_Details: "Once your application is reviewed and ready to go, you can continue from here by connecting your bookkeeping account to your new business account. Want to be prepared for next step? Read more about {how to choose a bookkeeping account}.",
    SveaBBA_WhatsNext_Header: "What's next?",
    SveaBba_WithPremium_Point_1: "Do your bookkeeping easy with ready-made templates.",
    SveaBba_WithPremium_Point_2: "Invoice and get paid more easily with customised features such as custom design, e-invoicing and recurring invoices.",
    SveaBba_WithPremium_Point_3: "Save time and streamline bookkeeping by connecting your bank.",
    SveaBba_WithPremium_Point_4: "Integrated salary management with automatic calculation of salary, taxes and fees incl. employer declaration.",
    SveaBba_WithPremium_Title: "With Premium you can, for example:",
    SveaBBAMigration_ActivateAccount_FinalizeApplication_ApprovedHeader: "Your application is approved",
    SveaBBAMigration_ActivateAccount_FinalizeApplication_ReadyToBeActivated: "Ready to be activated",
    SveaBBAMigration_ActivateAccount_FinalizeApplication_WhatsNext: "Once your application has been approved, you can proceed to activate the new Bokio Business Account. To do this, it requires that your current business account does not have\n- Scheduled or ongoing payments\n- Reserved transactions\n\nUpon activation, the balance will be transferred to your new bank account (within 1-2 banking days) and your bankgiro number will be transferred automatically. The transfer takes a few days and you will receive a notification in your Bank inbox when it’s completed. Any payments made during the transfer will be visible on your account a few days after the transfer is completed. We will close the previous business account for you and as soon as the activation is done, you can order your business card.",
    SveaBBAMigration_ActivateAccount_Prerequisites_Description: "Payments and transactions must be done before we can terminate your old account and transfer your funds to your new Bokio Business Account.",
    SveaBBAMigration_ActivateAccount_Prerequisites_Title: "Checklist",
    SveaBBAMigration_ActivateAccount_PrerequisitesNotice: "You have tasks to manage before you can activate your new account. See the table below. If you have any questions please {contact our support}.",
    SveaBBAMigration_ActivateAccount_SetPassword_Description: "With your new business account, you will log in with your password in Bokio. BankID will be used to identify yourself and sign things connected to your business account.",
    SveaBBAMigration_ActivateAccount_SetTwoFactor_Disabled_Description: "Would you like to add 2-factor authentication? If you don't add it now, you can always add it later in your settings.",
    SveaBBAMigration_ActivateAccount_SetTwoFactor_Disabled_Notice: "Adding 2-factor authentication keeps your account much safer. When you login we will require a code generated by your phone in addition to you password. [Read more about 2FA](https://www.bokio.se/hjalp/bokio-medlemskap/ditt-bokiokonto/tvafaktor-autentisering-med-bokio/).",
    SveaBBAMigration_ActivateAccount_SetTwoFactor_Enabled_Description: "Enter a verification code to verify your 2-factor authentication.",
    SveaBBAMigration_ActivateAccount_SetTwoFactor_Enabled_Notice: "You can deactivate the 2-factor authentication if you for example have lost your phone or for some other reason want to deactivate it. You can always activate it later again in your settings.",
    SveaBBAMigration_ActivateAccount_SetTwoFactor_Enabled_Verify_Error: "Failed to verify code",
    SveaBBAMigration_ActivateAccount_SignBankgiro_Description: "To make everything as smooth as possible for you, the bankgiro number {0} will be transferred and connected to your new bank account. Please confirm the transfer with your BankID. ",
    SveaBBAMigration_ActivateAccount_SignBankgiro_Title: "Confirm transfer of bankgiro number",
    SveaBBAMigration_ConfirmationModal_Description: "Confirm activation of your account to transfer the account balance to your upgraded Bokio Business Account.",
    SveaBBAMigration_ConfirmationModal_Explanation: "## You will keep your bankgiro number\nTo make everything as smooth as possible for you, the bankgiro number on your previous account will be transfered and connected to your new bank account.\n## Order your Mastercard debit card\nIf you have a business card on your previous account, this will be terminated. You will be able to order a new card. It may take up to 2 weeks for your new card to be delivered.\n## Your previous business account will be closed\nWe will close your previous account for you (this will not affect your bookkeeping in any way) and you can connect your new business account to a new bookkeeping account.",
    SveaBBAMigration_ConfirmationModal_NoticeContent: "The total account balance on your previous business account will safely be transferred to your new bank account within 1-2 banking days.",
    SveaBBAMigration_ConfirmationModal_NoticeHeading: "Transfer to your new bank account:",
    SveaBBAMigration_ConfirmationModal_Title: "Confirm activation",
    SveaBbaMigration_OrderMastercard_Details: "# Order Mastercard debit card \nYour business card on your previous account has been terminated and you will need to order a new card. It may take up to 2 weeks for your new card to be delivered.",
    SveaBBAMigration_SuccessModal_Explanation: "# Bank and tax account\nView all your Bokio Business Account transactions in one place.\n# Payments\nManage and sign all your outgoing payments, such as supplier invoices, salaries, and manual payments, from this dedicated section.\n# Transactions to record\nThe go-to location for recording both incoming and outgoing bank transactions.\n# Bokio cards\nOrder and manage your business card here. You can also block it and get a new one if necessary.\n# Bank inbox\nStay updated with important information from Svea.",
    SveaBBAMigration_SuccessModal_ExplanationHeading: "Explore the Bokio Business Account",
    SveaBbaMigration_SuccessModal_SeClearingAccountDescription: "To transfer money to your business account, use this bank account number.",
    SveaBeneficiaryOwnership_ApplicationStatusNotValid_NoticeDescription: "Your application is temporarily blocked. This is most likely a technical issue that our support will need to help you with. Please {contact our support} as soon as possible.",
    SveaCardOrder_UnderReview_Description: "You have provided information that Svea Bank must review manually. The review proceeding time to receive an answer depends on what is being reviewed in your particular application. We appreciate your patience. Feel free to {contact our support} if you have any questions regarding your card order.",
    SveaCardOrder_UnderReview_Header: "Card order under review",
    SveaConsent: "Sharing of account information",
    SveaInternationalPayment_AllowedCountries: "International payments can be made to the following countries:",
    SveaInternationalPayment_NotAllowedNotice_Description: "You don't have enough permissions to apply for international payment",
    SveaInternationalPayment_ReadyToOnboard_Description: "With Bokio Business Account you can make outgoing international payments. To get started, you first need to submit an application, which is a necessary step to ensure transaction security. It costs to make international payments, but the application itself and having the service activated is free of charge. The waiting time for a response depends on what is being reviewed in your specific application.",
    SveaInternationalPayment_ReadyToOnboard_Title: "Apply for international payments",
    SveaKyc_BlockServices_Notice: "In order to use the banking service Bokio Business Account you must {update your company details.}",
    SveaKyc_ReminderModal_Button: "Update company details",
    SveaKyc_ReminderModal_MainContent: "We ask you for this because banking service providers are required by law to regularly follow up on the information they have about their customers. It protects the financial market from money laundering and other criminal activity. Once completed, you can continue to use your Bokio Business Account as usual.\n\nHere you can read more about the [customer due diligence requirement](https://www.bokio.se/hjalp/bokio-foretagskonto-bankkoppling/bokio-foretagskonto/vanliga-fragor-om-bokio-foretagskonto/#kundkannedom).",
    SveaKyc_ReminderModal_NoticeContent: "In order to use the banking service Bokio Business Account, you must update your company details.",
    SveaKyc_ReminderModal_NoticeTitle: "You need to update your company information",
    SveaKyc_ReminderModal_Title: "Important for you with Bokio Business Account",
    SveaKycApplication_BlockBanner_Description: "In order to use the banking service Bokio Business Account, you must update your company details. Once completed, you can continue to use your Bokio Business Account as usual. We ask you for this because banking service providers are required by law to regularly follow up on the information they have about their customers. Here you can read more about the {customer due diligence requirement.}",
    SveaKycApplication_BlockBanner_Title: "You need to update your company details",
    SveaKycApplication_InfoBanner_Description: "As a Bokio Business Account holder, you must review and update your company details no later than **{0}**. Once completed, you can continue to use your Bokio Business Account as usual. We ask you for this because banking service providers are required by law to regularly follow up on the information they have about their customers. Here you can read more about the [customer due diligence requirement.](https://www.bokio.se/hjalp/bokio-foretagskonto-bankkoppling/bokio-foretagskonto/vanliga-fragor-om-bokio-foretagskonto/#kundkannedom)",
    SveaKycApplication_InfoBanner_Title: "Update your company details",
    SveaKycApplication_MainScene_BlockNotice: "In order to use the banking service Bokio Business Account, you must update your company details.",
    SveaKycApplication_MainScene_Description: "Providers of banking services are obliged by law to regularly follow up on the information they have about their customers. It protects the financial market from money laundering and other criminal activity. Once you're done, you can continue using your Bokio Business Account as usual. ",
    SveaKycApplication_MainScene_DueDateNotice: "As a Bokio Business Account holder, you must review and update your company details no later than **{0}**.",
    SveaKycApplication_MainScene_NoActionRequiredNotice: "Your company details is up-to-date.",
    SveaKycApplication_MainScene_ReadMoreText: "Here you can read more about the {obligation of customer knowledge}.",
    SveaKycApplication_MainScene_TopHeading: "Update your company information",
    SveaKycApplication_Settings_Subtitle: "Here you can update your company information when we and Svea Bank need to ensure that it is still correct.",
    SveaKycApplication_Settings_Title: "Company details for customer knowledge",
    SveaKycApplication_StartKycButton: "Update company details",
    SveaKycApplication_SuccessModal_Content: "We have received your updated company details. You can now continue to use the Bokio Business Account as usual.",
    SveaKycApplication_SuccessModal_Title: "Thank you",
    SveaNonConsent_BankCardNotice_Description: "If you do not consent, you will not be able to fully use the service and its features will be limited. This means that Bokio cannot access your card to show you more information.",
    SveaNonConsent_BankCardNotice_Title: "Your consent is needed to access your card",
    SveaNonConsent_BankInboxNotice_Description: "If you do not consent, you will not be able to fully use the service and its features will be limited. This means that Bokio cannot access your inbox to show you more details.",
    SveaNonConsent_BankInboxNotice_Title: "Your consent is needed to access your inbox",
    SveaNonConsent_BankInternationalPaymentsNotice_Description: "If you do not consent, you will not be able to fully use the service and its features will be limited. This means that Bokio cannot access international payments to show you more details.",
    SveaNonConsent_BankInternationalPaymentsNotice_Title: "Your consent is needed to access international payments",
    SveaNonConsent_BankPaymentNotice_Description: "If you do not consent, you won't be able to fully use the service and its features will be limited. This means Bokio cannot access your payments to show you more details.",
    SveaNonConsent_BankPaymentNotice_Title: "Your consent is needed to access your payments",
    SveaNonConsent_BankTransactionNotice_Description: "If you do not consent, you won't be able to fully use the product and its features will be limited. This means Bokio cannot access your transactions to show you more details.",
    SveaNonConsent_BankTransactionNotice_Title: "Your consent is needed to access your transactions",
    SveaNonConsent_ExistingTransactionsWhenGivingConsent_Notice: "If you want to instruct Svea Bank AB to share account information, you unfortunately have to {terminate your Bokio Business Account} and apply for a new one, where you choose to instruct Svea Bank AB to share account information with us.",
    SveaNonConsentNotice_Explanation: "In order to perform tasks related to your Business Account within Bokio, you need to either update your consent details or go to Svea Bank to take action.",
    SveaNonConsentNotice_Modal_Title: "Consent not given",
    SveaNonConsentNotice_ModalContent_Description: "You haven't given us your consent to fetch your account information from Svea.",
    SveaNonConsentNotice_ModalContent_Title: "Your consent is needed to access this service",
    SveaOffboarding_Description: "To terminate your Bokio Business Account, you need to contact Svea Bank. This is because Bokio Business Account is provided by Svea Bank AB. You can reach them via email at {hej.bank@svea.com} or via their telephone number {08-616 99 00}.",
    SveaOnboarding_CompanyDisclosure_Detail: "The entity concerned, which I represent, (the \"Company\"), hereby instructs Svea Bank AB (\"Svea\") to, on an on-going basis, share information about the Company's bank accounts held with Svea (including payment and transactional data) with Bokio AB (\"Bokio\") (the \"Instruction\"). This Instruction is valid from the date hereof, until it is revoked through Svea having received a notice in writing sent by a duly authorised representative of the Company.",
    SveaOnboarding_ConfirmBeneficialOwners_Description: "Please answer this carefully because you can't change this choice.",
    SveaOnboarding_ConfirmBeneficialOwners_Heading: "Is the following information about beneficial owner correct?",
    SveaOnboarding_ConfirmBeneficialOwnersStep_NoBeneficialOwnersExist: "Your company doesn't have any beneficial owner.",
    SveaOnboarding_CountryAndTinQuestionType_AddNewEntryButtonText: "Add new tax identification number",
    SveaOnboarding_CountryAndTinQuestionType_CountryListPlaceholder: "Choose Country",
    SveaOnboarding_CountryAndTinQuestionType_TinPlaceholder: "Tax Identification Number",
    SveaOnboarding_Finalizing_ThankYou_Heading: "Thank you for your application",
    SveaOnboarding_Finalizing_ThankYouForSubmittingYourKyc_Heading: "Thank you for submitting your application",
    SveaOnboarding_IncorrectResponseBeneficialOwner_NoticeDescription: "In order to activate Bokio Business Account, the beneficiary must be correct. On Bolagsverket's website you can login and make a new registration of beneficiary for your company. After 1-2 days you can then continue the activation. If you have any questions please {contact our support}.",
    SveaOnboarding_IncorrectResponseBeneficialOwner_NoticeHeader: "The information about beneficiary must be correct in order to proceed",
    SveaOnboarding_KycAmlHeading: "Customer knowledge for {0}",
    SveaOnboarding_KycApplicationHeading: "General customer knowledge",
    SveaOnboarding_KycBeneficialOwner_Description: "Questions about {0}",
    SveaOnboarding_KycBeneficialOwner_Heading: "Questions about ultimate beneficary",
    SveaOnboarding_KycQuestionsDescription: "We need to know you and your company a little better before you start using our services. Please answer the questions below truthfully.",
    SveaOnboarding_NoUserPermission_NoticeDescription: "To activate Bokio Business Account you need to have administration rights in Bokio.",
    SveaOnboarding_NoUserPermission_NoticeHeader: "Permissions are missing for your user",
    SveaOnboarding_ReviewDetails_AccountOwnerWarning: "Please note that the service currently only supports one user. Therefore, it is important that the person who will be using the business account is the one who activates it. As an account owner, you will be the only one who are able to manage the account.",
    SveaOnboarding_ReviewDetails_OptionalFeatures_Bankgiro: "Do you want a bankgiro linked to your business account?",
    SveaOnboarding_ReviewDetails_OptionalFeatures_Bankgiro_Description: "This is a service that you can choose for your Bokio Business Account at no extra cost. If you have an existing bankgiro, you will be able to choose whether you want to transfer it to your business account or not.",
    SveaOnboarding_ReviewDetails_OptionalFeatures_Title: "Optional services",
    SveaOnboarding_SniCodesStep_Description: "We are missing information about your company's SNI codes. Please select one or more SNI codes.",
    SveaOnboarding_SniCodesStep_ReadMoreLink: "Read more about SNI codes",
    SveaOnboarding_SniCodesStep_SearchPlaceholder: "Search SNI Codes",
    SveaOnboarding_SniCodesStep_SelectPlaceholder: "Select SNI codes",
    SveaOnboarding_SniCodesStep_Title: "SNI Codes",
    SveaOnboarding_SveaProductDefinitionId_Name_ForeignPayment: "international payments",
    SveaOnboarding_SveaProductDefinitionId_Name_SavingAccount: "savings account",
    SveaOnboarding_SveaProductDefinitionId_Name_TransactionAccount: "transaction account",
    SveaOnboarding_UnspecifiedBeneficialOwner_NoticeDescription: "Companies that are not registered as sole proprietorship must have at least one beneficiary in order to activate Bokio Business Account. On Bolagsverket's website you can login and register a beneficiary for your company. After 1-2 days you can then continue the activation. If you have any questions please {contact our support}.",
    SveaOnboarding_UnspecifiedBeneficialOwner_NoticeHeader: "There does not appear to be a beneficiary for this business",
    SveaRemoveSwish_ModalContent: "Are you sure you want to remove the Swish number **{0}** connected to **{1}**? Once removed, it cannot be undone.",
    SveaRemoveSwish_ModalTitle: "Remove Swish number",
    SveaReplaceCard_Modal_Description1: "Are you sure you want to replace your selected card ?",
    SveaReplaceCard_Modal_Description2: "This will permanently terminate the card and you can't undo this change.",
    SveaReplaceCard_Modal_TitleText: "Comfirm replace card",
    SveaReplaceCard_Reason_Option1: "Lost",
    SveaReplaceCard_Reason_Option2: "Stolen",
    SveaReplaceCard_Reason_Title: "Reason to replace card",
    SveaReplaceCard_SelectReason: "Please select the reason",
    SveaSavingsAccount_Onboarding_EntrypointButton: "Open new bank account",
    SveaSigPackageAgreement_ZeroSignatories_Notice_Description: "Unfortunately we can't find any signatory for your company. At least one signatory is required to activate Bokio Business Account.",
    SveaSigPackageAgreement_ZeroSignatoriesNotice_Header: "We were unable to find a signatory for the company. {Please contact our support.}",
    SveaSwishRemove_SuccessToastText: "Your Swish number has been removed",
    Swish: "Swish",
    SwishBusiness: "Swish Business",
    SwishBusiness_ReadyToBeUsed_Description: "Swish Business is ready to be used. Start enjoying effortless incoming payments by sharing your Swish number with your customers.",
    SwishCampaignBanner_DoesntHaveBba_Description: "Our business account just got better! Let your customers pay with Swish and immediately see your transactions in your bookkeeping. Apply for a Bokio Business Account and get started with **Swish Business** today – monthly fee waived for 2024 ({0}/month).",
    SwishCampaignBanner_HasBba_Description: "Get paid with Swish and immediately see your transactions in your bookkeeping. Fast, easy and secure for both you and your customers. Connect **Swish Business** to your Bokio Business Account – monthly fee waived for 2024 ({0}/month).",
    SwishCampaignBanner_Title: "Introducing Swish - monthly fee waived for the entire year",
    SwishConnected: "Swish connected!",
    SwishNumber: "Swish number",
    SwishOverview_BillingOfService_Description: "Read more about payment for Swish Business on {Billing of Bokio services}.",
    SwishOverview_BillingOfService_SectionTitle: "Billing of service",
    SwishOverview_ConnectedAccount_SectionTitle: "Connected bank account",
    SwishOverview_CopySwishNumberButton_Text: "Copy Swish number",
    SwishOverview_RemoveSwishNumberButton_Text: "Remove number",
    SwishOverview_SwishFaqLinkText: "Frequently asked questions about Swish",
    SwishOverview_TopBanner_Description: "All payments sent to your Swish number are received by the connected transaction account. You may only connect 1 Swish number to the transaction account you have within Bokio Business Account.",
    SwishOverviewPage_Feedback_Text: "Feel free to give feedback on how you think Swish Business works for you or if you have suggestions for improvements.",
    SwishOverviewPage_Feedback_Title: "How do you experience Swish Business?",
    SwishOverviewPage_OnboardingBanner_Description: "**Swish Business** is a service for those of you who want to get started and get paid immediately, without having to set up complicated cash register systems. You don't have to handle cash and the customers' payments go through directly.",
    SwishOverviewPage_OnboardingBanner_MonthlyCostBadge: "Free monthly fee {0} in 2024",
    SwishOverviewPage_OnboardingBanner_OnboardCTA: "Connect Swish Business",
    SwishOverviewPage_OnboardingBanner_StepList_ConnectNumber: "Connect a Swish number to the transaction account you have within Bokio Business Account. You have the option to connect an existing Swish number or get a new one.",
    SwishOverviewPage_OnboardingBanner_StepList_Sign: "Review the details and sign with your BankID.",
    SwishOverviewPage_OnboardingBanner_Title: "Connect Swish to your Bokio Business Account",
    SwishPricing_ReadMore_LinkLabel: "Read more about Swish",
    SwishProcessingMessage: "We are preparing your Swish information. It may take a few minutes.",
    SyncNow: "Sync now",
    SyncTransactions_Failed: "Failed to sync with the bank because of a temporary technical issue. Please try again tomorrow.",
    SyncTransactions_Success: "Successfully synced bank transactions. New transactions are ready to be recorded.",
    Terminate: "Terminate",
    TerminateAccount: "Terminate account",
    TerminateBba: "Terminate business account",
    TerminateCard: "Terminate card",
    TerminateCardDescription_1: "Are you sure you want to terminate your selected card?",
    TerminateCardDescription_2: "This will permanently disable the card and you can’t undo this change.",
    TerminateCards: "Terminate cards",
    Todo_ContinueNormalSveaOnboarding_Title: "You have started an application for a **Bokio Business Account**. Open to continue or see the latest status.",
    Todo_ContinueSwishOnboarding_Title: "You have started the connection of Swish Business but have not completed all the steps. Open to continue",
    TodoCard_Activate_Description: "Activate your business card and get all your card transactions automatically into Bokio, ready to be recorded.",
    TodoCard_Activate_Title: "Did you receive your card?",
    TodoCard_Order_Description: "Order your business card today and get all your card transactions automatically into Bokio, ready to be recorded.",
    TodoCheckMissingTransactionsWhenMigratingFromKlarnaRow_Description: "Make sure that the transactions for {0} are correct for the dates {1}. Compare the bank rows on your bank account with those imported into Bokio. If any transactions are missing, add them manually {here}. This is due to a time gap in the automatic import during the transition to our new authentication service, Enable Banking. After completing this, your bank connection will work as usual.",
    TodoCheckMissingTransactionsWhenMigratingFromKlarnaRow_Title: "Review imported transactions",
    TodoDepositMoney_Description: "You need to have money on the account to start making payments.",
    TodoDepositMoney_Title: "Deposit money to Bokio Business Account",
    TodoPayments_ReadyToSign: "You have {0} with a total amount of {1} ready to sign.",
    TodoPayments_ReadyToSign_MultipleCurrencies: "You have {0} in multiple currencies that are ready to sign.",
    TotalAmount: "Total amount",
    TransactionAccount: "Transaction account",
    TransactionDate: "Transaction date",
    Transfer: "Transfer",
    UnblockCard: "Unblock card",
    UpdateCardLimit: "Update card limit",
    UpdateCardLimitModal_CardLimitInputDescription: "The total limit on all purchases done in a 7-day period. You need to sign with Mobile BankID to confirm this change.",
    UpdateLinkedPhoneNumber: "Update linked phone number",
    UpdateLinkedPhoneNumberModal_LinkedPhoneNumberInputDescription: "Your Mastercard 3D secure verification code and future resent PIN codes will be sent to this phone number.",
    UpdateSettings: "Update settings",
    Upgrade: "Upgrade",
    UseDifferentDeliveryAddress: "Use different delivery address",
    ValidationError_PaymentDateNonBankingDay: "Payment date must be on a banking day",
    ViewAllAccounts: "View all accounts",
    ViewAndSign: "View and sign",
    ViewEditDetails: "View/Edit details",
    ViewEditRecipient: "View/Edit recipient",
    WithdrawMoney: "Withdraw money",
    XPayments: "{0} payments",
    YourCardIsReadyToOrder: "Your card is ready to order",
    YourPersonalNumber: "Your personal number",
  };
}