/* eslint-disable */

import type { SalaryLang } from "../types/SalaryLang";
import { translateTempImpl } from "../TranslatableLanguage";

export function SalaryLangDef(): SalaryLang {
  return {
    translateTemp: (key: string, neutral: string, svSE?: string, enGB?: string) => translateTempImpl(key, neutral, svSE, enGB),
    
    ABOUT_TO_DELETE_PAYROLL: "You're about to delete this payroll",
    ABOUT_TO_DELETE_PAYSLIP: "You're about to delete this payslip",
    AboutToDeleteEmployeePayslip: "You're about to delete {0}'s payslip",
    Absences: "Absences",
    AbsenceSettings: "Absence",
    AbsenceSettingsAndOverridesDescription: "Set vacation days for your employee. This step unlocks as soon as you’re done with the first step.",
    AbsenceState: "Absence state",
    AbsenceType: "Absence type",
    AccountsOfficeReference: "Accounts Office reference",
    AccountsOfficeRefTooltip: "You will find this on the letter that you received from HMRC when you first registered as an employer. It is in the format 123AB12345678.",
    ActivateEmployee: "Onboard employee",
    ActivateEmployee_Validation_Information: "In order to potentially send you a bill, we need this information about your company before you can activate your employees",
    ActivateEmployee_Validation_MissingCompanyInformation: "Missing company information",
    ActivateEmployee_Validation_UpdateCompanyInformation: "Update information",
    ActivateEmployee_Validation_UpdateCompanyInformation_NoAccess: "You do not have the rights to update this information. Contact the owner of the company and ask them to update this information.",
    ActiveAbsenceHeader: "Active",
    ADD: "Add",
    ADD_EMPLOYEE: "New employee",
    ADD_EMPLOYEE2: "Add employee",
    ADD_FIRST_EMPLOYEE: "Add your first employee",
    ADD_NEW_ROW: "Add new row",
    ADD_SALARY_ROW: "Add salary row",
    AddAbsence: "Add absence",
    AddADirector: "Add a Director",
    AddDays: "Add days",
    AddEmployeesToPeriod: "Add employee(s) to payroll",
    AddEmployerInformationFromHMRC: "Add employer information that you got when you registered as an Employer with the HMRC",
    AddJobTitle: "Add Job title",
    AddMoreDays: "Add more days",
    AddPayslips_InactiveEmployee_Tooltip: "An employee needs to be active to be used in a payroll.",
    AddPayslips_InactiveEmployees_Header: "Inactive",
    ADDRESS: "Address",
    Address_Line_1: "Address line 1",
    Address_Line_2: "Address line 2",
    Address_Line_3_Optional: "Address line 3 (optional)",
    AddStudentLoan: "Add student loan",
    Advance: "Advance",
    AdvancedOverridesDescription: "Bokio does the heavy lifting in all calculations for you. Sometimes, you might not agree with our base calculations. Use these overrides to change our base calculations as you see fit. These calculations will be stored on this employees contract settings, and will be used in upcoming payroll calculations.",
    AdvancedVacationReplacement_HelpText: "This will end up as a vacation replacement like: Hours x @ x 0.12 where 0.12 is your vacation replacement percentage",
    AGI_Content_ElementName: "Element name",
    AGI_Content_FieldCode: "Field code",
    AGI_Content_Heading: "Content in the file",
    AGI_Content_HU: "Main information",
    AGI_Content_IU: "Individual information",
    AGI_Content_Value: "Value",
    AGI_Error_AGINotSupported: "AGI is not available for this month",
    AGI_Error_MonthNotLocked: "The month needs to be locked first",
    AGI_FileValidation_HowToFix: "You can either fix these errors by first fixing the problems and then re-locking the month or by declaring manually",
    AGI_FileValidation_Title: "Skatteverket will most likely not accept this file",
    AGI_HowTo_Heading: "How you submit the declaration",
    AGI_HowTo_Intro: "Here you can download a file that you can then upload to Skatteverket  so you don't have to fill in the entire declarations manually.",
    AGI_HowTo_Update_Heading: "If you have to update the information at Skatteverket",
    AGI_HowTo_Update_Text: "If you need to update the information, our recommendation is that you do this manually at Skatteverket. We hope to be able to help you with this in the future, but at present it is not certain that it will be correct if you upload a new file from us.",
    AGI_Info: "The easiest way to declare is to upload this AGI-file ([review]({0})) that we generated for this month to Skatteverket.",
    AGI_Link: "Submission of employer declaration (AGI)",
    AGI_Link_Short: "Submission of AGI",
    AGI_Title: "Employer declaration, AGI",
    AGI_UploadFileHere: "You upload the file to Skatteverket at {0}",
    All: "All",
    AllJobTitles: "All titles",
    AMOUNT: "Qty",
    AMOUNT_TO_BE_PAID: "Amount to be paid",
    Annual: "Annual",
    Arreas: "Arrears",
    AT_AMOUNT: "@ {0}",
    BANK_ACCOUNT: "Bank account number",
    Bankgiro_ChooseFileFormat: "Choose file format",
    Bankgiro_CustomerNumberForBankgiro: "Customer number for Bankgiro",
    Bankgiro_CustomerNumberHelpText: "Contact your bank if you don't know your customer number",
    Bankgiro_NoMoreThanXCharacters: "max {0} characters",
    Bankgiro_Salaries: "Salaries",
    Bankgiro_SupplierPayments: "Supplier payments",
    Bankgironumber: "Bankgiro number",
    BasicDetails: "Basic details",
    BasicDetailsDescription: "Start by filling in some basic details about your employee! This step usually takes around 2 minutes if you have all the details up-front.",
    BBA_Info: "Pay through Bokios Business account. We automatically record the payment!",
    BBA_PaymentInfo: "Your payroll will be automatically recorded once the payment is done.",
    BENEFIT: "Benefit",
    BENEFITS: "Benefits",
    BillingItems_Description_FreeSeat: "Free active employees",
    BillingItems_Description_PaidSeats: "Extra active employees",
    BillingItems_Quantity: "Quantity",
    BillingItems_Sum: "Sum",
    BillingItems_Type: "Type",
    BokiosCalculation: "Bokio’s calculation",
    Bookkeep_BookkeptAs: "Recorded as",
    Bookkeep_CantBookkeepYet_Text: "Can not add entries in the future.",
    Bookkeep_ChangeSalaries: "Unlock payroll",
    Bookkeep_FinishSalaries: "Lock payroll",
    Bookkeep_FinishSalariesText: "Before locking the payroll make sure you are done with creating the payslips. When you have locked the payroll you can continue to pay and record the salaries and taxes.",
    Bookkeep_FromWhichAccountSalaries: "From which account were the salaries paid?",
    Bookkeep_FromWhichAccountTaxes: "From which account were the taxes paid?",
    Bookkeep_PayAndBookkeepSalaries: "Pay and record salaries",
    Bookkeep_Preview: "Show accounting",
    Bookkeep_Salaries: "Salaries",
    Bookkeep_SalariesBookkept: "Salaries are recorded",
    Bookkeep_ShowTaxReport: "Show tax report",
    Bookkeep_TaxBookkept: "Taxes are recorded",
    Bookkeep_UndoBookkeepingOfSalariesFirst: "Accounting of salaries need to be undone first",
    Bookkeep_UndoBookkeepingOfTaxesFirst: "Accounting of taxes needs to be undone first",
    Bookkeep_UnlockSalaryTaxPeriodFirst: "Salary period must be unlocked before undoing recording",
    BookkeepSalaries: "Record salaries",
    Button_LooksGood: "Looks Good!",
    CalculationDescription_DailySalary: "We base our daily salary calculation on the way most union agreements counts. 4,6% is an avarage daily salary by month if you take all working days in a year and divide them up.\n\nThis is how the calculation looks like: 1 day = 4,6% x monthly salary\n\nPutting in the values from {0}’s contract, we get: 1 x 4,6% x {1} = {2}",
    CalculationDescription_DailySalary_PartTimeEmployee: "Since {0} is working part-time, we have to take that into account:\n{1} x {2} = {3}",
    CalculationDescription_HourlySalary: "We base our hourly salary calculation on the way most union agreements counts.\n{0} makes {1}/month. You’ve set their work week to be {2} h/week.\n\nThis is how the calculation looks like: monthly salary x 12 months / (52 weeks in a year x work hours per week)\n\nPutting in the values from {0}’s contract, we get:\n{1} x 12 / (52 x {2}) = {4}",
    CalculationDescription_KarensDeduction: "The first time an employee is sick within a 5-day period, there’s a sick leave deduction withdrawn from their pay. This sick leave deduction is 20% of a weekly sick pay.\n\nExample:\n\nJane's weekly salary is 5 750 kr. The sick salary is 80% of that (4 600kr). The sick leave deduction is 20% of the sick salary (4600 x 20% = 920 kr)\n\nMonthly salary: 25 000 kr\n\nAbsent 2 days: -1150 x 2 (2 daily salaries) = -2 300 kr\n\nSick leave deduction = -920 kr\n\nSick pay = 2300 (2 days salary) x 80% = 1 840 kr\n\nRead more on how sick leave deductions work at [verksamt.se](https://www.verksamt.se/driva/anstalla/sjukskrivning/nar-en-anstalld-blir-sjuk/rakna-ut-karensavdrag)",
    CalculationDescription_SickSalary: "When an employee is sick they should still be paid a percentage of their regular salary. This is by default set to 80%.\n\nExample:\n\nJane's daily salary is 1 150 kr. If Jane is sick two days in a row, she’ll get 80% of the daily salary as sick salary for those days.\n\nMonthly salary: 25 000 kr\n\nAbsent 2 days: -1150 x 2 (2 daily salaries) = -2 300 kr\n\nSick leave deduction = -920 kr\n\nSick pay = 2300 (2 days salary) x 80% = 1 840 kr",
    CalculationDescription_VacationCompensation: "For employees on hourly salary, we’re using the “percentage rule”..\n\nThis is how the calculation looks like: Hourly salary x 12%\n\nExample:\n\nJohn is an hourly employee and has worked 100 hours this month. He gets 100 kr/h.\n\nSalary: 10 000 kr\n\nVacation compensation: 1 200 kr (10000 x 12%)",
    CalculationDescription_VacationSalary: "We’re using the “same salary”-rule. This means that one vacation day is: 1 daily salary + vacation salary (0,43 % of the monthly salary)\n\nThis is how the calculation looks like: Monthly salary x 0,43%\n\nPutting in the values from {0}’s contract, we get:\n{1} x {2} %= {3}",
    CalculationDescription_VacationSalary_PartTimeEmployee: "Since {0} is working part-time, we have to take that into account:\n{1} x {2} = {3}",
    CalculationDescription_WeeklySalary: "A weekly salary is used as a base to calculate sick pay.\n\nThis is how the calculation looks like: Yearly salary / weeks in a year\n\nPutting in the values from {0}’s contract, we get:\n({1} x 12) / 52 = {2}",
    CalculationDescription_WeeklySalary_PartTimeEmployee: "Since {0} is working part-time, we have to take that into account:\n{1} x {2} = {3}",
    Calculations: "Calculations",
    CANCEL: "Cancel",
    CashPayment_Info: "If your employees don’t have a swedish bank account, you can still use this payment method to get the recording and payslips correct.",
    CHANGE: "Change",
    CITY: "City",
    ClearingNumber: "Sort code",
    Commission: "Commission",
    COMPANY_SETTINGS: "Company settings",
    CompanySettingsModal_UseSameContactPerson_CheckboxLabel: "The salary contact person is the same as the company contact person",
    CompenasationBalanceInfo: "If the employee has vacation compensation that has not been paid out yet, fill in the sum here. This is common for hourly employees, or monthly employees with sales commission.",
    Compensation: "Compensation",
    CompensationBalance: "Compensation balance",
    CompensationUnit: "Compensation unit",
    CompletedVerificationModal_Description: "Nicely done! You can see the details on your recorded transaction below.",
    ConfirmAndContinue: "Confirm and continue",
    ConfirmChanges: "Confirm changes",
    ConnectOrInviteDirector: "Connect or invite this director",
    ContactPerson_Description: "The contact person is used when we generate files for Skatteverket",
    ContactPerson_Heading: "Salary contact person",
    Contract_DeleteConfirmation: "Are you sure you want to delete this contract?",
    Contract_SalaryPeriod_Description: "If you're unsure what period to pick, read our help article ",
    Contract_TaxColumn_Short: "column",
    ContractPeriod: "Period",
    ContractRenewsAt: "Contract renews at",
    ContractSettings: "Contract",
    ContractSettingsForm_AddPlaceOfEmployment: "Add place of employment",
    ContractSettingsForm_PlaceOfEmployment: "Place of employment",
    CorporationTaxUniqueTaxpayerReference: "Corporation Tax Unique Taxpayer Reference",
    COST: "Cost",
    CotaxReference: "COTAX reference",
    CREATE_NEW_EMPLOYEE: "Create new employee",
    CreateNewPayroll: "Create new payroll",
    CreatePayrollModal_PayDateWarning_FarFuture: "The chosen pay date {0} is far into the future. Please have a second look and make sure it's correct.",
    CreatePayrollModal_PayDateWarning_FarPast: "The chosen pay date {0} is far into the past. Please have a second look and make sure it's correct.",
    CreatePayrollModal_PaymentMethod_Description: "Note that all employees in this payroll needs to be paid out with the same payment method. If there are multiple different payment methods used with your employees, create a payroll for each payment method.",
    CreatePayrollModal_PickPaymentMethod: "Pick payment method",
    CreatePayrollModal_SetPayDate: "Set pay day",
    CreatePayrollModal_SetPayDate_Description: "What day will the salaries be paid out?",
    CreatePayrollUk_Validation_Title: "After you fix these {0} errors you can create the payroll",
    CreatePayrollUKModal_CreatePayroll: "Create payroll",
    CreatePayrollUKModal_PayDate_Heading: "Set payment date",
    CreatePayrollUKModal_PayDate_Label: "Payment date",
    CreatePayrollUKModal_PayDate_SubHeading: "Which date will the directors be paid?",
    CurrentBalances: "Current balances",
    CurrentContract: "Current contract",
    CurrentContractInvalid: "Current contract invalid",
    CurrentEmployee: "Current Employee",
    CurrentlyHaveNoActiveDirectorsPayrolls: "You currently have no active Directors payrolls running.",
    CurrentlyOnlySupportUkResidents: "Currently we only support directors that are UK residents for Directors Payroll.",
    CUSTOM: "Custom",
    CUSTOMTAX: "Custom tax",
    DailySalary: "Daily salary",
    DailySalaryRate: "Daily salary",
    DateOfBirth: "Date of birth",
    DAY: "Day",
    DayPerYear: "Days per year",
    DAYS: "Days",
    DaysEarnedInForNextYear: "Days earned in for next year",
    DaysLeft: "Days left",
    DaysLeftThisPeriod: "Days left this period",
    DaysPerYearInfo: "This value is based on the contract level. If you need to edit this value, we suggest creating a new contract for this employee with the correct amount of vacation days per year.",
    DaysUsed: "Days used",
    DaysUsedThisPeriod: "Days used this period",
    DELETE: "Delete",
    DeleteContract: "Delete contract",
    DeletePayslip: "Delete payslip",
    Director_PayrolIInterval_Monthly: "Monthly",
    Director_PayrollInterval_Anually: "Annually",
    DirectorDetails: "Director details",
    DirectorsAddress: "Director's address",
    DirectorSetup_AccountAlreadyLinkedToDirector: "This account ({0}) is already linked to a Director.",
    DirectorSetup_ConnectInviteEmployee_Button_Text: "Connect/Invite",
    DirectorSetup_ConnectInviteEmployee_Text: "Connect this Director to an existing Bokio user, or invite a new user. This will let them see their payslips when they log in.",
    DirectorSetup_ConnectToHmrc_ValidationError_Title: "After you fix these {0} issues you can connect to the HMRC",
    DirectorSetup_CreateDirector: "Create director",
    DirectorSetup_CreateDirector_Existing_Description: "Convert an already existing employee from expenses to a Director.",
    DirectorSetup_CreateDirector_Existing_Title: "An existing employee",
    DirectorSetup_CreateDirector_Myself_Description: "The director will be linked to this account. ({0})",
    DirectorSetup_CreateDirector_Myself_Title: "Myself",
    DirectorSetup_CreateDirector_New_Description: "Create a new Director ",
    DirectorSetup_CreateDirector_New_Title: "New Director",
    DirectorSetup_CreateDirector_Title: "Who are you creating a director for?",
    DirectorSetup_DirectorDetails_ValidationError_Title: "After you fix these {0} issues you can confirm the director details",
    DirectorSetup_EmployeeInviteSent: "Invite sent...",
    DirectorSetup_EmployementDetails_ValidationError_Title: "After you fix these {0} issues you can confirm the employement details",
    DirectorSetup_Employer_Save: "Save and continue",
    DirectorSetup_ExistingEmployee: "Existing employee",
    DirectorSetup_HmrcCredentials: "HMRC credentials",
    DirectorSetup_HmrcCredentials_AlreadyRegistered: "You have already registered your credentials with us",
    DirectorSetup_NewEmployeeWithoutP45: "New employee without P45",
    DirectorSetup_NewEmployeeWithP45: "New employee with P45",
    DirectorSetup_NoAccountConnected: "No account connected",
    DirectorSetup_Taxes_ValidationError_Title: "After you fix these {0} issues you can confirm the taxes",
    DirectorsPayroll_AddDirector: "Add Director",
    DirectorsPayroll_AddDirectorModal_Title: "Add directors to payroll",
    DirectorsPayroll_AddNewDirector: "Add new director",
    DirectorsPayroll_ChooseTaxPaydate: "Choose the date when you paid the taxes",
    DirectorsPayroll_CreateDirector: "New director",
    DirectorsPayroll_Deductions_Totals: "Deductions Totals",
    DirectorsPayroll_Director: "Director",
    DirectorsPayroll_DraftDirectors_Tooltip: "You need to convert these drafts to directors in order to use them in directors payroll",
    DirectorsPayroll_DueToHMRC: "**Due to HMRC** {0}",
    DirectorsPayroll_EmployeeNationalInsurance: "Employee National Insurance",
    DirectorsPayroll_EmployerNationalInsurance: "Employer National Insurance",
    DirectorsPayroll_FinalPayment_Status: "Final payment for the year",
    DirectorsPayroll_FPSsendingInProgressMessage: "Sending FPS to HMRC...\nThis might take a moment. We’ll notify you once it’s been sent.\nYou can leave this page and come back.",
    DirectorsPayroll_FPSSuccessfull: "FPS successfully sent! Here’s your receipt.",
    DirectorsPayroll_HasZeroGrossSalary_Notice: "You cannot submit a payroll with 0 gross salary. If you are not paying any employees in a tax month, you should instead submit an EPS {using another software}.",
    DirectorsPayroll_IncomeTax: "Income Tax",
    DirectorsPayroll_LastDateOfFPS_FinalPayment_SubmissionMessage: "Submit full payment submission (FPS) to HMRC by {0}. This is marked as the final FPS for the year. We will generate P60's to all directors that has been run in any payroll this year.",
    DirectorsPayroll_LastDateOfFPSSubmissionMessage: "Submit full payment submission (FPS) to HMRC by {0}",
    DirectorsPayroll_LateFPSSubmissionInfo: "You’re late with your FPS to HMRC. It was due {0}. When submitting a late FPS, you have to add \na reason, please select reason below:",
    DirectorsPayroll_LateReasonA: "A - Notional payment: Payment to expat by third party or overseas employer",
    DirectorsPayroll_LateReasonB: "B - Notional payment: Employment related security",
    DirectorsPayroll_LateReasonC: "C - Notional payment: Other",
    DirectorsPayroll_LateReasonD: "D - Payment subject to Class 1 NICs but P11D/P9D for tax",
    DirectorsPayroll_LateReasonE: "E - Micro Employer using temporary ‘on or before’ relaxation",
    DirectorsPayroll_LateReasonF: "F - Impractical to report work done on the day",
    DirectorsPayroll_LateReasonG: "G - Reasonable excuse",
    DirectorsPayroll_LateReasonH: "H - Correction to earlier submission",
    DirectorsPayroll_LateSubmissionReason: "Late submission reason",
    DirectorsPayroll_MarkAsFinalPayment_Button: "Mark as final payment for the year",
    DirectorsPayroll_NetPay_Totals: "Net Pay Totals",
    DirectorsPayroll_NoPayslips: "No directors added to this payroll yet.",
    DirectorsPayroll_Page_Title: "Directors payroll",
    DirectorsPayroll_Payroll_MarkAsDone: "Mark as done",
    DirectorsPayroll_Payroll_Unlock: "Unlock",
    DirectorsPayroll_PayrollDetails_Title_Annual: "Annual salary to directors",
    DirectorsPayroll_PayrollDetails_Title_Monthly: "Monthly salary to directors",
    DirectorsPayroll_PayrollInterval_Anually_Label: "Anually directors",
    DirectorsPayroll_PayrollInterval_Label: "Payroll interval",
    DirectorsPayroll_PayrollInterval_Monthly_Label: "Monthly directors",
    DirectorsPayroll_Record_Description: "Once you've paid out the salaries, and the payment date has passed, it's time to record the payment of the salaries.",
    DirectorsPayroll_RecordPayroll_NetWages: "Net wages",
    DirectorsPayroll_RecordPayroll_PaymentDateNotPassed: "You can record the salary payment once the payment date has passed.",
    DirectorsPayroll_RecordPayroll_Title: "Record payroll journal entry",
    DirectorsPayroll_RecordTaxesPaydateInfo: "Pay in everything due to HMRC by {0}.\nRead more on PAYE and how to pay it [here](https://www.gov.uk/pay-paye-tax).",
    DirectorsPayroll_SetPaydate_Label: "Payment date",
    DirectorsPayroll_StudentLoanInformation: "You cannot add an additional student loan as, HMRC only support one student loan being deducted from payroll per employee. You should only enter the loan with the lowest repayment threshold. For further clarification you should contact HMRC",
    DirectorsPayroll_SubmitRTIFPStoHMRC: "Submit RTI FPS to HMRC",
    DirectorsPayroll_SumTotals: "Sum totals",
    DirectorsPayroll_TaxesPaidToHMRC: "{0} Taxes paid to HMRC  {1}",
    DirectorsPayroll_UnavailableDirectors_Tooltip: "You can't use this type of directors for this payroll interval",
    DirectorsPayroll_Updated_LateFPS_SubmissionText: "Your FPS to HMRC was due {0}. When submitting an updated FPS after the due date you have to add a late reason, please select a reason below:",
    DirectorsPayrollAutoTaxPayComplete: "We’ve completed this automatically, since you didn’t have to pay any taxes",
    DirectorsPayrollFeedback_MessageSubject: "Feedback for directors payroll",
    DirectorsPayrollFeedback_Text: "To be able to improve and make the Directors payroll feature even better we would love to get your feedback.",
    DirectorsPayrollFeedback_Title: "Provide feedback on Directors payroll",
    DirectorsPayrolll_Deductions: "Deductions",
    DirectorsPayrollSetup: "Set up Directors payroll",
    DirectorsPayrollSetup_EmptyMessage: "Pay out salaries to Directors. Integrated to HMRC.",
    DiscardVacationDebt: "Discard vacation debt",
    DONE: "Done",
    Download_P45s_Action: "Download P45's",
    Download_P60s_Action: "Download P60's",
    Download_Payslips_Action: "Download payslips",
    DownloadP45: "Download P45",
    DRAFT: "Draft",
    DraftDescription: "You have to finalize drafts in order to use them in a payroll",
    DRAFTS: "Drafts",
    EDIT: "Edit",
    EDIT_EMPLOYEE: "Edit employee",
    EditAbsence: "Edit absence",
    EditContract: "Edit contract",
    EditDirector: "Edit director",
    EditedBy: "Edited by",
    EditTaxModal_Deduction_Description: "An example of deduction could be RnD-deduction. Here, you'll write how much you've gotten deducted. You must add the deductions yourself when you declare to Skatteverket, as Bokio's AGI-file don't include them. However, the recording takes it into account.",
    EditTaxModal_SocialTaxReduction_Label: "Deduction on social costs",
    EditTaxModal_TaxAlternatives_Heading: "Tax alternatives",
    EligibleEmployeePrepaidVacation: "Eligible for prepaid vacation",
    EMAIL: "Email",
    EmailPayslips_ErrorMessage: "An error occured when sending the payslips",
    EmailPayslips_SendingText: "We are currently sending the payslips to your employees. This may take a while.",
    EmailPayslips_SentText: "All payslips have been sent. If there are any new employees that are receiving their first payslip, you may want to ensure they received it.",
    EMAILS_DELIVERED: "Payslips delivered",
    Employee: "Employee",
    Employee_Archive: "Archive employee",
    Employee_ArchiveConfirmation: "Do you really want to archive {0}?",
    Employee_Archived_List: "Archived employees",
    Employee_ArchiveEmployeeTitle: "Archive an employee",
    Employee_Reactivate: "Unarchive employee",
    Employee_RecordTransactions_Notice: "You have vacation balance transactions to record.",
    EMPLOYEE_USER_DISASSOCIATE: "Disassociate from user",
    EMPLOYEE_USER_INVITATION_PENDING: "Waiting for invitation to be accepted.",
    EMPLOYEE_USER_PERMISSIONS: "Edit permissions",
    Employee_WithUserArchiveConfirmation: "Do you really want to archive {0}?{1}This employee is also connected to a Bokio user and will still be able to log in. If you would like to remove full access to your company for {0}, you also need to remove that user from your company under Settings.",
    EmployeeActivate: "Manage activation",
    EmployeeAdded: "Employee saved!",
    EmployeeAddedDescription: "Great! Activate {0} to add them to payrolls.",
    EmployeeChargeOnboard_ActivatedEmployees: "Activated employee(s)",
    EmployeeChargeOnboard_Billing: "A charge will be added to your next monthly billing. You can see your billing under {settings and billing}",
    EmployeeChargeOnboard_YouActivatedEmployees: "You have activated employee(s) for payroll!",
    EmployeeDetails: "Employee Details",
    EmployeeDetailsDescription: "When you’ve stated first name and last name, you can save this employee as a draft.",
    EmployeeDetailsTitle: "Don’t have all the details?",
    EmployeeForm_CreatingEmployeeForUser: "This employee will be connected to the user {0} with email {1}",
    EmployeeImport_ShowEmployees: "Show employees",
    EmployeeImport_SuccessHeading: "{ok} of {total} employees were successfully imported",
    EmployeeList_ActiveForPayroll_Header: "Active for payroll",
    EmployeeNeedsToBeOnboardedToVacationTracking: "This employee needs to be onboarded to vacation tracking for their balances to keep up to date, and be recorded correctly.\nMake sure to onboard this employee as soon as possible!",
    EmployeeNumber: "Employee number",
    EmployeeNumberTooltipMsg: "HMRC requires a unique number to be assigned to each employee. If this is an existing employee, ensure that you use the same employee number that was used in any previous software. Use a different number if you re-employ someone in the same tax year as they left, or if an employee has more than one job in the same PAYE scheme.",
    EMPLOYEES: "Employees",
    EmployeeSaveAsDraft_Validation_Footer: "Note: After fixing this you can save this employee as a draft if you don't have all the information.",
    EmployeeSaveAsDraft_Validation_Title: "After you fix these issue(s) you can continue",
    EmployeesVacation_DraftDescription: "You have to finalize drafts in order to onboard them to vacation tracking",
    EmployeeUser_ConfirmRevokeUser: "Do you want to disassociate from this user account?",
    EmployerDetails: "Employer details",
    EmployerInformation: "Employer information",
    EmployerPAYEReference: "Employer PAYE reference",
    EmployerSetup: "Employer setup",
    EMPLOYMENT_NUMBER: "Employment nr",
    EMPLOYMENT_TYPE: "Employment type",
    EmploymentDetails: "Employment details",
    EmploymentLastDateLabel: "Last day of employment",
    EmploymentStartDate: "Employment start date",
    EndDate: "End date",
    Error_GroundsChangedBeforeSave: "Relevant data changed before you saved. Please review the contents and try to save again.",
    Error_SetOurReferenceFirst: "Please set contact person (our reference) before you publish the salaries. You can do that {here}.",
    ErrorInYourPayroll: "Errors in your payroll",
    EstimatedPaidAccrual_HelpText: "From {0} to {1}, this employee has earned in {2} days. This is not including absence that is not eligible for vacation accrual.",
    ExportSieFile: "Export SIE file",
    ExtraVacationDaysUse_Warning: "This will lead to your employee getting a negative value in {0} days left. We suggest you either split this into unpaid leave or change their balance later.",
    Failed: "Failed",
    Female: "Female",
    FillIn: "Fill in",
    FinalizeDraftToEditVacationbalances: "You have to finalize drafts in order to edit their vacation balances",
    FinalPaymentTooltipHeading: "Final payment",
    FinalPaymentTooltipMessage: "Director's last working day: {0}",
    FIRSTNAME: "First name",
    Fix: "Fix",
    ForHourlyOrComissionEmployeePayoutCompensation: "For hourly and commission based employees, you pay out vacation compensation on their salary. If they have any outstanding vacation compensation (that you haven’t paid out yet), fill it in below.",
    FPSResubmitWarning: "Are you sure you want to resubmit the FPS? You should only do this if you have made some changes that need to be reflected on this submission.",
    FULLTIME: "Full-time",
    FULLTIME_EMPLOYEE: "Monthly employee",
    FulltimePer: "Full-time (100%)",
    FutureContracts: "Future contract(s)",
    Gender: "Gender",
    GenerateReportPlaceholder_OpenFilters: "Open filters",
    GetStarted: "Get started",
    GetStartedByAddingFirstDirector: "Get started with Directors payroll by adding your first director to Bokio.",
    GiveUsFeedback: "Give us feedback",
    GoTo_Billing: "Go to billing",
    GoToEmployees: "Go to employees",
    GoToPayroll: "Go to payroll",
    GoToSalary: "Go to salary",
    GoToTaxPeriod: "Go to tax period",
    GoToThisEmployeesContract: "Go to this employee's contract",
    GoToVacationPage: "Go to the vacation page",
    GROSS: "Gross",
    GROSS_INCOME: "Gross salary",
    GROSS_PAY: "Gross pay",
    HaveBalancesAndNeedToSetToZero: "You have balances set to days for this employee. Before you can turn on vacation tracking, you have to re-calculate the daily values to Vacation compensation. All fields except “Compensation” should be 0 before you can move on.",
    Here: "here.",
    Hmrc_Employee_HoursWorked_16HoursTo24: "16 hours up to 24 hours",
    Hmrc_Employee_HoursWorked_24HoursTo30: "24 hours up to 30 hours",
    Hmrc_Employee_HoursWorked_30hoursOrMore: "30 hours or more",
    Hmrc_Employee_HoursWorked_FieldName: "Hours worked",
    Hmrc_Employee_HoursWorked_LabelText: "Normal weekly hours worked?",
    Hmrc_Employee_HoursWorked_LessThan16: "Less than 16 hours",
    Hmrc_Employee_HoursWorked_Other: "Other",
    HmrcPassword: "HMRC password",
    HmrcUserId: "HMRC User ID",
    HOUR: "Hour",
    HOURLY_EMPLOYEE: "Hourly employee",
    HOURLY_EMPLOYEES: "Hourly employees",
    HourlyCommissionEmployeeVacationFieldInfo: "For hourly and commission based employees, you pay out vacation compensation on their salary. If they have any outstanding vacation compensation (that you haven’t paid out yet), fill it in below.",
    HourlySalary: "Hourly salary",
    HourlyVacationCompensationRate: "Vacation compensation",
    HOURS: "Hours",
    HowBokioCounts: "How Bokio counts",
    HowOftenDoYouPayDirector: "How often do you pay this director?",
    Invalid: "Invalid",
    IsThisUkAddress: "Is this a UK address?",
    JobTitle: "Job title",
    KarensDeduction: "Sick leave deduction",
    KilometerCompensation: "Compensation / km",
    Kilometers: "Kilometers",
    LastDateOfEmployment_CantBeMoreThan30DaysInFuture: "Last date of employment can't be set to a date more than 30 days in the future from today.",
    LASTNAME: "Last name",
    Left: "left",
    LimitOverride_LastUntil: "until {0}",
    LoanType: "Loan type",
    Locked: "Locked",
    LoweredSocialFees: "Lowered social tax",
    LoweredSocialFeesDueToCovid: "Due to Covid-19, the government has lowered social tax for up to 30 employees on salaries up to 25 000 kr. This applies to salaries from March - June. We will lower the social tax by default. You can choose which 30 employees to apply the reduction to if you have more than 30 employees. Read more on",
    Male: "Male",
    ManageActiveEmployees_ApplyChanges_Button: "Apply changes",
    ManageActiveEmployees_BillingDetails: "Billing details",
    ManageActiveEmployees_BillingDetails_IntroText: "This is an overview of how much you’ll be billed for if your number of active employees stays this way. ",
    ManageActiveEmployees_Blocked_UsedInPayrolls: "This employee is already used in a current and/or upcoming payroll, and cannot be deactivated until it’s either removed or done.",
    ManageActiveEmployees_ConfirmExtraCosts: "I understand I will be billed for employees above the free limit",
    ManageActiveEmployees_DisplayingXOutOfYEmployees: "Displaying {1} out of {2} employees.",
    ManageActiveEmployees_DraftInfo: "Employees in the draft section lacks some information. They won’t be able to be activated until all information is filled. Edit these under each employee from the employee list.",
    ManageActiveEmployees_Header: "Manage active employees",
    ManageActiveEmployees_IntroText: "You can manage which employees are included in the payroll. Employees must be activated before they can be used in a payroll.",
    ManageActiveEmployees_NoSalary: "Here, you can manage which employees can be used in payroll. You are currently on the **{0}** plan,\n you need Balans or Business to be able to activate employees.",
    ManageActiveEmployees_PageTitle: "Activate employees",
    ManageActiveEmployees_ReachedLimitNotice_LinkText: "Read more on price plans page",
    ManageActiveEmployees_ReachedLimitNotice_Text: "All employees above limit will cost **{1} kr/employee per month**.",
    ManageActiveEmployees_ReachedLimitNotice_Title: "You have reached the limit of {1} free active employees",
    ManageActiveEmployees_ReadMoreOnHowItWorks: "Read more on how it works",
    ManualBankTransfer_Info: "Download the Bank giro-file, and upload to your own bank. Manually record payments.",
    MarkAsFinalPayment: "Mark as final payment",
    MarkAsRecorded: "Mark as recorded",
    MarkManuallyRecordedConfirmationText: "Marking this payment as manually recorded means that you've recorded the payment of taxes via the manual recording functionality. Are you sure you want to mark this as manually recorded?",
    milageCompensationOverLimit_Warning: "The compensation per kilometer you have set is above the tax free threshold. The surplus will be taxed as fringe benefits.",
    Missing_Info: "Missing Information",
    MissingInfoNote: "Note: You can still save this employee as a draft if you don't have all the information right now.",
    MissingInformationFields: "After you've filled in these fields you can use this employee in Salary",
    MissingSettingsToFinishSalary: "There are some settings missing, set the following values to be able to finish salaries.",
    MODEL: "Model",
    MON_APR_SHORT: "Apr",
    MON_AUG_SHORT: "Aug",
    MON_DEC_SHORT: "Dec",
    MON_FEB_SHORT: "Feb",
    MON_JAN_SHORT: "Jan",
    MON_JUL_SHORT: "Jul",
    MON_JUN_SHORT: "Jun",
    MON_MAR_SHORT: "Mar",
    MON_MAY_SHORT: "May",
    MON_NOV_SHORT: "Nov",
    MON_OCT_SHORT: "Oct",
    MON_SEP_SHORT: "Sep",
    MONTH: "Month",
    Monthly: "Monthly",
    MonthlyVacationRate: "Vacation salary",
    MONTHS: "Months",
    MoveOverBalances: "Move over balances",
    MUST_ADD_PAYSLIPS_BEFORE_BOOKKEEP: "You must add at least one payslip before accounting this month",
    MustAddPayslipBeforeAddPayment: "You must add at least one payslip to be able to add payment",
    MyPayslips: "My payslips",
    MyPayslips_noPayslips_message: "You haven't been given any payslips yet. When you receive your first payslip it will show up here.",
    MyPayslips_NoUserText: "You have no employee connected to your account in this company. The administrator can connect you to your salary.",
    NAME: "Name",
    NationalInsuranceCategory: "National Insurance category",
    NationalInsuranceCategoryLetter: "NI category letter",
    NationalInsuranceNumber: "National Insurance number",
    NET: "Net",
    NET_INCOME: "Net salary",
    NET_PAY: "Net pay",
    NetAdjustmentSettings_Heading: "Map net salary adjustment",
    NetAdjustmentSettings_MapAccount_Label: "Choose accounting account:",
    NEW_EMPLOYEE: "New employee",
    NewContract: "New contract",
    NewHire: "New Hire",
    NextStep: "Next step",
    NiCalculationMethodsTooltip: "National Insurance contributions can currently be calculated either on an annual basis or on a standard monthly one. The monthly calculation method is only available for directors on a monthly salary frequency.",
    NiCategoryLetterAOnlySupported: "We currently only support NI category letter A. Users with other category letters will not be able to use this service.",
    NicDirectorsAnnualMethod: "Directors annual method",
    NicNationalInsurancePaymentMethod: "National Insurance payment method",
    NicStandardMonthlyMethod: "Standard monthly method",
    NO_ARCHIVED_EMPLOYEES: "You currently have no archived employees",
    NO_EMPLOYEES: "You have no employees yet",
    NO_EMPLOYEES_ACTION: "Do you want to create your first employee?",
    NO_EMPLOYEES_NOTICE_One: "Oops, looks like you don't have any employees yet. Add your first employee to ",
    NO_EMPLOYEES_NOTICE_Two: "get started with expenses!",
    NoActivePayrolls: "No active payrolls",
    NoEmployeesFound: "No employees found",
    NoJobTitle: "No title",
    NoP45Available: "There is no P45 available. An eP45 will be generated you receive your final payslip of this employment.",
    NoP60sAvailable: "There are no P60 end of year certificates available yet. A P60 will be generated at the end of each tax year.",
    NoPayslipMessage: "This employee does not have any payslips yet. When they receive their first payslip it will show up here.",
    NoStudentLoansAdded: "No student loans added",
    NotApplicable: "Not applicable",
    NoTaxRulesAvailable: "No tax rules available",
    NoTaxRulesAvailableDescription: "The tax agency has currently not released their full rulesets for {0}. We have calculated your costs on {1} rulesets for now.\nYou will be able to mark the payroll as done as soon as we've gotten the full ruleset from the tax agency.",
    NOTE: "Note",
    NOTHING_TO_BOOKKEEP: "There is nothing to record.",
    NotOnboardedToVacationTracking: "Not onboarded to vacation tracking yet. Edit to onboard",
    NumberOfSavedDaysEmployeeHas: "This employee has {0} saved days.",
    OffboardingModalText: "This marks this payment to this director as a final payment. You do this if a director leaves, and you don’t\n   intend to pay this director in the future. This means that we’ll be reporting this to HMRC, and we’ll also create\n   a P45, which will be found under this directors payslips.",
    OnBoardEmployees: "Onboard employees",
    OnboardEmployeesToVacationTracking: "Onboard employees to vacation tracking",
    OnboardEmployeesToVacationTrackingDescription: "Go through your employees and correctly set each employees vacation balance. Lastly, turn on vacation tracking for the employee. It’s important you do this so that the calculations will be correct from now on.\nOnce the employees are onboarded, Bokio will track accrued balances and used days.",
    OnBoardingEmployeeDescription: "Now that you’ve turned on vacation balance tracking, it’s time to onboard your employees. You do this by setting the balances, and turning on balance tracking for each employee.",
    Overridden: "Overridden",
    Overrides: "Overrides",
    OverridesAffectedDescription: "Overrides affected by the new changes:",
    OverridesAvailibiltyInfo: "Overrides will be available as soon as you finish the first step",
    OverridesAvailibiltyInfo_Commision: "Overrides is only available to employees with a set salary",
    OverridesDisabledInfo: "The values are disabled because you’re using this contract for at least one payslip. If you wish to override any values below, you need to create a new contract.",
    Owner: "Owner",
    P45: "P45",
    P60s: "P60s",
    Pain_AccountNumber: "Account number",
    Pain_Salaries: "Salary payment with Handelsbanken",
    Pain_XCharacters: "{0} characters",
    PartTime: "Part-time",
    PastContracts: "Past contract(s)",
    PayDate: "Pay date",
    PaydateIsTooEarlyInTheMonth: "When the pay day is too early in the month the payment and bookkeeping might end up in the preceding month.",
    PaydateNeedsToBeInOpenFisaclYear: "Pay date needs to be in an open fiscal year",
    PayeReferenceTooltip: "You will find this on the letter that you received from HMRC when you first registered as an employer. It is in the format 123/A456.",
    Payment_AddPayment_Button: "Add payment",
    Payment_CanNotSignImminent_Warning: "Your pay day is close! Send in for approval, and make sure Admin with payment permissions signs this as soon as possible to pay out this payroll.",
    Payment_CanNotSignOverdue_Warning: "Your pay day has passed! Send in for approval, and make sure Admin with payment permissions signs this as soon as possible to pay out this payroll.",
    Payment_CanSignImminent_Warning: "Your pay day is close! Schedule and sign this payment as soon as possible to pay out this payroll.",
    Payment_CanSignOverdue_Warning: "Your pay day has passed! Schedule and sign this payment as soon as possible to pay out this payroll.",
    PaymentInterval: "Payment interval",
    PaymentStatus_AwaitingApproval: "Awaiting approval",
    PaymentStatus_ReadyToSign: "Ready to sign",
    Payroll_BlockedByInitiatedPayment: "You can't unlock or add a payment if a payment already has been initiated",
    Payroll_EmptyClearingNumber_Warning_Text: "This will still work, but we strongly recommend that you move the bank identifier part to the dedicated field.",
    Payroll_EmptyClearingNumber_Warning_Title: "Bank identifier is empty",
    Payroll_InvalidBankDetails_Error_Text: "To use this employee in a payroll; Make sure they have valid swedish bank account details, or delete this payslip and recreate it in a cash payroll.",
    Payroll_InvalidBankDetails_Error_Title: "Invalid bank account details",
    Payroll_NewPayroll_Button: "New payroll",
    Payroll_open: "Open",
    Payroll_Overdue: "Overdue",
    Payroll_Paid: "Paid",
    Payroll_PayrollHistory: "Payroll history",
    Payroll_RecordPayments: "Record payments",
    Payroll_SalaryPaymentMethod_Cash: "Cash payment",
    Payroll_SalaryPaymentMethod_ManualBankTransfer: "Manual bank transfer",
    Payroll_ShowInPayments_Button: "Show in payments",
    Payroll_SignInPayments_Button: "Sign in payments",
    Payroll_Sorting_Newest: "Show newest first",
    Payroll_Sorting_Oldest: "Show oldest first",
    Payroll_TaxesAndPayments_FillInEmployerDeclaration: "Go to Skatteverket and fill in an Arbetsgivardeklaration.",
    Payroll_TaxesAndPayments_NetAdjustment_BlockedUntilMapped: "You must map all net salary adjustments before you can record.",
    Payroll_TaxesAndPayments_NetAdjustment_Mapping_Action: "Change mapping",
    Payroll_Title: "Payroll",
    Payroll_UndoBookkeeping_Action: "Undo accounting",
    PayrollAddPayslipsModal_NoEmployeesYet: "You don't have any employees yet",
    PayrollHistoryUK_NetTotal: "Net total",
    PayrollHistoryUK_NoOfPayslips: "No. payslips",
    PayrollHistoryUK_PayrollStatus: "Payroll status",
    PayrollHistoryUK_TaxesTotal: "Taxes total",
    PayrollHistoryUK_XInterval: "{0} interval",
    PayrollInterval: "Payroll interval",
    PayrollListUK_AddDirectors_Action: "Add directors to pay run",
    PayrollListUK_DeductionTotals: "Deduction totals",
    PayrollListUK_EmployerNITotals: "Employers national insurance",
    PayrollListUK_FPSFailed_Action: "Submit FPS to HMRC",
    PayrollListUK_GrossPayTotals: "Gross pay totals",
    PayrollListUK_MarkAsReady_Action: "Mark pay run as ready",
    PayrollListUK_NetPayTotals: "Net pay totals",
    PayrollListUK_NoOfDirectors: "No. of directors",
    PayrollListUK_PaymentDate: "Payment date",
    PayrollListUK_RecordPayrun_Action: "Record pay run",
    PayrollListUK_RecordTaxes_Action: "Record payment of taxes",
    PayrollListUK_SendingFPS_Action: "Sending FPS...",
    PayrollListUK_Status: "Status",
    PayrollListUK_Status_Draft: "Draft",
    PayrollListUK_Status_FPSFailed: "FPS failed",
    PayrollListUK_Status_FpsTimeout: "FPS timeout",
    PayrollListUK_Status_Recorded: "Recorded",
    PayrollListUK_Status_RecordTaxes: "Record taxes",
    PayrollListUK_Status_ReportToHMRC: "Report to HMRC",
    PayrollListUK_XIntervalPayRunForY: "{0} interval pay run for {1}",
    Payrolls_ActivePayrolls: "Active payrolls",
    PAYSLIP: "Payslip",
    Payslip_Logo_Customise_Text: "Customise layout",
    Payslip_Logo_Empty_Text: "No logo",
    PAYSLIP_PDF_BACKUP: "BACKUP",
    PayslipList_AddPayslips: "Add payslips",
    PayslipRow_SetCustomCostPerUnit_Label: "Set custom cost per",
    PayslipRow_UnsupportedRowType: "Unsupported row - click to delete!",
    PayslipRow_WeBelieveCostIs_Pt1: "According to Bokio, the cost is",
    PayslipRow_WillEarnVacationDays: "Will earn vacation days",
    PayslipRow_Workload_Tooltip: "Input the workload for when the vacation was earned. The workload is used to calculate the vacation pay. Note that it can result in a negative vacation pay if the workload is higher now than when it was earned. {Read more in the help article}.",
    PAYSLIPS: "Payslips",
    Payslips_Tooltip: "To view and edit payslips, add a payslip and then click on the employee's name",
    PayslipsMustHaveBookkeepableRow: "There must be at least one payslip with recordable rows to continue",
    PdfFileNamePrefix: "Payslip",
    PensureAdBodyEmployees: "Check out Bokio's occupational pension offering for your employees: simple, affordable and digital!",
    PensureAdBodySoloWithoutPension: "It appears your company does not currently have any paid occupational pension. Take part in Bokio's offering for occupational pension via Pensure!",
    PensureAdBodySoloWithPension: "It looks like your company currently has occupational pension set up. That's great, but did you know that you can get higher pensions with lower ensurance fees? Compare and challenge your occupational pension! Check out Bokios offering below.",
    PensureAdButtonText: "Go to the offering",
    PensureAdHeadingEmployees: "Occupational pension via Pensure",
    PERMANENT_EMPLOYEES: "Permanent employees",
    PERSONAL_NUMBER: "Personal number",
    PHONE_NUMBER: "Phone number",
    PlaceOfEmploymentInfo: "The main rule is that an employee has his place of employment where the main part of the work is carried out. There are a number of exceptions to this general rule. It is the employer who is responsible for making an assessment of where the employee has his place of employment.",
    PlaceOfEmploymentInfoLink: "Read more about 'Place of employment' at skatteverket.se (in swedish)",
    PlaceOfEmploymentInfoSummary: "An employee can only have one place of employment with the same employer.",
    Plan: "Plan",
    PostGraduate: "Postgraduate",
    PrepaidDaysLeft: "Prepaid days left",
    PrepaidDaysUsed: "Prepaid days used",
    PrepaidVacation: "Prepaid vacation",
    PrepaidVacationDaysLeft: "Prepaid vacation days left",
    PrepaidVacationDaysUsed: "Prepaid vacation days used",
    PreviousEmployeeNICPaid: "Previous Employee NIC paid year to date",
    PreviousEmployerNICPaid: "Previous Employer NIC paid year to date",
    PreviousOutdatedReceipts: "Previous, outdated receipt(s):",
    PreviousTaxablePay: "Previous taxable pay year to date",
    PreviousTaxablePayTooltip: "Enter the total amount earned by this employee through payroll since the beginning of the tax year. If it is an existing employee you can take the information from any previous software, or if it is a new employee the details can be found on their P45. Do not enter income earned in prior years.",
    PreviousTaxPaid: "Previous tax paid year to date",
    PreviousTaxPaidTooltip: "Enter the total amount paid in income tax by this employee through payroll since the beginning of the tax year. If it is an existing employee you can take the information from any previous software, or if it is a new employee the details can be found on their P45. Do not enter tax paid in prior years.",
    PricePlans_ActiveEmployees_Title: "Active employees",
    PricePlans_ManageEmployeeActivation: "Manage activation",
    PricePlans_Salary_Info: "You need to activate employees in order to use them in a payroll. Read more about seats and plans {0}.",
    ProprietorshipOwnerSalaryWarning: "When you're a sole proprietor you are not an employee of your business entity. Use the owner's drawings to pay yourself.",
    ReadMore: "Read more",
    ReadMoreHere: "Read more here",
    Reason: "Reason",
    RecordTransactions: "Record transactions",
    RecordVacationTransactions: "Record vacation transactions",
    REGION: "Region",
    Renews: "To:",
    ReplacedByOtherContract: "Replaced by other contract",
    Reports_AllEmployees: "All employees",
    ResetToDefaultValueDescription: "By saving these new changes, your overrides on this employee will be reset to Bokios default values.",
    RestoreTo: "Restore to",
    ResubmitFPSToHMRC: "Resubmit RTI FPS to HMRC",
    RowLabel_HolidayPay: "Holiday extra pay",
    RowLabel_OddHours: "Odd hours",
    RowLabel_Overtime: "Overtime",
    RowLabel_Salary: "Salary",
    RowLabel_VacationReplacement: "Vacation replacement",
    SALARY_COST: "Salary cost",
    Salary_FirstSalaryRating_Description: "Any thoughts that could help us improve your experience of the salary flow?",
    Salary_FirstSalaryRating_Title: "How did you experience the salary flow?",
    SALARY_FOR: "Salary for",
    Salary_NameUndefined: "Name unspecified",
    Salary_PaymentDateMustBeInMonth: "Payment date must be set within the selected month",
    SALARY_PERIOD: "Salary Period",
    SalaryPayrollDetails_Feedback_Title: "Do you have any feedback on payrolls?",
    SalaryPayslipDetails_Feedback_Title: "Do you have any feedback on the creation of the payslip specification?",
    SalaryReport_EditFilterAndTryAgain: "Try editing your filters and generate report again",
    SalaryReport_HourlyPaid_Description: "Get insights on how much you’ve paid to your hourly employees over a time period",
    SalaryReport_HourlyPaid_Placeholder: "This report analyzes all payslip rows for hourly paid employees, for each employee you’ve chosen.",
    SalaryReport_HourlyPaid_Title: "Hourly paid employees",
    SalaryReport_NoRowsFound: "Oops, no rows found",
    SalaryReport_Placeholder_GetStarted: "Get started by picking the time interval you want to generate the report on, or leave empty for generate for all time.",
    SalaryReport_PlaceholderTitle: "Generate {title} report",
    SalaryReport_ReportDateRange: "This report spans from {0} to {1}.",
    SalaryReport_SalaryTotal_Description: "See how much tax, salary, or both combined you’ve paid out over a time period.",
    SalaryReport_SalaryTotal_Placeholder: "This report analyzes all payslip rows with salay, for each employee you’ve chosen.",
    SalaryReport_SalaryTotal_Title: "Salary totals",
    SalaryReport_SickDays_Description: "See how many sick days your employees took out over a time period",
    SalaryReport_SickDays_Placeholder: "This report analyzes all payslip rows with sick days, for each employee you’ve chosen.",
    SalaryReport_SickDays_Title: "Sick days",
    SalaryReport_SubTitle: "{0} report",
    SalaryReport_VacationDebt_Description: "Calculate the current vacation debt",
    SalaryReport_VacationDebt_Title: "Vacation debt",
    SalaryReport_VacationSummary_Description: "See how many vacation days your employees have used over a time period",
    SalaryReport_VacationSummary_Placeholder: "This report analyzes all payslip rows with vacation, for each employee you’ve chosen.",
    SalaryReport_VacationSummary_Title: "Vacation summary",
    SalaryReports: "Salary reports",
    SalaryReports_Description: "We’ve created some report templates to give you an overview of your business.",
    SalaryReports_GenerateReport: "Generate report",
    SalaryReports_PayslipDate: "Payslip date",
    SalaryReports_PayslipLink: "Link to payslip",
    SalaryReports_PickOneToGetStarted: "Pick one to get started!",
    SalaryReports_RunReport: "Run report",
    SalarySettings: "Salary",
    SalaryTaxPeriod_FeedbackTitle: "Do you have any feedback on the new salary flow?",
    SAVE: "Save",
    SaveAbsence: "Save absence",
    SaveChanges: "Save changes",
    SavedDays: "Saved days",
    SavedDaysDescription: "Here, you can add saved days by the year they were created. If you use a saved day on a payslip, Bokio will automatically use the oldest saved day.",
    SavedDaysFromPreviousPeriod: "Saved days from previous period",
    SaveDraft: "Save draft",
    SaveEmployee: "Save employee",
    SearchEmployee: "Search employee",
    SelectDate: "Select date",
    SelectEmployee: "Select employee",
    SelectEmployeesWithHighestSalaries: "Select employees with highest salaries",
    SelectOneOfFollowingStatements: "Select one of the following statements",
    SelectXOrFewerEmployees: "Select {0} or fewer employees with lowered social tax",
    Send_DownloadPdf_Action: "Download files",
    Send_EmailLinks_Action: "Email links",
    Send_EmailLinks_Description: "You will send links for the employees to download their payslips. This require all of them to have a Bokio account.",
    Send_EmailLinks_MissingUsers: "Employees who can't login to Bokio",
    Send_EmailPdf_Action: "Email files",
    SEND_PAYSLIP_EMAIL: "You are about to email the payslips as PDFs, sending personal data over email like this is not recommended. Do you still want to do it?",
    SetPeriod: "Set period",
    SetSalaryPayDay: "Set salary pay day",
    Settings_Archive: "Archive",
    Settings_ArchivePlaceOfEmployment: "Archive place of employment",
    Settings_ConfirmArchivePlaceOfEmployment: "Do you want to archive place of employment {0}, {1}?",
    Settings_ManagePlaceOfEmployment: "Manage places of employment",
    Settings_WhenRemovingPlaceOfEmployment: "When archiving a place of employment it will no longer be possible to select it for employee contract. The place of employment will still be used on all contracts which has it set.",
    ShouldNotBeEmpty: "should not be empty",
    SICK_LEAVE: "Sick leave",
    SickSalaryRate: "Sick salary",
    SOCIAL_COSTS: "social costs",
    SOCIAL_TAX_TOTAL: "Social tax total",
    StartDate: "Start date",
    StarterType: "Starter type",
    StartingDate: "Starting date",
    Starts: "From:",
    Step: "Step",
    StudentLoan: "Student loan",
    StudentLoans: "Student loans",
    SubmissionFailed: "Submission failed",
    SUM: "Sum",
    SURE_OF_DELETE_PAYROLL: "Deleting the payroll is permanent. Are you sure you want to continue?",
    SURE_OF_DELETE_PAYSLIP: "Deleting the payslip is permanent. Are you sure you want to continue?",
    SYS_ERROR_INVALID_SALARY_PERIOD: "Invalid salary period",
    SYS_LOADING: "Loading",
    Table: "Table",
    TabTax_NumberOfPayslips: "No. payslips",
    TAX: "Tax",
    TAX_CALCULATED_ON: "Tax calculated on",
    TAX_COLUMN: "Tax column",
    TAX_PERCENTAGE: "Tax (%)",
    TAX_TABLE: "Tax table",
    TaxCode: "Tax code",
    TaxCodeTooltip: "A tax code usually contains several numbers and a letter, for example: 1006L. However, there are some two character tax codes such as BR or 0T, which may be used. You should use the tax code from any previous software if it is an existing employee or, if it is a new employee with a P45, use the tax code from the P45. If they are a new employee without a P45 use the tax code 1257L. If the employee is based in Scotland or Wales, add S or C respectively as prefix for tax purposes.",
    TAXES: "Taxes",
    TaxesDue: "Taxes due",
    TaxPeriod_AGITitle: "Submit AGI to Skatteverket",
    TaxPeriod_BBA_SumsNotMatching_Text: "This can be OK if you have paid the taxes in a different way. In that case you can complete this step.",
    TaxPeriod_BBA_SumsNotMatching_Title: "The scheduled payment(s) ({0}) does not match the expected taxes ({1})",
    TaxPeriod_CantLockPeriodInfo: "To be able to lock the period you need to make sure all payrolls are locked and paid",
    TaxPeriod_CompleteWithoutAnySalaries: "Complete the month without any salaries",
    TaxPeriod_Deadline: "Deadline:",
    TaxPeriod_DeclarationInfo: "As a registered employeer you have to declare your salaries to Skatteverket every month. Even if you have no salaries that month.",
    TaxPeriod_DeclareHere: "Open declaration (Skatteverket)",
    TaxPeriod_DonwloadAGI: "Download AGI",
    TaxPeriod_HowToRecordPayments: "How to record the tax payment",
    TaxPeriod_LockPeriod: "Mark step 1 as done",
    TaxPeriod_MarkDeclarationAsDone: "Mark declarations as completed",
    TaxPeriod_MarkDeclarationAsNotDone: "Undo mark as declared",
    TaxPeriod_MarkPaymentsAsDone: "Mark tax payment as done",
    TaxPeriod_MarkPaymentsAsNotDone: "Undo mark as paid",
    TaxPeriod_MissingNetAdjustmentsWarning: "There are net adjustments that have to be mapped before the taxes are recorded. Open the payrolls for the period and fix it there.",
    TaxPeriod_MustCompletePreviousStep: "The previous step has to be completed first",
    TaxPeriod_PeriodTitle: "Salaries and taxes {0}",
    TaxPeriod_RecordTaxesInfo: "When the tax deduction/return shows up on your tax account you can record them from here. You can't record before that because you can't record in the future.",
    TaxPeriod_Step1Title: "Complete all payrolls",
    TaxPeriod_Step2Title: "Declare the taxes to Skatteverket",
    TaxPeriod_Step3Title: "Pay the taxes to Skatteverket",
    TaxPeriod_Step4Title: "Record the taxes",
    TaxPeriod_TaxPaymentTitle: "Tax payment - salaries {0}",
    TaxPeriod_UnpaidPayslips: "There are some payrolls where a payment is not recorded, you can continue but please remember to pay them and come back and record them later.",
    TaxPeriod_V1RecordingInfo: "This period was recorded in the old way",
    TaxPeriod_V1RecordingInfo_Long: "Bokio used to record salary taxes together with the payment. This can make it harder to reconcile your tax account. If you want to split them for this month you can:\n1) Undo the recording here\n2) Record the payment manually or from your bank sync\n3) Record this step again",
    TaxPeriods_CurrentStep: "Current step",
    TaxPeriods_NotStarted: "Not started",
    TaxPeriods_WaitingForTaxAccount: "Waiting for tax account sync",
    TaxSettings: "Tax",
    TaxSettingsDescription: "If you don't know what to fill in here, please use Skatteverkets tables to figure it out. You can find them ",
    TaxTab_NotRecorded: "Not recorded",
    TaxTab_PayrollStatus: "Payroll status",
    TaxYear: "Tax year",
    TestCompanyQuickPayrollNotice_Completed_EmployeesCTA: "Go to payroll",
    TestCompanyQuickPayrollNotice_Completed_Text: "We have now added an employee and set up a payroll that you can edit and register. The test salary can be find under active payrolls.",
    TestCompanyQuickPayrollNotice_Completed_Title: "Salary test created",
    TestCompanyQuickPayrollNotice_CTA: "Create salary test",
    TestCompanyQuickPayrollNotice_Error: "Failed to create test salary",
    TestCompanyQuickPayrollNotice_MissingCompanyInfoWarning: "Missing required company information",
    TestCompanyQuickPayrollNotice_Text: "Get started and and try out Bokio's salary administration without adding any information yourself. We will automatically create an employee, set up a payroll and create payslip. You can easily edit and test various features such as vacation compensation, custom tax rate, employer declaration, accounting of salary and much more.",
    TestCompanyQuickPayrollNotice_Title: "Explore salaries with a simple click",
    ThereAreNoAbsencesToHandle: "There are no absences that has to be handled right now",
    TheyHaveOtherJobOrPension: "They have another job or pension",
    ThisIsCurrentlyOnlyJob: "This is currently their only job",
    ThisIsFirstJobSinceTaxYearStart: "This is their first job since the start of the tax year (April 6th)",
    TITLE: "Title",
    Total: "Total",
    TOTAL_THIS_YEAR: "Total this year",
    TotalOfCurrentPrepaidDebt: "Total of current pre-paid debt",
    TrackedVacationBalances: "Tracked vacation balances",
    TrackedVacationBalancesDescription: "This is the current state of your employees vacation balances. If you need to manually edit the balances, you can do that here. The edit will result in an unrecorded transaction that you'll have to record.",
    TrackedVacationBusinessDaysInfo: "Pick a date range to save. The number of working days is automatically calculated from the selected date range. The number of days can be adjusted manually if this is not correct.",
    TurnOnTracking: "Turn on tracking",
    TurnOnTrackingDescription: "Once the balances above are correct, it’s time to turn on the automatic vacation tracking for this employee.\nYou can still save the values above without turning it on, if you don’t have all the information at hands.",
    TurnOnTrackingInfo: "As soon as you’ve turned on vacation tracking for an employee, you cannot turn it off. You can always edit the balances.",
    TYPE: "Type",
    UndoBookkeepingForSalaries: "Undo accounting for salaries",
    UndoManuallyRecordedConfirmationText: "This will undo marking this payment as manually recorded and will move this back to being active. It will NOT undo any recordings that you've done.",
    UndoRecordingOfSalaryMessage: "You’re about to undo the recording of salaries. If you need to edit the salaries, this will require you to resubmit the FPS in step 2.",
    UndoSalaryBookkeepingModalNotice: "If you need to do an extra payment after you have changed the salaries you probably need to record it manually.",
    UNIT: "Unit",
    Unit_Day_P: "Days",
    Unit_Day_S: "Day",
    Unit_Hour_P: "Hours",
    Unit_Hour_S: "Hour",
    Unit_Meal_P: "Meals",
    Unit_Meal_S: "Meal",
    Unit_Month_P: "Months",
    Unit_Month_S: "Month",
    Unit_Pcs_P: "pce",
    Unit_Pcs_S: "pcs",
    UNLOCK_MONTH: "Unlock month",
    UNLOCK_PAY_PERIOD: "Unlock Pay Period",
    UNLOCK_PERIOD: "Unlock period",
    UNLOCK_PERIOD_DOWNLOAD_PDF_TEXT: "Download payslip PDF's for this period when unlocking.",
    UNLOCK_PERIOD_DOWNLOAD_PDF_TITLE: "### Download payslip PDF's",
    UNLOCK_PERIOD_NO_PAYSLIPS_TEXT: "You don't have any payslips that needs to be downloaded",
    UNLOCK_PERIOD_WARNING: "### Caution!\nWhen unlocking a period, Bokio will apply the latest rules on calculations for the payslips within this period. This means that some payslips may change values when unlocking.\n\nIt's good practice to download the current payslips to keep as a reference before unlocking.",
    UNLOCK_PERIOD_WARNING_LOCKNOTALLOWED: "### Caution!\nYou will not be able to lock this payroll again unless you upgrade your plan.\n",
    UnlockPayrollWarning: "You’re about to unlock this payroll. If you do this you are required to resubmit the FPS in step 2, which may result in a late submission.",
    UnmarkAsFinalPayment: "Unmark as final payment",
    UnpaidBalanceInfo: "If your company doesn't have pre-paid vacation the first year, it's usually common to have unpaid vacation. Fill in unpaid days left here.",
    UnpaidVacation: "Unpaid vacation",
    UnrecordedTransactions: "Unrecorded transactions",
    UnrecordedTransactionsDescription: "Every time you manually change the vacation balances for an employee, they'll end up here as an unrecorded transaction. We recommend that you do all your changes for every employee at the same time. This is so that the transactions can be recorded in bulk. The created verifications can not be annulled, bookkeeping can only be undone by changing the vacation balance.",
    UpdateOverridesWarningDescription: "Make sure you update your overrides accordingly if you wish to keep them up to date with your new changes.",
    VACATION_DAYS: "Vacation days",
    VacationBalance: "Vacation balance",
    VacationBalanceTracking: "Vacation balance tracking",
    VacationDate: "Vacation date",
    VacationDaysYearly: "Vacation days yearly",
    VacationDebt: "Vacation debt",
    VacationDebtDescription: "If your employee has any vacation debt, fill it in here. We prefill the discard date of the vacation debt based on swedish law (5 years from the starting date).",
    VacationDebtReport_Filter_Date: "Debt at",
    VacationDebtReportFooter_AccrualPeriod: "Accrual period",
    VacationDebtReportFooter_AccrualYearPreviousYear: "Accrual year: Previous year",
    VacationDebtReportFooter_SocialFeesForBorn: "Social fees for born",
    VacationDebtReportFooter_UsagePeriod: "Usage period",
    VacationDebtReportFooter_VacationDebt: "Vacation debt",
    VacationDebtReportFooter_VacationDebtIncludingSocialFees: "Vacation debt including social fees",
    VacationDebtReportHeader_PrintDate: "Print date",
    VacationDebtReportRowHeader_AccruedThisYearDays: "Accrued this year days",
    VacationDebtReportRowHeader_Amount: "Amount",
    VacationDebtReportRowHeader_Claim: "Claim",
    VacationDebtReportRowHeader_PaidDays: "Paid days",
    VacationDebtReportRowHeader_PrepaidDays: "Prepaid days",
    VacationDebtReportRowHeader_SavedDays: "Saved days",
    VacationDebtReportRowHeader_UnpaidCompensation: "Unpaid compensation",
    VacationDetails_AccuralTransactions_Title: "Accrual transactions",
    VacationDetails_Description_Header: "Description",
    VacationDetails_From_Header: "From",
    VacationDetails_FromYearEnd_Description: "From vacation year end",
    VacationDetails_OnlyRecorded_CheckboxLabel: "Include only recorded?",
    VacationDetails_To_Header: "To",
    VacationDetails_TransactionDate_Header: "Date",
    VacationDetails_Transactions_Title: "Transactions",
    VacationList_Accrued_ShortHeader: "Accrued",
    VacationList_Compensation_ShortHeader: "Compensation",
    VacationList_Paid_ShortHeader: "Paid",
    VacationList_Prepaid_ShortHeader: "Prepaid",
    VacationList_Saved_ShortHeader: "Saved",
    VacationList_Unpaid_ShortHeader: "Unpaid",
    VacationReplacementRate: "Vacation compensation",
    VacationSettings: "Vacation",
    VacationTermExplanationAccrued: "Vacation days earned in for next vacation year",
    VacationTermExplanationCompensation: "Vacation compensation, typically a percentage of commission pay or from hourly work",
    VacationTermExplanationPaid: "Available paid vacation days",
    VacationTermExplanationPrepaid: "Paid vacation days given as an advance from company to the employee, the resulting debt is usually written off after 5 years of employment",
    VacationTermExplanationSaved: "Unused paid vacation days from previous vacation years",
    VacationTermExplanationUnpaid: "Unpaid vacation days, this is mostly used to fill up the employees first vacation year when they have not earned enough paid days",
    VacationTracking_Change_VacationYearDate: "Change date",
    VacationTracking_Change_VacationYearDate_Warning: "Here, you can change the starting date on your vacation year. This will likely cause some of your vacation balances not to match up when the current year comes to an end, and you might have to fix these when you’re doing your vacation year end.",
    VacationTracking_ChangeVacationYearStartDate_Current: "Your starting date is set to {0} {1}",
    VacationTracking_Disable_Text: "Turn off",
    VacationTracking_Disable_Title: "Turn off vacation tracking",
    VacationTracking_Disable_Warning: "If you’re turning off your vacation tracking, and have vacation transactions that have been recorded, this will cause you to have to manually fix it back. I.e. All the vacation debt, earned-in days etc. will have to be reverted on the accounts they been recorded on.\n\nAre you sure you want to turn off vacation tracking?",
    VacationTracking_Onboarding_Notice: "Note: This feature is mainly for companies with employees. If you’re the only one in your company, and don’t take out much, or any, vacation – we recommend you to continue using the manual vacation tracking we have.",
    VacationTracking_Onboarding_Setup: "Set up vacation tracking",
    VacationTracking_Onboarding_Text: "Keep track of your employees vacation days, earn-ins and more!\nBy turning on vacation tracking Bokio will track earn-in, days left and record\nany vacation debt properly.",
    VacationTracking_Onboarding_Title: "Turn on vacation tracking",
    VacationTrackingFeedbackText: "You're using the vacation tracking feature, and to make this feature even better, we would love to get your feedback!",
    VacationTrackingForm_PaidAccrual_HelpText: "The paid accrual is handled automatically and should not require manual adjustment. If it is needed you can change it here. Note that it needs to be an exact number, see more ",
    VacationTransactions: "Vacation transactions",
    VacationTransactionsModal_Transactions_Heading: "You’re recording vacation balances for {0} employee(s)",
    VacationYearEnd_BeforeYouStart_ConfirmToStartVacationYearEnd: "I understand the above and I’m ready to start the vacation year end.",
    VacationYearEnd_BeforeYouStart_Heading: "Before you start",
    VacationYearEnd_BeforeYouStart_Text: "### What is the vacation year end??\n\nDuring the last vacation year, {0} to {1}, your employees used and earned in vacation. Every month that you’ve created payslips for your employees, we’ve recorded their earned in days for the upcoming year (if they’re monthly employees) and how many days they have been using in the last vacation year.\n\nNow, to make sure that everything is recorded correctly, and to restart their balances fresh for their upcoming vacation year, we have to move over the balances for the next year. Before we get started with this, it’s important that you’ve done a few things.\n\n### What do I have to prepare?\n\n**1. Make sure that you’ve created and recorded all payrolls up until {2}.**\n\nAll earn-in days are recorded the same month it is happening. Ex. John is working full-time in March. On the March payroll, we’ve recorded his earn-in for next year. However, all absence is recorded the month after it happened. Ex. Anna is working full-time in March, she took 4 days of vacation during March, but this is recorded on the payslip in April. This means that you have to record Aprils payroll before moving on with this flow.\n\n**2. By starting this flow, you should NOT unlock payrolls that has happened in the past**\n\nSince the balances are connected on a payslip level, unlocking old payrolls after you’ve started this flow will lead to your balances being updated again, and this will lead to balances being out of sync.",
    VacationYearEnd_ConfirmAndContinue: "Confirm and continue",
    VacationYearEnd_EBD_CompensationHourly_Description: "For hourly employees, we track whatever you have not paid out to them that should be base for vacation compensation.",
    VacationYearEnd_EBD_CompensationHourly_Percentage_Text: "Vacation compensation percentage (set on contract)",
    VacationYearEnd_EBD_CompensationHourly_Salary_Text: "Salary",
    VacationYearEnd_EBD_CompensationMonthly_Commission: "Commission",
    VacationYearEnd_EBD_CompensationMonthly_Description: "If you moved any days from the saved days in the previous step, your compensation balance will be moved here. Otherwise, you rarely have anything for monthly employees, unless you use the commission row on payslips.",
    VacationYearEnd_EBD_CompensationMonthly_ManuallyAdded: "Manually added",
    VacationYearEnd_EBD_CompensationMonthly_Saved_Text: "Moved from saved days",
    VacationYearEnd_EBD_CompensationMonthly_Title: "Vacation compensation balance",
    VacationYearEnd_EBD_PrepaidDays_Accrued_Text: "Accrued days for upcoming period",
    VacationYearEnd_EBD_PrepaidDays_DaysLeft_Text: "Pre-paid days left in previous period",
    VacationYearEnd_EBD_PrepaidDays_DaysUsed_Text: "Pre-paid days used in previous period",
    VacationYearEnd_EBD_PrepaidDays_Description: "If your employees have pre-paid days, and this is first year, we will move over the remainder of pre-paid days they have, to fill up to how many days this employee is eligible in a year. ",
    VacationYearEnd_EBD_PrepaidDays_Example: "Example:\nAnna is eligible 25 days of vacation a year. Her first year, she had 25 pre-paid vacation days to use. She started in the middle of the vacation year, so she only earned in 13 paid days for the upcoming year. She only used 10 of her pre-paid vacation days in the first year. So, for the upcoming year she will have:\n\n13 paid days\n12 pre-paid days (the remaining 3 that she didn’t use will disappear)\nTotal days: 25",
    VacationYearEnd_EBD_PrepaidDays_Title: "Pre-paid days to use in the upcoming period",
    VacationYearEnd_EBD_PrepaidDays_Vacation_Text: "Vacation days per year (set on contract)",
    VacationYearEnd_EBD_Workload_ContractPeriod_Text: "Contract from {0} to {1}",
    VacationYearEnd_EBD_Workload_Description: "If your employee had different workloads during the year, the value of a vacation day will be lower for the upcoming year. We calculate an average workload during the year and then save the vacation days value based on that.",
    VacationYearEnd_EBD_WorkLoad_Example: "Example:\nAnna worked 80% for 3 months the previous year, the remaining 9 months she worked 100%. The average workload will then be 95%. (3 months x 0,8) + (9 months x 1,0) / 12\n\nOne days worth will in the upcoming year look like this:\nMonthly salary:  25 000 kr\nAbsence:  1 day  -1 150 Kr¹\nVacation salary: 1 day 1 092,50 Kr²\nHoliday extra pay: 1 piece  190 Kr³\nSum total: 25 132,50\n\n¹ Absence = 25 000 x 4,6%\n² Vacation salary = 25 000 x 95% x 4,6% (daily salary precentage)\n³ Holiday extra pay = 25 000 x 95% x 0,43% (holiday extra pay percentage)",
    VacationYearEnd_EBD_Workload_Title: "Workload in previous year",
    VacationYearEnd_Employee_Intro: "In this step, you’ll be checking the employeees current balances, making sure they’re correct. You’ll also be moving over balances to the new vacation year for each employee.\n\nEven if you’re done with your employee, the new balances won’t be active until you’ve done all employees and recorded them in step 3.",
    VacationYearEnd_Employee_MultipleContracts: "Mixed contracts",
    VacationYearEnd_Employee_NoContracts: "No contracts",
    VacationYearEnd_EmployeeConfirmation_Summary: "This is how the balances for {0} will look like",
    VacationYearEnd_EmployeeDetailScene_BalanceSummary: "Balance Summary",
    VacationYearEnd_EmployeeDetailScene_CheckAndEditBalances: "Check and edit balances",
    VacationYearEnd_EmployeeStatus_Done: "Done",
    VacationYearEnd_EmployeeStatus_InProgress: "In progress",
    VacationYearEnd_EmployeeStatus_NotStarted: "Not started",
    VacationYearEnd_Finished_GoodJob: "Good job!",
    VacationYearEnd_Finished_Text: "All your employees balances are now up-to-date.\nYou also have a better overview of how much your vacation debt is!",
    VacationYearEnd_MoveOverBalances: "Move over balances ({0}/{1} employees done)",
    VacationYearEnd_MoveOverBalances_NotReady: "Move over balances",
    VacationYearEnd_RecordTransactions_Description: "In this step, we'll be creating 2 separate recordings. The first recording is to reset your current vacation debt up until the last day of the previous vacation year. The second recording will state your current vacation debt, from the 1st day of the new vacation year. This will lead to your vacation debt being up-to-date and tidy.",
    VacationYearEnd_RecordTransactions_Example: "This is how the recording will look like",
    VacationYearEnd_Step_NotReady: "This step will be available once you have completed the previous step(s)",
    VacationYearEnd_Title: "Vacation year end",
    VacationYearEndBalanceEdit_Compensation_Description: "Vacation compensation balance",
    VacationYearEndBalanceEdit_Compensation_Title: "Edit vacation compensation balance",
    VacationYearEndBalanceEdit_Day: "day",
    VacationYearEndBalanceEdit_Days: "days",
    VacationYearEndBalanceEdit_Paid_Description: "Earned in paid vacation days for next year",
    VacationYearEndBalanceEdit_Paid_Title: "Edit paid days for next year",
    VacationYearEndBalanceEdit_Prepaid_Description: "Pre-paid days to use in the next year",
    VacationYearEndBalanceEdit_Prepaid_Title: "Edit pre-paid days",
    VacationYearEndBalanceEdit_RestoreToDefault: "Restore to {0}",
    VacationYearEndBalanceEdit_Saved_Description: "Paid days left moved to saved days",
    VacationYearEndBalanceEdit_Saved_Example: "One paid vacation day for {0} is worth {1} kr. If you remove one (or more) of the saved days from here, we will automatically put it on “Vacation compensation” so that you can pay it out to your employee on their next payslip.",
    VacationYearEndBalanceEdit_Saved_Title: "Edit saved days for next year",
    VacationYearEndBalanceEdit_Unpaid_Description: "Unpaid days to use in the next year",
    VacationYearEndBalanceEdit_Unpaid_Title: "Edit unpaid days for next year",
    VacationYearEndBalanceEdit_Workload_Description: "Workload in the previous year",
    VacationYearEndBalanceEdit_Workload_Title: "Edit workload",
    VacationYearEndBalanceHistory_Compensation_Description: "Here's the list of all things that affected your vacation compensation for last vacation year.",
    VacationYearEndBalanceHistory_Compensation_Title: "History - Vacation compensation",
    VacationYearEndBalanceHistory_Paid_Description: "Here’s the list of all things that affected your accrual for last vacation year.",
    VacationYearEndBalanceHistory_Paid_Title: "History - Accrued days",
    VacationYearEndBalanceHistory_Quantity: "Qty",
    VacationYearEndBalanceHistory_Saved_Description: "Here’s the list of all things that affected your saved days for last vacation year.",
    VacationYearEndBalanceHistory_Saved_Title: "History - Saved days",
    VacationYearEndBanner_Tooltip: "You need to record all the vacation transactions to continue",
    VacationYearEndBannerDescription: "Your vacation year ends {0}. That means it’s time to look over your employees balances for the upcoming vacation year. We’ve set up a flow to help you move over balances to the new year, and record them correctly.",
    VacationYearEndBannerEmployeeDone: "{0}/{1} employees done",
    VacationYearEndBannerInfo: "Do this before you start preparing your salaries. By doing this later, the balances on your employees payslips will be off, and you will be using the wrong days in the wrong vacation period.",
    VacationYearEndBannerInSalary: "It’s time to do your vacation year end! Move over your employees balances to the new vacation year now. ",
    VacationYearEndBannerTitle: "It’s time to do vacation year end!",
    VacationYearEndEBD_Paid_Accrued_Text: "Accrued days during the year (rounded up)",
    VacationYearEndEBD_Paid_Title: "Paid days to use in the next vacation year",
    VacationYearEndEBD_Saved_Description: "We move all remainder of the paid days to saved days by default. If you instead want some or all of the days to be\npaid out to the employee, edit this value and we’ll automatically move the remainder of the days to “Vacation compensation” as you save it.",
    VacationYearEndEBD_Saved_Title: "Unused days from last year moved to saved days",
    VacationYearEndEBD_Unpaid_Description: "If your company has a limit on unpaid leave, you can add that here. This is common if you don’t use pre-paid vacation.",
    VacationYearEndEBD_Unpaid_Title: "Unpaid days to use in the next vacation year",
    VacationYearEndEBED_Adjusted_Status: "Manually adjusted",
    VacationYearEndEBED_Mobile_Adjusted_Status: "Adjusted",
    VacationYearEndEBED_WhatWeBaseOur_Header: "What we base our values on",
    VacationYearEndEmployeeBalanceDetails_Heading: "Go through balances",
    VacationYearEndEmployeeBalanceDetails_Text: "Look through each of the values and make sure they’re correct. If you spot something that you don’t agree with, you can edit the balances for the upcoming year. If you want to know where we got the values from, you can press the value to get an insight.",
    VacationYearEndEmployeeBalanceOverview_Heading: "How we move balances",
    VacationYearEndEmployeeBalanceOverview_LastYear: "Last year ({0} to {1})",
    VacationYearEndEmployeeBalanceOverview_NextYear: "New balances ({0} to {1})",
    VacationYearEndEmployeeBalanceOverview_Text: "Below you can see how we move over balances. In the left column, you will see how your employee has used their balances during the last vacation year, and how many days they have left. In the right column, you can see how these balances usually are moved.",
    VacationYearEndEmployeeBalanceTable_Accrued: "Accrued days (rounded up)",
    VacationYearEndEmployeeBalanceTable_Accrued_Tablet: "Accrued days (rounded up)",
    VacationYearEndEmployeeBalanceTable_Balances: "Balances",
    VacationYearEndEmployeeBalanceTable_Compensation: "Vacation compensation",
    VacationYearEndEmployeeBalanceTable_Debt: "Debt",
    VacationYearEndEmployeeBalanceTable_Paid: "Paid days left",
    VacationYearEndEmployeeBalanceTable_PrepaidDebt: "Prepaid debt, amount",
    VacationYearEndEmployeeBalanceTable_PrepaidLeft: "Prepaid days left",
    VacationYearEndEmployeeBalanceTable_PrepaidUsed: "Prepaid days used",
    VacationYearEndEmployeeBalanceTable_Saved: "Saved days left",
    VacationYearEndEmployeeBalanceTable_Unpaid: "Unpaid days left",
    VacationYearEndEmployeeBalanceTablesMobile_Accrued: "Accrued",
    VacationYearEndEmployeeBalanceTablesMobile_Compensation: "Comp.",
    VacationYearEndEmployeeBalanceTablesMobile_Last: "Last",
    VacationYearEndEmployeeBalanceTablesMobile_New: "New",
    VacationYearEndEmployeeBalanceTablesMobile_Paid: "Paid",
    VacationYearEndEmployeeBalanceTablesMobile_PrepaidDebt: "Debt",
    VacationYearEndEmployeeBalanceTablesMobile_PrepaidLeft: "Left",
    VacationYearEndEmployeeBalanceTablesMobile_PrepaidSectionHeader: "Prepaid",
    VacationYearEndEmployeeBalanceTablesMobile_PrepaidUsed: "Used",
    VacationYearEndEmployeeBalanceTablesMobile_Saved: "Saved",
    VacationYearEndEmployeeBalanceTablesMobile_Unpaid: "Unpaid",
    VacationYearEndRating_Feedback_Description: "Do you have any feedback that can help us improve your vacation year end experience?",
    VacationYearEndRating_Rate_Title: "How was your experience doing the vacation year end?",
    ValidFrom: "Valid from",
    ValidTo: "Valid to",
    ValuesNeedToBeRecalculatedAndSetDaysFieldsToZero: "These values above needs to be re-calculated to vacation compensation and filled in below, in the field for vacation compensation. You also have to set the fields above to “0” to be able to move on.",
    VaxaSupport: "VÄXA-support",
    VaxaSupportDescription: "If you use VÄXA-support, make sure to change this contract setting as soon as the VÄXA-support runs out. Read more about VÄXA-support eligibility on [Skatteverket](https://www.skatteverket.se/foretagochorganisationer/arbetsgivare/arbetsgivaravgifterochskatteavdrag/vaxastodtillfalligtsanktarbetsgivaravgift.4.361dc8c15312eff6fd37447.html?q=växa+stöd)",
    VaxaSupportEligible: "VÄXA-support eligible",
    verification_preview_text: "Your journal entry will look like this.",
    VerificationRecorded: "Verification recorded",
    ViewPayslip: "View payslip",
    Week1Month1: "Week 1/Month 1 (Non cumulative)",
    Week1Month1Tooltip: "Ticking this box results in non-cumulative tax calculations. This should be ticked if the P45 for the employee shows an entry in the Week 1/Month 1 box. Or, if they are a new employee without another job, but they have had a job since beginning of the tax year. Or, if they are an existing employee that has a tax code with the suffix M1.",
    WeeklySalary: "Weekly salary",
    WhatsGoodToKnow: "What's good to know:",
    WHEREOF: "whereof",
    Workload: "Workload",
    WorkloadDescription: "For a part-time employee, set values for compensation, workweek etc. as if the employee would work full time, and we will calculate the rest.",
    Workweek: "Workweek",
    Year: "Year",
    Yearly_VacationDays_Warning: "By swedish law, every employee is entitled to 25 days of vacation per year. We allow you to save less days per year, and it's up to you to stay compliant.",
    YearlyDays: "Yearly days",
    YES_DELETE: "Yes, delete!",
    YesResubmit: "Yes, resubmit",
    YOU_ARE_ABOUT_TO_EMAIL_PAYSLIP: "You are about to email all payslips in this month",
    YouAreAboutToUndoBookkeepingForTaxes: "You are about to undo accounting for taxes.",
    YouAreAboutToUndoBookkeepingForTheSalaryPayment: "You are about to undo accounting for the salary payment.",
    YouAreOnlyAllowedToPayOutUpToTheAvailableBalance: "You are only allowed to pay out up to the available balance",
    YouCantInputMoreDaysThanYouveSelected: "You can't input more days than you've selected",
    YouNeedToInputMoreThan0Days: "You need to input more than 0 days",
    ZIP_CODE: "Postal code",
  };
}