/* eslint-disable */

import type { OnboardingLang } from "../types/OnboardingLang";
import { translateTempImpl } from "../TranslatableLanguage";

export function OnboardingLangDef(): OnboardingLang {
  return {
    translateTemp: (key: string, neutral: string, svSE?: string, enGB?: string) => translateTempImpl(key, neutral, svSE, enGB),
    
    AutomateTransactions_box1_button: "Connect to your bank",
    AutomateTransactions_box1_header: "Set up bank connection",
    AutomateTransactions_box1_text: "Reduce mistakes and simplify your bookkeeping by connecting Bokio to your existing business bank.",
    AutomateTransactions_box2_button: "Open a business account",
    AutomateTransactions_box2_header: "Open Bokio Business Account",
    AutomateTransactions_box2_text_1: "Banking service fully integrated in Bokio for even more automation, control and convenience.",
    AutomateTransactions_box2_text_2: "Bokio Business Account requires you to sign up for a plan first.",
    AutomateTransactions_Name: "Welcome {0}, let's unlock automation for you in Bokio",
    AutomateTransactions_Text: "Get a smooth start by syncing your transactions. Choose one or both options depending on your needs:",
    Autopilot_AllPricesExclVat: "All prices are excluding VAT",
    Autopilot_BokioAndRedflag: "Bokio and Red Flag have merged and we are evaluating how the service currently hosted at Red Flag will be presented within the Bokio brand. Thanks to your participation, we have received more information on how we can do this in the best way.",
    Autopilot_BokioAutopilot: "Bokio Autopilot",
    Autopilot_BuyAutopilot: "Buy Autopilot",
    Autopilot_DontMatchExpectations: "The features don’t match my expectations",
    Autopilot_DontNeed: "I don't need it at the moment",
    Autopilot_DontSeeTheValue: "I don't see the value the service provide",
    Autopilot_EverythingFromInvoicing: "Everything from invoicing, accounting and archiving of paper is handled digitally. You always have an updated picture of how things are going for your business, and you don't have to buy expensive accounting services to keep track of your finances - Autopilot will take care of it.",
    Autopilot_Feature1: "Always up to date on how things are going for your business",
    Autopilot_Feature2: "Company account and debit card with auto recorded transactions",
    Autopilot_Feature3: "Upload receipts and bills - autopilot does the rest",
    Autopilot_Feature4: "You will never miss a VAT or tax payment",
    Autopilot_GetStartedWithAutopilot: "Get started with Bokio Autopilot",
    Autopilot_HelpUsGetBetter: "Help us get better",
    Autopilot_HelpUsImprove: "Help us improve",
    Autopilot_Included: "Included",
    Autopilot_IncludedList: "- One user-friendly service for your limitited company or sole proprietorship\n- Company account and debit card with auto recorded transactions\n- Invoicing in Sweden and abroad directly in the service\n- Easy handling of receipts and bills\n- Automatic handling of taxes and fees\n- Financial statements for your company\n- Always up to date on how things are going for your business",
    Autopilot_NotInterested: "Not interested",
    Autopilot_Other: "Other",
    Autopilot_Price: "From 498 kr/month",
    Autopilot_PriceAndPayment: "Price and payment",
    Autopilot_ReadMore: "Read more",
    Autopilot_ReceivedFeedbackInterested: "We have received your feedback and we will do our best to further improve Bokio Autopilot. We will contact you when the service is released, so that you will be one of the first to start using it.",
    Autopilot_ReceivedFeedbackNotInterested: "We have received your feedback and we will do our best to improve Bokio Autopilot and further offerings.",
    Autopilot_SleepTight: "Sleep tight without worrying about missing something – let the autopilot do the job.",
    Autopilot_ThankYouGetBetter: "Thank you for helping us get better",
    Autopilot_ThankYouImprove: "Thank you for helping us get better",
    Autopilot_ThankYouInterest: "Thank you for showing interest in Bokio Autopilot",
    Autopilot_ThePrice: "Price",
    Autopilot_ThePriceIsBasedOn: "The price is based on your company's turnover. Please note that 1 498 kr is invoiced in addition to the standard fee per year if you choose to buy to the year-end closures and 998 kr for the year-end report.",
    Autopilot_TurnoverAbove7500: "Turnover above 75 000 kr/month",
    Autopilot_TurnoverAbove7500Price: "1 198 kr/month",
    Autopilot_TurnoverUpTo10000: "Turnover up to 10 000 kr/month",
    Autopilot_TurnoverUpTo10000Price: "498 kr/month",
    Autopilot_TurnoverUpTo75000: "Turnover up to 75 000 kr/month",
    Autopilot_TurnoverUpTo75000Price: "798 kr/month",
    Autopilot_WeAppreciateFeedback: "We appreciate feedback and if you have any thoughts about Bokio Autopilot, you can describe your needs and thoughts below so that we can create a world-class accounting software.",
    Autopilot_WeUnderstand: "We understand that you are not interested in Bokio Autopilot but we would really appreciate if you could tell us why. It would help us to improve our offers in the future which hopefully will better suit your needs.",
    Autopilot_WhatIsMissing: "What do you think is missing or can be improved?",
    Autopilot_WhatIsTheReason: "What is the reason you don’t want to use Bokio Autopilot?",
    Autopilot_WhatsIncluded: "What's included",
    Autopilot_YouCanUseTheService: "You can already use the service today at",
    Bookeeping_SearchInfo: "Search in the search field to find the right accounting template. We have created these templates so you don't have to care about debit and credit.",
    Deals_BokioDeals: "Bokio Deals",
    Deals_BuyDeals: "Buy Deals",
    Deals_ChoosePaymentInterval: "Choose if you want to pay yearly or monthly for Bokio Deals",
    Deals_DontNeed: "I don't need it at the moment",
    Deals_DontSeeTheValue: "I don't see the value the service provide",
    Deals_Feature1: "30+ pre-negotiated deals with leading suppliers",
    Deals_Feature2: "Up to 45 % discount on goods and services",
    Deals_Feature3: "Legal services, insurance, shipping, pension and much more",
    Deals_Feature4: "Focus on your business. We compare and negotiate prices for you",
    Deals_GetStartedWithDeals: "Get started with Bokio Deals",
    Deals_HelpUsGetBetter: "Help us get better",
    Deals_HelpUsImprove: "Help us improve",
    Deals_Monthly: "Monthly",
    Deals_MonthlyPrice: "99 kr/month",
    Deals_NotInterested: "Not interested",
    Deals_Other: "Other",
    Deals_PayLess: "Pay less with Bokio Deals",
    Deals_PayLessDetails: "The concept is simple – save money and precious time while you gather all your purchases in one place. We negotiate all the deals and with the combined purchasing power of all of our users we are able to offer you services and goods priced as if your order came from a big corporation. We compare and review services and suppliers, and negotiate terms and prices for you while you can focus on your core business.",
    Deals_PayLessList: "- Up to 35 % discount on consumable inventory\n- Up to 15 % on travel and accommodation\n- Up to 45 % on shipping and logistics\n- Up to 25 % on electronics and mobiles\n- Up to 25 % on insurance and pension\n- Up to 10 % on fuel",
    Deals_Payment: "Payment",
    Deals_PaymentInformation: "Payment information will be added when you complete the purchase",
    Deals_Price: "Price",
    Deals_ReadMore: "Read more",
    Deals_ReceivedFeedbackInterested: "We have received your feedback and we will do our best to further improve Bokio Deals. We will contact you when the service is released, so that you will be one of the first to start using it.",
    Deals_ReceivedFeedbackNotInterested: "We have received your feedback and we will do our best to improve Bokio Deals and further offerings.",
    Deals_ReduceYourCosts: "Reduce your costs and save time on your purchases of services and goods.",
    Deals_Save16: "Save 16 %",
    Deals_ThankYouGetBetter: "Thank you for helping us get better",
    Deals_ThankYouImprove: "Thank you for helping us get better",
    Deals_ThankYouInterest: "Thank you for showing interest in Bokio Deals",
    Deals_TheServiceIsNotAvailable: "The service is not available yet. We are currently evaluating how the service should be set up. You'll be notified when the product is released.",
    Deals_WeAppriciateYourFeedback: "We appreciate feedback and if you have any thoughts about Bokio Deals, you can describe your needs and thoughts below so that we can create a world-class service.",
    Deals_WeUnderstandNotInterested: "We understand that you are not interested in Bokio Deals but we would really appreciate if you could tell us why. It would help us to improve our offers in the future which hopefully will better suit your needs.",
    Deals_WhatDoYouThink: "What do you think is missing or can be improved?",
    Deals_WhatIsTheReason: "What is the reason you don’t want to use Bokio Deals?",
    Deals_WhatsIncluded: "What's included",
    Deals_Yearly: "Yearly",
    Deals_YearlyPrice: "999 kr/year",
    FAQSection_link: "FAQ section",
    FeatureInterest_HelpUsImproveDescription: "We understand that you are not interested but we would really appreciate if you could tell us why. It would help us to improve our offers in the future which hopefully will better suit your needs.",
    FeatureInterest_WhatsTheReason: "What is the reason you don’t want to use {0}?",
    FinancialOverview_AccountingAndBankDetails: "Accounting and bank details",
    FinancialOverview_AccountingDetails: "Accounting details",
    FinancialOverview_BankDetails: "Bank details",
    FinancialOverview_Customers: "Customers",
    FinancialOverview_Days: "days",
    FinancialOverview_DueToday: "Due today",
    FinancialOverview_Expenses: "Expenses",
    FinancialOverview_Expenses_EmptyStateText: "As soon as you've started recording some expenses, you'll see your top 5 expenses here!",
    FinancialOverview_GetStartedWithBookkeeping: "Start recording",
    FinancialOverview_Header: "Financial overview",
    FinancialOverview_Income: "Income",
    FinancialOverview_IncomeAndExpense_EmptyStateAction: "Record transaction",
    FinancialOverview_IncomeAndExpense_EmptyStateText: "This graph shows your income and expenses. Right now, we don't have enough data to display during the selected period. Start recording to see your data displayed in the graph!",
    FinancialOverview_IncomeAndExpense_Header: "Income and expenses",
    FinancialOverview_InvoiceOwedToYou: "Invoices owed to you",
    FinancialOverview_Items: "Items",
    FinancialOverview_Last12Months: "Last 12 months",
    FinancialOverview_NewInvoice: "Create invoice",
    FinancialOverview_NewSupplierInvoice: "New supplier invoice",
    FinancialOverview_OverDue: "Overdue",
    FinancialOverview_ProfitAndLoss: "Profit and loss",
    FinancialOverview_RecordExpenses: "Record expense",
    FinancialOverview_Result: "Profit or loss",
    FinancialOverview_SupplierInvoiceYouNeedToPay: "Supplier invoices you need to pay",
    FinancialOverview_Top5_CreateInvoice: "Create invoice",
    FinancialOverview_Top5Articles_EmptyStateText: "Once you've been paid for some invoices with custom items within this period, your top 5 items will show up here",
    FinancialOverview_Top5Customers: "Top 5 customers/items",
    FinancialOverview_Top5Customers_EmptyStateText: "Once you've been paid for some invoices, your top 5 customers will show up here",
    FinancialOverview_Top5Expenses: "Top 5 expenses",
    FinancialOverview_Top5Expenses_Other: "Other",
    FinancialOverview_TotalExpense: "Total expenses",
    FinancialOverview_TotalIncome: "Total income",
    FinancialOverview_ViewArticles: "View items",
    FinancialOverview_ViewCustomerInvoices: "View all active customer invoices",
    FinancialOverview_ViewCustomers: "View customers",
    FinancialOverview_ViewProfitAndLoss: "View Profit \u0026 Loss report",
    FinancialOverview_ViewSupplierInvoices: "View all active supplier invoices",
    GetStarted_Bookkeeping_ListItem1: "Import previous accounting",
    GetStarted_Bookkeeping_ListItem2: "Check opening balances",
    GetStarted_Bookkeeping_ListItem3: "Record your first receipt",
    GetStarted_Bookkeeping_ReadMoreText: "Read more on getting started with accounting",
    GetStarted_Bookkeeping_Text1: "Upload your receipts or invoices and our smart templates help you record items to the right accounts.",
    GetStarted_Bookkeeping_Text2Heading: "If you are moving to Bokio",
    GetStarted_Bookkeeping_Text3Heading: "Watch how to record in 5 step",
    GetStarted_Bookkeeping_Title: "Accounting",
    GetStarted_Invoicing_ListItem1: "Create your first invoice",
    GetStarted_Invoicing_ReadMoreText: "Read more on getting started with invoices",
    GetStarted_Invoicing_Text1: "Create and send invoices easily with just a few clicks. Get notified for any expired or unpaid invoices.",
    GetStarted_Invoicing_Text2: "You can send invoices directly via email or download as a pdf.",
    GetStarted_Invoicing_Text3Heading: "Watch how to create an invoice",
    GetStarted_Invoicing_Title: "Invoicing",
    GetStarted_Notification_LinkToGettingStarted: "Welcome back! To get started with the rest of Bokio, you can find more getting started guides under Help. {Go to Getting started}",
    GetStarted_SalaryAndExpenses_ListItem1: "Add employees",
    GetStarted_SalaryAndExpenses_ListItem2: "Add and payout salaries",
    GetStarted_SalaryAndExpenses_ListItem3: "Create your first salary",
    GetStarted_SalaryAndExpenses_ReadMoreText: "Read more on getting started with salaries",
    GetStarted_SalaryAndExpenses_Text1: "Manage your company's employees, salaries and other expenses fast and easy. ",
    GetStarted_SalaryAndExpenses_Text2Heading: "How to get started",
    GetStarted_SalaryAndExpenses_Text3Heading: "Watch how to manage salaries",
    GetStarted_SalaryAndExpenses_Title: "Salary",
    GotIt_Confirm_action: "Got it!",
    HelpMenuItem_ShowOnboarding: "Show starter guide",
    HideOnboardingModal_GoodJob: "Great job getting started with Bokio! You can always access the starter guide later using the help button in the top menu.",
    Important_Dates_Wizard_Start_Button: "Setup important dates",
    Important_Dates_Wizard_Start_Heading: "Get started with Important dates",
    Important_Dates_Wizard_Start_Heading_P_2: "Important dates differ between different companies, but may for example be when you have to declare VAT, submit an employer's return or pay preliminary tax. {Make sure your fiscal year is set correctly} so you get dates that are relevant to your business.",
    Important_Dates_Wizard_Start_P_1: "As a business owner, there are several events and deadlines to keep track of during the year. On this page you will get help with this!",
    Important_Dates_Wizard_Start_P_2: "To set up your Important Dates, you will also have to answer some additional questions. If you feel unsure about any part, you can always change it at a later time.",
    ImportantDates_FaultySettings_Quarterly_Error: "Invalid fiscal year settings. Quarterly VAT reporting is only supported when expected turnover is below 40 million SEK. No new important dates are loaded from Skatteverket. [Review settings](bokio:/companyid/settings-r/fiscals)",
    ImportantDates_FaultySettings_Yearly_Error: "Invalid fiscal year settings. Yearly VAT reporting is only supported when expected turnover is below 1 million SEK. No new important dates are loaded from Skatteverket. [Review settings](bokio:/companyid/settings-r/fiscals)",
    ImportantDatesDisclaimer: "We fetch important dates from Skatteverket based on your company settings. Some dates may not be relevant to you, in that case you can hide them individually or for all future events and can revert them back at anytime when those become relevant to you.",
    Navigation_Menu_AboutNavigation_action: "About navigation",
    Navigation_NewNavigation_heading: "We've launched a new navigation!",
    Navigation_NewNavigationReadMore_Message: "You can see more about what we've changed under the new help section here.",
    Onboarding_BbaToPremiumPlus_PromotionModal_Calculation: "Calculation",
    Onboarding_BbaToPremiumPlus_PromotionModal_Calculation_account: "{0} Cashback on deposited capital",
    Onboarding_BbaToPremiumPlus_PromotionModal_Calculation_card: "{0} Cashback on card spend",
    Onboarding_BbaToPremiumPlus_PromotionModal_calculator_heading: "Cashback with Premium {0}",
    Onboarding_BbaToPremiumPlus_PromotionModalHeading: "Cashback and bookkeeping support is a {0}!",
    Onboarding_BbaToPremiumPlus_PromotionModalHeading_preamble: "Based on your business account activity you can get **{0} / month** back by upgrading to Premium Plus. Use our calculator to get a better picture of how much you can earn each month:",
    Onboarding_FreeToPaidPlan_PromotionItem_tagline_bba: "All functions and an integrated bank account and business card",
    Onboarding_FreeToPaidPlan_PromotionItem_tagline_plus: "All functions, an integrated business account, {0} on capital and {1} card spend back to your pocket.",
    Onboarding_FreeToPaidPlan_PromotionItem_tagline_premium: "All functions are included in our all-in-one solution",
    Onboarding_FreeToPaidPlan_PromotionListItem_with_bba: "with business account",
    Onboarding_FreeToPaidPlan_PromotionModal_CTA_button: "Learn more",
    Onboarding_FreeToPaidPlan_PromotionModalHeading_cashback: "Easy bookkeeping {0} {1} /month in cashback!",
    Onboarding_FreeToPaidPlan_PromotionModalHeading_noCashback: "The only business account you need",
    Onboarding_FreeToPaidPlan_PromotionModalPreamble: "Upgrade to the Premium plan and activate your Bokio Business Account for even better bookkeeping. Select the Bokio experience package that suits you.",
    Onboarding_FreeToPaidPlan_PromotionModalPreamble_cashback: "Based on your account activities you can get **{0} / month** with cashback by upgrading to Premium Plus for **599 kr / month**. You also have the option to choose a Premium plan offer as well.",
    Onboarding_HideOnboarding_ActionText: "Hide starter guide",
    Onboarding_PremiumToBba_PromotionListItem_bba_1: "Bookkeeping made even easier",
    Onboarding_PremiumToBba_PromotionListItem_bba_2: "Full view and control of your business's finances",
    Onboarding_PremiumToBba_PromotionListItem_bba_3: "No transaction costs or hidden fees",
    Onboarding_PremiumToBba_PromotionListItem_cashback_2: "{0} cashback on card spend",
    Onboarding_PremiumToBba_PromotionListItem_cashback_3: "{0} cashback on account balances",
    Onboarding_PremiumToBba_PromotionListItem_cashback_free_support: "Free Bookkeeping support",
    Onboarding_PremiumToBba_PromotionListItem_heading_banking: "Business account",
    Onboarding_PremiumToBba_PromotionListItem_heading_cashback: "Business account with cashback",
    Onboarding_PremiumToBba_PromotionListItem_PromotionModalPreamble_banking: "Bank account integrated to your bookkeeping with business card and much more",
    Onboarding_PremiumToBba_PromotionListItem_PromotionModalPreamble_cashback_benefit: "You can get **{0} / month** in cashback if you activate Bokio business account and upgrade to Premium Plus for **599 kr / month**.",
    Onboarding_PremiumToBba_PromotionListItem_PromotionModalPreamble_cashback_not_benefit: "You can get cashback if you activate Bokio business account and upgrade to Premium Plus.",
    Onboarding_PremiumToBba_PromotionListItem_PromotionModalPreamble_listheading: "Bokio Business Account and:",
    Onboarding_PremiumToBba_PromotionModal_CTA_button_bba: "Get Bokio Business Account",
    Onboarding_PremiumToBba_PromotionModalHeading_PremiumToBba_cashback: "Like a traditional bank - {0} {1} in cashback",
    Onboarding_PremiumToBba_PromotionModalHeading_PremiumToBba_noCashback: "Upgrade your bank experience with no extra cost",
    Onboarding_PremiumToBba_PromotionModalHeading_PremiumToBba_preamble: "Experience the advantage of banking and accounting combined. Open your Bokio Business Account today and enjoy all of the benefits all in one place. You can also explore adding cashback by upgrading to Premium Plus.",
    Onboarding_PromotionChecklist_bba: "Free business account",
    Onboarding_PromotionChecklist_bookkeeping: "Premium bookkeeping",
    Onboarding_PromotionChecklist_cashback: "Cashback",
    Onboarding_PromotionChecklist_support: "Free bookkeeping support",
    Onboarding_PromotionListItem_Recommended: "Recommended",
    Onboarding_PromotionModal_CTA_button: "Learn more",
    Onboarding_PromotionModal_postpone_button: "Maybe later",
    Onboarding_TrialToPaidPlan_PromotionItem_tagline_bba: "All features included, an integrated bank account, business card and much more",
    Onboarding_TrialToPaidPlan_PromotionItem_tagline_plus: "All features, business account with {0} cashback on account balances and {1} on card spend.",
    Onboarding_TrialToPaidPlan_PromotionItem_tagline_premium: "All functions are included in our all-in-one solution",
    Onboarding_TrialToPaidPlan_PromotionListItem_with_bba: "with Bokio Business Account",
    Onboarding_TrialToPaidPlan_PromotionModal_cancelButton: "Stay on Free",
    Onboarding_TrialToPaidPlan_PromotionModal_CTA_button: "Learn more",
    Onboarding_TrialToPaidPlan_PromotionModalHeading_noCashback: "Thanks for trying out Bokio, your trial has now ended",
    Onboarding_TrialToPaidPlan_PromotionModalPreamble: "You can continue with the experience from trial by getting Premium, upgrade Premium with a business account or go with our Premium Plus plan if you want to activate cashback on your business account. If you choose to not upgrade your price plan, you will stay on Free.",
    Onboarding_TrialToPaidPlan_PromotionModalPreamble_cashback: "Based on your account activities you can get {0} with cashback by upgrading to Premium Plus for {1}. If it doesn't quite fit your needs you can continue with the experience from trial by getting Premium. You can also downgrade to Free if you are happy with a minimal offer.",
    OnboardingCard_Accounting_ActionText: "Record transaction",
    OnboardingCard_Accounting_Description: "Stay up to date and monitor your business performance.",
    OnboardingCard_Accounting_Header: "Accounting made easy",
    OnboardingCard_Accounting_LearnMoreLink: "https://www.bokio.co.uk/help/getting-started/get-started-with-bokio/getting-started-with-bookkeeping-in-bokio/",
    OnboardingCard_AddCustomer_ActionText: "Create customer",
    OnboardingCard_AddCustomer_Description: "Enter your customer contact details that will appear on an invoice",
    OnboardingCard_AddCustomer_Header: "Add customers",
    OnboardingCard_AddCustomer_LearnMoreLink: "https://www.bokio.co.uk/help/invoice-customers/customers-and-articles/add-a-customer/",
    OnboardingCard_BankFeed_LearnMoreLink: "https://www.bokio.co.uk/help/bank-feeds/",
    OnboardingCard_Consultation_Action: "Book free video call",
    OnboardingCard_Consultation_Description: "Right now you can meet one of us in a video call to get help with your questions",
    OnboardingCard_Consultation_Header: "In-person walkthrough",
    OnboardingCard_CustomiseInvoice_ActionText: "Customise invoices",
    OnboardingCard_CustomiseInvoice_Description: "Add your company logo, edit your colors and address layout.",
    OnboardingCard_CustomiseInvoice_Header: "Customise your invoices",
    OnboardingCard_CustomiseInvoice_LearnMoreLink: "https://www.bokio.co.uk/help/invoice-customers/",
    OnboardingCard_Expense_ActionText: "Create expense claim",
    OnboardingCard_Expense_Description: "Keep track of all expense claims and make reimbursements simple.",
    OnboardingCard_Expense_Header: "Smooth reimbursements",
    OnboardingCard_Expense_LearnMoreLink: "https://www.bokio.co.uk/help/expenses/for-admins/record-expenses/",
    OnboardingCard_Help_Action: "Show the guide",
    OnboardingCard_Help_Description: "5 simple steps to get started with Bokio.",
    OnboardingCard_Help_Header: "Starter guide",
    OnboardingCard_ImportSIE_LearnMoreLink: "https://www.bokio.se/hjalp/komma-igang/importera-bokforing/importera-bokforing-steg-for-steg/",
    OnboardingCard_InviteUser_Description: "Collaborate with your business partner, accountant or employees",
    OnboardingCard_InviteUser_Header: "Invite a user",
    OnboardingCard_InviteUser_LearnMoreLink: "https://www.bokio.co.uk/help/manage-your-account/your-bokio-account/multiple-users-can-work-in-the-same-company/",
    OnboardingCard_Invoice_LearnMoreLink: "https://www.bokio.co.uk/help/getting-started/get-started-with-bokio/getting-started-with-invoicing-in-bokio/",
    OnboardingCard_Invoices_ActionText: "Create invoice",
    OnboardingCard_Invoices_Description: "Create, send and track invoices with just a few clicks.",
    OnboardingCard_Invoices_Header: "Optimise your sales",
    OnboardingCard_SIEImport_ActionText: "Import bookkeeping",
    OnboardingCard_SIEImport_Description: "Get your previous bookkeeping into Bokio using your SIE-file",
    OnboardingCard_SIEImport_Header: "Import older bookkeeping",
    OnboardingCard_Supplier_ActionText: "Create supplier invoice",
    OnboardingCard_Supplier_Description: "Add your supplier invoices and easily keep track of your costs.",
    OnboardingCard_Supplier_Header: "Keep track of your costs",
    OnboardingCard_Supplier_LearnMoreLink: "https://www.bokio.co.uk/help/supplier-invoices/bills/how-do-supplier-invoices-work/",
    OnboardingCard_Tour_Description: "See how you easily find your way around Bokio.",
    OnboardingCard_Tour_Header: "Quick start",
    OnboardingCardBlock_Header: "Get started with Bokio",
    OnboardingChecklist_menu_hide_text: "Hide the checklist",
    OnboardingChecklist_menu_show_text: "Show the checklist",
    OnboardingInviteUser_Heading: "Invite new user",
    OnboardingInviteUser_SubText: "Invite a new user to collaborate in Bokio. Whether it is your accountant, business partner, payroll manager or employee, working together in Bokio is easy.",
    OnboardingVideoCard_ActionText: "Product tour (5 min) / Show me around (5 min)",
    OnboardingVideoCard_Description: "Our aim is to make it easy to run a company, saving you time and allowing you to focus on growing your business. Let us show you how!",
    OnboardingVideoCard_Header: "We are here to help your company grow",
    Premium_BuyPremium: "Buy premium",
    Premium_Cost: "299 kr/month",
    Premium_DontNeed: "I don't need it at the moment",
    Premium_Expectations: "The features don’t match my expectations",
    Premium_Feature1: "Bokio will sync and record the events on your tax account",
    Premium_Feature1Li1: "Bokio will record all transactions on your tax account and reconcile the balances.",
    Premium_Feature1Li2: "Submit your employer declarations (AGI) directly to Skatteverket without having to leave Bokio.",
    Premium_Feature2: "Bokio will sync and record your Zettle transactions",
    Premium_Feature2Li1: "Save time and make sure things gets done properly. All transactions on your Zettle account are imported daily into Bokio and recorded without you having to lift a finger.",
    Premium_Feature2Li2: "Get a comprehensive overview of your company's finances by viewing your Zettle transactions together with the rest of your Bokio transactions.",
    Premium_Feature3: "Get answers to accounting questions and quick chat support",
    Premium_Feature3Li1: "You are always first in line. Your questions will be answered before all free users.",
    Premium_Feature3Li2: "We also help you with accounting questions, in addition to technical questions included in our free support. You can get answers to questions such as which account you should record a specific receipt to, or simply make sure you have done things in the correct way.",
    Premium_Feature3Li3: "Get help getting the most out of Bokio. Every other Monday, Bokio offers Webinars where we go through how to record transactions, send invoices, create salaries and other important features in Bokio.",
    Premium_GetStarted: "Get started with Bokio Premium",
    Premium_HelpUsGetBetter: "Help us get better",
    Premium_HelpUsGetBetterDescription: "We appreciate feedback and if you have any thoughts about Bokio Premium, you can describe your needs and thoughts below so that we can create a world-class accounting software.",
    Premium_HelpUsImprove: "Help us improve",
    Premium_Included: "Included",
    Premium_Monthly: "Monthly",
    Premium_NotInterested: "Not interested",
    Premium_Other: "Other",
    Premium_Payment: "Payment",
    Premium_PaymentDescription: "Choose if you want to pay yearly or monthly for Bokio Premium.",
    Premium_PaymentInformation: "Payment information will be added when you complete the purchase",
    Premium_Price: "Price",
    Premium_ReadMore: "Read more",
    Premium_ReceivedFeedbackInterested: "We have received your feedback and we will do our best to further improve Bokio Premium. We will contact you when the service is released, so that you will be one of the first to start using it.",
    Premium_ReceivedFeedbackNotInterested: "We have received your feedback and we will do our best to improve Bokio Premium and further offerings.",
    Premium_Save10: "Save 10%",
    Premium_SaveTimeAndSpeedUp: "Save time and speed up your workflow with integrations and faster support.",
    Premium_ThankYouHelp: "Thank you for helping us get better",
    Premium_ThankYouImprove: "Thank you for helping us get better",
    Premium_ThankYouInterest: "Thank you for showing interest in Bokio Premium",
    Premium_ThankYouInterestDescription: "The features aren't available yet. In order for us to gain deeper insight in how to prioritise our work, it’s importat for us to do this type of market research. Thanks to your participation, we have received more information on how we should design the future of Bokio. As a thank you, we promise to contact you when the service is released, so that you will be one of the first to receive it.",
    Premium_Title: "Bokio Premium",
    Premium_Value: "I do not see the value of the service provide",
    Premium_WhatIsMissing: "What do you think is missing or can be improved?",
    Premium_WhatsIncluded: "What's included",
    Premium_Yearly: "Yearly",
    Premium1_MonthlyPrice: "199 kr/month",
    Premium1_YearlyPrice: "2 149 kr/year",
    Premium2_MonthlyPrice: "299 kr/month",
    Premium2_YearlyPrice: "3 229 kr/year",
    ProductTour_StepFour_FBLinkSrc: "https://www.facebook.com/groups/240735629672293/",
    ProductTour_StepFour_FBLinkText: "Join the Facebook group",
    ProductTour_StepFour_P1: "In the top bar you’ll find our help section. Use it to search for your question and we'll link you to the right place.",
    ProductTour_StepFour_P2: "Or, why not join our community of entrepreneurs on Facebook to get support from your peers?",
    ProductTour_StepFour_Title: "Still not sure where to find things?",
    ProductTour_StepOne_AccountingText: "Here’s where your Journal lives. You'll get a quick overview of the latest journal entries, or you can record a new one.",
    ProductTour_StepOne_ExpenseText: "Did you spend money for your company? Here’s where you claim back your expenses. Add your employees to your company so they can claim their expenses as well.",
    ProductTour_StepOne_InvoiceText: "Create, send and keep track of invoices. Create customers and items to make invoicing faster.",
    ProductTour_StepOne_SalaryAndExpense: "Expense claims",
    ProductTour_StepOne_SuppliersText: "Keep track of outgoing payments. Create suppliers and register their invoices.",
    ProductTour_StepOne_Title: "Everything in Bokio under one roof",
    ProductTour_StepThree_GuideLinkSrc: "https://www.bokio.co.uk/help/bokio-membership/your-bokio-account/multiple-users-can-work-in-the-same-company/",
    ProductTour_StepThree_GuideLinkText: "This guide helps you invite your accountant.",
    ProductTour_StepThree_H1: "Invite users",
    ProductTour_StepThree_InParaLinkText: "Settings \u003E Users",
    ProductTour_StepThree_P1: "Under Settings in the side menu, you can configure your company. Fill in missing details, or upload your logo and choose color scheme for your invoices.",
    ProductTour_StepThree_P2: "Have a co-founder or accountant that you want to share your insights with? You can invite users to get access to your company in",
    ProductTour_StepThree_Title: "Set up your company for success",
    ProductTour_StepTwo_Li1Header: "Record with or without receipt",
    ProductTour_StepTwo_Li1Text: "Do you have a bill to pay that has an invoice connected to it, or have you bought something that needs recording?",
    ProductTour_StepTwo_Li2Header: "Import from bank",
    ProductTour_StepTwo_Li2Text: "Import bank transactions from your business account.",
    ProductTour_StepTwo_Li3Header: "Create an invoice",
    ProductTour_StepTwo_Li4Header: "Create salary",
    ProductTour_StepTwo_P1: "Pressing the big green Record button will take you straight to where you can record transactions.",
    ProductTour_StepTwo_P2: "If you press the arrow on the button you’ll get more alternatives:",
    ProductTour_StepTwo_Title: "The Record button is your go-to place for everything new",
    ProductTourCard_CTA: "Product tour",
    PromotionModal_HighDepositFree_boxHeader: "We recommend Premium {0} - Flexibility with only a binding period of 3 months",
    PromotionModal_HighDepositFree_boxText: "You can get {0}/month in cashback if you activate Bokio business account and upgrade to Premium {1} for {2}/month. {3}",
    PromotionModal_HighDepositFree_linkText: "See how we calculated here",
    PromotionModal_HighDepositFree_listHeader: "Bokio Business Account and",
    PromotionModal_HightDepositFree_heading: "Get a business account - {0} ~ {1}/month in cashback",
    PromotionModal_HightDepositFree_notice_content: "- Get Premium **Plus** for the cost of Premium for the first month (Save 300 SEK). The repayment is made to your Bokio business account\n- Double your cashback on card spend (2%) for the first month\n\nOffer ends after September 30th.",
    PromotionModal_HightDepositFree_notice_title: "Limited Time Offer for Premium Plus",
    PromotionModal_HightDepositFree_preamble: "All for your company at one place, to a set price without hidden fees. Get a integrated bank account, bank giro, and a business card for free.",
    PromotionModal_LowDepositFree_cancel_button: "Download your data",
    PromotionModal_LowDepositFree_cta_button: "Get started",
    PromotionModal_LowDepositFree_heading: "Dump your bank, pick a plan and get a Bank account with no added cost",
    PromotionModal_LowDepositFree_Notice_header: "Limited time offer",
    PromotionModal_LowDepositFree_Notice_text: "Get a annual report at Årsredovisning Online or a 1000 SEK gift card from our partner Bokadirekt.se when you upgrade to any of our plans. Offer ends after September 30th. Upon your first billing you will receive more information about the offer by email.",
    PromotionModal_LowDepositFree_preamble: "All for your company at one place, to a set price without hidden fees. Just select the plan that aligns with your company’s goals and get a integrated bank account, bank giro, and a business card for free.",
    PromotionModal_LowDepositFree_Premium_checklist: "Bookkeeping with templates | Integrated bank account, bankgiro and business card | Integrated invoicing | Employee salaries | Automatic bank connection",
    PromotionModal_LowDepositFree_Premium_price: "From {0}/month",
    PromotionModal_LowDepositFree_PremiumPlus_checklist: "{0} return on invested capital | {1} return on card spend | Live chat and bookkeeping support",
    PromotionModal_LowDepositFree_PremiumPlus_price: "From {0}/month",
    Start_StartUsingBokioPopoverHeading: "Start using Bokio",
    Start_StartUsingBokioPopoverText: "Get started by uploading your first receipt or invoice, import from bank/file or create your invoice or salary.",
    Survey_Description: "We want to get to know you better to tailor Bokio to your needs",
    Survey_Header: "Hang tight!",
    Survey_StepOne_Answer1: "Bookkeeping",
    Survey_StepOne_Answer2: "Invoicing",
    Survey_StepOne_Answer3: "Managing payslips",
    Survey_StepOne_Answer4: "Closure",
    Survey_StepOne_Answer5: "VAT returns",
    Survey_StepOne_Answer6: "Just looking around",
    Survey_StepOne_CustomAnswer: "Something else",
    Survey_StepOne_Option4: "Closure",
    Survey_StepOne_Question: "What do you want to do in Bokio?",
    Survey_StepThree_Header: "Thank you for helping Bokio!",
    Survey_StepThree_Text: "We always want to get to know our users better so that we can make a great product for you.",
    Survey_StepTwo: "Step 2 of 2",
    Survey_StepTwo_AccountantOption: "I am an accountant",
    Survey_StepTwo_Question1: "Do you have any business activity yet?",
    Survey_StepTwo_Question2: "Are you moving from another accounting software?",
    Survey_StepTwo_Question3: "Are you using an accountant?",
    Survey_StepTwo_Question4: "Are you new to accounting?",
    Survey_StepTwo_Question5: "Are there any employees besides yourself?",
    Survey_StepTwo_Question6: "Do you manage your bookkeeping in a software other than Bokio?",
    TestCompanyBumper_CTA: "Start your real company",
    TestCompanyBumper_Message: "You are one of the most active test company users in the recent period. Are you starting to feel comfortable with Bokio? Maybe you are changing now to add your real business?\n\nDon't forget that we have a trial period where you get access to all the features and that you get to use Bokio for free afterwards if you don't upgrade your plan.",
    TestCompanyBumper_Title: "Well done {0}!",
    TestCompanyPortal_CreateTestCompany: "Create a test company",
    TestCompanyPortal_Default: "Try out bookkeeping and get to know our features in a test company.",
    TestCompanyPortal_InvoicesScene: "We create a customer and generate an invoice for you so you can get to know and test the invoice tool quick and easy.",
    TestCompanyPortal_Modal_Body: "In a test company, you can for example try bookkeeping, create invoices or salaries before doing it in your real company. In some cases, we can also generate test data for you to make it even easier to get to know the feature.",
    TestCompanyPortal_Modal_Input_Cancel: "Cancel",
    TestCompanyPortal_Modal_Input_CreateTestCompany: "Create test company",
    TestCompanyPortal_Modal_Input_Default: "{0} (test)",
    TestCompanyPortal_Modal_Input_Title: "Test Company Name",
    TestCompanyPortal_Modal_Title: "Do you want to create a test company?",
    TestCompanyPortal_Title: "Try this feature in a test company",
    TestCompanyPortal_Transactions: "We will generate a number of transactions for you so you can try bookkeeping right away.",
    TroubleLoadingVideo_message: "Trouble viewing the video?  Read the details in our {0}",
    WelcomeMessage_Viktor_Message: "My name is Viktor and I’m one of the founders of Bokio. Like us, you probably believe that bookkeeping and other business management is unnecessarily complicated. This is exactly why Bokio exists – to make business life easier!\n\nNow your business is up and running! A great way to get started is to simply record your first event.\n\nWelcome to Bokio!",
    WelcomeMessageTestCompany_Viktor_Message: "My name is Viktor and I am one of the founders of Bokio. With this test company you can easily try out and get comfortable with the product at your own pace.\n\nFeel free to bookkeep something and test out our bookkeeping templates, create an invoice or check out our salary function.\n\nWelcome to Bokio!",
    WelcomeModalBox_RequiresPlan: "Requires a plan",
    Wizard_Done_Heading: "Important dates setup completed",
    Wizard_Done_Heading_P_1: "We have now implemented all dates that are important for your company’s needs and excluded deadlines for things that are not relevant. If things change for your company you can always show hidden dates and unhide tasks there. {Read more in our help centre here}.",
    Wizard_Question_CustomersInOtherEUCountries_Heading: "Does the company have customers in another EU country?",
    Wizard_Question_CustomersInOtherEUCountries_No: "This option will exlude everything on this topic, but you can always change is again on the important date page.",
    Wizard_Question_CustomersInOtherEUCountries_Yes: "This option will allow you to precise what EU taxes is relevant for your company.",
    Wizard_Question_Employees_Heading: "Does the company have employees?",
    Wizard_Question_Employees_No: "This option will exlude everything on this topic, but you can always change this again on the important date page.",
    Wizard_Question_Employees_Yes: "This option will import deadlines for {arbetsgivardeklaration} and {arbertsgivaravgift och avdragen skatt}.",
    Wizard_Question_RealEstate_Heading: "Does the company own real estate?",
    Wizard_Question_RealEstate_No: "This option will exlude everything on this topic, but you can always change this again on the important date page.",
    Wizard_Question_RealEstate_Yes: "This option will import deadlines for {fastighetsdeklaration}.",
    Wizard_Question_SellGoodsFromEUToIndividuals_Heading: "Does the company sell goods to individuals within the EU that were bought from a country inside of EU?",
    Wizard_Question_SellGoodsFromEUToIndividuals_No: "This option will exlude everything on this topic, but you can always change is again on the important date page.",
    Wizard_Question_SellGoodsFromEUToIndividuals_Yes: "This option will import deadlines for {moms på OSS}.",
    Wizard_Question_SellGoodsFromOutsideEUToIndividuals_Heading: "Does the company sell goods to individuals within the EU that were imported from a country outside of EU?",
    Wizard_Question_SellGoodsFromOutsideEUToIndividuals_No: "This option will exlude everything on this topic, but you can always change is again on the important date page.",
    Wizard_Question_SellGoodsFromOutsideEUToIndividuals_Yes: "This option will import deadlines for {moms på IOSS} and {IOSS-deklaration}.",
    Wizard_Question_SkatteverketEService_Heading: "Does this company plan to use Skatteverkets e-service?",
    Wizard_Question_SkatteverketEService_No: "This option will exlude deadlines for the e-service.",
    Wizard_Question_SkatteverketEService_Yes: "This option will exlude deadlines for posting forms.",
    Wizard_Question_TradeWithOtherCompaniesInEU_Heading: "Does the company trade with other companies in EU?",
    Wizard_Question_TradeWithOtherCompaniesInEU_No: "This option will exlude everything on this topic, but you can always change is again on the important date page.",
    Wizard_Question_TradeWithOtherCompaniesInEU_Yes: "This option will import deadlines for {periodisk sammanställning via e-tjänst} and {blankett}.",
  };
}