/* eslint-disable */

import type { PartnersLang } from "../types/PartnersLang";
import { translateTempImpl } from "../TranslatableLanguage";

export function PartnersLangDef(): PartnersLang {
  return {
    translateTemp: (key: string, neutral: string, svSE?: string, enGB?: string) => translateTempImpl(key, neutral, svSE, enGB),
    
    Action_AddUser: "Add user",
    Action_DeleteInvite: "Delete invite",
    Action_SendInvite: "Send invite",
    ActionAdd: "Add",
    ActionEdit: "Edit",
    ActionRemove: "Remove",
    Actions: "Actions",
    ActionSearch: "Search",
    Companies: "Companies",
    Company: "Company",
    CompanyOrg: "Org No.",
    CreatePartner: "Create Partner",
    CreatePartnerAction: "Create",
    Invitations: "Invitations",
    InvitedUsers: "Invited users",
    InviteUsers: "Invite users",
    MyCustomers: "My customers",
    Partner: "Partner",
    PartnerName: "Name",
    PartnerNamePlaceholder: "Name of the partner",
    Permissions: "Permissions",
    Receipts: "Receipts",
    Settings: "Settings",
    ToRegister: "To register",
    UserEmail: "Email",
    UserEmail_Label: "User's email address",
    UserName: "Name",
    Users: "Users",
  };
}