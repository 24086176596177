/* eslint-disable */

import type { ClosuresLang } from "../types/ClosuresLang";
import { translateTempImpl } from "../TranslatableLanguage";

export function ClosuresLangDef(): ClosuresLang {
  return {
    translateTemp: (key: string, neutral: string, svSE?: string, enGB?: string) => translateTempImpl(key, neutral, svSE, enGB),
    
    AccountBalance_Description: "You have asserted that the balance of your bank statement on the bank corresponds to the balance show in each account on the last day of your financial year (eg 171231, the recorded value on account 1200 will match the bank's statement on the same date).",
    AccountTax_Description: "You have asserted that the balance of the tax account available on Skatteverkets website match with the balance stated in account 1630 on the last day of your financial year.",
    AccrualAndPrepayment_Description: "Accrual is required when the invoice and outcome of the cost/income are in different fiscal years. For example, you have goods or services that you have provided in the fiscal year but have not sent the invoice until next fiscal year. Another example is if you received goods or services during the fiscal year, but you have not received the invoice until next fiscal year. However, there are some exceptions such as when an individual invoice amount is less than 5000 sek and recurring invoices such as insurance invoices. {Read more here}.",
    AccrualAndPrepayment_headling: "Accrual and prepayments",
    AnnualGeneralMeeting_Download_WithAuditor: "AGM with auditor",
    AnnualGeneralMeeting_Download_WithoutAuditor: "AGM without auditor",
    AnnualGeneralMeetingDescription: "Guide and example protocol",
    AO_AccountCreatedViaEmail: "An account will be created in Årsredovisning Online. If you already have an account, you will be asked to confirm access to it. The account will be created for the following email:",
    AO_BookkeepResult_description: "If a profit occurs, it is moved to equity (if not distributed to shareholders) in the balance sheet which as a consequence increases, while a loss will reduce the equity. Please review the amount presented below and adjust if needed, by clicking on “this is how we calculated button”.",
    AO_BookkeepResult_heading: "Record the profit or loss for the financial year",
    AO_Complete_description: "You now have an account in Årsredovisning Online so you can get started working on your financial statement. When completed update your progress by clicking on update button and go to the next step. If you have made any changes in your accounting since starting Årsredovisning Online you should resend the accounting.",
    AO_Complete_description_download: "Once you are done with the annual report you don't need to download a SIE-file from Årsredovisning Online for the booking of the profit, it will instead be booked with just one click in the next step here in Bokio.",
    AO_Complete_heading: "Complete Årsredovisning Online",
    AO_Connect_Heading: "Connect to Årsredovisning Online",
    AO_ConnectAccount_Action: "Connect",
    AO_GotoWebsite: "Go to Årsredovisning Online",
    AO_Information: "Your accounting for the financial year will be exported to Årsredovisning Online, to make the process simpler for you. Årsredovisning Online will assist you in creating the financial statement, income tax declaration and annual general meeting protocol you will need for your closure. Payment is made after you complete your reports. Read more about {terms and conditions}.",
    AO_Reconnect_Heading: "Reconnect to Årsredovisning Online",
    AO_ReconnectAccount_Action: "Reconnect",
    AO_ReconnectViaEmail_Message: "Your connection to Årsredovisning Online has expired. Please reconnect to keep working with your financial statement. You will reconnect with this email:",
    AO_Status_NotYetStarted: "Not started yet. Go to Årsredovisning Online to get started on your financial statement!",
    AO_YearEndResultAndTaxAreAlreadyRecorded: "The year-end result and tax are successfully recorded.",
    AOClosure_CreateAnnualReport: "Create your year-end report",
    AOClosure_Descriptions: "You will complete your financial statements at Årsredovisning Online.",
    AOClosure_Notice_TestCompany: "You need to convert to a real company to do your closure using Årsredovisning Online",
    AOClosure_SupportIncluded: "Årsredovisning Online support included",
    AOClosure_ToDoAtAO: "To do at Årsredovisning Online",
    AOClosure_ToDoInBokio: "To do in Bokio",
    AOClosureModal_InsturctionText: "You will need to fill and complete some information before we send your accounting for your fiscal year to Årsredovisning Online where you can complete your financial statements. Årsredovisning Online only supports K2.",
    AOClosureModal_Payment: "Payment",
    AOClosureModal_PaymentTerms: "Årsredovisning Online has a standard fee of {0}. You can test their services and only pay if you decide to complete the financial statements in their software. If you choose to create the financial statement you will complete your payment at Årsredovisning Online.",
    AOClosureModal_Thanks: "Thank you for choosing Årsredovisning Online",
    AreYouSure_UndoRecording: "Are you sure you would like to undo the recording?",
    AreYouUnsureHowToDoItYourself: "Are you unsure how to do it yourself?",
    Arsredovisning_Purchase_Clarify: "You will need to fill and complete some information before we send your accounting for your financial year to Årsredovisning Online where you can complete your financial statements. You could test their services and only pay if you decide to complete the financial statements in their software. (Be ware that this service only supports K2 and K3).",
    ArsredovisningOnline: "Årsredovisning Online",
    ArsredovisningOnline_Description: "We will send your accounting for your financial year to Årsredovisning Online where you can complete your financial statements. You could test their service and only pay if you decide to complete the financial statements in their software. Be aware that this service only supports K2:",
    ArsredovisningOnline_ErrorFromAo_Label: "Error message from Årsredovisning Online:",
    BalanceReportExplainIncome: "If you want do a more thorough check you can go through the general ledger to verify that the accounting matches up.",
    BokioClosure: "Bokio Closure",
    BokioClosure_AlmostFinished_CheckList: "**You are almost done**\nBefore our accountants can start working with your closure we kindly ask you to complete this and the upcoming step. ",
    BokioClosure_AlmostFinished_Form: "**You are almost done**\nBefore our accountants can start working with your closure we kindly ask you to complete this step. ",
    BokioClosure_AlternateAgencies: "Show alternate agencies",
    BokioClosure_Buy_Action: "Confirm purchase",
    BokioClosure_Description: "Our accounting professional can help you complete your closure. To be able to complete your closure we would need you to provide us with some information regarding your company in the following step:",
    BokioClosure_PayIn6Month: "* Pay interest-free in 6 months",
    BokioClosure_Purchase_Clarify: "We will support you in completing your closure process. However, we would need your assistance and input with some information regarding your company to complete your closure. Therefore, we kindly ask you to fill in the following pages as thoroughly as possible so we can help you complete your closure.",
    BokioClosure_Purchase_Thank: "Thank you for choosing Bokio closure",
    BokioClosure_Unavailable: "Unavailable at this time",
    BokioClosure_YearEndAccountsByAccountant: "Year end accounts by accountant",
    BokioClosureCooperativeManual_CreateAnnualFinancialStatementNotSupported: "Creating an annual financial statement is not supported",
    BokioClosureK2K3_BookkeepClosure: "Record other year-end journal entries",
    BokioClosureK2K3_BookkeepTax: "Record income tax return",
    BokioClosureK2K3_BookkeepYear: "Record year-end result",
    BokioClosureK2K3_CompleteTaxReturn: "Complete your income tax return",
    BokioClosureK2K3_CreateAnnualReport: "Create your year-end report",
    BokioClosureK2K3_Description: "An accountant from one of our partner agencies will help you complete your closure.",
    BokioClosureK2K3_OfferExiresIn: "Offer expires in {0} days",
    BokioClosureK2K3_SupportIncluded: "Bokio support included",
    BokioClosureK2K3_Supports: "Supports",
    BokioClosureK2K3_WhatOurAccountsDo: "What our accountants will do",
    BokioClosureLimitedManual_BookkeepClosure: "Record the tax and result for the year",
    BokioClosureLimitedManual_CloseYear: "Close your fiscal year",
    BokioClosureLimitedManual_CreateAnnualReports: "Create year-end reports",
    BokioClosureLimitedManual_CreateTaxReturn: "Create income tax return",
    BokioClosureLimitedManual_Description: "Manual closure lets you record all year-end journal entries and close your fiscal year in Bokio. If you have the need to create a year-end report and income tax return we suggest you to choose one of the other packages.",
    BokioClosureLimitedManual_Support: "Support as per your current Bokio plan",
    BokioClosureManual_BookkeepClosure: "Record all year-end journal entries, tax and profit or loss",
    BokioClosureManual_CloseYear: "Close your fiscal year",
    BokioClosureModal_DividendSupport: " ",
    BokioClosureModal_InstructionText: "We will support you in completing your closure process. We kindly ask you to fill in the upcoming pages as thoroughly as possible so we can help you complete your closure and get all your necesseray company information.",
    BokioClosureModal_Payment: "Payment",
    BokioClosureModal_PaymentAdjustments: "If we find that we need to do adjustments to your accounting we will contact you to come to an understanding of how to proceed. We won’t do any changes or consultant work before we have received your approval. Our consultant fee is 1 000 SEK/hour.\n\n*If you already paid for this package you can ignore this message.*",
    BokioClosureModal_PaymentTerms: "By proceeding you accept the payment terms. You can change your package and we will only charge you once step five has been completed. ",
    BokioClosureModal_ThankYou: "Thank you for choosing year end accounts by accountant",
    BokioClosureModal_Title: "Year end accounts by accountant",
    BokioClosurePackage_DigitalClosure_Body: "You can create your year-end report, sign with BankID and send it to the Swedish Companies Registration Office, completely digitally for only SEK 1,000.",
    BokioClosurePackage_DigitalClosure_Heading: "Do you want to try our new digital year-end closing service?",
    BokioClosurePartnershipManual_CreateNEBilaga: "Create the NE appendix of the partnership",
    BokioClosureProprietorship_CreateNEBilaga: "Create and review your NE appendix",
    BokioClosureProprietorship_Description: "Our accounting professional will review and complete your closure.",
    BokioClosureProprietorship_ExpertSupport: "Bokio expert support",
    BokioClosureProprietorshipManual_BookkeepResult: "Record the result for this year",
    BokioClosureProprietorshipManual_CreateNEBilaga: "Create your NE appendix",
    BokioClosureProprietorshipManual_Description: "You will manually complete your closure within Bokio. ",
    BokioClosureProprietorshipManual_Title: "Manual Closure",
    BokioClosureProprietorshipManual_WhatYouHaveToDo: "What you manually have to do",
    BokioClosures_ServiceProvidedByPartners: "The service is provided by our partners through Bokio.",
    BookkeepAndContinue: "Record and continue",
    BookkeepAndContinueToNextStep: "Record &amp; continue",
    BookkeepingIncomeExpenses_Description: "To complete this year's closure you need to record and account for all your income and expenses belonging to the financial year, such as supplier and customer invoices, receipts, salaries and expenses. If you have income or expenses that you have not created or received by the end of the year, these will be handled in step 3 of the closure checklist.",
    BookkeepingNothingInPeriod: "You haven't recorded against a bank account this fiscal year",
    BookkeepingYourIncomeAndExpenses: "Accounting all your income and expenses",
    BookkeepPeriodisation_heading: "Record accrual and prepayments",
    BookkeepResult: "Record result for year",
    BookkeepResultAndTax: "Record result and tax for year",
    BookkeepResults_description: "The last step of the closure is to record a transfer of this year's result to the balance sheet.",
    BookkeepResults_description_dividend: "The last step of the closure is to record a transfer of this year's result to the balance sheet.",
    BookkeepResults_heading: "Record your results",
    BookkeepTax: "Record tax",
    BookkeepTax_description: "As part of the closure process you need to record the corporate tax for the financial year. Corporate tax is taxed on the profit that the company has made during the financial year. Please review the amount presented below and adjust if needed by clicking on the “this is how we calculated\" button.",
    BookkeepTax_heading: "Record corporate tax",
    ChangeDate_ButtonText: "Change date",
    CheckFiscalYear_StatusTitle: "Check fiscal year",
    CheckFiscalYears_NoticeTitle_Error: "Fiscal year stated in Bokio is incorrect",
    CheckFiscalYears_NoticeTitle_Warning: "Make sure that fiscal year is correctly stated in Bokio",
    ChoosePackage: "Choose package",
    ChoosePackage_Description_WithNe: "Once you've completed the closure you'll be able to download the numbers for your NE attachment.",
    ChoosePackage_Description_WithoutNe: "Now you have completed and confirmed the initial checklist, the next step is to decide how you want to proceed with your closure. The following option is available for your company.",
    ChooseTypeOfClosure: "Choose type of closure",
    CloseYear: "Close year",
    CloseYearAndShowClosure: "*TRANSLATE*",
    Closure: "Year-end closure",
    Closure_Cancel_Button: "Cancel",
    Closure_Rating_Description: "Any thoughts that could help us improve your experience with the closure?",
    Closure_Rating_Title: "How was your experience completing this closure?",
    ClosureCooperativeManual_Description: "Doing a manual closure only allows you to record the year's taxes and result as well as close your fiscal year. A cooperative must always create their annual financial statement and have an accountant. Depending on the size of the organization the financial statement should be created in accordance with the K2 or K3 regulations and that is something done outside of Bokio. What you can do in Bokio:",
    ClosureDefaultManual_Description: "Doing a manual closure only allows you to record the year's taxes and result as well as close your fiscal year in Bokio.",
    ClosureDescription: "Reconcile, accrual, and record last year’s tax and result",
    ClosureFeedback_Checkbox1_label: "I complete my closure outside of Bokio",
    ClosureFeedback_Checkbox2_label: "Someone else in the company does the closure",
    ClosureFeedback_Checkbox3_label: "I pay someone to complete my closure for me",
    ClosureFeedback_Checkbox4_label: "It is not relevant for me at this time, I will complete my closure at a later date",
    ClosureFeedback_Feedback_Message1: "Before continuing, could you answer the following question?",
    ClosureFeedback_Feedback_Message2: "What is your reason for dismissing this reminder?",
    ClosureFeedback_Feedback_Title: "Hide closure information",
    ClosureLimitedPartnershipManual_Description: "Doing a manual closure only allows you to record the year's taxes and result as well as close your fiscal year in Bokio. Most limited partnership companies do not need to send any financial statement to Bolagsverket unless it is a larger company or have any legal persons as partners. What you can do in Bokio:",
    ClosureNoYearsToClosureText: "When your fiscal year has ended, it will show up here.",
    ClosureNoYearsToClosureTextCompleted: "When you have completed your first closure it will show up here.",
    ClosureNoYearsToClosureTextUpcoming: "When your next fiscal year is created it will show up here.",
    ClosureNoYearsToClosureTitle: "No closures to show",
    ClosurePackage_CloseYear: "Close your fiscal year",
    ClosurePackage_GetStarted_Button: "Get started",
    ClosurePackageSelection_OtherPackageButton_GoToPrevYearEndSol: "Go to previous year-end solution",
    ClosurePackageSelection_OtherPackageDescription: "To select one of Bokio's other year-end packages below, you need to use the previous year-end solution.",
    ClosurePackageSelection_OtherPackageHeader: "Other year-end packages",
    ClosurePackageSelection_OtherPackageOne_Desc: "An accounting consultant from one of our collaboration agencies will help you complete your financial statements.",
    ClosurePackageSelection_OtherPackageOne_Info: "Supports only K3",
    ClosurePackageSelection_OtherPackageOne_Price: "14 990 kr",
    ClosurePackageSelection_OtherPackageOne_Title: "Year-end closure by an accountant",
    ClosurePackageSelection_OtherPackageThree_Price: "0 kr",
    ClosurePackageSelection_OtherPackageThree_Title: "Manual year-end closure",
    ClosurePackageSelection_OtherPackageTwo_Desc: "An accounting consultant from one of our collaboration agencies will help you complete your financial statements.",
    ClosurePackageSelection_OtherPackageTwo_Info: "Supports only K2",
    ClosurePackageSelection_OtherPackageTwo_Price: "7 990 kr",
    ClosurePackageSelection_OtherPackageTwo_Title: "Year-end closure by an accountant",
    ClosurePartnershipManual_Description: "Doing a manual closure only allows you the year's taxes and result, close your fiscal year and create the NE appendix. Do note that we only create an NE appendix and that this needs to be shared between you and the other partners. What you can do in Bokio:",
    ClosuresTodoReminder_ErrorMessage: "An error occurred when creating the todo reminder.",
    CompanyInformation_IHaveSubmitted: "I have submitted this form",
    CompleteCompanyInformation_description: "Please fill in this form which contains information that we can't read from your bookkeeping but is necessary for us to complete your financial statements.",
    CompleteCompanyInformation_heading: "Complete company information",
    CompleteCompanyInformation_Status: "Current progress:",
    CompleteVATReport: "Complete VAT returns",
    CompleteVATReport_Description: "Before you are able to complete your year-end closure you must review and complete your VAT returns belonging to your financial year. To be able to complete your last VAT return you need to close all previous VAT returns in the right order starting with the oldest VAT return first.",
    ConfirmAndContinue: "Confirm and continue",
    ConfirmDividend_description: "If your company has decided to pay dividends to its shareholders, this must be documented in the minutes of the annual general meeting and recorded in the coming financial year. {Read more}",
    ConfirmDividend_heading: "Confirm dividend",
    ConfirmToUnlockStep_description: "Are you sure you want to unlock this step?",
    ConfirmToUnlockStep_heading: "Unlock this step",
    ContinueToNextStep: "Continue to next step",
    ContinueWithAO: "Continue with Årsredovisning Online",
    ContinueWithBokioClosure: "Buy Bokio Closure",
    ContinueWithManualClosure: "Continue with manual closure",
    Cost_heading: "Interest cost",
    CreateTaxReturn: "Create your income tax return",
    CreateYearEndBookkeeping: "Record year-end journal entries",
    CreateYourClosure: "Create your closure",
    CreateYourClosure_Description: "In the end of and every year you need to close your financial year by doing a closure. The purpose is to complete all your accounting so you can give a true and fair view of your accounts up to the last day of your financial year. This is necessary by law and also a great way to assist you in checking that your accounting is correct and up to date.",
    CreatingYourDocuments_description: "We are right now creating your closure files you will need. We will send the files to your mail when they are completed. We will update this page with what steps you need to do.",
    CreatingYourDocuments_heading: "Sit back and relax, we will create your files now",
    DatesAndEvents_ChangePackage: "Change package",
    DatesAndEvents_ClosureDateAndEvents: "Closure date and events",
    DepositionsStep_Info: "*TRANSLATE*",
    DepositionsStep_InfoTitle: "*TRANSLATE*",
    DepositionsStep_Title: "*TRANSLATE*",
    Depreciation_Action: "If you haven't recorded this you need to do it before you proceed to the next step.",
    Depreciation_Button: "Depreciate",
    Depreciation_Description_InfoBar: "There are {0} asset(s) in the asset registry in Bokio that needs to be depreciated",
    Depreciation_Description_InfoBar_NoAssetsToDepreciate: "There are no assets in the asset registry in Bokio that needs to be depreciated",
    Depreciation_Description1: "Assets are resources owned by a company and which have future economic value that can be measured in value. For example, buildings, machinery and fixtures. {Read more}.",
    Depreciation_heading: "Depreciation",
    Depreciation_ProvideInformation: "Please provide us information regarding any asset that need to be depreciated and relevant information such as type, depreciated method, previous accumulated depreciation, depreciated period,journal entry number etc. {Read more}.",
    Depreciations_ConfirmModal_Text: "You have assets in Bokio's asset registry which are not depreciated up to date. If you continue without depreciating the bookkeeping and asset registry will be out of sync. If you have recorded the depreciations manually, consider archiving the asset. If you want to track assets in Bokio's registry you can create new assets and always do depreciations from there.\n\n\nDo you want to continue?",
    Depreciations_ConfirmModal_Title: "You have assets in registry without depreciations",
    DepreciationStep_Info: "*TRANSLATE*",
    DepreciationStep_InfoTitle: "*TRANSLATE*",
    DepreciationStep_Title: "*TRANSLATE*",
    DigitalArchive_Backup_Content: "If you want to access files created during the year-end process, those are collected below.  ",
    DigitalArchive_Backup_Heading: "Back-up closure data",
    DigitalArchive_ConfirmUnlockYearContent: "As Bokio has disabled it’s year end service you will no longer be able to make edits to this year end. We therefore urge you to download your current files before you continue. Unlocking a fiscal year will also unlock any following years.",
    DigitalArchive_Download: "Download all ",
    DigitalArchive_Files: "Files",
    DigitalArchive_HowToFile: "{How to file your annual report online to Bolagsverket.}",
    DigitalArchive_HowToFile_Link: "https://bolagsverket.se/en/sjalvservice/etjanstersjalvservice/lamnainarsredovisningendigitalt/saharlamnarduinarsredovisningendigitalt.1667.html",
    DigitalArchive_Info_Content_CloseYear: "Bokio has disabled it’s year end service. This means that you will no longer be able to create new reports, make edits or submit documents using this service. You can still close the year and download any files already created with the service.",
    DigitalArchive_Info_Content_DownloadFiles: "Bokio has disabled it’s year end service. This means that you will no longer be able to create new reports, make edits or submit documents using this service. You will still be able to download any files associated with the year end report.",
    DigitalArchive_Info_Heading: "Information regarding Bokios year end service",
    DigitalArchive_LockYear_Content: "You are not able to make any changes to this year end closure but you can still close the year. You will need to submit your documents to Bolagsverket within 7 months after the end of a financial year.",
    DigitalArchive_UnlockYear_Accept: "I have downloaded my files and understand that I won’t be able to do any changes in the year end closure.",
    DigitalArchive_UnlockYear_Content: "As Bokio has disabled it’s year end service you will no longer be able to make edits to this report. If you make any changes to your bookkeeping you will need to make a new year end. Unlocking a financial year will also unlock any following years.",
    DigitalArchive_UnlockYearEnd: "Unlock year end",
    Download_AGMMinutes_heading: "Download AGM minutes template",
    DownloadAllFiles_ButtonText: "Download all files",
    FeatureToggle_ActivateNow_Action: "Activate now",
    FeatureToggle_TryNewClosures: "We just launched a completely new and improved closure experience. Activate and try it out today!",
    FillYourAnswer: "Fill in your answer",
    FinishStep_Title: "*TRANSLATE*",
    FinishStep_TitleFinished: "The closure is finished. Good work!",
    FiscalYear_QuickCloseError_FiscalYearNotBalanced: "The fiscal year is not balanced",
    FiscalYearMismatch_ErrorMsg: "The fiscal year that you have specified in Bokio does not correspond to the fiscal year that is registered at Bolagsverket.",
    FiscalYearMismatch_WarningMsg: "The fiscal year that you have specified in Bokio does not correspond to the fiscal year that is registered at Bolagsverket. This difference can arise if your company has an extended first fiscal year but submits the annual report before the end of the fiscal year.\n\nIf you are aware of this difference, and intend to submit the annual report earlier, you can disregard this warning. If you do not have an extended fiscal year and this warning came unexpectedly, we recommend that you change the fiscal year in Bokio to one registered at Bolagsverket.",
    FreeOfCharge: "Free of charge",
    GetHelpByOneOfOurPartners: "Get help by one of our partners.",
    Goods_heading: "Purchases of goods (applies if you sell goods)",
    GoToMyPagesAtBolagsverket_action: "Go to {My Pages} at Bolagsverket",
    HireAConsultant: "Hire a consultant",
    HowWeCounted: "How this was calculated",
    IAlreadyBookkept: "I have already recorded this",
    ImportBookkeeping: "Import Accounting",
    Income_heading: "Income",
    IncomeFromAccrualFunds: "Income from accrual funds",
    IncomeFromInvestmentFunds: "Income from investment funds",
    InitialChecklist_NotOpenYet: "You cannot close the fiscal year {0} until after {1}",
    InvalidOrgNumberChecksum: "The organization number for the company seems to have a typo (invalid checksum). Click {here} to update it.",
    InvalidOrgNumberFormat: "The organization number for the company is improperly formatted. Click {here} to update it.",
    K2Tooltip_Text: "Smaller companies generally use K2 which is a simplified and standardized regulatory framework.",
    K3Tooltip_Text: "K3 is more extensive and more suitable for large enterprises",
    LimitedCompanyPackage_Description: "K2 and K3 are two difference accounting policies that can be used by limited companies. Smaller companies generally use K2 which is a simplified and standardised regulatory framework while K3 is more extensive and more suitable for large enterprises. Read more under {help section}.",
    LockableState_CanNotCloseYear: "You will be able to start your year-end report after {0}, when this fiscal year has ended.",
    LockableState_ClosedYearCannotChangePackage: "To change the year-end closure service, you have to first unlock this fiscal year at the last step of the currently selected closure service.",
    LockableState_MustLockPreviousYearsFirst: "To continue, you must first complete your year-end closures for previous fiscal years.",
    LockYear_description: "When this financial year is locked, it will not be possible to add any more accounting during this period. You can reopen the financial year again, if anything should be corrected. Unlocking a financial year will also unlock any following years.",
    LockYear_heading: "Lock year",
    LockYearTooSoon_Info: "You are about to close the fiscal year which ended less than 2 weeks ago. We recommend you to wait until 2 week after it has ended as there may still be unrecorded events.",
    LockYearTooSoon_Title: "The fiscal year ended less than 2 weeks ago",
    LockYearWithUnbalancedReport_Info: "Your assets and liabilities for this period do not balance. This may be due to expenses or incomes that have been added without recalculating your taxes and results. Locking this fiscal year will cause an imbalance for the next fiscal year. Are you sure you wish to continue?",
    ManualClosure: "Manual Closure",
    ManualClosure_Description: "If you want to closure all by yourself use this option.",
    ManualClosureModal_FreeMessage: "This package is free of charge.",
    ManualClosureModal_InstructionText: "This option is suitable if you want to complete the closure all by yourself. In the following steps you will need to fill in and record all necessary transactions regarding your closure. Remember to lock the financial year in the last step.",
    ManualClosureModal_ThankYou: "Thank you for choosing Manual closure",
    ManualClosureModal_Title: "Manual Closure",
    ManualYearEndClosureDescription: "Record closure transactions and close year",
    MissingOrgNumber: "The organization number is missing for the company. Add it {here}.",
    NEAppendix: "NE appendix",
    NeAppendixWarning_NegativeCells: "There are cells with a negative value which will not be accepted by Skatteverket. This could be because of some issue in the bookkeeping. It could also be that the amount should be moved to a cell on the opposite side, for example a negative asset should be moved to liabilities. {Read more about the warning}.",
    NEBilaga: "NE appendix-{year}",
    NEBilaga_DeclarationHelp: "Filing help",
    NEBilaga_Description: "This page shows how the NE appendix at Skatteverket will look. This is not supposed to be printed. It's here to help you fill in your declaration.  The NE appendix is filled out based on your bookkeeping. There are more fields than the ones shown here and those needs to be filled in manually. Typically this is because it's tax adjustments that Bokio has no information about.",
    NEPurchaseInitiationError_GenericMessage: "Failed to start NE Attachment purchase, please try again later.",
    NEPurchaseInitiationError_InvalidRecipientInfoDetails: "Some contact information about the business has not been specified. Please make sure all [company infromation](bokio:/companyid/settings-r/company-settings) is correctly provided such as company name, personal identity number, address, contact person and email.",
    NEPurchaseInitiationError_InvalidRecipientInfoTitle: "Invalid contact information",
    NextStep: "Next step",
    NonBokioUser_Completed_Description: "The annual report for {0} is complete.",
    NonBokioUser_Completed_Title: "Completed!",
    NonBokioUser_OfferBody: "Discover our plans",
    NonBokioUser_OfferHeading: "Continue to simplify your accounting!",
    NonBokioUser_OfferText: "Offer",
    NonRefundable: "Non deductible costs",
    NonTaxableIncome: "Non taxable income",
    NotSureBookeepingIsCorrect: "Not sure if the bookkeeping is correct?",
    OneReport: "One report",
    OpenFiscalYear_Action: "Open year",
    OpenTheForm: "Open the form",
    Others_heading: "Other purchases",
    Overview_ClosuresNotice_Paragraph: "Avoid the last minute stress of submitting your year-end closure by planning ahead. We have put together guides to help you understand what needs to be done.",
    Overview_ClosuresNotice_Title: "Get started with your closure",
    OverviewOfEssentialAccounts: "Overview of essential accounts",
    Periodisation_Cost_OutstandingInterestLosses: "Are there any interest costs that will be paid in the next fiscal year?",
    Periodisation_Cost_OutstandingInterestLosses_InputLabel: "Upcoming interest costs",
    Periodisation_Cost_PrepaidInterestLosses: "Did you prepay any interest costs for next fiscal year?",
    Periodisation_Cost_PrepaidInterestLosses_InputLabel: "Prepaid interest costs",
    Periodisation_Goods_GoodsInventory: "Is the value of your inventory different than the value that was recorded in the end of the fiscal year?",
    Periodisation_Goods_GoodsInventory_Hint: "For example you might find broken goods when you do your inventory, then you would get a negative value here because the value is lower than the accounting says.",
    Periodisation_Goods_GoodsInventory_InputLabel: "Inventory difference",
    Periodisation_Goods_GoodsOutstandingExpenses: "Did you receive any goods that will be invoiced in the next fiscal year?",
    Periodisation_Goods_GoodsOutstandingExpenses_InputLabel: "Upcoming goods payments (Ex VAT)",
    Periodisation_Goods_GoodsPrepaidExpenses: "Were you invoiced for any goods that you will receive in the next fiscal year?",
    Periodisation_Goods_GoodsPrepaidExpenses_InputLabel: "Paid amount",
    Periodisation_Income_OutstandingInterest: "Were there any interests that you should have received but is going to be paid in the next fiscal year?",
    Periodisation_Income_OutstandingInterest_InputLabel: "Interest to receive",
    Periodisation_Income_OutstandingSales: "Did you sell any goods or services that will be invoiced in the next fiscal year?",
    Periodisation_Income_OutstandingSales_InputLabel: "Amount to invoice (Ex VAT)",
    Periodisation_Income_PrepaidInterest: "Did you receive any interest that belongs to the next fiscal year?",
    Periodisation_Income_PrepaidInterest_InputLabel: "Received interest",
    Periodisation_Income_RecievedAdvances: "Did you send an invoice for any goods or services that will be delivered in the next fiscal year?",
    Periodisation_Income_RecievedAdvances_InputLabel: "Received payments (Ex VAT)",
    Periodisation_Others_OtherOutstandingExpenses: "Did you receive any goods or services that will be invoiced in the next fiscal year?",
    Periodisation_Others_OtherOutstandingExpenses_InputLabel: "Upcoming payment (Ex VAT)",
    Periodisation_Others_OtherPrepaidExpenses: "Were you invoiced for any goods or services that you will receive in the next fiscal year?",
    Periodisation_Others_OtherPrepaidExpenses_InputLabel: "Invoice amount (Ex. VAT)",
    Periodisation_Salary_PrepaidSalaries: "Did you prepay any salaries for the next fiscal year? If you use Bokio Salary you can select No since it is then handled automatically",
    Periodisation_Salary_PrepaidSalaries_InputLabel: "Salary amount",
    Periodisation_Salary_UnpaidSocials: "Were there any social fees for the year that will be paid in the next fiscal year? If you use Bokio Salary you can select No since it is then handled automatically",
    Periodisation_Salary_UnpaidSocials_InputLabel: "Social fees to pay",
    PeriodisationStep_Info: "*TRANSLATE*",
    PeriodisationStep_InfoTitle: "*TRANSLATE*",
    PeriodisationStep_Title: "*TRANSLATE*",
    PeriodizationAccount_OutgoingBalance_HelpArticle: "If you're unsure then please see our guide.",
    PeriodizationAccount_OutgoingBalance_Warning: "The following accrual accounts have an outgoing balance: {0}. Make sure the accrual account is correctly named to follow the BAS standard. You can update the name of accrual accounts under {Chart of Accounts.}",
    Progress_ClosureChecklist: "Year-end journal entries",
    Progress_ClosurePackage: "Choose closure package",
    Progress_CompanyInformation: "Closure form",
    Progress_CompleteClosure: "Complete closure",
    Progress_InitialChecklist: "Reconciliation",
    Progress_SubmittedToBokio: "Submitted to Bokio",
    Provision_Description1: "Provisions are liabilities that there is an uncertainty with respect to maturity date and / or amount but are likely to result in an outflow of economic benefits in the future for example warranties. {Read more}.",
    Provision_Description2: "If you need to record any provisions, please state type and amount below.",
    Provision_heading: "Provisions",
    Provisions_Action: "If you haven't recorded this you need to do it before you proceed to the next step.",
    ReadyToDownload: "Ready to download",
    RecordedAs_VerificationNumber: "Recorded as",
    ResendBookkeeping: "Resend accounting",
    Resources_NEBilaga: "Show NE appendix",
    ResultBeforeTax: "Profit/loss before tax",
    ResultStep_Title: "*TRANSLATE*",
    Salary_heading: "Salaries",
    SavedUnderskott: "Saved deficit",
    SelectClosurePackage_HowToPayModal_DescOne: "You can complete steps one and two in the year-end flow without payment. In step three you will be prompted to accept the payment terms and will be invoiced {price} for the service. ",
    SelectClosurePackage_HowToPayModal_DescTwo: "We charge for offering a digitized year-end solution where you avoid paperwork and everything is gathered in one place. This means that we create your year-end report, income declaration 2 and associated SRU files, enable signing with BankID and submit the year-end report digitally.",
    SelectClosurePackage_HowToPayModal_HeaderOne: "At what stage do we invoice for the service?",
    SelectClosurePackage_HowToPayModal_HeaderTwo: "What do we charge for?",
    SelectClosurePackage_HowToPayModal_SelectPackageAndCont_Button: "Select package and continue",
    ShowAllClosures: "Show all closures",
    ShowInfo: "Show info",
    SignAnnualGeneralMeetting_description: "In addition you need to complete and sign the Annual General Meeting protocol. The protocol must be dated the same date as the Financial statement. However the meeting protocol does not need to be sent into Bolagsverket.",
    SignAnnualGeneralMeetting_description_llc: "In addition you need to complete and sign the Annual General Meeting protocol. The protocol must be dated after the Financial statement. However the meeting protocol does not need to be sent into Bolagsverket.",
    SignAnnualGeneralMeetting_heading: "Sign the Annual General Meeting protocol",
    SignSendCertificateFinancialStatementToBolagsverket_description: "A board member or the managing director shall certify that the financial statement have been determined at the Annual General Meeting. The certificate shall contain the dates of the Annual General Meeting and information on how the total profit or loss has been processed.",
    SignSendCertificateFinancialStatementToBolagsverket_heading: "Sign and send certificate of approval together with the financial statement to Bolagsverket",
    SignSendCertificateFinancialStatementToBolagsverket_subdescription1: "The certificate:",
    SignSendCertificateFinancialStatementToBolagsverket_subdescription2: "shall be original",
    SignSendCertificateFinancialStatementToBolagsverket_subdescription3: "shall be signed by the board member or the managing director",
    SignSendCertificateFinancialStatementToBolagsverket_subdescription4: "shall state the date of the Annual General Meeting",
    SignSendFinancialStatementToBolagsverket_description: "A copy of the financial statement needs to be signed and sent to Bolagsverket together with the certificate of approval. See section about certificate of approval and about Annual General Meeting. The address to Bolagsverket is Bolagsverket, Årsredovisningar, 851 98 Sundsvall.",
    SignSendFinancialStatementToBolagsverket_description1: "If you are a larger company or the owners are legal entities- you need to send it to Bolagsverket. The adress to Bolagsverket is Bolagsverket, Årsredovisningar, 851 98 Sundsvall.",
    SignSendFinancialStatementToBolagsverket_description2: "If you are a larger company you need to send it to Bolagsverket.A copy of the financial statement needs to be signed and sent to Bolagsverket together with the certificate of approval. See section about certificate of approval. The dates in the financial statement needs to be the same date as the Association annual General Meeting protocol. See section about Annual General Meeting. The address to Bolagsverket is Bolagsverket, Årsredovisningar, 851 98 Sundsvall.",
    SignSendFinancialStatementToBolagsverket_heading: "Sign and send financial statement to Bolagsverket",
    SignSendIncomeTaxLimitedPartnershipToSkatteverket_description: "Submit the income declaration (inkomstdeklaration 4) before your deadline. Read more at your personal page at Skatteverket",
    SignSendIncomeTaxLimitedPartnershipToSkatteverket_heading: "Submit the income declaration (inkomstdeklaration 4) of the limited partnership at skatteverket.se",
    SignSendIncomTaxToSkatteverket_description: "You need to submit your tax declaration before the deadline. You can both submit the declaration and find your deadline at your company page at skatteverket.se.",
    SignSendIncomTaxToSkatteverket_description1: "You need to add the figures from your NE appendix to your personal declaration. Sign and send in your personal tax declaration before their given deadline, Read more at your personal page at Skatteverket.",
    SignSendIncomTaxToSkatteverket_description2: "Each partner reports its share of the profit.You need to add the figures from your N3A or N3B- bilaga to our personal declaration. Sign and send in your personal tax declaration before their given deadline. Read more at your personal page at Skatteverket.",
    SignSendIncomTaxToSkatteverket_heading: "Submit your income declaration at skatteverket.se",
    SignSendPrivateIncomeSkatteverket_description: "You need to add the figures from your N3A or N3B- bilaga to our personal declaration. Sign and send in your personal tax declaration before their given deadline. Read more at your personal page at Skatteverket.",
    SignSendPrivateIncomeSkatteverket_heading: "Fill in your private income declaration at Skatteverket.se",
    Skip_Button: "Skip",
    State_Active: "Active",
    State_Completed: "Completed",
    State_Incomplete: "Incomplete",
    State_Ongoing: "Current year",
    State_Upcoming: "Upcoming",
    StatusAtBolagsverket_label: "Status at Bolagsverket",
    StatusOfProgress: "Status of progress: ",
    Step: "Step",
    SubmissionStatus_Accepted_ApprovedAndSubmitted: "Submitted and approved",
    SubmissionStatus_Deleted: "Deleted ",
    SubmissionStatus_Draft_AwaitingSignature: "Awaiting signature",
    SubmissionStatus_InjunctionSent: "Injunction sent",
    SubmissionStatus_NotRegistered: "Report removed due to previously signed report already having been submitted",
    SubmissionStatus_NotSubmitted: "Not submitted",
    SubmissionStatus_Registered_Signed: "Signed and awaiting approval",
    SubmissionStatus_SupplementReceived: "Supplement received",
    SubmissionStatus_Unknown: "Unknown",
    SubmissionStatusFailed_ErrorMessage: "Could not retrieve the submission status from Bolagsverket",
    SubmitReports: "Submit the year-end report digitally with BankID to Bolagsverket",
    SuitableForSmallBusinesses: "Suitable for small businesses",
    SWE_TaxResultRounding: "Rounding",
    SWE_TaxResultRounding_Text: "Rounded according to Skatteverkets rules",
    Task_HelpFromExperts: "Help from our accouting experts",
    Task_SupportFromAO: "Support from Årsredovisning Online",
    Task_WeBookkeepOtherYearEndResult: "We record all other year-end journal entries",
    Task_WeBookkeepYearEndResult: "We record the year's tax and result",
    Task_WeBookkeepYearEndResultAndTax: "We record the year's tax and result",
    Task_WeBookkeepYourResultToghether: "We help you record the year's result",
    Task_WeCloseFinalYear: "We close your financial year",
    Task_WeCompleteIncome: "We can complete your income tax return",
    Task_WeCreateAndReviewBilaga: "We create and review your NE appendix",
    Task_WeCreateBilga: "We create your NE appendix for you",
    Task_WeCreateFinancialStatment: "We create your financial statement and close your financial year",
    Task_YouCloseFinancialInBokio: "You close your financial year in Bokio",
    Task_YouCompleteChecklist: "You complete the closure checklist",
    Task_YouCompleteClosureChecklist: "You complete the closure checklist",
    Task_YouCompleteIncomeThroughAO: "You complete your income tax return through Årsredovisning Online",
    Task_YouCompleteYear: "You record all year-end journal entries",
    Task_YouCompleteYearEndThroughAO: "You record all year-end journal entries through Årsredovisning Online",
    Task_YouCreateFinancialStatement: "You create your financial statement",
    Task_YouCreateFinancialThroughAO: "You create your financial statement through Årsredovisning Online",
    Task_YouCreateIncome: "You complete your income tax return",
    Task_YouProvideCompanyInformation: "You provide us with company information",
    Task_YouSendDocumentsToErket: "You send the documents to bolagserket",
    Task_YouSendK1DocumentsToSkaket: "You send the documents to skatteverket",
    Task_YouSendK2DocumentsToSkaket: "You send the files and documents to the authorities",
    TaxAndResults: "Tax and results",
    TaxAndResultsError_InvalidIncomeAdjustmentValue: "Invalid income adjustment value in field \"{0}\"",
    TaxAndResultsError_PeriodisationFundWithdrawalOverLimit: "Periodisation fund withdrawal is over limit",
    TaxAndResultsError_ValidatonError: "Validation error in field {0}",
    TaxAndResultsField_AllocationToAccrualFund: "Allocation to accrual fund",
    TaxAndResultsField_IncomeForRemainingAccrualFunds: "Income for remaining accrual funds",
    TaxAndResultsField_IncomeForRemainingInvestmentFunds: "Income for remaining investment funds",
    TaxAndResultsField_OtherNonRecordedCosts: "Other non-recorded costs",
    TaxAndResultsField_OtherNonRecordedIncome: "Other non-recorded income",
    TaxAndResultsField_UnusedLossFromPreviousYear: "Unused loss from previous year",
    TaxDeclaration: "Tax declaration",
    TaxDeclarationDescription: "Generate SRU-files to hand in INK2 to Skatteverket",
    TaxResult: "Taxable result",
    TaxStep_Info: "*TRANSLATE*",
    TaxStep_InfoTitle: "*TRANSLATE*",
    TaxStep_Title: "*TRANSLATE*",
    TheEntireYearEndProcess: "The entire year-end process",
    Tip_InviteAccountant: "Did you know that you can invite multiple users to your company, e.g. an accounting experts that can help you with your year-end closure? [Invite users]({0})",
    TransferOfYearsResult: "Transfer of year's result",
    TransferOfYearsResult_description: "In limited companies, you should transfer the previous year's results from the account on 2099 (Net profit) to account 2098 (Profit or loss from the previous year), where all the results from the previous year's profits will accumulate.",
    UK_CloseYear_LimitedCompany_Body: "Before you close the year, ensure that you have filed your year-end accounts with Companies House and that the balance sheet in Bokio agrees to the year-end accounts filed.",
    UK_CloseYear_SoleTrader_Body: "Before you close the year, ensure that you submit your self assessment tax return to HMRC and ensure this agrees to Bokio.",
    UK_CloseYear_Title: "Close year",
    UK_ReviewChecklist_Body: "To prepare for your year-end closure in Bokio, we recommend that you review the checklist below:\n\n- Review fixed assets and ensure that all depreciation and disposals have been recorded\n- Complete a bank reconciliation to verify that your bank statements matches the balance in account 1200\n- Make sure all your VAT returns have been submitted for the current fiscal year\n- All your customer and supplier invoices have been recorded\n- All other income and expenses have been recorded\n- Check that all balance sheet accounts are correct\n- Record corporation tax liability and ensure it agrees to Corporation Tax Return (applies to limited companies)",
    UK_ReviewChecklist_Title: "Review checklist",
    UndoPart: "*TRANSLATE*",
    UndoRecording_TransferEquity: " Undo recording",
    UndoSkip_Button: "Undo skip",
    UnlockStep: "Unlock step",
    UnlockToMakeChanges: "To make changes on this step you have to unlock it",
    UnlockYear: "Unlock year",
    UnlockYear_TransferEquityVerification_Info: "Are you sure you would like to unlock this fiscal year? This will remove the reset equity verification if you've already recorded it.",
    UpdateContactInformation_action: "Update contact information",
    UpdateStatus: "Update status",
    UsedUnderskott: "Used deficit",
    UtdelningsSupport: "Dividend support",
    VatNotCompletedWarningContent: "Before closing a fiscal year you must account for your VAT returns. Finish the incomplete VAT returns to create the correct journal entries. If you have already created the entries through import or manual accounting you can ignore the VAT return reports in Bokio.\n\nDo you want to continue?",
    VatNotCompletedWarningTitle: "You have not completed all VAT returns for the fiscal year",
    VATReport: "VAT returns",
    WhatToDoNext_heading: "What to do next",
    WhatToDoNext_Link: "https://www.bokio.se/hjalp/bokslut/bokslut/bokfora-foregaende-ars-resultat-i-aktiebolag-vid-nytt-rakenskapsar/",
    WhatToDoNextContent: "Now when the closing of the books and the annual report have been completed, it is time to book previous year's dividends. Read more about how to proceed {here}. ",
    YearEndClosurePeriod: "Year-end closure period {0}",
    YearEndClosureServices: "Year-end closure services",
    YearEndReport_BankIdSignatureMessage: "Please sign the year-end report for \"{0}\" with organisation number {1}",
    YearEndReportDescription: "Sign with BankID and digital hand-in to Bolagsverket",
    YearEndResult: "Yearly result",
    YouChooseArsredovisningOnline: "You have chosen Årsredovisning Online",
    YouChooseManualClosure: "You have chosen Manual closure",
    YourBalanceAssistance: "If your accounts do not match your accounting see {help section} for further assistance.",
    YourFiles: "Your documents",
    YourFilesBeenEmailed: "Your documents have been sent to your email",
    YourFilesReady_heading: "Your documents are ready",
    YourResults: "Your results",
    YourYearlyTax: "Your yearly tax",
  };
}