/* eslint-disable */

import type { SignUpLang } from "../types/SignUpLang";
import { translateTempImpl } from "../TranslatableLanguage";

export function SignUpLangDef(): SignUpLang {
  return {
    translateTemp: (key: string, neutral: string, svSE?: string, enGB?: string) => translateTempImpl(key, neutral, svSE, enGB),
    
    AccountantSignUp_ThisAccountExistsAlready: "This account already exists",
    AccountantSignUp_UserExists_Preamble: "You already have an account with Bokio. Do you want to sign in or do you want to create your practice with an existing account?",
    AddYourCompanyToBokio: "Add your company to Bokio",
    ApproveTerms: "Approve terms",
    CashMethod: "Cash method",
    ChoosePasswordScreen_Preamble: "Now choose a strong password for your new account:",
    ChoosePasswordScreen_Title: "Thank you {0}, your email has been verified!",
    CompanyNameOrOrgNumber: "Company name or registration number",
    ConfirmEmailScreen_ActivationLinkText: "Activate account",
    ConfirmEmailScreen_CheckYourMail: "Check your mail!",
    ConfirmEmailScreen_CheckYourMailInstructions: "We've sent an email to {0}. Open it and click on the link {1} to confirm your email address and continue.",
    ConfirmEmailScreen_HaventGotConfirmationMail: "Haven't received the mail?",
    ConfirmEmailScreen_OrEnterTheFourDigitCodeInTheSameMail: "Or: Enter the four digit code from the same email:",
    ConfirmEmailScreen_SendAgainOrContactSupport: "{Send again}",
    CreateCompany_TestCompany_InlineLabel: "(Test company)",
    CustomizeBokioForYourCompany: "Customise Bokio for your business",
    CustomizeCompanySettingsScreen_NeedHelpAnswer: "Need help answering this?",
    CustomizeCompanySettingsScreen_NeedHelpAnswer_Link2: "https://www.bokio.co.uk/help/accounting/fiscal-years/how-do-fiscal-years-work/",
    CustomizeCompanySettingsScreen_NotSureWhichBookkeepingMethod: "Not sure which accounting method you have?",
    CustomizeCompanySettingsScreen_NotSureWhichBookkeepingMethod_Link2: "https://www.bokio.co.uk/help/getting-started/bookkeeping/which-accounting-method-should-i-use/",
    CustomizeCompanySettingsScreen_NotSureWhichVATPeriod: "Not sure if you are registered for VAT?",
    CustomizeCompanySettingsScreen_NotSureWhichVATPeriod_Link2: "https://www.gov.uk/vat-registration/",
    CustomizeCompanySettingsScreen_Preamble: "You can always change this later in the settings.",
    CustomizeCompanySettingsScreen_PreambleLink: "https://www.verksamt.se/minasidor/-/minasidor/foretag/{0}",
    CustomizeCompanySettingsScreen_SelectBookkeepingMethod: "Select bookkeeping method",
    CustomizeCompanySettingsScreen_SelectVatPeriod: "Select VAT period",
    Error_EmailAlreadyConfirmed: "The email address has already been confirmed.",
    Error_IncorrectConfirmationCode: "We could not use the code. Please verify that the code is correct and that it hasn't been used.",
    Error_PasswordAlreadySet: "The password has already been set",
    Error_UserMustBeLoggedIn: "You must be logged in",
    ErrorPage_ClickHereToRestart_Action: "Click here to retry",
    ErrorPage_PageTitle: "Ooops!",
    ErrorPage_Preamble: "We are sorry, something unexpected has happened. The event has been logged and we have started working on a solution. In the meantime:",
    FakeCompanyName: "Testföretaget",
    FiscalYearValidation_InTheFuture_Warning: "You can't select a fiscal year in the future",
    FiscalYearValidation_InThePast_Warning: "You can't select a fiscal year in the past",
    FiscalYearValidation_NotSet: "Fiscal year is not set",
    ForgotPassword_Preamble: "No worries, that happens to everyone sometimes. Type your email to get a reset link.",
    ForgotPassword_SendLink: "Send the link",
    ForgotPassword_Title: "Forgot password?",
    GovernmentCompanyInfoUrl: "https://www.gov.uk/government/organisations/companies-house",
    HasInvitedYouToBokio: "{inviter} has invited you to Bokio. Activate your account by filling in name and password.",
    HowOftenDoYouReportVAT: "How often do you report VAT?",
    Idle_LoginAgainText: "You have been logged out, please login again.",
    ImportCompanyFromSEPage_HelpText: "Import SE file(s) with your bookkeeping. {Click here to read more about importing SE files}",
    ImportCompanyFromSEPage_ReconsiliationAccruals_CheckBoxText: "I have completed the entire bookkeeping incl. reconciliations, accurals and depreciation.",
    ImportCompanyFromSEPage_ReconsiliationAccruals_HelpLinkText: "{Do you need help to answer?}",
    ImportCompanyFromSEPage_Title: "Import your previous bookkeeping",
    InitialFiscalYearInput_CompanyHasMadeAtLeastOneFinancialStatement: "Another fiscal year",
    InitialFiscalYearInput_CompanyIsNewlyFounded: "First fiscal year",
    InitialFiscalYearInput_Label: "Which fiscal year are you doing the company's bookkeeping for?",
    InvoiceMethod: "Invoice method",
    Login_BankIdButton: "Continue with BankID",
    Login_BankIdDescription: "Please log in with BankID to continue",
    Login_BankIdLoginAlreadyConfigured: "BankID is already configured",
    Login_BankIDSessionAlreadyInProgress: "A BankID session is already in progress",
    Login_BankIDUserAborted: "Login aborted",
    Login_ConnectAgency: "Connect existing account to our Practice manager",
    Login_EmailDescription: "Login to Bokio by entering your email address",
    Login_EmailDescriptionAgency: "Log in to start using Bokio's Practice manager",
    Login_LoginHeading: "Log in to your account",
    Login_LoginWithBankId: "Login with BankID",
    Login_MirLoginNotConfigured: "BankID not configured",
    Login_NotAValidPersonalNumber: "Personal number is not valid",
    Login_NotHaveAnAccount: "Don't have an account? {Sign up}",
    Login_PasswordDescription: "Please enter your password to continue",
    Login_PersonalNumberNotAttachedToAccount: "Personal number doesn't match account",
    Login_Refresh_Title: "Refresh your login",
    Login_Text: "Login with email and password.",
    Login_Title: "Welcome!",
    Login_TOTP_Text: "Complete your login by entering a verification code.",
    Login_UnableToAuthenticateWithBankID: "Unable to login with BankID. Please check the personal number and try again in a short while.\nContact support if the problem doesn't go away.",
    Monthly: "Monthly",
    NavigateWarning: "Are you sure you want to leave? You may not be able to continue your sign up.",
    NoCompaniesForUserReason: "To get started using Bokio you must first create a company to manage - a real one or a fictive company for testing purposes.",
    OneTimePerYear: "One time per year",
    PasswordField_Placeholder: "At least {0} characters",
    PasswordValidation_Accepted: "Accepted",
    PasswordValidation_NotSafe_Warning: "This password was found in a data leak from another website. To keep your account safe we suggest you pick another password.",
    PasswordValidation_Perfect: "Perfect!",
    PasswordValidation_TooShort: "Too short",
    PasswordValidation_Weak: "Weak!",
    PickCompanyPage_Experiment_Continue: "Fetch company details",
    PickCompanyPage_Experiment_DontHaveAnyCompany: "Don't have a business yet? Create a test company",
    PickCompanyPage_Experiment_FakeDetailsTitle: "Choose test company type",
    PickCompanyPage_Experiment_FakeDetailsTitlePreamle: "We will prefill the rest of the details so you can test out Bokio faster. You can later adjust these under Settings.",
    PickCompanyPage_Experiment_FindCompany: "Find company",
    PickCompanyPage_Experiment_ManySearchResultsTips: "- Your company has recently been established, and the information has not yet been updated in the Companies House database.\n- The company name might be misspelled and/or does not correspond to the Companies House register. Try searching using your organization number instead.",
    PickCompanyPage_Experiment_SearchForOrgNumberOrName_Label: "Search for your company",
    PickCompanyPage_Experiment_SignUpManual_Link: "continue without fetching details",
    PickCompanyPage_Experiment_SignUpSearchTips_Heading: "Couldn't find your company? No worries, you can also ",
    PickCompanyPage_Experiment_TipsCouldntFindYourCompany_Heading: "Couldn't find your company? This could be because:",
    PickCompanyPage_Experiment_ZeroOrManyTriesSearchResultsTips: "- Your company has recently been established, and the information has not yet been updated in the Companies House database.\n- The company name might be misspelled and/or does not correspond to the Companies House register. Try searching using your organization number instead.",
    PickCompanyPage_Experiment_ZeroSearchResultsFillInManually: "Enter your company details manually",
    PickCompanyPage_Experiment_ZeroSearchResultsTips_Heading: "This could be because:",
    PickCompanyPage_SearchForOrgNumberOrName_Label: "Company name or company number",
    PickCompanyPage_SearchForOrgNumberOrName_Placeholder: "XXXXXXXX",
    PickCompanyStep_AddCompany: "Add your company",
    PickCompanyStep_Epilogue_Part_One: "Don't have a business yet? Use ",
    PickCompanyStep_Epilogue_Part_Three_Link: "register a company with verksamt.se",
    PickCompanyStep_Epilogue_Part_Two_Link: "Bokio's service for starting a limited company",
    PickCompanyStep_NoCompanyListed: "If you cannot find your company please provide the details below to proceed.",
    PickCompanyStep_OrganisationIdentifier: "Organisation number or company name",
    PickCompanyStep_WeWillFetch: "We will import your company details from Companies House.",
    PickType_Agency_CTA: "Create a client company",
    PickType_AgencyCompanyDescription: "Creates the company for a client. You will get to invite the customer in the last step.",
    PickType_AgencyCompanyHeader: "Add a client",
    PickType_Type_Real_Description: "This will set up your company in Bokio. You can later invite any partners and employees to this company.",
    PickType_Type_Real_Title: "I want to set up my real company in Bokio",
    PickType_Type_Test_Description: "This creates a test company where you can try out features without it affecting any real taxes etc. Once you feel comfortable, you can create your real company on the same login.",
    PickType_Type_Test_Title: "I want to try out Bokio first",
    PickTypePage_PageTitle: "What do you want to do in Bokio?",
    PreStep_ChooseAgency_Description: "Your new client company with be connected to this agency",
    PreStep_ChooseAgency_Placeholder: "Choose agency",
    PreStep_ChooseAgency_Title: "Which of your agencies is this for?",
    PreStep_ChooseCompany_Title: "Which company would you like to create?",
    PreStep_CreateClient_Multiple_Description: "Create a client company and manage your client’s accounting as a part of your agency in Bokio.",
    PreStep_CreateClient_SingleAgency_Description: "Create a client company and manage your client’s accounting as a part of {0} in Bokio.",
    PreStep_CreateClient_Title: "Client company",
    PreStep_CreateRegularCompany_Description: "Create your own company and use it to run your business in Bokio.",
    PreStep_CreateRegularCompany_Title: "My own company",
    Quarterly: "Quarterly",
    ResendConfirmationEmailScreen_Action: "Send new mail!",
    ResendConfirmationEmailScreen_Title: "Verify your email",
    ResetPassword_ClickHereToResendEmail: "Send a new link",
    ResetPassword_Error_BrokenLink: "Broken password reset link: missing reset code",
    ResetPassword_Preamble: "Choose a new password to get back in business!",
    ResetPassword_Preamble_TwoFactor: "Almost there!",
    ResetPassword_TheLinkYouClickedHasExpired: "The link that you clicked has expired.",
    ResetPassword_Title: "Reset password",
    SearchForYourCompany: "Enter your business/trading name",
    SessionTimedOut_ClickHereToLogin_Action: "Click here to log in",
    SessionTimedOut_Notice: "Ooops! It seems as you have been logged out.",
    SessionTimedOut_PageTitle: "Ooops!",
    SessionTimedOut_Preamble: "It seems as you have been logged out.",
    SetCompanyScreen_Text: "If your business is a LTD or LLP you can fetch most of your company's information from Companies House",
    Signup_Preamble: "No commitments.",
    Signup_Title: "Create an account and get started with the easiest Bookkeeping software",
    SignUpAccountant_StartStep_LogInToExistingAccount: "If you want to create a practice manager in Bokio using your existing account, {click here}.",
    SignUpAccountant_StartStep_Title: "Create a free account as an accountant",
    SignupRejectedLimitedBeta: "We're very sorry, but Bokio is currently not generally available in your region and your email has not been registered for the early access programme.\nPlease visit [our web site](https://www.bokio.co.uk/request-invite?email={0}) to request an invitation.",
    StartDate: "Start date",
    StartScreen_AlreadyHaveAnAccount: "Already have an account? {Log in}",
    StartScreen_ApproveTerms_Label: "I accept the {general terms and conditions} and {privacy policy}",
    StartScreen_Text: "Sign up today, no commitments.",
    StartScreen_Text_Agency: "No commitments.",
    StartScreen_Title: "Create a free account in Bokio",
    Step_ChoosePassword: "Choose password",
    Step_Confirm: "Confirm e-mail",
    Step_SetCompany: "Create company",
    Step_Start: "Create account",
    StepNotice_ChoosePassword: "Password is already set for {email}. Go to {step}",
    StepNotice_Confirm: "{email} is already verified. Go to {step}",
    StepNotice_Start: "You are probably logged out. Go to {step}",
    Todo_CompanyOnboarding_BeforeYouStart: "Before you start:",
    Todo_CompanyOnboarding_CheckVatPeriod: "Double check your VAT period, bookkeeping methods and fiscal year",
    Todo_CompanyOnboarding_CreateNewInvoice: "Create new invoice",
    Todo_CompanyOnboarding_FirstBill: "Upload your first bill",
    Todo_CompanyOnboarding_FirstEmployee: "Add yourself or someone else as your first employee",
    Todo_CompanyOnboarding_FirstInvoice: "Send your first invoice",
    Todo_CompanyOnboarding_FirstPayroll: "Do your first payroll",
    Todo_CompanyOnboarding_FirstTransaction: "Record your first transaction",
    Todo_CompanyOnboarding_GoToEmployees: "Go to employees",
    Todo_CompanyOnboarding_GoToFiscalSettings: "Go to fiscal year settings",
    Todo_CompanyOnboarding_GoToImportBankTransaction: "Go to import bank transaction",
    Todo_CompanyOnboarding_GoToImportBookkeeping: "Go to import bookkeeping",
    Todo_CompanyOnboarding_GoToIncomingBalances: "Go to incoming balances",
    Todo_CompanyOnboarding_GoToLayoutAndLogo: "Go to layout and logo",
    Todo_CompanyOnboarding_GoToStaffAndSalaries: "Go to staff and salaries",
    Todo_CompanyOnboarding_Guide: "Guide:",
    Todo_CompanyOnboarding_GuideBookkeepingLink: "https://www.bokio.co.uk/help/accounting/bookkeeping-in-bokio/how-to-record-transactions-in-bokio/",
    Todo_CompanyOnboarding_GuideSalariesLink: "https://www.bokio.se/hjalp/lon/skapa-lon/hur-fungerar-bokio-lon/",
    Todo_CompanyOnboarding_GuideSalesLink: "https://www.bokio.co.uk/help/invoices/create-invoice/how-do-i-create-an-invoice/",
    Todo_CompanyOnboarding_GuideSupplierInvoicesLink: "https://www.bokio.co.uk/help/suppliers-and-purchases/supplier-invoices/how-do-supplier-invoices-work/",
    Todo_CompanyOnboarding_ImportBookeeping: "Import your previous bookkeeping",
    Todo_CompanyOnboarding_ImportTransactions: "Import your transactions from your old bank",
    Todo_CompanyOnboarding_LearnAccounting: "Learn about how to do your accounting easy with Bokio",
    Todo_CompanyOnboarding_LearnSalaries: "Learn about how to work with salaries in Bokio (in Swedish)",
    Todo_CompanyOnboarding_LearnSales: "Learn about how to work with sales in Bokio",
    Todo_CompanyOnboarding_LearnSuppliers: "Learn about how to work with suppliers and purchases in Bokio",
    Todo_CompanyOnboarding_LogoAndInvoiceLayout: "Upload your logo and set your invoice layout",
    Todo_CompanyOnboarding_MatchIncomingBalances: "Make sure incoming balances match your previous bookkeeping",
    Todo_CompanyOnboarding_OpenGuide: "Open guide",
    Todo_CompanyOnboarding_UploadReceipt: "Upload receipt",
    Todo_CompanyOnboarding_UploadSupplierInvoice: "Upload supplier invoice",
    UserExists_CreateAgencyButton_Action: "Create practice",
    UserExists_CreateCompanyButton_Action: "No, I want to create a new company",
    UserExists_LoginButton_Action: "Yes, I tried to log in",
    UserExists_PageTitle: "Did you intend to log in?",
    UserExists_Preamble: "We discovered that you already have a Bokio account,{&lt;br /&gt;}is that what you tried to reach?",
    Verify_LoginAgainText: "We want to verify that it's really you.",
    VerifyAccountScene_Accountant_Preamble: "We just need to confirm that you are the owner before creating a new agency.",
    VerifyAccountScreen_Action: "Log in and create a new company",
    VerifyAccountScreen_Preamble: "We just need to confirm that you are the owner before creating a new company.",
    VerifyAccountScreen_Title: "Alright, no problem!",
    VerifyCompany_Preamble: "You can always change this later in your settings.",
    VerifyCompany_Title: "Nice, this is what we found",
    VerifyCompanyPleaseProvideMoreInformation_Title: "Enter your company details",
    WelcomeTo: "Welcome to",
    WhatAccountingMethodAndVatSchemeDoYouUse: "What accounting method and VAT scheme does the company use?",
    WhatAccountingMethodDoYouUse: "What accounting method does the company use?",
    WhatVatSchemeDoYouUse: "What VAT scheme does the company use?",
    WhenDoesYourFiscalYearEnd: "When does the company's fiscal year end?",
  };
}