/* eslint-disable */

import type { BackOfficeLang } from "../types/BackOfficeLang";
import { translateTempImpl } from "../TranslatableLanguage";

export function BackOfficeLangDef(): BackOfficeLang {
  return {
    translateTemp: (key: string, neutral: string, svSE?: string, enGB?: string) => translateTempImpl(key, neutral, svSE, enGB),
    
    Accountant: "Accountant",
    AccountantPanel_EmptyStateDesc_Comment: "Comments where you have been mentioned or you have created for this client will be shown here.",
    AccountantPanel_EmptyStateDesc_Task: "Pending tasks which have been assigned to you for this client will be shown here.",
    AccountantPanel_EmptyStateTitle_Comment: "You have no comments yet",
    AccountantPanel_EmptyStateTitle_Task: "You have no tasks yet",
    AccountantPanel_MyTasks_Dropdown: "My tasks",
    AccountantPanel_Notes_CreatedBy: "{0} by {1}",
    AccountantPanel_OpenInBokioBackoffice: "Open in Bokio Practice Manager",
    AccountantPanelHeader: "Open Bokio Practice Manager to manage tasks and comments. The information shown in this side panel is only visible for you and not for your client.",
    Accountants: "Accountants",
    ActiveQuotesRequests_EmptyState_Description: "When you get quote request from a company in Bokio, you will be able to see them here.",
    ActiveQuotesRequests_EmptyState_Title: "You don’t have any active requests",
    AddCompany: "Add client",
    AddEditNote_AssignedTask_Notice: "You will be able to track if the assignee has read the task or changed the task’s status.",
    AddEditNote_AssignedTo_Label: "Assigned to",
    AddEditNote_DueDateCheckbox_Label: "Due date not required",
    AddEditNote_ModalDescription_Add: "This will create a new {0} for your client **{1}**",
    AddEditNote_ModalDescription_Add_QuoteRequest: "This will create a new {0} for the quote request from **{1}**",
    AddEditNote_ModalDescription_Edit: "This will edit the existing {0} for your client **{1}**",
    AddEditNote_ModalDescription_Edit_QuoteRequest: "This will edit the existing {0} for the quote request from **{1}**",
    AddEditNote_ModalFooter_Create: "Create {0}",
    AddEditNote_ModalFooter_CreateAndSend: "Create and send {0}",
    AddEditNote_ModalTitle_Add: "Add {0}",
    AddEditNote_ModalTitle_CreateNew: "Create new {0}",
    AddEditNote_ModalTitle_Edit: "Edit {0}",
    AddEditNoteModal_PlaceHolder: "Add a comment. Use @ to mention.",
    AddEditNotes_AssigneeOption_Text: "{0} (Myself)",
    AddMe: "Add me",
    AddNewButton: "Add new",
    AddPartner: "Add new practice",
    AddToClients: "Add to practice",
    AddUser: "Add user",
    AddUserByEmail_Placeholder: "Type email address here",
    Agency: "Practice",
    Agency_ExportNotes: "Export notes",
    Agency_ID: "ID",
    AgencyClientNotes_Filter_AccountantAndClient: "Accountant and Client",
    AgencyClientNotesTable_LatestUpdate: "Latest update",
    AgencyClientNotesTable_ReadAt: "Read at {0}",
    AgencyClientOverview_ReadFullMessage: "Read full message",
    AgencyClientQuote_ConfirmRegistration: "Confirm registration",
    AgencyClientQuote_ConfirmRejection: "Confirm reject request",
    AgencyClientQuote_NoticeHeading: "Some content and functionality is unavailable",
    AgencyClientQuote_NoticeText: "To access to all client details and functionality, you must be assigned to the client under *assigned accountants* below.",
    AgencyClientQuote_RegisterClient: "Accept request",
    AgencyClientQuote_RegisterModalText: "This will register **{0}** as an active client in your agency. Assigned accountants will have access to manage the company’s accounting.",
    AgencyClientQuote_RejectModal_Tip: "You can view archived requests by filtering on *‘show archived clients’* on the **Quote requests** page.",
    AgencyClientQuote_RejectModalText: "This will reject and archive the quote request received from **{0}**.",
    AgencyClients_EditClientCompanyInvitation: "Edit client company invitation",
    AgencyClients_ImportClient_Banner: "You have access to one company not connected to this practice.",
    AgencyClients_ImportClients_Banner: "You have access to {0} companies not connected to this practice.",
    AgencyClients_ManageClient: "Manage client",
    AgencyClients_RemoveDraft_Description: "Are you sure you want to remove the client company and the company owner invitation?",
    AgencyClients_RemoveDraft_Disclaimer: "This will cancel the company owner invitation sent to {0} and delete the client **{1}**. If you want to change the company owner email or resend the invitation, you can edit the invitation.",
    AgencyClients_RemoveDraft_Title: "Remove client company and invitation",
    AgencyClientsComments_EmptyState_Message: "When you add comments to your client they will appear here",
    AgencyClientsNotes_Filter_MyNotes: "Only show my notes",
    AgencyClientsNotes_Filter_OnlyAssignedToMe: "Assigned to me",
    AgencyClientsNotes_Filter_OnlyCreatedByMe: "Created by me",
    AgencyClientsTasks_EmptyState_Message: "When you add tasks to your client they will appear here",
    AgencyExportNotes_EmptyState: "You don't have any active clients now.",
    AgencyExportNotes_TooltipInfo: "Only accountants assigned to a particular client can export notes.",
    AgencyImportClients_EmptyState: "There are no companies to connect right now.",
    AgencyImportClients_Error_AccountantOwnsCompany: "Accountants in your practice can't be the owner of any client companies.",
    AgencyImportClients_Error_ConnectedToAnotherAgency: "At least one company is already connected to another practice.",
    AgencyImportClients_Error_NotEnoughPermissions: "You lack the permission to manage users in at least one client company.",
    AgencyImportClients_Errors_ManyCompanies_Title: "{0} companies cannot be added as clients because of the following reasons:",
    AgencyImportClients_Errors_OneCompany_Title: "One company cannot be added as a client because of the following reason:",
    AgencyImportClients_Tooltip_AccountantOwnsCompany: "You cannot connect this company to your practice because an accountant from your practice owns it.",
    AgencyImportClients_Tooltip_ConnectedToAnotherAgency: "This company is already connected to another practice.",
    AgencyImportClients_Tooltip_NotEnoughPermissions: "You lack the permission to manage users in this company.",
    AgencyNotes_Summary: "Showing {0} of {1}",
    AgencyNotes_ViewAll: "View all",
    AgencyOverview_ViewAllTasks: "View all tasks",
    AgencyPartner_AfterSignUp_CongratulationsMsg: "Congratulations, you are now an Agency Partner! Depending on how many clients you have in Bokio, your agency ends up at one of the three levels: Bronze, Silver or Gold. The higher level you are at, the greater benefits you get as an Agency partner.",
    AgencyPartner_AfterSignup_ExistingCustomers_Heading: "Existing customers in Bokio",
    AgencyPartner_AfterSignUp_ExistingCustomers_Text: "There are a few different scenarios where you can have customers linked to your user in Bokio who are not yet added to the agency environment. How to add your customers to your account. {0}!",
    AgencyPartner_AfterSignUp_Heading: "Grow together with Bokio",
    AgencyPartner_AfterSignup_NewCustomers_Heading: "New customers to Bokio",
    AgencyPartner_AfterSignUp_NewCustomers_Text: "As an Agency Partner, you receive a commission for new customers in Bokio. Therefore, it is important that they are registered via your agency account and 'Create customer'. {0}!",
    AgencyPartner_AfterSignUp_TierInfo: "You are now in the {0}",
    AgencyPartner_AuthorityCheck_Accept: "I hereby confirm that I have the authority to approve the Bokio Agency Partner contact on behalf of {0}",
    AgencyPartner_ConfirmAndSign_Button: "Confirm and sign",
    AgencyPartner_NameOfSignatory: "Name of signatory",
    AgencyPartner_SupportBox_Text: "Would you like to get in touch with our team to know more about Bokio Agency Partner? Contact us at {0}",
    AgencyPartner_TermsAndCondition_Accept: "I have read and accept the {0} for Bokio Agency Partner",
    AgencyPartnerTier_Bronze: "Bronze",
    AgencyPartnerTier_Gold: "Gold",
    AgencyPartnerTier_Heading: "Agency Partner tier",
    AgencyPartnerTier_Silver: "Silver",
    AgencyQuoteRequest_KeepNotes: "Keep the tasks and comments for this company.",
    AgencyQuoteRequestDetails_HasBBA_Label: "Has Bokio Business Account",
    AgencySettings_AgencyInformation_Description: "Manage your practice details such as name, contact information and business details",
    AgencySettings_AgencyInformation_Title: "Practice information",
    AgencySettings_EditAgencyInformation_Title: "Edit practice information",
    AgencySettings_ImportClients_Description: "Connect client companies that you have access to in Bokio to Practice Manager",
    AgencySettings_ImportClients_Title: "Connect companies to Practice Manager",
    AgencySettings_Users_NoPermission: "You don't have permission to view this page. Please contact the administrator of this agency.",
    AgencyUsers_EditPermissions: "Edit permissions",
    AgencyUsers_ManageAccountant: "Manage accountant",
    AgencyUsers_RemoveAccountant: "Remove accountant",
    AllAccountants: "All accountants",
    AllAssignees: "All assignees",
    AllClients: "All clients",
    AllCompanies: "All clients",
    AllPricePlans: "All plans",
    AllStatuses: "All statuses",
    ApplicantEmail: "Applicant email",
    Apply: "Apply",
    ArchivedQuotesRequests_EmptyState_Description: "When you reject a quote request from a company, you will be able to see them here.",
    ArchivedQuotesRequests_EmptyState_Title: "You don’t have any archived requests",
    AssignedSupportTickets_AssignedTo_Label: "Assigned to",
    AssignedSupportTickets_Checkbox: "Show only unread",
    Assignee: "Assignee",
    AssignToMe_CheckBox: "Assign to me",
    BackOffice_Name: "Back office",
    Bank: "Bank",
    BokioAgency: "Practice Manager",
    BokioBusinessAccount: "Bokio Business Account",
    BokioForAccountants: "Bokio for accountants",
    BookkeepingSupport_Label_ShowingReceipts: "Showing: {0}/{1}",
    CancelInvitation: "Cancel invitation",
    CancelInvitationModal_Content: "Are you sure you want to cancel the invite for {0}?",
    CancelInviteModal_Title: "Cancel invite for {0}",
    ChangeClientPricePlan_Link: "Change price plan",
    ChooseClientCompany: "Choose client company",
    ChoosePractice: "Choose practice",
    Client: "Client",
    ClientDetails_AccountantPermissions_Info: "*Accountant permissions are subject to modification by the company owner. Please reach out to the owner if any permissions are missing.",
    ClientDetails_CannotAddAccountants: "You don’t have permissions to add accountants to this client. Contact the practice admin to gain appropriate permissions or to be assigned to this client.",
    ClientDetails_Select_ChooseAccountant: "Choose accountant",
    ClientDetails_Title_UserPermission: "No accountants assigned to client",
    ClientOwnership_AgencyResponsibilities_Header: "Practice responsibilities",
    ClientOwnership_AgencyResponsibilities_Paragraph: "Select what type of responsibilities you have for this client:",
    ClientOwnership_AgencyResponsibilities_Select: "Select areas of responsibility",
    ClientOwnership_InviteClient_Paragraph: "Please enter the email and user permissions of the legal owner of **{0}**",
    ClientOwnership_InviteClient_Title: "Invite client and assign ownership",
    ClientOwnership_Paragraph: "Please enter the details of the person who owns the company you are creating.",
    ClientOwnership_Title: "Client and practice setup",
    ClientQuotes_Scene_PageTitle: "Client quote requests",
    ClientRequests_AssignedAccountant: "Assigned accountant",
    ClientRequests_DeclineInvitation_Content: "Are you sure you want to decline this request?",
    ClientRequests_DeclineInvitation_Title: "Decline request",
    ClientRequests_Description: "The companies below have sent a request to one of your accountants to be connected to your practice. If the request is accepted, the accountant will be added to the company and the practice will have access to it.",
    ClientRequests_EmptyState: "When you get client requests from companies in Bokio, you will be able to see them here. Right now, there are no requests for your practice.",
    Clients: "Clients",
    Clients_ActiveClients: "Active clients",
    Clients_Checkbox: "Only show clients assigned to me",
    Clients_CreateClient_CTA: "Create client",
    Clients_EmptyState: "There is nothing to show yet. Once you have created a client they will appear here.",
    Clients_PendingClients: "Client invitations",
    Clients_Tooltip_PendingClients: "Once a company owner accepts the invitation, assigned accountants will be able to access the company.",
    ClientSince: "Client since",
    ClientSince_BeforeSept23: "Before Sep 23",
    Closure: "Closure",
    Comment: "Comment",
    Comment_TextField_Label: "Describe the comment",
    Comments: "Comments",
    Comments_AssignedAccountantsCanView_Notice: "Only accountants assigned to this client can view or add comments",
    Comments_AssignedAccountantsCanView_QuoteRequest_Notice: "Only accountants assigned to this quote request can view or add comments",
    CompaniesInQueue_EmptyState_Description: "When you have new clients it will start showing up here.",
    CompaniesInQueue_EmptyState_Title: "No client to show",
    CompanyColumn_AssignedConsultant_Title: "Accountant",
    CompanyColumn_CompanyName_Title: "Client name",
    CompanyColumn_CompanyType_Title: "Type",
    CompanyColumn_LastCompletedMonth_Title: "Last completed month",
    CompanyColumn_NextVatOn_Title: "Next VAT on",
    CompanyColumn_Status_Title: "Status",
    CompanyColumn_TodoItems_Title: "Todo items",
    CompanyDetails_ApplicationDetails_Heading: "Application details",
    CompanyDetails_Title: "Client details",
    CompanyInQueue_OnboardModal_Title: "Onboard - {0}",
    CompanyInQueue_OnboardNow: "Onboard now",
    Consulting: "Consulting",
    ContactUsOption_Description: "Get in touch with our team for expert guidance on how to use Bokio to its full potential",
    ContactUsOption_Title: "Learn how to use Bokio",
    ContractAgreements: "Contract agreements",
    ContractSigned: "Contract signed",
    Conversations: "Conversations",
    Conversations_LastActivity: "Last activity",
    Conversations_ViewAll: "View all conversations",
    Create_WhatCompany: "What company would you like to create?",
    CreateAgency_Create_AgencyName: "Practice name",
    CreateAgency_Create_Preamble: "Enter your practice information to complete your account setup",
    CreateAgency_Create_Title: "Practice business details",
    CreateAgency_Employee_Preamble: "To continue to Practice Manager you need to first ask your administrator to invite you to your practice.",
    CreateAgency_Employee_Title: "Before you can continue",
    CreateAgency_UserType_AdminDescription: "Create and manage your practice. Invite employees and add clients you work with.",
    CreateAgency_UserType_AdminTitle: "Create practice",
    CreateAgency_UserType_EmployeeDescription: "You need an invitation to a practice to start using the service.",
    CreateAgency_UserType_EmployeeTitle: "Get invite to a practice",
    CreateAgency_UserType_Preamble: "Choose if you want to create a practice or if you are waiting for an invitation to one that already exists",
    CreateAgency_UserType_Title: "How do you want to continue?",
    CreateAgencyPreStep_AnotherAgency: "Create another agency",
    CreateAgencyPreStep_Description: "Use your existing agency to manage all your clients in the most efficient way to be able to enjoy the benefits of being an Agency Partner.",
    CreateAgencyPreStep_Title: "You already have an agency",
    CreateAgencyPreStep_ViewClients: "View all your clients",
    CreateClient_SearchClientCompany: "Search client company",
    CreateClient_WeWillImport: "We will import the company details from Companies House.",
    CreateClientRating_Feedback_Description: "Any thoughts that could help us improve your experience with creating a new client company?",
    CreateClientRating_Rate_Title: "How was your experience creating a new client company?",
    CreateNewClientCompanyOption_Context: "Create a new client company and start managing accounts and collaborating in Bokio",
    CreateNewClientCompanyOption_Title: "Create a new client company",
    CreateNoteRating_Feedback_Description: "Any thoughts that could help us improve your experience with adding notes?",
    CreateNoteRating_Rate_Title: "How was your experience adding a note to your client?",
    CreateTestClient_CTA: "Create test client",
    CreateTestClientCompanyOption_Description: "Jump straight into a test client company and explore the full suite of Bokio accounting features",
    CreateTestClientCompanyOption_Title: "Get started with a test client",
    CreationDate: "Creation date",
    CustomClientColumnsRating__Feedback_Description: "Any thoughts that could help us improve your experience with customising your client view?",
    CustomClientColumnsRating_Rate_Title: "How was your experience with customising your client view?",
    CustomerEmailAddress: "Customer email address",
    CustomerEmailExample: "customer@mail.com",
    CustomizeBokioForYourClient: "Customise Bokio for your client",
    DateAndPrice: "Date and price",
    DeleteAgency: "Delete agency",
    DeleteAgency_IAgreeToLetBokioDelete: "I agree to let Bokio delete the agency {0} ({1}).",
    DeleteAgency_IAgreeToLetBokioDelete_NoOrgNumber: "I agree to let Bokio delete the agency {0}.",
    DeleteAgency_IUnderstandLossOfData: "I understand that I will not be able to access the agency {0} ({1}) and all associated client companies in Bokio.",
    DeleteAgency_IUnderstandLossOfData_NoOrgNumber: "I understand that I will not be able to access the agency {0} and all associated client companies in Bokio.",
    DeleteAgency_Restriction_MoreClientsConnected: "Remove all the active clients in the agency",
    DeleteAgency_Restriction_MoreUsersConnected: "Remove all the accountants in the agency",
    DeleteAgency_Restriction_PendingClientRequest: "Decline any client request for this agency",
    DeleteAgency_Restriction_PendingQuoteRequest: "Decline any quote request for this agency",
    DeleteAgency_Restrictions_Heading: "To delete the agency, you need to do the following:",
    DeleteAgency_Section1_Heading: "What does it mean to delete this agency in Bokio?",
    DeleteAgency_Section1_Paragraph1: "When you delete the agency **{0}**, no one will be able to access it again.\n\nAll connected client companies with be disconnected from the agency and all assigned accountants will lose access to client companies.\n\nYour personal account **{1}** will not be deleted, and you can still access other agencies or companies not directly connected to **{0}** in Bokio.",
    DeleteAgency_Section1_Paragraph2: "We strongly recommend you to export notes for all your clients. You will not be able to access it again when you delete this agency.",
    DeleteAgency_Section2_Heading: "What is the reason for you to delete your agency?",
    DeleteAgency_Section2_Paragraph2: "We are sorry to see you go and would love to understand why you are deleting your agency so we can create an even better service for the future. Please provide us with some feedback.",
    DeleteAgency_Warning: "Once you have deleted your agency no one will be able to access it again!",
    DisconnectAgency_AdditionalFeedback_Label: "Feedback to Bokio",
    DisconnectAgency_AdditionalFeedback_PlaceHolder: "Please take a moment to give us feedback on how we can improve.",
    DisconnectAgency_AgencyFeedBack_AgencyClosed: "The agency has now been closed",
    DisconnectAgency_AgencyFeedBack_DuplicateAgency: "This is a duplicate agency",
    DisconnectAgency_AgencyFeedBack_NoLongerActiveInAgency: "I am no longer active in the agency",
    DisconnectAgency_AgencyFeedBack_Other: "Other reason",
    DisconnectAgency_BokioIsMissingFunctionality_FeedbackOption: "Bokio is missing functionality that my agency needs",
    EditClientCompanyInvitationModal_Description: "A new invitation will be sent to the company owner of **{0}** at the following email. The previous invitation will be cancelled.",
    EditClientCompanyInvitationModal_ResendInvitation: "Resend invitation",
    EditInvitation: "Edit invitation",
    EditUserModalTitle: "Edit permissions for {0}",
    EmailAddress: "Email address",
    EndDate: "End date",
    ExternalCommunication_Warning: "External communication. Remember to only include information that is intended for your client.",
    FeedBack_Dashboard_Header: "New dashboard for Bokio Practice Manager",
    FeedBack_Dashboard_Text: "We value your feedback! Our new dashboard is now here, and we need your input to enhance the overall experience! Share your thoughts with us today and contribute to making it even better!",
    FeedbackModal_Dashboard_Description: "Help us improve the dashboard by sharing your thoughts!",
    FeedbackModal_Dashboard_InputPrompt: "What would you like to see in your dashboard?",
    Filter_ShowCompleted: "Show completed",
    Filter_ShowOnlyByActive: "Show only by active",
    Filter_UnreadConversations: "Unread conversations",
    FilteredSupportTickets_IncludePreviousStatus_Checkbox: "Include previous status",
    FiltredSupportTicket_MarkForBilling_Button: "Mark for billing",
    FiscalYearEnds: "Fiscal year end date",
    FormFilledIn: "Form filled in",
    HelpWithK10: "Help with K10",
    Here: "here.",
    Hmrc_Backoffice_EmptyState_Text: "There are no VAT returns to show or the client haven’t connected to HMRC yet. You can update the VAT list at any time by going to the VAT period listing inside Bokio.",
    IncludeDeletedAgencies: "Include deleted agencies",
    InviteAccountant: "Invite accountant",
    InviteAlreadyUsed: "This invitation has already been used!",
    InviteAnAccountantOption_Context: "Invite a co-worker to your agency and start assigning clients and responsibilities",
    InviteAnAccountantOption_Title: "Invite an accountant",
    InviteCompanyOwner_Msg_Placeholder: "Please do not enter personal information",
    InviteCompanyOwner_PersonalMessage_Label: "Message to the company owner (max 200 characters) (optional)",
    InviteUser: "Invite user",
    JoinAgency: "Join {x}",
    KrPerMonth: "{0} kr/month",
    LastActivity: "Last activity: {0}",
    LastBookkeptOn: "Last recorded on",
    LastUpdatedBy: "Last updated by",
    LastUpdatedByClient: "Last updated by user",
    LeaveAgency: "Leave agency",
    LeaveAgency_AgencyOwner_ErrorPart: "You are not able to leave this agency since you are the owner of the agency in Bokio.To leave the agency you must first transfer ownership to another user.",
    LeaveAgency_IUnderstandLossOfAccess: "I understand that I will not be able to access the agency {0} ({1}) and associated client companies in Bokio.",
    LeaveAgency_IUnderstandLossOfAccess_NoOrgNumber: "I understand that I will not be able to access the agency {0} and associated client companies in Bokio.",
    LeaveAgency_NoAgencyOwner_Error: "To leave the agency, you must have an agency owner.",
    LeaveAgency_Section1_Heading: "What does it mean to leave this agency in Bokio?",
    LeaveAgency_Section1_Paragraph1: "When you leave the agency **{0}**,you will not be able to access this agency unless you are invited again.\n\nYou will also lose access to all the associated client companies you are currently assigned to.\n\nYour personal account **{1}** will not be deleted, and you can still access other agencies or companies not directly connected to **{0}** in Bokio.",
    LeaveAgency_Section1_Paragraph2: "We strongly recommend you to export notes for all your clients. You will not be able to access it again when you leave this agency.",
    LeaveAgency_Section2_Heading: "What is the reason for you to leave this agency?",
    LeaveAgency_Section2_Paragraph: "We are sorry to see you go and would love to understand why you’re leaving this agency so we can create even better services. We would really appreciate some feedback.",
    ManageContractAgreement: "Manage contract agreement",
    ManageContractAgreement_InvalidForm_Price: "Price needs to be filled for each row in the {0} section",
    ManageContractAgreement_InvalidForm_StartDate: "Start date needs to be filled for each row in the {0} section",
    ManagedBy: "Managed by",
    Maybe_Later: "Maybe later",
    MentionsMe: "Mentions me",
    MoveToBokio_ClientRange: "How many clients do you want to move?",
    MoveToBokio_Info: "A transfer often takes approximately 2 business days, but can also take up to 5 business days depending on the number of clients.",
    MoveToBokio_Placeholder: "Enter a short message",
    MoveToBokio_PreviousSystem: "Current accounting service",
    MoveToBokio_RequestHelp: "Request help",
    MoveToBokio_Subject: "Agency interest to move clients to bokio",
    MoveToBokio_Title: "Need help moving over your clients?",
    MoveToBokio_TitleMessage: "We can offer you a complimentary agency service to assist in migrating your existing clients into Bokio Byrå.",
    MustbeNumeric: "Must only contain numbers",
    NewAgencyGuide_Description: "Continue setting up {0} by creating your first client or inviting a co-worker to collaborate.",
    NewAgencyGuide_Notice_Context: "Now it’s time to start collaborating with your clients in Bokio",
    NewAgencyGuide_Notice_Title: "You’re all set!",
    NewAgencyGuide_Title: "Welcome to Bokio Agency!",
    NewBackofficeRedirect_Notice: "Please follow the {link} to access the new backoffice.",
    NextClosure: "Next closure",
    None_Assigned: "None assigned",
    NonRecurrentCompanyColumn_OrderedOn_Title: "Ordered on",
    NonRecurrentCompanyColumn_SupportOn: "Support on",
    Notes_AssignedAccountantsCanView_Notice: "Only accountants assigned to this client can view or add notes",
    Notes_AssignedDetails: "Assigned to {0}",
    Notes_CreatedBy: "Created by {0} {1}",
    Notes_DueDateDetails: "Due by {0}",
    Notes_LimitedText: "{0}....\n{1}",
    Notes_NotificationStatusDetails: "{0} by {1} {2}",
    Notes_Title: "Notes",
    Notes_Tooltip: "{count} more {type}",
    Notes_Unassigned: "Unassigned",
    Notification_OverdueTooltip: "{type} - {count} overdue",
    Notification_Tooltip: "{type} - {count} new",
    NotificationsWillNotBeRemoved_Notice: "This will not remove notifications which have already been sent.",
    NotStarted: "Not started",
    NoUserExistsWithThatEmailAddress: "We could not find a user with the given email address",
    NoUserFoundWithEmail: "No user found with email {0}",
    NumberOfAccountants: "No. of accountants",
    NumberOfClients: "No. of clients",
    NumberPayingClients: "No. of paying clients",
    OnboardNow: "Onboard now",
    OpenInBokio: "Open in Bokio",
    Optional: "Optional",
    OrganisationNumber: "Org. number",
    Overview_OnboardingChecklist_CreateCompany_Button: "Create a new company",
    Overview_OnboardingChecklist_CreateCompany_Heading: "Already have a client you’d like to add?",
    Overview_OnboardingChecklist_CreateCompany_Text: "Create a new client company and start managing accounts and collaborate in Bokio",
    Overview_OnboardingChecklist_Import_Heading: "Connect customers",
    Overview_OnboardingChecklist_Intro: "Here you can find tips on things that are relevant to get started with for your practice",
    Overview_OnboardingChecklist_Invite_Button: "Invite accountant",
    Overview_OnboardingChecklist_Invite_Heading: "Invite accountants",
    Overview_OnboardingChecklist_Invite_Text: "Easily connect all your accountants to the practice for a simplified and user-friendly experience",
    Overview_OnboardingChecklist_Moving_Button: "Book free consultation",
    Overview_OnboardingChecklist_Moving_Heading: "Moving clients from another system",
    Overview_OnboardingChecklist_Moving_Text: "We offer free of charge practice service, to assist client migration",
    Overview_OnboardingChecklist_NewAgency_Heading: "New practice in Bokio",
    Overview_OnboardingChecklist_NewAgency_Text: "Set up your practice and start managing your clients easily.",
    Overview_OnboardingChecklist_TestClient_Button: "Start a test company",
    Overview_OnboardingChecklist_TestClient_Text: "We'll set you up with a prefilled test client so that you can jump straight into Bokio",
    Overview_PageTitle: "Welcome to Bokio Practice Manager",
    Overview_PartnerProgramme_InviteMore: "Invite {0} more paying clients to access the {1}",
    Overview_PartnerProgramme_Onboardedclients: "Client{s}{} onboarded in this month",
    Overview_PartnerProgramme_Readmore: "Read more about Bokio Partner programme",
    Overview_PartnerProgramme_Unsigned_Header: "Bokio Partner Programme",
    Overview_PartnerProgramme_Unsigned_Text: "Join the Partner Programme to gain benefits and earn commission for your customers. {Read more}",
    Overview_SupportBox_Heading: "Do you need help or guidance? ",
    Overview_SupportBox_Text: "Discover how Bokio Practice Manager and our partner program can benefit your practice. {0} Learn more by contacting us at {1}",
    Overview_TotalNumberOfComments: "Total no. of comments",
    Overview_TotalNumberOfTasks: "Total no. of tasks",
    Page_ClientRequests: "Client requests",
    Page_QuoteRequests: "Quote requests",
    Partner: "Partner",
    PartnerContent_BecomePartner_Text: "Become a partner and start earning commissions",
    PartnerContract_Pending: "Pending",
    PartnerContract_Signed: "Signed",
    PartnerContract_Unsigned: "Unsigned",
    PartnerHelpscoutMailbox: "Helpscout mailbox id",
    PartnerInfo_Banner_ButtonText: "Sign up now",
    PartnerInfo_Banner_Heading: "Sign up as an Agency Partner and start earning commissions today",
    PartnerInfo_Banner_Text: "Discover endless commission opportunities as a valued Agency Partner. Become an partner and start earning commissions based on all clients who join your agency.",
    PartnerInfo_Clarification_Marketplace: "{**Visible in the marketplace:} Contact us at {byrasupport@bokio.se} to get more information about what it means and to get a quote. As an Agency Partner, you do not need to be visible on the marketplace, but it is an option if desired.\n",
    PartnerInfo_Pros_Grow_Heading: "Grow together with Bokio! ",
    PartnerInfo_Pros_Grow_Text: "Make your agency visible to all our customers through the \"Hire an accountant\" marketplace and on Bokio's website, where Bokio users turn when they need help with bookeeping.",
    PartnerInfo_Pros_NewCustomers_Heading: "Commission for new customers in Bokio",
    PartnerInfo_Pros_NewCustomers_Text: "As an agency partner, you receive a commission for new customers you bring to Bokio. For those who have a commitment period left, we also pay the difference.",
    PartnerInfo_Pros_Services_Heading: "Commission for customers price plans",
    PartnerInfo_Pros_Services_Text: "As an Agency partner, you receive exclusive commissions for your customers' price plans. The more your customers use Bokio, the more commission you get!",
    PartnerInfo_Text: "Become an Agency Partner and get commissions for your customers' fees. Depending on how many clients you have in Bokio, your agency ends up at one of the three levels: Bronze, Silver or Gold. The higher level you are at, the greater benefits you get as an Agency partner.",
    PartnerInfoTable_AccordingOffer: "According to offer",
    PartnerInfoTable_Commission_Amount: "Commission - based on the end customer's monthly fee",
    PartnerInfoTable_Commission_Customers: "Requirements for paying customers",
    PartnerInfoTable_Commission_Onboarding: "Commission - onboarding new users",
    PartnerInfoTable_Marketplace_Cost: "Cost to appear on the marketplace",
    PartnerInfoTable_Marketplace_Title: "Visible in the marketplace - \"Hire an accountant\"**",
    PartnerInfoTable_Marketplace_Visibility: "Visibility on Bokio's website",
    PartnerInfoTable_Support_Text: "Priority agency support",
    PartnerInfoTable_Support_Title: "Support",
    PartnerName: "Practice name",
    Partners: "Practices",
    PendingClients_CompanyOwnerEmail: "Company owner email",
    PendingClients_DateSent: "Date sent",
    PendingClients_Status_Tooltip: "Invitations are only valid for 48 hours",
    PendingInvites: "Pending invites",
    PendingTodoItems: "Pending todo items",
    Period: "Period",
    Permission_BookkeepingSupport_Description: "The accountant has permission to handle the clients' bookkeeping.",
    Permission_BookkeepingSupport_Title: "Bookkeeping support",
    Permission_ClosureSupport_Description: "The accountant has permission to handle closures for clients.",
    Permission_ClosureSupport_Title: "Closure support",
    Permission_ManageCompanies_Description: "The accountant has permission to add or remove client companies as well as to manage those who are responsible for the customers.",
    Permission_ManageCompanies_Title: "Manage clients",
    Permission_ManageQuoteRequests_Description: "The accountant has permission to handle quote requests from clients.",
    Permission_ManageQuoteRequests_Title: "Manage quote requests",
    Permission_ManageUsers_Description: "The accountant has permission to add and remove other accountants as well as to edit their permissions.",
    Permission_ManageUsers_Title: "Manage accountants",
    Permission_PartnerSettings_Description: "The accountant has permission to edit the account settings.",
    Permission_PartnerSettings_Title: "Manage settings",
    Permission_SelfAssign_Description: "The accountant has permission to assign themselves to a client.",
    Permission_SelfAssign_Title: "Self-assign",
    Permission_Viewer_Title: "Viewer",
    Plan_PaidUntil: "Paid until",
    PricePerMonth: "Price (kr/month)",
    Priority_High: "High",
    Priority_Low: "Low",
    Priority_Medium: "Medium",
    PromotionBanner_MoveToBokio_BookFreeConsultation: "Book free consultation",
    PromotionBanner_MoveToBokio_Description: "We can offer you a personalised agency service, free of charge, to assist in migrating your clients over to Bokio Byrå from another accounting system.",
    PromotionBanner_MoveToBokio_Title: "Need help moving over your clients to Bokio?",
    ProvideFeedback: "Provide feedback",
    QuoteRequest_RegisterClient_SuccessHeading: "{0} has been registered {View active clients}",
    QuoteRequestRating_Rate_Description: "Any thoughts that could help us improve your experience with quote requests?",
    QuoteRequestRating_Rate_Title: "How was your experience with quote requests?",
    QuoteRequests_ActiveTab: "Active",
    QuoteRequests_ArchivedTab: "Archived",
    ReasonToReject: "Reason to reject",
    RecordingSupport_PageTitle: "Recording support",
    RecordingSupport_RecordingSupportTab_Assigned: "Assigned tickets",
    RecordingSupport_RecordingSupportTab_Filtered: "Filter tickets",
    RecordingSupport_RecordingSupportTab_Pending: "Pending",
    RecordingSupport_RecordingSupportTab_Search: "Search",
    RecordingSupport_SearchSupportTicket_SearchCompany: "Search company",
    RegistrationDate: "Registration date",
    Reject: "Reject and archive",
    RejectAndArchive: "Reject request",
    RejectQuoteRequest_Feedback_AgencyCantFulfilRequest: "Agency can't fulfil the request",
    RejectQuoteRequest_Feedback_CompanyDidntLikeTheOffer: "Company didn't like the offer",
    RejectQuoteRequest_Feedback_CompanyDidntRespond: "Company didn't respond",
    RejectQuoteRequest_Feedback_Other: "Other",
    RejectQuoteRequest_FeedbackLabel: "Why are you rejecting this request?",
    RejectQuoteRequest_PersonalMessage_Label: "Personal message to the company (max 200 characters)",
    RejectRequest: "Reject request",
    ReleaseNotes_Message_Agency: "New features have been added.",
    ReleaseNotes_Message_Company: "New features have been added to Bokio Agency.",
    RemoveClient: "Remove client",
    RemovedClient: "Removed client",
    RemoveMe: "Remove me",
    RemovePartnerCompany: "Remove client",
    RemovePartnerCompanyModalMessage: "Are you sure you want to remove the company {0} from {1}?",
    RemoveResponsibleAccountantWarning_Text: "User {email} is the responsible accountant for this company. Removing this user will also remove the responsible accountant.",
    RemoveResponsibleAccountantWarning_Title: "Remove responsible accountant",
    RemoveUserModalMessage: "Are you sure you want to remove the user {0}?",
    RemoveUserModalTitle: "Remove the user {0}",
    Request: "Request",
    Reset: "Reset",
    Responsibilities_Accounting: "Accounting",
    Responsibilities_AdvisoryServices: "Advisory services",
    Responsibilities_Bookkeeping: "Bookkeeping",
    Responsibilities_ForecastingAndBudgeting: "Forecasting and budgeting",
    Responsibilities_ManagementAccounts: "Management accounts",
    Responsibilities_Payroll: "Payroll",
    Responsibilities_Reconciliation: "Reconciliation",
    Responsibilities_ReviewAndCorrectionOfBookkeeping: "Review and correction of bookkeeping",
    Responsibilities_Salary: "Salary",
    Responsibilities_TaxAdvice: "Tax advice",
    Responsibilities_TaxReturns: "Self Assessment tax return",
    Responsibilities_VatReturns: "VAT Returns",
    Responsibilities_YearEnd: "Year-end accounts",
    ResponsibilitiesModal_Description: "Select the services you are providing for your client:",
    ResponsibleAccountant: "Responsible accountant",
    ReviewAndConnect: "Review and connect",
    Salary_LastDateToSubmit: "Last date to submit",
    Salary_LastDateToSubmitForMonth: "Last date to submit for {month}",
    Salary_LastLockedMonth: "Last locked month",
    Salary_NumberOfEmployees: "No of employees",
    Scene_Admin_Title: "Admin",
    Scene_ClientsInQueue_Onboarding: "Onboarding",
    Scene_ClientsInQueue_WaitingForApproval: "Waiting for approval",
    Scene_Companies_NonRecurrent: "Non recurrent",
    Scene_Companies_Recurrent: "Recurrent",
    Scene_Companies_Title: "Clients",
    Scene_CompaniesInQueue_Title: "Clients in queue",
    Scene_ManageCompanies_Title: "Manage clients",
    Scene_MyCompanies_Title: "My clients",
    Scene_Overview_Title: "Overview",
    Scene_Partners_Title: "Practices",
    Scene_Settings_Title: "Settings",
    Scene_Todo_Title: "Todo",
    Scene_Users_Title: "Users",
    Search_BackOfficeAgencies_Placeholder: "Search for Name / ID",
    SendInvite: "Send invite",
    ShowAllClientsImAMemberOf: "Show all clients I'm a member of",
    ShowArchived: "Show archived clients",
    SignUp_Banner_ButtonText: "Become Agency Partner",
    SignUp_Banner_Text: "Become an Agency Partner and get commissions for your customers' price plans. The more customers you have in Bokio, the greater benefits you get!",
    StartDate: "Start date",
    Status: "Status",
    SubmissionDate: "Submission date",
    SupportTicket_OrgNumberMissing: "Missing org number",
    SupportTicketList_AssignButton: "Assign",
    SupportTicketList_AssignTo_Label: "Assign to",
    SupportTicketList_TicketInfoHeading_AccountingType: "Accounting type:",
    SupportTicketList_TicketInfoHeading_Comments: "Comments",
    SupportTicketList_TicketInfoHeading_CompanyId: "CompanyId:",
    SupportTicketList_TicketInfoHeading_CompanyType: "Company type:",
    SupportTicketList_TicketInfoHeading_PricePlan: "Price plan:",
    SupportTicketList_TicketInfoHeading_Updated: "Updated",
    SupportTicketList_Unassigned: "Unassigned",
    Task: "Task",
    Task_EditAccess_Anyone_Option: "Anyone can edit the task",
    Task_EditAccess_CheckboxLabel: "Edit access",
    Task_EditAccess_OnlyMe_Option: "Only I can edit",
    Task_TextField_Label: "Describe the task",
    Task_TitleField_Label: "Title",
    Tasks: "Tasks",
    Tasks_AssignedAccountantsCanView_Notice: "Only accountants assigned to this client can view or add tasks",
    Tasks_AssignedAccountantsCanView_QuoteRequest_Notice: "Only accountants assigned to this quote request can view or add tasks",
    Tasks_MarkAs: "Mark as",
    TasksAndComments_Title: "Tasks and comments",
    TransferCompany_CompanyToTransfer: "Client to transfer",
    TransferCompany_PartnerToTransferTo: "Practice to transfer to",
    TransferCompany_TransferToAnotherPartner: "Transfer to another practice",
    TryAgencyBanner_Content: "We are developing Bokio for accountants so it will be as easy as possible for you to work with your clients. Register as an accountant and get access to our client management tool.",
    TryAgencyBanner_CTA: "Try Bokio for accountants",
    TypeHere_Placeholder: "Type here...",
    UpdatedByClientAt: "Updated by client at",
    UserIsAMemberOfTheFollowingCompanies: "{0} has access to the following clients",
    Users: "Users",
    Users_AgencyOwner: "Practice owner",
    Users_AgencyOwner_Paragraph: "The practice is owned by {0} ({1}).",
    Users_ChangeAgencyOwner: "Change practice owner",
    Users_ChangeOwner: "Change owner",
    Users_ChangeOwnerWarning_Header: "Warning!",
    Users_ChangeOwnerWarning_Paragraph: "Only the practice owner can change the owner. Once you have changed the owner only the new owner can change the ownership.",
    UserX_CreatedANoteOn_DateY: "Created by {0} on {1}",
    Vat_Completed: "Completed",
    Vat_ReadyToSubmit: "Ready to submit",
    Vat_Submitted: "Submitted",
    VatStatus_NonPeriodic: "VAT Status",
    VatStatus_Periodic: "VAT Period",
    ViewActiveClients: "View active clients",
    ViewAllDetails: "View all details",
    ViewRequest: "View request",
    WaitingSince: "Waiting since",
    WeNeedSomeMoreInfo: "We need some basic info about you before getting started",
    WorksBestOnComputer: "This page works best on a computer browser",
    WorkStatus_Archived: "Archived",
    WorkStatus_Completed: "Completed",
    WorkStatus_ConsultationComplete: "Consultation completed",
    WorkStatus_ConsultationInProgress: "Consultation in progress",
    WorkStatus_Contacted: "Contacted",
    WorkStatus_ContractSent: "Contract sent",
    WorkStatus_ContractSigned: "Contract signed",
    WorkStatus_Incomplete: "Incomplete",
    WorkStatus_New: "New",
    WorkStatus_OnboardingComplete: "Onboarded",
    WorkStatus_OnboardingInProgress: "Onboarding",
    WorkStatus_Ongoing: "In progress",
    WorkStatus_Overdue: "Overdue",
    WorkStatus_Waiting: "Waiting",
    WriteReason: "Write a reason to reject to backtrace in the future",
    YouAreNotAuthorizedToAccessCompanyInfo: "You are not authorized to access this information. Please add yourself to the client or contact the administrator.",
  };
}