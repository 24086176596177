/* eslint-disable */

import type { PayslipCalculationsLang } from "../types/PayslipCalculationsLang";
import { translateTempImpl } from "../TranslatableLanguage";

export function PayslipCalculationsLangDef(): PayslipCalculationsLang {
  return {
    translateTemp: (key: string, neutral: string, svSE?: string, enGB?: string) => translateTempImpl(key, neutral, svSE, enGB),
    
    Error_HolidayNotPermamentEmployee: "Holiday pay is only valid for permanent employees",
    UnitShouldBeDay: "The unit should be set to 'day'",
  };
}