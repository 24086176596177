/* eslint-disable */

import type { InvoicesLang } from "../types/InvoicesLang";
import { translateTempImpl } from "../TranslatableLanguage";

export function InvoicesLangDef(): InvoicesLang {
  return {
    translateTemp: (key: string, neutral: string, svSE?: string, enGB?: string) => translateTempImpl(key, neutral, svSE, enGB),
    
    AccountingApplication: "Accounting",
    AccountReceivables_AmountLeftToImport_label: "Amount left to import",
    AccountReceivables_EmptyState_message: "Import unpaid customer invoices that are not created in Bokio. By importing you will also be able to register and record upcoming payments from the invoice section.",
    AccountReceivables_InvoicesImported_label: "Invoices imported",
    AccountReceivables_TotalAmountImported_label: "Total amount imported",
    Activate_Recurring_Invoice: "Activate recurring invoice",
    ActiveInvoices_ColHeading: "Active invoices",
    ActiveRecurringInvoices: "Active recurring invoices",
    ADD: "Add",
    Add_Another_Contact_Info: "Now you can save up to 3 contacts for the same customer. Easily add and manage all contact details in one place.",
    Add_Another_Contact_Info_Button: "Add contact",
    Add_Another_Contact_Info_Title: "Add another contact?",
    Add_Contact_Blocked_Info: "In order to add a new contact, it is mandatory to complete the previous contact with at least one contact detail",
    Add_Contact_Blocked_Title: "Add another contact?",
    Add_Contact_Dropdown_Button: "Add up to 3 contacts to customer",
    ADDANDSAVEASARTICLE: "Add and save as item",
    AddComment: "Add comment",
    AddFromTimeTracking: "Add from time tracking",
    AddInfo_action: "Add info",
    AddItemOrService_heading: "Items/services",
    AdditionalInformation_Attachment_Description: "You can attach jpeg, png or pdf files up to {size}. Attached files will be added to the final invoice as additional pages once published. Maximum file size for a single item is {size} and all files cannot exceed {sizes}.",
    AdditionalInformation_Attachment_Heading: "Attach files:",
    AdditionalInformation_EInvoice_Description: "This is additional information that may be required by the e-invoice receiver, e.g project reference.",
    AdditionalInformation_EInvoice_Heading: "E-invoices:",
    AdditionalInformation_Heading: "Additional information",
    AdditionalInformation_RotRut_Description: "Rot and rut are deductions that apply to construction, maintenance, etc. {Read more}",
    AdditionalInformation_RotRut_Heading: "Rot/Rut:",
    AddMore: "Add more",
    AddNewAccount: "New",
    ADDNEWCUSTOMER: "New customer",
    ADDNEWROW: "Add new row",
    ADDRESS: "Address",
    AddressEmpty: "&lt;Address missing&gt;",
    ADDRPOSSETFIELD: "Invoice address position",
    AddTemplate: "Add template",
    ADDTEXTROW: "Add text",
    AddToInvoice: "Add to invoice",
    AdvancedSettings: "Terms and foreign customer",
    AlertMessage_RecurringInvoice_Edit_Invoices: "Edit all upcoming invoices",
    AlertMessage_RecurringInvoice_Text: "Changes made to this draft will not affect any upcoming invoices",
    AlertMessage_RecurringInvoice_Title: "This draft invoice was automatically created based on recurring invoice",
    AlienCustomerInvoice_NoInvoices_EmptyState: "No customer invoices have been imported yet. Once you have imported your first invoice it will appear here.",
    AlienCustomerInvoice_NoOpeningBalanceMessage: "There are no customer invoices to import since you have no previous accounting history added in Bokio. To import invoices you first have to set your opening balances in {settings}.",
    AlienInvoice_Error_NoDeleteInvioceHasPayment: "The invoice cannot be deleted as it has payments registered",
    AlienInvoiceValidation_ExceedsOutstanding: "The total exceeds the remaining amount to import ({0})",
    AlienInvoiceValidation_InvoiceNumberAheadOfSequence_message: "Invoice number is ahead of invoice sequence",
    AlienInvoiceValidation_InvoiceNumberConflict_message: "Invoice number conflict. Invoice number is already in use",
    AlienInvoiceValidation_TotalImportedExceedsReceivablesTotal_message: "Total imported amount exceeds the allocated amount in account receivables",
    AlienSupplierInvoice_Instructions: "Import unpaid supplier invoices that are not created in Bokio. By importing you will also be able to register and record upcoming payments from supplier and purchases.",
    AlienSupplierInvoice_NoInvoices_EmptyState: "No supplier invoices have been imported yet. Once you have imported your first invoice it will appear here.",
    AlienSupplierInvoice_NoOpeningBalanceEmptyState: "There are no supplier invoices to import since you have no previous accounting history added in Bokio. To import invoices you first have to set your opening balances in {settings}.",
    AlienSupplierInvoice_PaymentVerification_Explanation: "Payment for imported invoice: {0}",
    AlienSupplierInvoice_SuccessfulAdd: "Invoice has been added! You can also find it in {suppliers and purchases} from where you can register and record upcoming payments.",
    AlienSupplierInvoices_TradeCreditors: "Trade creditors",
    ALLCUSTOMERS: "All customers",
    AllInvoices_ColHeading: "All invoices",
    AllRecurringInvoices: "All recurring invoices",
    AllSuppliers: "All Suppliers",
    AmendInvoice_CreateCreditNote_Action: "Credit now",
    AmendInvoice_CreateCreditNote_Text: "Send a credit note to indicate that the original invoice is nullified.",
    AmendInvoice_CreateCreditNote_Title: "Create credit note",
    AmendInvoice_DeleteBookkeeping_Action: "Delete accounting",
    AmendInvoice_DeleteBookkeeping_Text: "Journal entry of the invoice, payments, settlements and reminders will be deleted.",
    AmendInvoice_DeleteBookkeeping_Title: "Delete accounting",
    AmendInvoice_Edit_Action: "Edit",
    AmendInvoice_Edit_Text: "The invoice can be edited as long as it hasn't been recorded.",
    AmendInvoice_Edit_Title: "Edit invoice",
    AmendInvoice_EditCreditNote_Text: "The credit note can be edited as long as it hasn't been recorded.",
    AmendInvoice_EditCreditNote_Title: "Edit credit note",
    AmendInvoice_NoCorrectionsAvailable: "There are no actions available for this invoice.",
    AmendInvoice_UndoCreditNote_Action: "Undo now",
    AmendInvoice_UndoLastPayment_Action: "Delete now",
    AmendInvoice_UndoLastPayment_Text: "The payment may be deleted as long as the invoice hasn't been recorded.",
    AmendInvoice_UndoLastPayment_Title: "Delete last payment",
    AmendInvoice_UndoPublish_Action: "Undo now",
    AmendInvoice_UndoPublish_Text: "You may undo the latest published invoice if it's not recorded. The invoice will then be removed.",
    AmendInvoice_UndoPublish_Title: "Undo publish",
    AMOUNT: "Quantity",
    AMOUNT_DUE: "Amount due",
    Amount_Owing: "Amount Owing",
    AmountToPay: "Amount to pay",
    ApartmentNumber: "Apartment number",
    Approve_Action: "Approve",
    ApproveAndSignPayment: "Approve and sign payment",
    ARTICLE: "Item",
    Article_Heading: "Items",
    Article_UnspecifiedKindError: "No type selected",
    ArticleCountSuffix: "",
    ArticleDeleteMessage: "Are you sure you want to delete this item?",
    ArticleName: "Item name",
    Articles_ArticleShortInfo: "{0} each, {1} % VAT",
    Articles_DraftShortInfo: "Item draft",
    Articles_Edit_Heading: "Edit item",
    Articles_NoArticles_Text: "You can fix this by creating your &lt;a href=\"#/articles/new\"&gt;first item&lt;/a&gt;.",
    Articles_NoArticles_Title: "You have no items yet",
    Articles_Search_Text: "Start searching by typing in \"description\".",
    Articles_Search_Title: "Saved items",
    Articles_UnspecifiedFixAction: "You have items without any specified type. {Fix in items}",
    Articles_UnspecifiedKindWarning: "You have {0} without any specified type. They need to be fixed to be used on an invoice.",
    ArticlesCreateFirstArticle_action: "Let's start by creating your first item",
    ArticlesImport_article: "item",
    ArticlesImport_articles: "items",
    ArticlesImport_Begin: "Download the .csv template file to begin",
    ArticlesImport_Begin_Skip: "Go to next step ",
    ArticlesImport_Begin_Skip2: "if you have already downloaded the template",
    ArticlesImport_Cancel_Import: "Cancel the import",
    ArticlesImport_Error_Missing_Info: "Missing information",
    ArticlesImport_Great_upload_Confirm: "Great! Your file contains ",
    ArticlesImport_Have_Saved: "have been saved",
    ArticlesImport_Import: "Import",
    ArticlesImport_No_Articles: "Hmm, you don't have any items! Let's try uploading that file again.",
    ArticlesImport_No_Cancel: "No, cancel",
    ArticlesImport_Reupload: "Re-upload file",
    ArticlesImport_Row_Warning_1: "We've noticed ",
    ArticlesImport_Row_Warning_2: "of your rows have warnings",
    ArticlesImport_setp2_Instructions: "2. Save your file in .csv format and upload it here.",
    ArticlesImport_step1_Instructions: "1. Open the downloaded file in a program that supports .csv format and fill in your information. See the example below",
    ArticlesImport_View_Articles: "View items",
    ArticlesImport_Yes_Erase: "Yes, erase import",
    AtivoFinans_TermsAndConditions_Modal_Title: "Factoringgruppen Terms and Conditions",
    Attachment_Delete_Action: "Yes, delete",
    Attachment_Delete_Description: "You are about to permanently delete your attachments. Are you sure you want to delete attachments?",
    Attachment_Delete_Heading: "Delete attachments",
    Attachment_Description: "Attached files will appear as additional pages in the final invoice. Maximum file size is {size} and all files can’t exceed {sizes}. ",
    Attachment_DimensionsForFileAreTooLarge: "Attachment dimensions for {0} are too large and not supported. Max height: {1}, Max width: {2}",
    Attachment_ExceedMaximumSize: "Maximum file size for a single item is {0} and all files cannot exceed {1}.",
    Attachment_Heading: "Attach files",
    Attachment_NotSupportedPdf: "Your pdf file ({0}) is damaged or not supported by Bokio.",
    Attachment_ThisAttachmentCouldNotBeUploaded: "This attachment ({0}) could not be uploaded",
    Attachment_TypeNotSupported: "{0} is not supported.",
    automated_bookkeeping_from_ecommerce: "Automated bookkeeping for your ecommerce",
    AutomaticallySet: "&lt;Automatically Set&gt;",
    AutomaticChecking_Activation_Accept: "Yes, record automatically",
    AutomaticChecking_Activation_Decline: "Don't record automatically",
    AutomaticChecking_Activation_Description: "To make your life easier we will automatically record your events in invoices, such as publishing an invoice, payments, reminders and more. You can change this under invoice settings later.",
    AutomaticChecking_Activation_Notice: "If you don't use Bokio for your Bookkeeping today you should not activate this.",
    AutomaticChecking_Activation_Title: "Automatic recording of invoice events",
    AutomaticChecking_BokioPayment_Description: "You have Bankgiro and OCR number with Bokios business account as a payment option on this invoice. If the user pays with the correct OCR number, they payment will be registered and record automatically.",
    AutomaticChecking_IgnoredRecord: "This event will not be recorded automatically because the invoice hasn't been recorded yet.",
    AutomaticMatchingModal_Description: "Make new {0}'s {1} as your default for domestic invoice payments and enable OCR number generation. This allows for automatic checking of invoices.",
    AutomaticMatchingModal_Title: "Automatic checking of Invoices",
    AutomaticPayment_Heading: "Automatic payments",
    AutomaticPayment_History: "with automatic payments",
    AwaitingApproval: "Awaiting approval",
    Back: "Back",
    BackToAddText: "Back to add text",
    BACKTOCUSTOMERLIST: "Back to client list",
    BACKTOINVOICE: "Back to invoice",
    BALANCE: "Balance",
    BalanceType_CurrencyLosses: "Currency loss",
    BalanceType_DebtCancellation: "Debt",
    BalanceType_Financing: "Financing",
    BalanceType_Invoice: "Invoice",
    BalanceType_Payment: "Payment",
    BalanceType_Reminder: "Reminder",
    BalanceType_Settlement: "Settlement",
    BankAccount: "Bank account",
    BetaFeature: "Beta Feature",
    BokioIbanReferenceNoticeText: "* IMPORTANT INFO about the numeric unique payment reference:\na) The bank transfer payment MUST have the correct numeric reference in the message to the payee (beneficiary),\nb) Insert the numeric reference only, do not insert any comments or extra words,\nc) If your bank allows two references (for you and payee) and you feel unsure, please enter the reference in both fields.",
    BOKIOSTERMS: "Bokios common terms",
    BOOKKEEP_PAYMENT: "Record payment",
    Bookkeep_Reminder: "Record reminder",
    Bookkeeping_Deactivated: "Bookkeeping deactivated",
    Bookkeeping_Prepared: "Accounting prepared",
    BookkeepSupplierInvoice_RecordingPaymentNotice: "The invoice is paid and the payment will be recorded at the same time as the invoice.",
    BOOKKEEPT: "Recorded",
    BOOKKEEPT_AS: "Recorded as",
    BookkeptOutsideOfBokio: "Outside of Bokio",
    BY: "by",
    CANCEL: "Cancel",
    CannotDeleteInvoicePaymentWhenNotLast: "Can only delete the last registered payment for an invoice",
    CASHINVOICE: "cash invoice",
    CashInvoiceHeader: "Cash Invoice",
    CashInvoiceNum: "Cash Invoice no",
    CashInvoiceNumber: "Cash Invoice number",
    CashInvoicePublished_label: "Cash invoice was published",
    CashInvoicePublished_WithPaymentLink_label: "Cash invoice was published from payment link",
    CashInvoices: "Cash invoices",
    CashInvoiceSettings_heading: "Cash invoice settings",
    Celebrate_First_Published_Invoice: "Brilliant! Your first invoice was just published.",
    CHANGE: "Change",
    Change_contact_tooltip_info: "Now you can save up to 3 contacts for the same customer and easily change them here using the dropdown below.",
    CHANGED: "Changed",
    CharactersLeft: "characters left",
    CHOSECUSTOMER: "Choose customer",
    ChoseDeliveryMethod: "Choose delivery method",
    CHOSENARTICLE: "Chosen item",
    CHOSESUPPLIER: "Choose supplier",
    CHOSEVAT: "Choose VAT",
    CITY: "City",
    ClaimRotRutDeduction: "Claim Rot/Rut deduction from customer",
    ClearingAccount: "Clearing Account",
    ClickHereToDownload: "Click here to download again",
    ClosePreview: "Close preview",
    CollapseAll: "Collapse all",
    Comment: "Comment",
    Comments: "Comments",
    COMPANY: "Company",
    COMPANYCUSTOMER: "Company",
    COMPANYDETAILS: "Sender information for your company",
    CompanyForm_ContactDetails_text: "Who should your customer contact with questions?",
    CompanyForm_OrgNumber_Tooltip: "Your company number is a unique (or combination of letters or numbers) which can be found at &lt;a href=\"https://beta.companieshouse.gov.uk\" target=\"_blank\"&gt;Companies House&lt;/a&gt;",
    CompanyForm_VATNumber_Tooltip: "Your VAT number is usually 9 characters and can be found at &lt;a href=\"https://beta.companieshouse.gov.uk\" target=\"_blank\"&gt;Companies House&lt;/a&gt; ",
    CompanyModal_AddOcrToInvoice: "Add OCR number to invoice",
    CompanyModal_PaymentMethod_MultiplePaymentMethods_message: "Do you want to add more payment methods? Add them directly to the invoice by adding a \"New text row\", which you can find under \"Add items/service\"",
    CompanyModal_Payments_tooltip: "Domestic payment details will be used in the invoice if the selected customer is from the UK. For international customers, international payment method will be automatically applied.",
    CompanyName: "Company name",
    CompanySettings_label: "Company settings",
    CompanyTaxRegistered: "Tax Registered",
    Completed_ColHeading: "Completed",
    ConfirmAndSend_action: "Confirm and send",
    ConfirmDelete_heading: "Confirm delete",
    Connect_To_ECommerce: "Connect ecommerce",
    Connect_To_ECommerce_Description: "Connect to your ecommerce to import orders which are recorded automatically",
    ConnectionFailed: "Connection failed",
    ConnectToCreditInvoice_Info: "Without connecting an invoice it will affect accounts receivable negatively.",
    ConnectToCreditInvoice_Warning: "Are you sure about this? This credit note is not connected to an invoice! Without a connection it will affect accounts receivable negatively.",
    ConnectToInvoice_label: "Connect to invoice",
    CONTACT: "Contact",
    Contact_Index: "Contact 0{1}",
    CONTACTPERSON: "Contact person",
    CONTACTUS: "Contact us",
    CONTENT: "Content",
    CONTINUE: "Continue",
    COPY: "Copy",
    COPY_INVOICE: "Copy invoice",
    COPY_RECURRING_INVOICE: "Copy recurring invoice",
    CorrectCreditNote: "Amend credit note",
    CorrectInvoice: "Amend invoice",
    Correction_InvoiceIsBookkept: "The invoice must have no payments and it must not be recorded.",
    Correction_NoPaymentsToDelete: "There are no payments to delete.",
    Correction_OnlyTheLatestNonBookkeeptInvoiceCanBeUndone: "The invoice must be the latest and not yet recorded.",
    COUNTRY: "Country",
    CountrySubdivisionMissing_Notice: "To publish invoices you need to set company location",
    CountrySubdivisionMissing_Notice_ContactOwner: "Please contact company owner or user with permission to company settings.",
    CountrySubdivisionModal_Description: "Due to Brexit, we need to know which country your company is located in. This will let us apply new rules so your accounting is correct.\n\nWe need to know which country in the United Kingdom your business is located in so we can make sure your bookkeeping is correct following Brexit.\n\nThere will be new rules for importing and exporting goods to and from EU countries for English, Scottish and Welsh businesses. Northern Irish businesses will continue to apply the rules of the EU when selling and purchasing goods from the EU.\n\nOnce you have answered this question, we will handle the bookkeeping automatically based on the date selected and whether it is before or after Brexit for both invoicing and bookkeeping.",
    CountrySubdivisionModal_Title: "Select company location",
    CreateInvoice_ChangeCustomerTerms_message: "Changing these values will also change the defaults for your selected customer",
    CreateInvoice_CompanyInfomationMissing_Info_message: "Company information ({0}) is missing",
    CreateInvoice_EditLayout_heading: "Edit layout, logo, colour",
    CreateInvoice_EmailDelivery_Tooltip_message: "The email will be sent in your customer's set language",
    CreateInvoice_InvoiceInformationValid: "Invoice details are OK!",
    CreateInvoice_NavigateToInvoiceList: "To the list",
    CreateInvoice_NotEnoughPermissionsForPublish_Warning_message: "As this is your company's first invoice, you don't have the required permissions to set your first invoice number under invoice settings. Please contact your administrator in order to publish.",
    CreateInvoice_RecipientInformationValid: "Information about recipient is OK!",
    CreateInvoice_Update_Success: "The invoice was updated!",
    CreateInvoice_Update_Text: "You are about to update the invoice. This will permanently overwrite the previous invoice information.",
    CreateInvoice_Update_Title: "Do you want to update the invoice?",
    CreateNew: "Create new",
    CreateQuote_Update_Text: "You are about to update the quote. This will permanently overwrite the previous quote information.",
    CreateQuote_Update_Title: "Do you want to update the quote?",
    Credit_ColHeading: "Credited",
    CreditAmount: "Credit amount",
    CreditConfirm: "Are you sure you want to credit the invoice?",
    CreditDraft_ColHeading: "Credit draft",
    CreditedAmount: "Credited amount",
    CreditedInvoice_label: "Invoice was credited",
    CreditForInvoice_label: "Credited for invoice",
    CreditInvoice_action: "Credit note",
    CreditInvoice_IncludeInAccountsReceivable: "Include in accounts receivable report",
    CreditInvoiceForInvoice_label: "Credit note for invoice",
    CreditInvoiceHeader: "Credit note",
    CreditInvoiceNum: "Credit note no",
    CreditInvoiceNumber: "Credit note number",
    CreditInvoicePublished_label: "Credit invoice was published",
    CreditInvoiceSettings_heading: "Credit note settings",
    CreditOrDebitCard: "Credit or debit card",
    Currency: "Currency",
    Currency_WarningMessage_RateMightBeWrong: "The exchange rate might be wrong. Consider refreshing or enter the exchange rate manually.",
    CURRENTBUTOKINVOICES: "Sent invoices",
    CurrentPDFVersion: "Current PDF",
    CUSTOMER: "Customer",
    Customer_Contacts_Label: "Who should your customer contact for questions?",
    Customer_DeleteCustomer_message_warning: "Are you sure you want to remove customer {0}? This cannot be undone!",
    Customer_Label: "Customer",
    CUSTOMER_NO: "Customer.No.",
    Customer_PlaceholderName: "&lt;Client without name&gt;",
    Customer_ValidDays: "Valid days",
    Customer_ValidDays_Tooltip: "Number of days before the valid from is due.",
    CustomerArchived_message: "Archived",
    CUSTOMERCONTACT: "Contact person",
    CustomerDeleteMessage: "Are you sure you want to delete this customer?",
    CustomerDeleteTitle: "Delete customer",
    CustomerDeleting_modal_heading: "Deleting customer {0}",
    CustomerDetails_label: "Customer details",
    CustomerForm_ContactDetails_heading: "Contact details",
    CustomerForm_ContactDetails_text: "Who are you sending the invoice to?",
    CustomerForm_CustomerNumber_Tooltip: "The customer number is automatically generated for every new customer if the field is left empty.",
    CustomerForm_Delivery_Tooltip: "Define how the goods are delivered. Ex warehouse means that the risk is taken over by the customer as soon as the goods leave the warehouse.",
    CustomerForm_OrgNumber_Tooltip: "A company number is a unique (or combination of letters or numbers) which can be found at &lt;a href=\"https://beta.companieshouse.gov.uk\" target=\"_blank\"&gt;Companies House&lt;/a&gt; ",
    CustomerForm_OverdueInterest_Tooltip: "This is where you define the interest rate added when a payment is overdue. Remember that you must create a new invoice with the added interest rate and send it to your customer.",
    CustomerForm_PaymentDays_Tooltip: "Number of days before the payment is due.",
    CustomerForm_PostalAddress2_Tooltip: "This will be displayed as the second line or the C/O of the address.",
    CustomerForm_VATNumber_Tooltip: "A VAT number in the UK is usually 9 characters can be found on your VAT registration certificate or by logging on to the &lt;a href=\"https://www.gov.uk/log-in-register-hmrc-online-services\" target=\"_blank\"&gt;government gateway&lt;/a&gt; ",
    CustomerImport_ShowCustomers: "Show customers",
    CustomerImport_SuccessHeading: "{ok} of {total} customers imported",
    CustomerImport_SuccessText: "Imported customers will be available in your customer list",
    CustomerImport_Validation_UniqueCustomerNumber: "The customer number must be unique",
    CustomerName_label: "Customer",
    CUSTOMERNO: "Client number",
    CUSTOMERNOMAXERR: "The maximum customer number allowed is",
    CUSTOMERNOMINERR: "The minimum customer number allowed is",
    CUSTOMERNOSETFIELD: "Which customer number do you want to start with?",
    CUSTOMERNOSETMSG: "Thank you for creating your first customer!",
    CUSTOMERNOSETPLACEHOLDER: "Customer number",
    CustomerNumber: "Customer number",
    CUSTOMEROVERVIEW: "Customer overview",
    CustomerReference: "Customer reference",
    CUSTOMERS: "Customers",
    Customers_AddFirstCustomer: "Add your first customer",
    Customers_Date_Error: "Whoops, this isn't a valid date. Let's try again.",
    Customers_DateHistory_Input: "Balance due as of",
    Customers_DateHistory_Label: "History",
    Customers_DateHistory_TotalDue: "Total receivables",
    Customers_EmptyState_Header: "No customers to show",
    Customers_EmptyState_PrimaryAction: "Add customer",
    Customers_EmptyState_SecondaryAction: "Import customers",
    Customers_EmptyState_Text: "You haven’t added any customers yet. Add or import customers to use them in invoices and quotes.",
    Customers_NoCustomers_Text_Pt1: "You can fix this by",
    Customers_NoCustomers_Text_Pt2: "creating your first customer",
    Customers_NoCustomers_Title: "You have no customers yet",
    Customers_NoResults: "We didn't find any results for this period. Try a different date.",
    Customers_NumberPrefix: "#",
    Customers_Search_Placeholder: "Search for customer",
    CustomersCompaniesShowMore_action: "Show more",
    CustomersImport_Complete: "Complete",
    CUSTOMERSPARTOFALLSENT: "Client fraction of all sent invoices",
    CustomerStatus_Label: "Status",
    CUSTOMERSWITHDELAYED: "Clients with invoices gone overdue!",
    CustomerType_label: "Customer type",
    DailyTakings_ActivationCouldNotBeCompleted_heading: "Your Zettle authorisation could not be completed.",
    DailyTakings_AreYouSureDisconnect_heading: "Are you sure you want to disconnect from Zettle?",
    DailyTakings_ChoosePaymentAccount_label: "Choose payment account for Zettle card payments",
    DailyTakings_ConnectAndImport_action: "Connect and import from Zettle",
    DailyTakings_ConnectedToIZettle_heading: "You're connected with Zettle!",
    DailyTakings_ConnectToIZettle_heading: "Connect with Zettle",
    DailyTakings_DailyTakings_label: "Daily takings",
    DailyTakings_DisconnectMenu_action: "Disconnect",
    DailyTakings_DisconnectMessage_message: "By disconnecting from Zettle no more daily takings will be generated from Zettle transactions. Previously recorded daily takings will not be deleted. Are you sure you want to disconnect?",
    DailyTakings_Error_General_ContactSupport: "An error occured and no journal entry was created. {0}",
    DailyTakings_Error_IZettle_ECommercePurchasesOmitted: "Some purchases were omitted because they came from an unsupported source; ecommerce.",
    DailyTakings_Error_IZettle_PaymentMethod_WithReason: "We could not create a journal entry for this Zettle event which you may need to bookkeep manually. See our {0} for more information.",
    DailyTakings_Error_IZettle_PaymentMethodUnknown: "We could not create a jounal entry for this unknown Zettle event. You may need to bookkeep this manually.",
    DailyTakings_FetchError_error: "An error occurred when fetching your daily takings",
    DailyTakings_FixGap_DeclineButton: "Decline",
    DailyTakings_FixGap_Description: "This gap is due to that a new fiscal year was missing at the time of importing. The gap is for days between {0} and {1}. If you have already handled the gap by recording verifications manually you can click decline. Otherwise you can click import and we will import missing days.",
    DailyTakings_FixGap_RequestButton: "Import",
    DailyTakings_FixGap_Title: "We have found that you have gap in your imports from Zettle",
    DailyTakings_ImportFrom_label: "Start importing from",
    DailyTakings_iZettle_ApiUserName: "Bokio Zettle integration",
    DailyTakings_iZettle_GetStarted_Link: "https://www.bokio.co.uk/help/integrations/izettle/get-started-with-izettle-in-bokio/",
    DailyTakings_iZettle_Help_Link: "https://www.bokio.co.uk/help/integrations/izettle/when-izettle-events-can-t-be-handled/",
    DailyTakings_IZettleConnectionMessage_message: "If you selected a date earlier than today, and have transactions in Zettle, daily takings will appear here shortly. All future transaction from Zettle will be recorded automatically the day after.",
    DailyTakings_iZettleDescription_message: "By connecting your Bokio account to Zettle we can automatically register your daily payments through your Zettle device.",
    DailyTakings_iZettleIntegration_label: "Zettle integration",
    DailyTakings_iZettleMessage_notice: "Connecting Zettle will create journal entries for daily takings, Zettle card fees and transfers from Zettle to your bank account from &lt;strong&gt;{0}&lt;/strong&gt; and continuously for future events.",
    DailyTakings_JournalEntry_BankFees: "Bank fees",
    DailyTakings_JournalEntry_Other: "Other",
    DailyTakings_JournalEntry_Payout: "Payouts",
    DailyTakings_MarkAsHandled_action: "Mark as handled",
    DailyTakings_PaymentAccountDescription_message: "Transfers for Zettle card payments will be automatically recorded to the above payment account. Remaining Zettle payment will be automatically handled by Bokio.",
    DailyTakings_PoweredBy_label: "Powered by ",
    DailyTakings_ReadMore_action: "Read more about Zettle automatic imports",
    DailyTakings_SomethingWentWrongsActivation_error: "Something went wrong and your Zettle authorisation could not be completed.",
    DailyTakings_Status_Handled: "Handled",
    DailyTakings_StatusMessage_UnsupportedEventTypeAdjustment: "Unsupported event: Adjustment",
    DailyTakings_StatusMessage_UnsupportedEventTypeEmoneyTransfer: "Unsupported event: E-money transfer",
    DailyTakings_StatusMessage_UnsupportedEventTypeFeeDiscountRevocation: "Unsupported event: Fee discount revocation",
    DailyTakings_StatusMessage_UnsupportedEventTypeFrozenFunds: "Unsupported event: Frozen funds",
    DailyTakings_StatusMessage_UnsupportedEventTypeMissingCounterpart: "Unsupported event: Missing event in event pair",
    DailyTakings_StatusMessage_UnsupportedEventTypeUnknownEvent: "Unsupported event: Unknown ",
    DailyTakings_StatusMessage_UnsupportedEventTypeVoucherActivation: "Unsupported event: Voucher activation",
    DailyTakings_StatusMessage_UnsupportedPaymentMethod: "Unsupported payment method",
    DailyTakings_TryActivatingAgain_message: "Try activating again or ",
    DailyTakings_YesDisconnect_action: "Yes, disconnect",
    DATE: "Date",
    DateIssued: "Date issued",
    DateOfCashInvoice_label: "Date of cash invoice",
    DateOfCreditInvoice_label: "Date of credit note",
    DateOfInvoice_label: "Date of invoice",
    DateOfNextInvoice: "Date of next invoice",
    DAYS: "Days",
    DaysOverdue: "Days overdue",
    DEBT: "Debt",
    Decline_Action: "Decline",
    Deduction: "Deduction",
    Default: "Default",
    Default_Customer_Contact: "Default contact",
    Default_DeliveryTerms: "",
    Default_OverdueInterestRate: "",
    Default_PaymentTerms: "30",
    DELAYED_DEBT: "Delayed debt",
    DELAYEDINVOICES: "\n    ",
    DELAYFEE: "Overdue interest",
    DelayIntrestEmpty: "&lt;Interest on arrears missing&gt;",
    DELETE: "Delete",
    DELETE_PAYMENT: "The payment of {0} for this invoice will be deleted",
    DeleteArticle: "Delete item",
    DELETECONFIRM: "Are you sure you want to delete this invoice?",
    DeleteCustomer_button_action: "Delete customer",
    Deleted_ColHeading: "Deleted",
    DeleteInvoice_heading: "Delete invoice",
    DeleteInvoice_label: "Do you want to delete this invoice?",
    DeletePaymentModalText: "Are you sure you want to delete the selected payment? The payment of {0} for this invoice will be deleted.",
    DeletePaymentModalTitle: "Confirm delete",
    DeleteRecordingModalText_CashMethod: "The prepared recording will be removed and can be redone, any existing payments will not be altered.",
    DeleteRecordingModalText_InvoiceMethod: "Journal entry {0} will be deleted and the supplier invoice can then be recorded again, any existing payments will not be altered.",
    DeleteRecordingModalTitle_CashMethod: "Delete the prepared recording",
    DeleteRecordingModalTitle_InvoiceMethod: "Delete journal entry for supplier invoice",
    DeleteScheduledPaymentModalText: "The scheduled payment of {0} for this invoice will be cancelled.",
    DeleteScheduledPaymentModalTitle: "Do you want to cancel the scheduled payment?",
    DeleteVerificationsModalTitle: "Do you want to delete these journal entries?",
    DELIVER_CLIENT: "Deliver invoice to client",
    DELIVERY: "Delivery",
    Delivery_WasDownloaded: "The invoice was downloaded as a PDF when it was published",
    DELIVERYHISTORY: "Delivery history",
    DeliveryHistory_Billing: "Billing",
    DeliveryHistory_Billing_description: "The invoice was added to the company and email has been sent",
    DeliveryHistory_EmailSentAs_Link: "Link",
    DeliveryHistory_EmailSentAs_LinkAndPdf: "Link and attached PDF",
    DeliveryHistory_SentAs: "Invoice sent as",
    DeliveryHistory_SentAs_EInvoice: "E-invoice",
    DELIVERYMETHOD: "Method",
    DeliveryMethod_CustomerEmail: "Customer email",
    DeliveryMethod_CustomerEmails: "Customer email(s)",
    DeliveryMethod_DeliveryOptions: "Invoice delivery options",
    DeliveryMethod_Download_Description: "You can preview your invoice before publishing it.",
    DeliveryMethod_Download_Label: "Download the invoice as a PDF to send it by yourself.",
    DeliveryMethod_DownloadHeading: "Download PDF",
    DeliveryMethod_DownloadPublish: "Publish and download",
    DeliveryMethod_EInvoice_CannotSendEinvoiceUntilReceiverHasApproved: "The e-invoice can not be sent before the customer has approved you as a sender",
    DeliveryMethod_EInvoice_Description: "Send your invoice as e-invoice to companies",
    DeliveryMethod_EInvoice_Enable: "Activate e-Invoice",
    DeliveryMethod_EInvoice_Instructions1: "With e-invoice, your company can send invoices electronically to companies, government agencies, municipalities and county councils, both within Sweden and to other countries.",
    DeliveryMethod_EInvoice_Instructions2: "Please verify your company details to activate e-Invoice.",
    DeliveryMethod_EInvoice_MessageFromReceiver: "Message from receiver",
    DeliveryMethod_EInvoice_NoAllowToSend: "The receiver doesn't accept e-invoices from you",
    DeliveryMethod_EInvoice_PricingDescription: "within Sweden and EU/EES",
    DeliveryMethod_EInvoice_PricingInfo: "{0} per e-invoice",
    DeliveryMethod_EInvoice_ReadMore_Link: "https://www.bokio.se/hjalp/fakturera-kunder/skicka-faktura/e-faktura/",
    DeliveryMethod_EInvoice_Send: "Send invoice",
    DeliveryMethod_EInvoice_SendAsEInvoice: "Send as e-invoice",
    DeliveryMethod_EInvoice_Title: "E-invoice",
    DeliveryMethod_EInvoice_VerifyPaymentDetails: "Verify payment details",
    DeliveryMethod_EInvoice_VerifyPaymentDetailsAndActivate: "Verify and activate e-invoice",
    DeliveryMethod_EInvoice_WaitingForApproval: "Waiting for approval",
    DeliveryMethod_Email_Description: "We send the invoice by email to your client. The email will contains a link to view/download the invoice, provides better email tracking after sending.",
    DeliveryMethod_Email_EmailPreview_label: "Email preview",
    DeliveryMethod_Email_EnterPersonalMessage_placeholder: "Enter personal message (maximum 500 characters)",
    DeliveryMethod_Email_IncludePersonalMessage_checkbox: "Include personal message",
    DeliveryMethod_Email_Label: "Send your invoice as email and get track of your invoices.",
    DeliveryMethod_Email_SendWithLink: "Invoice as link to the PDF (Recommended for better tracking)",
    DeliveryMethod_Email_SendWithLinkAndPdf: "Invoice as link and an attached PDF (We can’t track the open status for attached PDF)",
    DeliveryMethod_EmailHeading: "Email to customer",
    DeliveryMethod_Financing: "Get paid faster!",
    DeliveryMethod_Financing_OldFactoringApi: "Get paid now!",
    DeliveryMethod_Free: "Free",
    DeliveryMethod_GetUpTo: "Get up to {0}",
    DeliveryMethod_heading: "Delivery method",
    DeliveryMethod_MissingCustomer: "You need to select a customer before using automatic invoicing, invoice financing or e-invoice.",
    DeliveryMethod_MissingCustomerRows: "You need to select a customer and add invoice rows before using automatic invoicing or invoice financing.",
    DeliveryMethod_PreviewEmail: "Preview Email",
    DeliveryMethod_PreviewPDF: "Preview PDF",
    DeliveryMethod_SellPublish: "Sell and publish",
    DeliveryMethod_SendApproval: "Send for approval",
    DeliveryMethod_SendPublish: "Publish and send",
    DELIVERYSTATUS: "Status",
    DeliveryStatus_Accepted: "Accepted",
    DeliveryStatus_Clicked: "Invoice opened",
    DeliveryStatus_label: "Delivery status",
    DeliveryStatus_MessageFromReceiver_heading: "Message from receiver",
    DeliveryStatus_Processing: "Processing",
    DeliveryStatus_Rejected: "Rejected",
    DeliveryStatus_Sent: "Sent",
    DELIVERYTERMS: "Terms of delivery",
    DeliveryTermsEmpty: "&lt;Delivery terms missing&gt;",
    DESCRIPTION: "Description",
    DETAILS: "Details",
    DIDYOUGETTHEEMAIL: "Did you get the email?",
    DIFFERENT: "Different",
    DifferentDeliveryMethodFromOriginalInvoiceSelected: "This delivery method does not match the original invoice. It should only be used when the credit note is not intended to be delivered to the customer or if Bokio support have instructed you to use this method.",
    DOITNOW: "Do it now",
    DONE: "Done",
    DOWNLOAD: "Download",
    Download_Publish_label: "Download and publish",
    DownloadCashInvoice: "Download cash invoice",
    DownloadCreditInvoice: "Download credit note",
    DownloadInvoice: "Download {0}",
    DownloadInvoice_Action: "(Click to download)",
    DownloadInvoicePDF: "Download Invoice PDF",
    DOWNLOADNOUN: "Download",
    DownloadPDF: "Download PDF",
    DownloadPDF_Heading: "Choose the PDF you want to download",
    DownloadPDFText: "Download your invoice as a PDF file to send to your client",
    DownloadQuote: "Download quote",
    DownloadQuotePDF: "Download Quote Pdf",
    Draft_ColHeading: "Draft",
    DRAFT_CONTINUE: "This invoice is still a draft. Send invoice to continue.",
    Draft_was_deleted: "Draft was deleted",
    DraftInvoiceHeader: "Draft",
    DueDate_ColHeading: "Due date",
    DueDateCannotBeBeforeInvoiceDate_error_message: "Due date cannot be before invoice date",
    ECommerce__Interest_Interested_CompletedText: "We will notify you when ecommerce is available.",
    ECommerce__Interest_Interested_DescriptionText: "We are working on bringing ecommerce support to Bokio. In the coming months we will start introducing ecommerce and reduce the time you spend on bookkeeping",
    ECommerce__Interest_Interested_InputText: "We would appreciate if you could tell us about what setup you have in terms of ecommerce platform and payment methods, and if you have any specific needs. We will use your feedback to develop this new feature.",
    ECommerce__Interest_Interested_TitleText: "Thanks for showing interest",
    ecommerce_banner_steps_introduction: "Connect to your ecommerce and get all sales automatically imported to Bokio. Save time with less manual work, make it easy to bookkeep correctly and get more control over your sales.",
    ecommerce_banner_steps_introduction_step_1: "Connect your ecommerce platform to Bokio",
    ecommerce_banner_steps_introduction_step_2: "Your orders will be synced automatically",
    ecommerce_banner_steps_introduction_step_3: "Get ready-made proposals for your bookkeeping and register with just a few clicks",
    ecommerce_bookkeeping_days_to_record_notice: "You have {0} invoice journals to record",
    ecommerce_bookkeeping_last_updated: "Last updated {0}",
    ecommerce_bookkeeping_modal_save_verification: "Record",
    ecommerce_bookkeeping_modal_verification_not_valid: "This verification cannot be saved, please check the details and try again",
    ecommerce_bookkeeping_suggestions_issues: "We are aware that ecommerce bookkeeping suggestions for foreign sales have some issues. If you have foreign sales please wait to record your sales, we are working on a solution to this problem. Please contact support@bokio.se if you have any questions.",
    ecommerce_concerned_about_using_your_real_company: "Don’t want to use your real company in Bokio?",
    ecommerce_concerned_about_using_your_real_company_text: "You can create a test company to try out the ecommerce feature without any concerns about disrupting your actual bookkeeping.",
    Ecommerce_connect: "Connect",
    ecommerce_connection_modal_button: "Connect to {0}",
    ecommerce_connection_modal_inner_text_1: "To ensure a smooth connection, Bokio needs to connect with &lt;b&gt;a user that can create API users with read access.&lt;/b&gt; Please confirm that your linked account has full access to be able to connect.",
    ecommerce_connection_modal_inner_text_2: "To make things easier, we recommend using your administrator account for {0} when connecting your store with Bokio. Once your account is connected, you're good to go!",
    ecommerce_continue_where_you_left_off: "Continue where you left off?",
    ecommerce_direct_payement_help_content_body_paragraph_2: "When recording direct payments in ecommerce we recommend using account {0}. Using {0} means that bank rows in &lt;b&gt;Transaction to Record&lt;/b&gt; can easily be recorded with a template.",
    ecommerce_direct_payment_help_content_body_paragraph_1: "Bokio imports sales and suggests journal entries to record.",
    ecommerce_direct_payment_help_content_tip_body: "When recording the bank rows you can use the template &lt;b&gt;Utbetalningar för Swish.&lt;/b&gt;",
    ecommerce_direct_payment_help_content_title: "Swish or other direct payment methods",
    ecommerce_disconnect_modal_content: "You are about to disconnect your ecommerce connection from Bokio. Before you disconnect please let us know the reason. This will be really helpful for us to improve the feature.",
    ecommerce_disconnect_modal_title: "Disconnect ecommerce?",
    ecommerce_feedback_banner: "We value your feedback! Our new Ecommerce integration is currently in beta, and we need your feedback. Share your thoughts with us today and contribute to making it even better!",
    Ecommerce_FirstBookkeepingRating_CompletedDescription: "Your feedback is essential to improving our services and we would love to hear more from you. [Book a convenient time](https://calendly.com/bokio-sales/intervju-med-bokio-ehandel) for an interview with us.",
    ECommerce_FirstBookkeepingRating_Description: "Any thoughts that could help us to improve the ecommerce feature?",
    ECommerce_FirstBookkeepingRating_Title: "How was your ecommerce experience?",
    ecommerce_guide_here: "getting started guide here",
    ecommerce_import_orders_from_shopify: "Import orders from Shopify",
    ecommerce_import_orders_from_woocommerce: "Import orders from Woocommerce",
    ecommerce_integration_select_another_platform: "Share your interest with us to add support for another platform",
    ecommerce_integration_select_block_codat_terms_part1: "By clicking the connect ecommerce button you do agree to the send data and setup a profile in the [Codat Platform](https://www.codat.io/powered-by-codat/). You can read [Codat account opening terms and conditions](https://www.codat.io/privacy-policy).",
    ecommerce_integration_select_block_codat_terms_part2: "You can view CODAT's privacy policy",
    ecommerce_integration_select_block_heading: "Select ecommerce platform",
    ecommerce_integration_select_block_subheading: "Start selecting the first ecommerce",
    ecommerce_integration_select_block_subheading_summary: "Our ecommerce integration is easy to setup and you don’t need help from an expert. If you want some extra guidance, read our",
    ecommerce_integration_select_block_summary: "Connect to your ecommerce to automatically import your orders and bookkeep easily.",
    ecommerce_integration_select_bokio_invoice_notice_body_paragraph_1: "Currently, we don't recommend using Ecommerce as it doesn’t support the use of Bokio Invoices (Don't worry, We're working on it.). Please contact support@bokio.se if you have this use case as we would appreciate understanding how we can support your business.",
    ecommerce_integration_select_bokio_invoice_notice_title: "Do you use Bokio Invoicing for your ecommerce sales?",
    ecommerce_integration_select_scene_another_platform_question: "Using another platform?",
    ecommerce_integration_select_stepper_step1: "Select Platform",
    ecommerce_integration_select_stepper_step2: "Configure Bookkeeping",
    ecommerce_integration_select_stepper_step3: "Payment accounts",
    ecommerce_integration_setup_import_date: "Import Date",
    ecommerce_integration_setup_import_note: "For now, you can only import orders from today onwards.",
    ecommerce_integration_setup_product_type_subtitle: "Define what kind of product you are selling. This will affect your bookkeeping later.",
    ecommerce_integration_setup_product_type_title: "Select your Product type",
    ecommerce_integration_setup_summary: "You need to choose whether your products are goods or services (or both). By defining the types of products you sell, we can produce more specific and correct accounting. For example, we can use profit and loss accounts that are specific to different VAT levels. ",
    ECommerce_Integration_Suggestion_Button: "Suggest Integration",
    ECommerce_Integrations_Main_Header: "Here you will find all of the currently available ecommerce integrations",
    Ecommerce_interest_recieved: "Thanks for your interest!",
    ecommerce_linking_failed_message: "Bokio could not connect to your ecommerce store, please try again.",
    ECommerce_Missing_Integration: "Do you have an integration that we are missing?",
    ECommerce_Missing_Integration_Description: "We are always looking at how we can improve the support we offer for ecommerce. Let us know which integration you have by clicking the button below!",
    ecommerce_new_payment_method_body: "We have detected {0} new payment methods. In order to correctly bookkeep please setup the new payment methods.",
    ecommerce_new_payment_method_title: "New payment method detected.",
    ecommerce_no_orders_yet_explanation: "No orders yet? Don't worry, you will be able to see all of the available orders soon.",
    ecommerce_no_orders_yet_question: "No orders yet?",
    ecommerce_nonsupportedVAT_message: "The invoice journal dated {0} includes VAT rates not currently supported by our bookkeeping templates.",
    Ecommerce_onboarding_modal_description: "Please take a moment and validate the configurations for your ecommerce bookkeeping.",
    ecommerce_order_lowercase: "order",
    ECommerce_OrderNumber: "Order number",
    ECommerce_Orders: "Orders",
    ecommerce_orders_lowercase: "orders",
    ECommerce_OrderSyncing_FooterNote: "*Order syncing started on {0}. Orders prior to this date are not included.",
    ecommerce_payment_method_dropdown_action_button_text: "Create new account",
    ecommerce_payment_method_dropdown_prompt: "Didn't find the account?",
    ecommerce_payment_method_list_header_bookkeeping_account: "Bookkeeping account",
    ecommerce_payment_method_list_header_payment_method: "Payment method",
    ecommerce_payment_methods_dropdown_not_found_subtitle: "You can easily create an account using create new account option",
    ecommerce_payment_methods_dropdown_not_found_text: "No Accounts found",
    ecommerce_payment_methods_dropdown_search_placeholder: "Search account",
    ecommerce_payment_methods_loading_message: "We are loading payment method from your ecommerce website.",
    ecommerce_payment_methods_scene_summary: "In order for payments to be registered correctly, you need to ensure that your various payment methods (e.g. Bank account, Swish, Stripe, etc.) are linked to the correct accounting accounts in Bokio.\n\nPayout and fees are recorded from **Transaction to Record** when you receive a payout. All you need to do when receiving a payout is to read the fee from a payout report. The fee is then added when recording the payout. [Read more about payment accounts](https://www.bokio.se/hjalp/integrationer/e-handel/bokfor-forsaljning-fran-din-e-handel/).",
    ecommerce_payment_methods_scene_title: "Setup your Payment Accounts",
    ecommerce_payment_processor_help_content_tip_body: "You can use either &lt;b&gt;Utbetalning med avgift&lt;/b&gt; or &lt;b&gt;Utbetalning med avgift (moms)&lt;/b&gt; template.",
    ecommerce_payment_processors_help_content_body_paragraph_1: "Bokio imports sales and suggests journal entries to record.",
    ecommerce_payment_processors_help_content_body_paragraph_2: "Payout and fees are recorded from &lt;b&gt;Transaction to Record&lt;/b&gt; when you receive a payout. So when you receive a payout all you need to do is read the fee from a payout report. The fee is then added when recording the payout.",
    ecommerce_payment_processors_help_content_body_paragraph_3: "For more details read this",
    ecommerce_payment_processors_help_content_title: "Payment processor methods (Klarna, Stripe, Paypal etc...)",
    Ecommerce_Pending_Uppercase: "Pending",
    Ecommerce_platform: "Platform",
    Ecommerce_product_type: "Product type",
    ecommerce_record_invoice_journal: "Record invoice journal",
    ecommerce_recording_is_temporarily_disabled: "Ecommerce recording is temporarily disabled. We are working to restore this feature as soon as we can",
    ecommerce_refund: "refund",
    ecommerce_refunds: "refunds",
    ecommerce_select_ecommerce: "Select ecommerce",
    ECommerce_Setting_Description: "Here you can change your Ecommerce settings",
    ECommerce_Settings: "Ecommerce",
    ecommerce_setup_integration_title: "Configure your bookkeeping settings",
    Ecommerce_setup_scene_settings_label: "Configuration",
    ecommerce_shopify_connection_modal_inner_text_1: "To enable Shopify connection, you will need to [add the bokio shopify app to your store](https://apps.shopify.com/bokio).",
    Ecommerce_Successfully_Connected: "Successfully connected",
    Ecommerce_Successfully_Connected_description: "Great news! Your {0} store has been successfully linked to Bokio. Continue with your setup process",
    ecommerce_successfully_connected_to_platform: "Successfully connected to {0}",
    ECommerce_SyncIssue_Notice_AuthError: "We're having trouble connecting to your store from Bokio at the moment. We recommend trying to re-link your connection. Please contact support@bokio.se if re-linking doesn't resolve the issue within a few hours.",
    ECommerce_SyncIssue_Notice_AuthError_Title: "Failed to connect to your store",
    ECommerce_SyncIssue_Notice_Default: "We have detected an issue when trying to sync your ecommerce store. Please contact support@bokio.se if you continue to see this message.",
    ECommerce_SyncIssue_Notice_Default_Title: "Experiencing an issue with your store",
    ECommerce_SyncIssue_Notice_FetchError: "We are experiencing problems trying to fetch data from your ecommerce store. Typically this happens when we are not allowed to communicate with your ecommerce store. Please verify that the firewall configuration at your website's host allows us to make requests from 20.77.137.247. Please contact support@bokio.se if you need further assistance.",
    ECommerce_SyncIssue_Notice_FetchError_Title: "Failed to retrieve data from the store",
    ECommerce_SyncIssue_Notice_MappingError: "The data in your ecommerce database is not in the format we expect. This can for example be caused by add-ons to your store. Please contact support@bokio.se to explore why this problem is occurring for you.",
    ECommerce_SyncIssue_Notice_MappingError_Title: "Discovered an unexpected format",
    ECommerce_SyncIssue_Notice_ValidationError: "Parts of the data read from your ecommerce store is invalid or contain inconsistencies. Please contact support@bokio.se to explore why this problem is occurring for you.",
    ECommerce_SyncIssue_Notice_ValidationError_Title: "Invalid or inconsistent data in your store",
    ECommerce_SyncIssue_Relink: "Re-link",
    ECommerce_TotalAmount: "Total",
    ECommerceIntegrationProductKindsUsed: "Product kinds used",
    ECommerceInterestBlock_ECommerceComingSoon_Badge: "Coming Soon",
    ECommerceInterestBlock_ECommerceComingSoon_Description: "Connect your ecommerce platform to Bokio to automatically record your ecommerce sales on a regular basis. Use this to save time and let you focus on developing your business",
    ECommerceInterestBlock_ECommerceComingSoon_Heading: "Automated bookkeeping from Ecommerce",
    ECommerceInterestBlock_FirstSellingPoint: "Connect your ecommerce platform to Bokio",
    ECommerceInterestBlock_Interested_Label: "Keep me updated",
    ECommerceInterestBlock_SecondSellingPoint: "Your orders are automatically imported",
    ECommerceInterestBlock_ThirdSellingPoint: "Bokio will record your ecommerce orders automatically",
    EDIT: "Edit",
    Edit_an_invoice: "Edit an invoice",
    Edit_Recurring_Invoice: "Edit recurring invoice",
    EditArticle: "Edit item",
    editCompanyModal_label: "Signed in as",
    EditCompanySettings_action: "Edit company",
    EDITCUSTOMER: "Edit customer",
    EditInvoice: "Edit invoice",
    EditRow_heading: "Edit row",
    EditTextTemplate: "Edit text template",
    EditYourCompanyInformation: "Edit invoice sender information, your company",
    EditYourCompanyInformationMobile: "Edit your company information",
    EInvoice: "E-Invoice",
    EInvoice_Activate_Terms: "By activating you are agreeing to share your company information to PEPPOL Access Points.",
    EInvoice_AddAdditionalFields_action: "Add additional fields",
    EInvoice_AdditionalFields_label: "Additional fields",
    EInvoice_AdditionalInformation_heading: "Additional information",
    EInvoice_BuyerReference_label: "Buyer reference",
    EInvoice_CannotRegisterParty_AlreadyConnected: "E-invoice is already activated for this company.",
    EInvoice_CannotRegisterParty_AlreadyRegistered: "Your company is already registered at Crediflow. Most likely you have activated e-invoice through another e-invoice system. Please contact support for further information, support@bokio.co.uk.",
    EInvoice_CannotRegisterParty_NotAvailableInThisRegion: "E-invoice is not available for the selected region. Please contact support.",
    EInvoice_CannotUpdateParty_UpdateFailed: "Can not register company. Please contact support.",
    EInvoice_ChooseReceiver_Feedback_DoesNotAllow: "The receiver you chose wont allow you to send e-invoices. Please try a different receiver or another delivery method.",
    EInvoice_ChooseReceiver_Feedback_RequestAccess: "A request will be sent to the receiver. You can send them e-invoices after they accept.",
    EInvoice_ChooseReceiver_Feedback_ResultsFound: "{n} receivers found for organisation number {org}",
    EInvoice_ChooseReceiver_FieldLabel: "Choose receiver",
    EInvoice_ChooseReceiver_Preamble: "Some companies require access request before you send the first e-invoice.",
    EInvoice_ChooseReceiver_SectionHeading: "E-invoice receiver details",
    EInvoice_ChooseSender_Feedback_DoesNotAllow: "The sender you chose doesn't support e-invoices. Please try a different sender.",
    EInvoice_ChooseSender_FieldLabel: "Choose e-Invoice supplier",
    EInvoice_ChooseSender_Preamble: "Some companies require access request before you can receive the first e-invoice.",
    EInvoice_ChooseSenderReceiver_Placeholder: "Choose one or search on GLN",
    EInvoice_Configure_Description: "Configure e-Invoice settings by going to {Settings » Configure e-Invoice}",
    EInvoice_ContractReference_label: "Contract reference",
    EInvoice_Deactivate: "Deactivate e-invoice",
    EInvoice_Deactivate_Description: "Are you sure you want to deactivate e-Invoice? You won’t be able to use e-Invoicing anymore.",
    EInvoice_DeliveryAddress_label: "Delivery address",
    EInvoice_DeliveryInformation_label: "Delivery information",
    EInvoice_DeliveryStatusApproved_Message: "The e-invoice has been successfully received by the recipient.",
    EInvoice_DeliveryStatusProcessing_Message: "The e-invoice has been submitted and is awaiting processing by our e-invoice service provider.",
    EInvoice_DeliveryStatusRejected_Message: "The e-invoice has been rejected by the receiver.",
    EInvoice_DeliveryStatusSent_Message: "The e-invoice has been sent from our e-invoice service provider to the recipient.",
    EInvoice_EditModal_Title: "Edit e-Invoice details",
    EInvoice_EInvoiceInformation_heading: "E-invoice information",
    EInvoice_FailedDeactivate: "Something went wrong when deactivating e-invoice for your company. Please contact support@bokio.co.uk",
    EInvoice_GetStarted_Description: "Get started with e-Invoice by going to {Settings » Configure e-Invoice}",
    EInvoice_GLN_FieldLabel: "GLN",
    EInvoice_GLN_IDontHaveANumber: "I don't have a GLN (Global Location Number)",
    EInvoice_GLN_ReadMore: "Read more about GLN",
    EInvoice_GLN_Tooltip: "A GLN — short for Global Location Number — is a number that identifies a business. Among other things, a GLN can identify a company, an organization, an invoice address, or a delivery location.",
    EInvoice_GLNInput_IDontHaveANumber: "I don't have a GLN",
    EInvoice_GLNInput_IHaveANumber: "I already have a GLN",
    EInvoice_GLNInput_Placeholder: "Enter your GLN",
    EInvoice_GLNInput_ReadMoreLink: "https://gs1.se/en/standards-and-services/global-location-number-gln/",
    EInvoice_GLNInput_Title: "Do you have GLN (Global Location Number)? (Optional)",
    EInvoice_IndividualsCantBeReceivers_Paragraph: "Currently this feature is only available for corporations.",
    EInvoice_InviteStatus_Sent: "Request sent on {0}",
    EInvoice_InviteSupplierButton: "Request for e-Invoices",
    EInvoice_InvoicePeriod_heading: "Invoice period",
    EInvoice_Message_Description: "Your customers will see this when they search for you in any system.",
    EInvoice_MessageFromSender: "Message from sender",
    EInvoice_NoPreferencesSelected_Activate: "Choose to either use e-Invoices for sending or receiving",
    EInvoice_NoPreferencesSelected_Edit: "You will not be able to send or receive e-Invoices. Your company will still be in the PEPPOL Access Points. To remove your company from the PEPPOL Access Points you need to deactivate e-Invoice.",
    EInvoice_Notice_InviteApproved: "Request approved. You will receive e-invoices from {supplier}",
    EInvoice_Notice_InviteDenied: "Request denied. Please try again.",
    EInvoice_Notice_InviteSent: "Your request is sent to {name} that you prefer to receive as e-Invoices. Once they start sending e-Invoices, you will get it directly inside Bokio. In case if you are not receiving as e-Invoices contact {name} directly.",
    EInvoice_OrderReference_label: "Order reference",
    EInvoice_PartyCouldNotBeFound_RegisterService: "Party could not be found",
    EInvoice_PartyCouldNotBeInvited_InviteFailed: "Request access could not be sent to receiver",
    EInvoice_PartyCouldNotBeRegistered_RegisterService: "Party could not be registered",
    EInvoice_PartyCouldNotBeUnregistered_RegisterService: "Party could not be unregistered",
    EInvoice_PaymentDetails_Title: "Payment details (to get paid for invoices you send)",
    EInvoice_PaymentMethod: "E-invoice payment method",
    EInvoice_PaymentMethodDetailsHasChanged: "Payment details for E-invoice has changed. It's now taken from your stored payment methods, under Company Settings.",
    EInvoice_PostInvoiceFailed_PostFailed: "The e-invoice could not be sent",
    EInvoice_ProjectReference_label: "Project reference",
    EInvoice_ReceiverStatus_DoesNotAllow: "Access denied",
    EInvoice_ReceiverStatus_Permits: "Access required",
    EInvoice_ReceiverStatus_Prefer: "Prefer",
    EInvoice_ReceivingMessage_Placeholder: "E.g we accept e-Invoices from everyone. Make sure to provide a valid reference number.",
    EInvoice_SearchPartyFailed_SearchFailed: "Search failed",
    EInvoice_SendingMessage_Placeholder: "E.g we are happy to send e-Invoices to all our business customers.",
    EInvoice_SharingInfoWithPeppol_Description: "By activating e-Invoice you are agreeing to share all the above information to PEPPOL Access Points.",
    EInvoice_Supplier_ActiveAndEnableReceiving: "To receive e-Invoices directly in Bokio, make sure you have activated your e-Invoice and enabled receiving e-Invoices.",
    EInvoice_Supplier_NotFoundSupplier: "To receive e-Invoice you must request your supplier to send upcoming invoices as e-Invoices. Unfortunately {name} is not registered in PEPPOL system.",
    EInvoice_Supplier_OrgNumberTooltipMessage: "The supplier is created by an e-invoice",
    EInvoice_Supplier_RequestSupplierToSend: "To receive e-Invoice you must request your supplier to send upcoming invoices as e-Invoices.",
    EInvoice_SupplierEmailToSendInvitation: "Supplier's email to send request to",
    EInvoice_SupportEInvoice: "{0} supports e-Invoices",
    EInvoice_UseEInvoiceFor_NoPreferencesActive: "You need to activate e-Invoices for sending or receiving to start using e-Invoices",
    EInvoice_UseEInvoiceFor_Title: "Use e-Invoice for",
    EInvoice_Validation_OneOfPayments: "At least one of the e-invoice payment methods must be filled in",
    EInvoice_WhatHappensNow: "What happens now?",
    EInvoiceDetails: "E-Invoice details",
    EInvoiceDiscoverabilityBanner_Description_Variant_Supplier: "Manage your supplier invoices safer and quicker by using our e-invoice feature. All invoice details such as OCR number, payment date, and amount are filled in automatically so that nothing can go wrong.",
    EInvoiceDiscoverabilityBanner_Description_Variant_SupplierInvoice: "Manage your supplier invoices safer and quicker by using our e-invoice feature.",
    EInvoiceDiscoverabilityBanner_Title_Variant_Supplier: "Receive e-invoices from your suppliers – get started now!",
    EInvoiceDiscoverabilityBanner_Title_Variant_SupplierInvoice: "New: Receive e-invoices directly in Bokio!",
    EInvoiceReceived: "E-Invoice received",
    EInvoices_CannotUpdateParty_UpdateFailed: "Cannot register your company. Please contact support.",
    EMAIL: "Email",
    Email_Preview_Download: "Having trouble viewing the {0}? Download the",
    Email_Preview_Download_Link: "PDF here.",
    Email_Preview_Note: "*Please note that this is just a preview of what the email will look like for your customer. The button and link will work once it's sent.",
    EMAILARRIVED: "I got it",
    EmailDeliveryFailed: "Failed to send invoice to the specified e-mail.",
    EMAILDIDNTARRIVE: "The email didn't arrive",
    EmailedTo_label: "Emailed to",
    EmailEmpty: "&lt;Email address missing&gt;",
    Emails: "emails",
    EmailSent: "Email sent",
    EnableRoundedSum: "Enable rounded amount",
    Error_AllRowsZero: "The invoice must contain at least one row with a non-zero amount",
    Error_ChooseRecipient: "Please choose a customer to receive the invoice",
    Error_CouldNotGenerateIbanReference: "Could not generate Iban Reference for {0} and {1}",
    Error_CustomerLacksInformation: "Please enter all necessary information about the customer",
    Error_DueBeforeIssued: "Please ensure the due date occurs after the issue date",
    Error_Generic: "An unexpected error occurred. If this problem persist, please contact support. ",
    Error_MessageFromCrediflow: "Message from Crediflow",
    Error_MissingDate: "Please select issue date and due date",
    Error_UserCompanyLacksInformation: "Please enter all necessary information about your company",
    ExchangeRate_WarningMessage_Unavailable: "The exchange rate for the foreign currency you are trying to convert to is currently unavailable. Please try refreshing the exchange rate or enter it manually.",
    ExchangeRateUnavailable: "Exchange rate is unavailable.",
    ExcludingVAT: "Excluding VAT",
    ExpandAll: "Expand all",
    Export_DataFormat_Label: "Data to include in the export",
    Export_DataFormat_Row: "Row details",
    Export_DataFormat_Summary: "Summary of each invoice",
    Export_DateFilter_AllDates: "All dates",
    Export_DateFilter_CustomDates: "Custom dates",
    Export_DateFilter_Label: "Filter invoices by date",
    Export_Info: "The export will only include currently visible invoices. Change the filtering of the list to include others.",
    Export_ModalTitle: "Export invoices",
    ExternalInvoice_VerificationTitle_PaymentOf: "Payment of",
    Factoring_Actions_PaymentFromCustomerToProvider: "Register payment from customer to {0}",
    Factoring_Actions_PaymentFromCustomerToProvider_condensed: "... from customer to {0}",
    Factoring_Actions_PaymentFromCustomerToYou: "Register payment from customer to you",
    Factoring_Actions_PaymentFromCustomerToYou_condensed: "... from customer to you",
    Factoring_Actions_PaymentFromProvider: "Register payment from {0} to you",
    Factoring_Actions_PaymentFromProvider_condensed: "... from {0} to you",
    Factoring_Activated_Popover_Fees: "No fixed cost and no lock-in period",
    Factoring_Activated_popover_message: "You've been invited to use invoice financing in Bokio! You can activate the service under settings and add-ons. ",
    Factoring_Activated_Popover_Payment: "Quick payments",
    Factoring_Activated_popover_Readmore: "Read more",
    Factoring_Activated_Popover_Terms: "Favorable terms",
    Factoring_AfterPublish_Brief: "You have now sold your invoice and will be paid {0}",
    Factoring_AfterPublish_Deposition: "Payment when your customer have paid",
    Factoring_AfterPublish_SentForReview_Title: "Invoice sent for review",
    Factoring_AfterPublish_SolidInvoice: "You have sold your invoice!",
    Factoring_Ativo_SupportMail: "kund@fg.se",
    Factoring_BookkeepCreditFactoring: "Record crediting of sold invoice",
    Factoring_BookkeepFactoring: "Record factoring",
    Factoring_BookkeepingInfo_instructions: "Now that your invoice is created you will need to record it as normal. Because you have sold your invoice you will also need to record this transaction.",
    Factoring_BookkeepingInfo_MoreInfo_action: "More details of the process",
    Factoring_BookkeepingInfo_PaymentFromCustomer_instructions: "When Factoringgruppen has received a payment from your customer they will notify you by email and you will need to register that payment here.",
    Factoring_BookkeepingInfo_PaymentFromProvider_instructions: "When you have received a payment for the financed amount you need to register that amount as a payment using the button at the top of this page.",
    Factoring_ContinueOnboarding: "Continue onboarding",
    Factoring_CreditedFeeIncVAT: "Credited fee (inc VAT)",
    Factoring_CreditedSoldVolume: "Credited amount",
    Factoring_CreditingWithOtherDeliveryMethod: "You are about to credit an invoice financed by Factoringgruppen. After publishing this credit note don't forget to notify {0} that the invoice should be credited.",
    Factoring_DeletePayment_Description: "Delete payment from {0} to you at {1} with amount {2}",
    Factoring_DeletePayment_Title: "Delete payment from factoring provider",
    Factoring_Error_ChangeOrgNumber: "Sender of this invoice does not match the activation of Invoice financing",
    Factoring_Error_CompanyHasNoFactoringSubscriptionItem: "Company has no factoring subscription, contact {0} for more help.",
    Factoring_Error_CustomerCompanyOrgNumberMissing: "The customers organization number is required for Invoice Financing. You can set this under Edit customer.",
    Factoring_Error_CustomerPrivatePersonalNumberMissing: "The customer's personal identity number is required for Invoice Financing. You can set this under Edit customer.",
    Factoring_Error_InvoiceDateSetToFuture: "Invoice date can't be set to a future date.",
    Factoring_Error_InvoiceDateSetToPast: "Invoice date can't be set to a date more than five days in the past.",
    Factoring_Error_UnableToSellDueToInternalError: "Unable to sell invoice due to an internal error",
    Factoring_Evaluation_Progress_Notice: "Factoringgruppen will update you after the evaluation using {0}",
    Factoring_Evaluation_Progress_Notice_Title: "Invoice evaluation in progress",
    Factoring_Evaluation_Success_Modal: "The Factoringgruppen will update you after the evaluation",
    Factoring_Evaluation_Success_Modal_Title: "We successfully received your request",
    Factoring_FeeIncVAT: "Fee to {0} (inc VAT)",
    Factoring_InvoiceWasSoldTo: "Invoice was sold to {0}",
    Factoring_Onboarding_BankAccountNumber: "Bank account number",
    Factoring_Onboarding_BankClearningNumber: "Clearning number",
    Factoring_Onboarding_BankID_Title: "Almost done! Please confirm with your BankID",
    Factoring_Onboarding_CompanyAlreadyActiveInAtivo: "The company you are trying to register is already active in Factoringgruppen and cannot be activated through Bokio. Contact {0} for more help.",
    Factoring_Onboarding_CompanyDetails_Title: "Submit company information",
    Factoring_Onboarding_CompletedInformation: "If you have completed the onboarding process with Factoringgruppen, there may be a slight delay before you can start using factoring in Bokio.\nIf you have any questions regarding the onboarding, you can contact {0}.",
    Factoring_Onboarding_ConfirmAndGoToAtivo: "Confirm and go to Factoringgruppen",
    Factoring_Onboarding_Confirmation: "Confirmation",
    Factoring_Onboarding_ConfirmationInformationLabel: "When confirming you will be taken to our partner Factoringgruppen to finish the onboarding.",
    Factoring_Onboarding_CreditCheckDisclaimer: "When you start the process to activate factoring, a credit check will be made.",
    Factoring_Onboarding_ErrorMessageFromAtivo: "Error message from Factoringgruppen:",
    Factoring_Onboarding_ErrorWhenGettingData_GenericMessage: "An error occurred while retrieving data from Factoringgruppen. Please contact {0} if you keep having problems.",
    Factoring_Onboarding_Failure_Description: "Your sign up could not be approved by Factoringgruppen. You can contact support@bokio.co.uk if you have questions to why this failed.",
    Factoring_Onboarding_Failure_Title: "You can not sign up",
    Factoring_Onboarding_Flow_Description: "A person from Factoringgruppen will contact you within 1 working day to complete the signup. You’ll be asked to confirm your bank account and sign the deal with BankID in this step. By clicking Submit I approve that the information is being sent to Factoringgruppen.",
    Factoring_Onboarding_Flow_Title: "What happens when I submit?",
    Factoring_Onboarding_GoToInvoices: "Go to invoices",
    Factoring_Onboarding_Heading: "Sign up for Invoice purchase",
    Factoring_Onboarding_Help: "When you sell your invoice through Bokio, you will usually be paid withing 24 hours after your invoice is approved by Factoringgruppen. Our partner Factoringgruppen will be buying the invoice from you from a rate of 2,75% of the invoiced amount. This is only the sign up for invoice financing. No invoices will be sold in these steps.",
    Factoring_Onboarding_Help_Old: "When you sell your invoice through Bokio you will usually be paid within 24 hours of publishing your invoice. Our partner Factoringgruppen will be buying the invoice from you from a rate of 2,75% of the invoiced amount. This is only the sign up for invoice financing. No invoices will be sold in these steps.",
    Factoring_Onboarding_InvoiceFinancing: "Invoice financing",
    Factoring_Onboarding_KYU_AML: "AML (Anti-Money Laundering) is international rules and laws that have been created to counter money laundering and financing of terrorism. Factoringgruppen, which is our partner for invoice purchases, must have processes to identify and counteract this. These issues are intended to do so.",
    Factoring_Onboarding_KYU_AmountTurnover: "This information is taken from your projected turnover in fiscal year settings",
    Factoring_Onboarding_KYU_AnnualAmount: "How much is your predicted turnover the coming 12 months?",
    Factoring_Onboarding_KYU_BusinessOutside: "Do you do business outside of Sweden?",
    Factoring_Onboarding_KYU_CollateralToCreditor: "What collateral did you give to your current creditor?",
    Factoring_Onboarding_KYU_Country: "Country of citizenship",
    Factoring_Onboarding_KYU_Funding: "Do you have any funding today?",
    Factoring_Onboarding_KYU_HasPep: "Is any of your company's owners, board members or any \"verkliga huvudmän\" a politically exposed person (PEP)?",
    Factoring_Onboarding_KYU_HasPep_Hint: "What is a Politically Exposed Person (PEP)? A politically exposed person is a person who has or has had important public functions in a state or in an international organization.\n\nWhy do we ask the question?\nAccording to the requirements of the Money Laundering Act, we must ask questions if you are a person in a politically exposed position. A person in a politically exposed position, family members and known employees of this person, is considered by his position and influence to have a position that poses a risk of being exploited for, among other things, bribe crimes.",
    Factoring_Onboarding_KYU_HowSellProduct: "In what way do you sell your products or services?",
    Factoring_Onboarding_KYU_KindOfBusiness: "What does your business provide?",
    Factoring_Onboarding_KYU_Location: "To which country/countries?",
    Factoring_Onboarding_KYU_MajorityOwners: "Does your company have shareowners? In this case, indicate the individuals who own 25% or more in the company",
    Factoring_Onboarding_KYU_MonthlyAmount: "How many invoices (amount) do you think you will sell to us per month, on average?",
    Factoring_Onboarding_KYU_MonthlyValue: "How much in total invoice value do you think you will sell to us per month?",
    Factoring_Onboarding_KYU_NeedPermissionToConduct: "Does your company need any special permission from an authority to conduct your business?",
    Factoring_Onboarding_KYU_OwnPercent: "Ownage in percentage",
    Factoring_Onboarding_KYU_PartialOwners: "Are there other people who directly, or indirectly, control the company but who are not co-owners or board members (these people are sometimes referred to as \"verkliga huvudmän\")?",
    Factoring_Onboarding_KYU_PartialOwners_Hint: "En verklig huvudman är den eller de personer som ytterst äger eller kontrollerar exempelvis ett företag eller en förening. Läs mer om verklig huvudman på bolagsverket.se",
    Factoring_Onboarding_KYU_ProductOfBusiness: "What do you sell to your customers?",
    Factoring_Onboarding_KYU_PurposeOfBusiness: "Why do you want to activate Invoice purchase?",
    Factoring_Onboarding_KYU_SellRoutine: "How often do you estimate you will sell invoices?",
    Factoring_Onboarding_KYU_Title: "We need some information about your ownership. We are obligated by law to ask this to try to prevent money laundry.",
    Factoring_Onboarding_KYU_YourCustomers: "Who are your customers?",
    Factoring_Onboarding_ManualProcessingNotice: "Your sign up is processed by Factoringgruppen and you will under normal circumstances soon be ready to sell invoices",
    Factoring_Onboarding_Missing: "No invoice financing onboarding was found for this company",
    Factoring_Onboarding_PartialOwner: "Principals",
    Factoring_Onboarding_Payment: "To where should payments be sent?",
    Factoring_Onboarding_PEP: "Politically exposed person",
    Factoring_Onboarding_SendToAtivo: "Sent to Factoringgruppen",
    Factoring_Onboarding_ShareOwner: "Shareowner",
    Factoring_Onboarding_Shares: "Shares",
    Factoring_Onboarding_SignHint: "The service is free to use for sending the invoice (except for terminating the debt collectoion) and your customer only pays any additional reminders or claims by law.",
    Factoring_Onboarding_SignUpGoBackModalHeader: "Are you sure you want to go back?",
    Factoring_Onboarding_SignUpGoBackModalInfo: "By going back to previous step your processes on Factoringgruppen will be lost and will be needed to be refilled. Are you sure you want to go back?",
    Factoring_Onboarding_SignUpInAtivo: "Sign up in Factoringgruppen",
    Factoring_Onboarding_SignUpInformation: "Our partner Factoringgruppen will be buying the invoices from you.\nGo to Factoringgruppen and complete all the steps to sign up for invoice purchase in Bokio. Use the link to go there.",
    Factoring_Onboarding_Success_Description: "You are soon ready to get started selling your invoices. A person from Factoringgruppen will contact you within one working day to complete the signup. You’ll be asked to confirm your bank and sign the deal with BankID in this step.",
    Factoring_Onboarding_Success_Title: "Awesome!",
    Factoring_Onboarding_SystemUnavailable: "An error occurred when sending to Factoringgruppen. Your answers have been saved, try again later. Please contact {0} if you keep having problems with sending the request.",
    Factoring_Onboarding_TC: "I wish to activate factoring and I agree to the {Terms and Conditions}",
    Factoring_Onboarding_YouCanSellInvoicesNowHeader: "Awesome, you can sell invoices now!",
    Factoring_Onboarding_YouCanSellInvoicesNowText: "Now you are ready to sell invoices. You sell your invoices by choosing invoice purchase in the delivery method when creating an invoice.",
    Factoring_Options_CashBuffer: "I want a cash buffer on the corporate account",
    Factoring_Options_CollateralCompanyChattelPledge: "Company chattel pledge",
    Factoring_Options_CollateralGuarantee: "Guarantee",
    Factoring_Options_CollateralNo: "I have not left any colleteral",
    Factoring_Options_CollateralOther: "Other",
    Factoring_Options_Company: "Company",
    Factoring_Options_CustomerDontPayInTime: "My clients sometimes do not pay on time",
    Factoring_Options_CustomerRequiredLongPayTerms: "My customers require long payment terms and my company gets to \"act bank\"",
    Factoring_Options_EveryDay: "Every day",
    Factoring_Options_EveryMonth: "Every month",
    Factoring_Options_EveryMoreSeldom: "More seldom",
    Factoring_Options_EveryQuarter: "Every quarter",
    Factoring_Options_EveryWeek: "Every week",
    Factoring_Options_ExpansivGrowth: "To avoid having to tie up money because my company is in a growth phase",
    Factoring_Options_Factoring: "Factoring",
    Factoring_Options_InventoryFinancing: "Inventory financing",
    Factoring_Options_InvestmentLoan: "Investment loan",
    Factoring_Options_LargeContractRequireLiquidity: "To be able to free up cash when my company gets a big deal",
    Factoring_Options_Leasing: "Leasing",
    Factoring_Options_LossesAffectingLiquidity: "My company has made losses, which means we are short of cash",
    Factoring_Options_OnlineServicePortal: "Through service or product portal online",
    Factoring_Options_OnlineStore: "In online store",
    Factoring_Options_OrderFinancing: "Order financing",
    Factoring_Options_Other: "Other",
    Factoring_Options_Overdraft: "Overdraft",
    Factoring_Options_PersonalSales: "Through personal sales",
    Factoring_Options_PhysicalStore: "In physical store",
    Factoring_Options_PrepareSeasonalVariations: "My company has seasonal variations and sometimes needs cash / liquidity supplements",
    Factoring_Options_Private: "Private",
    Factoring_Options_PrivateFunds: "Private funds",
    Factoring_Options_PrivateProcurement: "Through private procurement",
    Factoring_Options_Products: "Products",
    Factoring_Options_ProductsAndServices: "Products and services",
    Factoring_Options_PublicProcurement: "Through public procurement",
    Factoring_Options_PublicSector: "Public sector",
    Factoring_Options_ReduceAdministrationAndReminder: "I want to reduce the company's invoice administration and reminders",
    Factoring_Options_Services: "Services",
    Factoring_Options_Subscription: "Subscription",
    Factoring_Options_SupplierRequireShortPayTerm: "My suppliers require short payment terms",
    Factoring_PayoutType_Label: "Payout type",
    Factoring_Publish_CannotSell_AdministerOnly: "This invoice could not be financed automatically, for more information please contact {0}.",
    Factoring_Publish_CannotSellInvoice: "This invoice cannot be financed. Reason: {0}. For more information contact {1}",
    Factoring_Publish_CannotSellInvoice_Title: "Invoice can not be financed",
    Factoring_Publish_GetUpTo: "Get up to",
    Factoring_Publish_GetUpTo_Description: "for your invoice upfront",
    Factoring_Publish_LoadingProgress: "Sending invoice to Factoringgruppen",
    Factoring_Publish_Postpone: "Your company is not eligible to publish invoices via our financing service, contact us if you think it is incorrect.",
    Factoring_Publish_ReadMore: "Read more about invoice financing",
    Factoring_Publish_RequestEvaluation: "Request evaluation",
    Factoring_Publish_SellBrief: "After your invoice is approved, you can get up to {0} upfront paid to your account from Factoringgruppen. Upfront payments are typically paid out within 24 hours.",
    Factoring_Publish_SellBrief_Description: "Depending on your customer's solvency, the upfront amount might be lowered. The remaining amount will be paid out once the customer has paid Factoringgruppen.",
    Factoring_Publish_SellBrief_OldFactoringAPI: "Sell your invoice and get up to {0} upfront paid to your account from Factoringgruppen. Upfront payments are typically paid out within 24 hours.",
    Factoring_Publish_Submit: "Sell and publish",
    Factoring_Publish_TAC: "Terms and conditions",
    Factoring_Publishing_Benefits_Brief: "When you sell your invoice through Bokio, you will usually be paid within 24 hours after your invoice has been approved. Our partner, Factoringgruppen, will be approving and buying the invoice from you.",
    Factoring_Publishing_Benefits_Brief_Old: "When you sell your invoice through Bokio you will usually be paid within 24 hours of publishing your invoice. Our partner Factoringgruppen will be buying the invoice from you.",
    Factoring_Publishing_Benefits_P1: "Receive payments faster to your account",
    Factoring_Publishing_Benefits_P1_Old: "Payments instantly paid to your account",
    Factoring_Publishing_Benefits_P2: "Guided payment registration and accounting",
    Factoring_Publishing_Benefits_P3: "Getting started is free",
    Factoring_Publishing_Benefits_P4: "Activated with BankID",
    Factoring_Publishing_Decline: "The invoice financing company declined to buy your invoice",
    Factoring_Publishing_Heading: "Get paid instantly!",
    Factoring_Publishing_NotAvailable: "Factoring system is not available right now",
    Factoring_ReceivedFactoringPayment: "Received payout of loan from {0}",
    Factoring_ReceivedRemainingFactoringPayment: "Received remaining payout from {0}",
    Factoring_ReceivedRemainPayout: "Received following payout of {0}",
    Factoring_RemainPayoutAfterFullPayment: "Remaining payout from Factoringgruppen after full payment from customer",
    Factoring_Request_Evaluation_Button: "Send Request",
    Factoring_Request_Evaluation_Explained: "By requesting manual evaluation Factoringgruppen will personally look over if they can finance this invoice. When that is done Factoringgruppen will contact you through email to inform you of the result.",
    Factoring_Request_Manual_Evaluation: "Request manual evaluation",
    Factoring_ReviewModal_Description: "Factoringgruppen will get back to you with a decision. They usually respond within 24 hours.",
    Factoring_SellInvoiceBanner: "{New feature!} Now you can sell your invoices through Bokio and get paid instantly.",
    Factoring_SellInvoiceReversed: "The selling of invoice was reversed",
    Factoring_SellYourInvoice_heading: "Sell your invoices",
    Factoring_SoldVolume: "Amount sold",
    Factoring_SumReceived: "Amount received",
    Factoring_SystemUnavailable: "The invoice financing service is currently out-of-service. Please try again later.",
    Factoringgruppen_Support: "Factoringgruppen support",
    FactoringOnboarding_AtivoFinansContractDetails: "Here are the details for your contract with Factoringgruppen",
    FactoringOnboarding_CouldNotGetTermsConditions_error: "Something went wrong and we could not load the terms and conditions from Factoringgruppen",
    FactoringOnboarding_GoBackMessageDetails: "By going back your contract from Factoringgruppen will be cancelled and will need to be requested again. Do you want to go back?",
    FactoringOnboarding_LoadingContractAtivo_message: "Creating your contract offer from Factoringgruppen...",
    FactoringRejectedHistory_Description: "For more information, contact Factoringgruppen directly.",
    FactoringRejectedHistory_Title: "Invoice can't be financed",
    FeatureBanner_Customise_action: "Customise",
    FeatureBanner_CustomiseInvoice_Heading: "Customise your invoice layout",
    FeatureBanner_CustomiseInvoice_message: "Add a logo, brand colour and change your layout. It can always be changed later from Settings.",
    FeedbackModal_InvoiceReports_message: "Feedback on this page?",
    FILTERARTICLES: "Filter",
    FilterInvoiceState_All: "All states",
    FilterInvoiceType_All: "All types",
    FINISHYOURREGISTRATION: "Finish your registration",
    ForeignCustomer: "Foreign customer",
    GetStarted_action: "Get started",
    GiveBetaFeedback: "Give feedback",
    GLN: "GLN (Global Location Number)",
    Help_Article: "help article",
    HI: "Hello!",
    HIDE: "Hide",
    HideEmailPreview: "Hide email preview",
    HousingDesignationOrgNumber: "Org no. of housing designation",
    HOW_TO: "How to?",
    IACCEPT: "I accept",
    Import: "Import",
    ImportArticles_Articles: "Import {number} items",
    ImportArticles_DuplicatedProductCode: "This product code is used by another item",
    ImportArticles_FailToImportedArticle: "Try again to import the remaining items.",
    ImportArticles_Heading: "Import items",
    ImportArticles_NotValidKind: "Type must contain {services} or {goods}",
    ImportArticles_NotValidUnit: "The unit is not supported. Available units can be viewed in the dropdown.",
    ImportArticles_NumberOfImportedArticle: "{number} of {total} are imported successfully",
    ImportArticles_NumberOfImportedArticle_Description: "Imported items will be available in your items list.",
    ImportArticles_PasteData_Sample: "This is the properties and format we use on the items in Bokio:",
    ImportedInvoice_AccountsReceivable_Success_message: "You can also find your imported invoices under {0} in the menu where you can register and record upcoming payments.",
    ImportInvoice_button: "Import invoice",
    ImportInvoice_Notes_Placeholder_message: "Enter information about what this invoice was for. This will make it easier to find later",
    IncludingVAT: "Including VAT",
    INVOICE: "invoice",
    Invoice_AdditionalFields_InfoText_text: "Some invoice receivers require additional information when you send an invoice. Please make sure to check the specific requirements of your receiver before sending, as they may reject the invoice if any information is missing.",
    Invoice_amount_label: "Invoice amount (inc VAT)",
    Invoice_BusinessAccountPromotion_CTA: "Get started with Bokio Business Account",
    Invoice_BusinessAccountPromotion_Description: "To get automatic registration of invoice payments activate your Bokio Business Account.",
    Invoice_Created_From_Quote_Message: "Created from quote {0}",
    Invoice_delivery_options_desc: "invoice",
    Invoice_Email_Preview: "Email preview",
    INVOICE_NUM: "Inv. No.",
    Invoice_ShouldBeBookkept: "Should be recorded",
    InvoiceAlreadyConnectedTo_label: "Invoice {number} is already connected to {this invoice}",
    InvoiceAmount_Label: "Amount",
    InvoiceBookkeeper_EnsureNotBookkeptManually: "Please make sure you have not already recorded these items.",
    InvoiceBookkeeper_Error_DefaultMessage: "Something went wrong when accounting this row",
    InvoiceBookkeeper_Error_WithMessage: "Could not register journal entry:",
    InvoiceBookkeeper_ShowList_Action: "Record these",
    InvoiceCreatedInBokio: "Invoice created in Bokio",
    InvoiceCreditedSum: "Credited amount",
    InvoiceCredits: "Credits invoice",
    InvoiceCurrency: "Invoice currency",
    INVOICEDATE: "Date of invoice",
    INVOICEDEADLINE: "Due date",
    InvoiceDelivery_EmailMessagePreview_message: "{0} sent you {1} ({2}) for {3}, due on {4}",
    InvoiceDelivery_EmailMessagePreview_message_CashInvoice: "{0} sent you a cash invoice ({1}) with the paid amount of {2}",
    InvoiceDelivery_EmailMessagePreview_message_CreditInvoice: "{0} sent you a credit note ({1}) for {2}",
    InvoiceDetails_Accounting_Heading: "Accounting",
    InvoiceDetails_CreateCreditNote_DateLabel: "Date of credit note",
    InvoiceDetails_CreateCreditNote_Success: "The invoice has been credited!",
    InvoiceDetails_CreateCreditNote_Text: "You will create a credit note.",
    InvoiceDetails_CreateReminder_Action: "Create reminder",
    InvoiceDetails_DeletePayment_Success: "The payment was deleted!",
    InvoiceDetails_DeletePayment_Text_Pt1: "Journal entry",
    InvoiceDetails_DeletePayment_Text_Pt2: "will be deleted as well.",
    InvoiceDetails_DeleteVerifications_Success: "Your accounting has been corrected.",
    InvoiceDetails_EditInvoice_Action: "Edit invoice",
    InvoiceDetails_Payment_Bookkeep_Action: "Record",
    InvoiceDetails_Payment_BookkeepNow_Action: "Record now",
    InvoiceDetails_Payment_InputAmountFromBankStatement: "Insert the amount from your bank statement",
    InvoiceDetails_Payment_Success: "The payment has been saved!",
    InvoiceDetails_Reminder_DeliveryMethod_Label: "Delivery method",
    InvoiceDetails_Reminder_DownloadFile_Action: "Download file",
    InvoiceDetails_Reminder_Fee_Info: "In Sweden, you have a statutory right to claim a fee of up to 60 SEK in the event of non payment if the recipient is a private individual, or up to 450 SEK if it is a company.",
    InvoiceDetails_Reminder_Fee_Label: "Reminder fee",
    InvoiceDetails_Reminder_Heading_CreateReminder: "Create reminder",
    InvoiceDetails_Reminder_Heading_SendReminder: "Send reminder",
    InvoiceDetails_Reminder_HintSendReminderWithDifferentMethod: "It might be a good idea to send the reminder in another way than the original invoice. The non-payment could be the effect of the invoice not being properly delivered to the recipient.",
    InvoiceDetails_Reminder_Message_Label: "Message",
    InvoiceDetails_Reminder_SendByEmail_Option: "Send by email",
    InvoiceDetails_Reminder_SendEmailNow_Action: "Send now",
    InvoiceDetails_Reminder_Success: "The reminder has been registered",
    InvoiceDetails_UndoPublish_Success: "The invoice has been reverted to a draft!",
    InvoiceDetails_UndoPublish_Text: "When an invoice is unpublished it will delete the current invoice. Are you sure you want to undo publish?",
    INVOICEFEE: "Invoice fee",
    InvoiceFinancingVerificationTitle_CreditFactoring: "Reverting sold invoice",
    InvoiceFinancingVerificationTitle_FactoringWithRecourse: "Sold invoice #{0} for {1}",
    InvoiceFinancingVerificationTitle_PaymentFromFactoringProvider: "Factoring provider paid {1} for invoice #{0} ",
    InvoiceHeader: "Invoice",
    InvoiceHistory_EmailCouldNotBeSent_message: "Email could not be sent",
    InvoiceHistory_FromBokioAccount: "from Bokio Business Account",
    InvoiceHistory_Overpaid: "Overpaid",
    InvoiceHistory_ToBokioAccount: "to Bokio Business Account",
    InvoiceHistory_ToStripeAccount: "to your Stripe account",
    InvoiceImported_label: "Invoice was imported",
    InvoiceInsights_heading: "Invoice insights",
    InvoiceIssued: "Invoice issued",
    InvoiceMail_BillingInvoiceSubject: "You have a new invoice from Bokio!",
    INVOICENO: "Invoice number",
    INVOICENOMAXERR: "The maximum invoice number allowed is",
    INVOICENOMINERR: "The minimum invoice number allowed is",
    InvoiceNum: "Invoice no.",
    InvoiceNumber: "Invoice number",
    InvoiceNumber_Tooltip_message: "Enter the same invoice number that you have assigned the invoice in your other system. It’s important that the invoice numbers matches between systems.",
    InvoiceNumberPrefix_Preview_Text: "Preview:",
    InvoicePeriodiseReconciliation_WarningMessage: "Invoices that are sent in one year but paid in the next are to be recorded in accrual basis. Read {this help page to see how}",
    InvoicePeriodiseReconciliation_WarningTitle: "Convert {0} to accrual basis",
    InvoicePeriodiseReconciliationModal_Title: "Convert to accrual basis",
    InvoicePortal_BankTransferHeader: "Bank transfer",
    InvoicePortal_CardDetailsHeader: "Card details",
    InvoicePortal_CardInformation: "Card information",
    InvoicePortal_CVC: "CVC",
    InvoicePortal_ExpiryDate: "Expiry date",
    InvoicePortal_Header: "Your invoice",
    InvoicePortal_Heading: "Invoice Portal",
    InvoicePortal_Pay: "Pay",
    InvoicePortal_PaymentMethod: "Payment method",
    InvoicePortal_PaymentStatus: "Payment status",
    InvoicePortal_PayWithCard: "Pay with card",
    InvoicePortal_ReceiptNumber: "Receipt number",
    InvoicePortal_ReceiveReceipt: "Insert your email below to receive the link to the payment confirmation.",
    InvoicePortal_SampleEmail: "email@example.com",
    InvoicePortal_SendReceipt_Success: "An email has been sent.",
    InvoicePortal_StripeCheckout_Successful: "Your payment is handled by Stripe, it takes a while to be done.",
    InvoicePortal_StripePayment_IsSucceed: "Your payment is successful",
    InvoicePortal_ViewReceipt: "View receipt",
    InvoicePoweredBy: "Invoice powered by",
    InvoicePreview: "Invoice preview",
    InvoicePublished: "Invoice published successfully",
    InvoicePublished_FromQuote_label: "Invoice was published from quote {0}",
    InvoicePublished_label: "Invoice was published",
    InvoicePublishedWithAutomaticPayments_label: "Invoice was published with automatic payments",
    InvoiceReport_ErrorMessage_CouldNotLoadReport: "Could not fetch invoice report: ",
    InvoiceReport_ErrorMessage_NoCustomersReceived: "The request did not receive any customers",
    InvoiceReportCannotBeViewedOnFreePlan: "Graph is not available on your current plan",
    InvoiceReports_Tooltip_message: "This data shows the total amount in {0} for all of your sent invoices. This does not include credited or draft invoices. The exact date range applied can be seen underneath the chart.",
    InvoiceReports_Top5Articles_EmptyStateText: "Once you've invoiced with custom items within this period, your top 5 items will show up here.",
    InvoiceReports_Top5Customers_EmptyStateText: "Once you've invoiced within this period, your top 5 customers will show up here",
    InvoiceReports_Top5Customers_Tooltip_message: "This data shows the total amount in {0} for each of your top 5 invoiced customers. This does not include draft invoices. The exact date range applied can be seen underneath the pie chart.",
    InvoiceReports_Top5Items_Tooltip_message: "This data shows the total amount in {0} for each of your top 5 invoiced items. This data does not include draft invoices. The exact date range applied can be seen underneath the pie chart.",
    InvoiceRow_EnterArticleName_placeholder: "Enter item name",
    InvoiceRow_Select_EnterArticleName_placeholder: "Search for a saved item",
    InvoiceRowModal_Vat_Tooltip_Skatteverket: "In Sweden, most goods and services are subject to a 25% VAT. Some categories are taxed at lower rates, such as 12%, 6%, or 0%. A list of these categories and their rates can be found on the {Skatteverket website}.",
    InvoiceRowModal_Vat_Tooltip_VatCalculator: "Our {VAT calculator} can assist with calculating VAT for purchases.",
    InvoiceRows_HaveToFixBeforePublish: "{0} invoice rows have issues to fix before you can publish your invoice",
    InvoiceRows_HaveUnspecifiedType: "At least one invoice row needs to be specified as service or goods.",
    InvoiceRows_HaveVatWhenUnregistered: "One or more invoice rows have a VAT amount but your company is not registered for VAT",
    InvoiceRowsEmpty: "You haven't added any invoice rows yet.",
    InvoiceRowUnitType_Centimeter: "centimeter",
    InvoiceRowUnitType_Centimeters: "centimeters",
    InvoiceRowUnitType_Day: "day",
    InvoiceRowUnitType_Days: "days",
    InvoiceRowUnitType_Gigabyte: "GB",
    InvoiceRowUnitType_Gigabytes: "GB",
    InvoiceRowUnitType_Gram: "gram",
    InvoiceRowUnitType_Grams: "grams",
    InvoiceRowUnitType_Hectar: "ha",
    InvoiceRowUnitType_Hectars: "ha",
    InvoiceRowUnitType_Hour: "hour",
    InvoiceRowUnitType_Hours: "hours",
    InvoiceRowUnitType_Kilogram: "kilogram",
    InvoiceRowUnitType_Kilograms: "kilograms",
    InvoiceRowUnitType_Kilometer: "km",
    InvoiceRowUnitType_Kilometers: "kms",
    InvoiceRowUnitType_Liter: "liter",
    InvoiceRowUnitType_Liters: "liters",
    InvoiceRowUnitType_Megabyte: "MB",
    InvoiceRowUnitType_Megabytes: "MB",
    InvoiceRowUnitType_Meter: "meter",
    InvoiceRowUnitType_MeterCubic: "m³",
    InvoiceRowUnitType_Meters: "meters",
    InvoiceRowUnitType_MeterSquared: "m²",
    InvoiceRowUnitType_Mile: "mile",
    InvoiceRowUnitType_Miles: "miles",
    InvoiceRowUnitType_Millimeter: "millimeter",
    InvoiceRowUnitType_Millimeters: "millimeters",
    InvoiceRowUnitType_Minute: "minute",
    InvoiceRowUnitType_Minutes: "minutes",
    InvoiceRowUnitType_Month: "month",
    InvoiceRowUnitType_Months: "months",
    InvoiceRowUnitType_Piece: "piece",
    InvoiceRowUnitType_Pieces: "pieces",
    InvoiceRowUnitType_Ton: "ton",
    InvoiceRowUnitType_Tons: "tons",
    InvoiceRowUnitType_Week: "week",
    InvoiceRowUnitType_Weeks: "weeks",
    InvoiceRowUnitType_Word: "word",
    InvoiceRowUnitType_Words: "words",
    InvoiceRowUnitType_Year: "year",
    InvoiceRowUnitType_Years: "years",
    INVOICES: "Invoices",
    Invoices_AddRowToInvoice_message: "Add a row to your invoice",
    Invoices_CreateCashInvoice_action: "Create cash invoice",
    Invoices_CreateCreditInvoice_action: "Create credit note",
    Invoices_CreateInvoice_action: "Create an invoice",
    Invoices_EmptyState_Active_Header: "No active invoices to show",
    Invoices_EmptyState_Active_Text: "When you have active customer invoices that await payments or handling, they'll show up here.",
    Invoices_EmptyState_All_Header: "No invoices to show",
    Invoices_EmptyState_All_Text: "When you have published customer invoices, they’ll show up here.",
    Invoices_EmptyState_Date_NoResults: "There are no results for the selected date. Try again with another date.",
    Invoices_EmptyState_Dates_NoResults: "There are no results for the selected dates. Try again with other dates.",
    Invoices_EmptyState_Keywords_NoResults: "There are no results that match your search. Try again with other keywords.",
    Invoices_EmptyState_PrimaryAction: "Create invoice",
    Invoices_EmptyState_SecondaryAction: "Learn more about creating invoices",
    Invoices_EmptyState_SecondaryAction_Route: "https://www.bokio.co.uk/help/invoices/create-invoice/how-do-i-create-an-invoice/",
    Invoices_InvoiceNumber: "Invoice number",
    Invoices_InvoiceNumberPrefix: "Invoice number prefix",
    Invoices_InvoiceRows_text: "Invoice rows",
    Invoices_NoInvoices_Title: "You have no invoices yet",
    Invoices_NoInvoicesFound_SearchFilter: "We didn't find any invoices. Try entering a different search.",
    Invoices_NoneCompleted_EmptyState: "When invoices are published they will show up here.",
    Invoices_NoOpenInvoices_EmptyState: "When you have active customer invoices they'll show up here.",
    Invoices_NumberPrefix: "#",
    Invoices_ResetSearch_action: "Reset search",
    Invoices_Validation_Inline_DoNow_message_P: "There are {0} issues to fix before you can publish your invoice.",
    Invoices_Validation_Inline_DoNow_message_S: "There is {0} issue to fix before you can publish your invoice.",
    Invoices_Validation_Inline_Ready_message: "Your {0} are complete.",
    INVOICESENT: "Invoice sent",
    InvoiceSettings_CustomerNumberTaken: "The customer number {0} is already taken",
    InvoiceSettings_Heading: "Invoice and quote settings",
    InvoiceSettlementVerificationTitle_BankFee: "Settled invoice #{0} with bank fee for {1}",
    InvoiceSettlementVerificationTitle_CurrencyGainLoss: "Settled invoice #{0} with currency gain / currency loss for {1}",
    InvoiceSettlementVerificationTitle_CustomerBadDebt: "Settled invoice #{0} with bad debt for {1}",
    InvoiceSettlementVerificationTitle_RoundedOffCurrency: "Settled invoice #{0} with rounded off currency for {1}",
    InvoicesReport_Top5Articles: "Top 5 items",
    InvoicesReport_Top5Customers: "Top 5 customers",
    InvoiceStatusEventLabel_Approved: "The invoice has been approved",
    InvoiceStatusEventLabel_ApprovedForDelivery: "The invoice has been approved for delivery by recipient",
    InvoiceStatusEventLabel_Clearing: "Scheduled for disbursement",
    InvoiceStatusEventLabel_Closed: "The invoice has been closed",
    InvoiceStatusEventLabel_DebtCollection: "A debt collection demand has been sent",
    InvoiceStatusEventLabel_Dispute: "The invoice has been disputed",
    InvoiceStatusEventLabel_Ebv: "Efterbevakning",
    InvoiceStatusEventLabel_FinalNotice: "A final notice has been sent",
    InvoiceStatusEventLabel_PendingRepurchasing: "The invoice has been transferred back to you",
    InvoiceStatusEventLabel_Recalled: "The invoice has been recalled",
    InvoiceStatusEventLabel_Rejected: "The invoice has been rejected",
    InvoiceStatusEventLabel_RejectedByRecipient: "The invoice has been rejected by the receiver",
    InvoiceStatusEventLabel_Reminder: "A reminder has been sent",
    InvoiceStatusEventLabel_Sent: "The invoice has been sent",
    InvoiceTerms_tooltip: "&lt;b&gt;Days&lt;/b&gt;&lt;br/&gt;Number of days before the payment is due.&lt;br /&gt;&lt;br/&gt; &lt;b&gt;Interest on overdue payments&lt;/b&gt;&lt;br/&gt; This is where you define the interest rate added when a payment is overdue.&lt;br /&gt; &lt;br /&gt;&lt;b&gt;Delivery&lt;/b&gt; &lt;br/&gt;&lt;br /&gt; Define how the goods are delivered. Warehouse means that the risk is taken over by the customer as soon as the goods leave the warehouse.",
    InvoiceTo: "Invoice to",
    InvoiceWasSavedAs: "The invoice was saved as invoice",
    Is_ready_to_be_review: "is ready to be reviewed",
    IsForeignCustomer: "Is foreign customer",
    Items_EmptyState_Header: "No {0} to show",
    Items_EmptyState_PrimaryAction: "Add {0}",
    Items_EmptyState_SecondaryAction: "Import {0}",
    Items_EmptyState_Text: "You haven’t added any {0} yet. Add or import {0} to use them in invoices and quotes.",
    ItemsToCredit_heading: "Items to credit",
    iZettle_ConfirmPurcahse_ZeroAmount: "Your Zettle usage doesn't exceed the free quota",
    iZettle_ConfirmPurchase_OnlyForFreePlan: "Buying Zettle is only avaliable for Free package",
    IZettle_ConnectModal_ConfirmAndImport: "Import transactions",
    IZettle_ConnectModal_ConfirmAndImport_action: "Confirm and import",
    IZettle_ConnectModal_ConfirmMessage: "I confirm the above date range and that I've logged out from Zettle.",
    IZettle_ConnectModal_ConfirnYourInformation_heading: "Confirm your information is correct",
    IZettle_ConnectModal_LogoutFromIZettle_action: "Log me out from Zettle",
    IZettle_ConnectModal_LogoutFromIZettle_message: "Logout from Zettle to ensure you connect with the correct account.",
    iZettle_ConnectModal_PricePlansLink: "https://www.bokio.se/prisplaner/",
    IZettle_ConnectModal_WarningMessage1: "Connecting with Zettle will import {ALL} transactions from the date you've selected, {0} until today, into your payment account {1}. This process is automatic.",
    IZettle_ConnectModal_WarningMessage2: "To ensure you're using the correct Zettle account, {please log out from Zettle first}.",
    iZettle_NoActiveIntegration: "There is not active Zettle integration",
    KIND: "Item type",
    KIND_GOODS: "Goods",
    KIND_SERVICES: "Services",
    Language: "Language on invoice/quote",
    LastEditedBy: "Last edited by",
    LastInvoiceSent: "Last invoice sent",
    LayoutAndColor_FreePlan: "Upgrade your plan to personalise your invoices as well as quotes with your own logo and colour.",
    LEFT: "Left",
    LegalCountry: "Legal country",
    LOADING: "Loading",
    LOGIN: "Log in",
    LOGOUT: "Log out",
    MakeInvoiceAccrual_Title: "Make invoice accrual?",
    Mark_As_Default_Contact: "Select as default",
    Max_Number_Customers: "Max {0} customers",
    MENU: "Menu",
    MESSAGE_INVOICE: "Message on Invoice",
    Monthly: "Monthly on {0}",
    MonthlyLastDay: "Monthly on last day",
    More_Action: "More",
    MOSTINCOME: "Highest revenue",
    MOSTINVOICED: "Most invoices",
    Multiple_contact_notice: "It is now possible to send {0} to up to 4 contacts. Type any email or select from the contact suggestions from your customer.",
    Multiple_contact_notice_invoice: "an invoice",
    Multiple_contact_notice_quote: "a quote",
    MultipleEmailHistory_InvoiceRecipients: "recipients",
    MultipleEmailHistory_InvoiceSentAs: "Invoice has been sent to {0} as ",
    MultipleEmailHistory_ReminderSentAs: "Reminder has been sent to {0} as ",
    MYARTICLES: "My items",
    MYCUSTOMERS: "My customers",
    MYINVOICES: "My invoices",
    NAME: "Name",
    NavigateToCreditNote: "Open credit note",
    NavigateToInvoice_Action: "Go to the invoice",
    NeedFiscalYearToBookkeep: "You have to create a fiscal year for {0} to record the payment",
    New_Article: "New item",
    NEWARTICLEDESCR: "New item",
    NEWARTICLEPRCODE: "Code not set",
    NewCashInvoice_label: "New cash invoice",
    NewCreditInvoice_label: "New credit note",
    NEWCUSTOMER: "New customer",
    NEWINVOICE: "New invoice",
    NewRecurringInvoice: "New recurring invoice",
    NewSupplier: "New supplier",
    NewTextTemplate: "New text template",
    NextInvoice: "Next invoice",
    No: "No",
    NOACCOUNTDESCRIPTION: "Don't have an account? Try Bokio now and add the information as you go",
    NoBalancesSelectedDateCustomersHistory: "There are no balances due for the selected date. Try choosing a different date.",
    NOCUSTOMERSWITHDELAYED: "No clients with invoices overdue!",
    NoDetailsProvided: "No details provided for this contact. Add details or delete this contact.",
    NOINVOICESTEXT: "You have no invoices yet.",
    Notes_Optional_label: "Notes (optional)",
    NotSelected: "Not selected",
    Number_of_Orders: "Number of Orders",
    number_of_orders_short: "No of orders",
    number_of_refunds_short: "No of refunds",
    NUMBERINVOICES: "Number of invoices",
    NumberOfInvoices_label: "No. of invoices",
    NumberOfItemsAtPrice_After: "each",
    NumberOfItemsAtPrice_Between: "at",
    OccurranceType_Annually: "Annually",
    OccurranceType_Custom: "Custom",
    OccurranceType_Daily: "Daily",
    OccurranceType_Monthly: "Monthly",
    OccurranceType_MonthlyLastDay: "Monthly on Last Day",
    OccurranceType_Quarterly: "Quarterly",
    OccurranceType_Weekly: "Weekly",
    OcrNumber: "OCR No",
    OcrNumberFullText: "OCR number",
    OK: "Ok",
    OnlinePayment_Interest_Interested: "Submit my interest",
    OnlinePayment_Interest_NotInterested: "I’m not interested",
    OnlinePayment_Interest_NotInterested_CompletedText: "",
    OnlinePayments: "Online payments",
    OnlinePayments_Interest_Description: "With Stripe you’ll be able to receive card payments from your customers.",
    OnlinePayments_Interest_FeatureTitle: "Card payments by stripe BETA",
    OnlinePayments_Interest_Interested_CompletedText: "You can start using Stripe integration soon! You are placed in a queue and will receive an email when it is your turn so you can onboard with Stripe and start offering card payments to your customers.",
    OnlinePayments_Interest_NotInterested_NotNeeded: "I do not need the service",
    OnlinePayments_Interest_NotInterested_NoValue: "I do not see the value of the service",
    OnlinePayments_Interest_NotInterested_Other: "Other",
    OnlinePayments_Interest_NotInterested_TooExpensive: "Stripe price is too high",
    OnlinePayments_Interest_NotInterested_WantOtherAlternative: "I prefer another payment provider",
    OnlinePayments_Interest_ServiceFee: "Service fees per transactions",
    ORDINAL_EIGHTEENTH: "18th",
    ORDINAL_EIGHTH: "8th",
    ORDINAL_ELEVENTH: "11th",
    ORDINAL_FIFTEENTH: "15th",
    ORDINAL_FIFTH: "5th",
    ORDINAL_FIRST: "1st",
    ORDINAL_FOURTEENTH: "14th",
    ORDINAL_FOURTH: "4th",
    ORDINAL_NINETEENTH: "19th",
    ORDINAL_NINTH: "9th",
    ORDINAL_SECOND: "2nd",
    ORDINAL_SEVENTEENTH: "17th",
    ORDINAL_SEVENTH: "7th",
    ORDINAL_SIXTEENTH: "16th",
    ORDINAL_SIXTH: "6th",
    ORDINAL_TENTH: "10th",
    ORDINAL_THIRD: "3rd",
    ORDINAL_THIRTEENTH: "13th",
    ORDINAL_THIRTIETH: "30th",
    ORDINAL_THIRTY_FIRST: "31st",
    ORDINAL_TWELFTH: "12th",
    ORDINAL_TWENTIETH: "20th",
    ORDINAL_TWENTY_EIGHTH: "28th",
    ORDINAL_TWENTY_FIFTH: "25th",
    ORDINAL_TWENTY_FIRST: "21st",
    ORDINAL_TWENTY_FOURTH: "24th",
    ORDINAL_TWENTY_NINTH: "29th",
    ORDINAL_TWENTY_SECOND: "22nd",
    ORDINAL_TWENTY_SEVENTH: "27th",
    ORDINAL_TWENTY_SIXTH: "26th",
    ORDINAL_TWENTY_THIRD: "23rd",
    ORGNO: "Org.no",
    ORGNUMBER: "Company number",
    OrgNumberEmpty: "&lt;Legal registration number missing&gt;",
    OriginalInvoice: "Original invoice",
    OriginalInvoiceRowValue: "Original invoice quantity: {number}",
    ORNNR: "Org no.",
    OtherCountryForAddress: "Other country for mail",
    OURREFERENCE: "Our contact",
    Outstanding_ColHeading: "Outstanding",
    OutstandingInvoiceSummary_Label: "Outstanding (inc VAT)",
    Overdue_Label: "Overdue",
    OverdueInvoiceSummary_Label: "Overdue (inc VAT)",
    OverduePaymentInterest: "Overdue payment interest",
    OverPaid_ColHeading: "Overpaid",
    OverpaidInvoiceSummary_Label: "Overpaid (inc VAT)",
    PAID: "Paid amount",
    PaidFromAccount: "Paid from this account",
    PaidLoanFromAccount: "Payout of loan from account",
    PaidToAccount: "Paid to this account",
    PaidWith: "Paid with",
    PartPaid_ColHeading: "Partially paid",
    PASSWORD: "Password",
    PasteImport_ClearPaste: "Clear paste",
    PasteImport_ColShort: "Column {index}",
    PasteImport_Column: "Column {index}: ",
    PasteImport_ColumnHelp_Description: "This list shows all the properties you can import, in what order we expect them and what format the data should be. You will be able to both change the column order and manually enter data.",
    PasteImport_ColumnHelp_Heading: "The data you can import",
    PasteImport_ConfirmColumns: "Confirm columns",
    PasteImport_ConfirmColumns_Description: "In this step you should map your data to the correct labels. The labels product code could be optional.",
    PasteImport_ConfirmData: "Confirm data",
    PasteImport_ConfirmData_Description: "Look over your imported data and fix possible faults and continue. You can also go back in the flow in you want to make changes to the labels or data import.",
    PasteImport_ConfirmModal_Description: "You will need to redo everything after the step you are going to.",
    PasteImport_ConfirmModal_Title: "Are you sure you want to go back?",
    PasteImport_FailedToImportSome: "Failed to import {failCount} rows. You can either try to fix that or create these rows manually.",
    PasteImport_How_ManyRows: "You are about to import {0} rows.",
    PasteImport_IgnoreColumn: "Ignore column",
    PasteImport_Input_AnyText: "Free text",
    PasteImport_Input_Decimal: "Number",
    PasteImport_Input_Field: "Property",
    PasteImport_Input_Integer: "Whole number",
    PasteImport_Input_Percentage: "Percentage",
    PasteImport_Input_PossibleValues: "Input rules",
    PasteImport_NotValidVAT: "VAT must be either 0%, 5% or 20%",
    PasteImport_PasteData: "Paste data",
    PasteImport_PasteData_Description: "Here you can import items by copying the data you want to import and pasting it into Bokio.",
    PasteImport_PasteData_Heading: "Paste your data",
    PasteImport_PasteData_Hint: "Paste your data here",
    PasteImport_PasteData_Instruction: "You need to copy the data from a table. Sometimes it will works straight from another program. Otherwise we recommend Excel/Google docs",
    PasteImport_ResolveEmptyColumn: "Item columns can not be unselected",
    PasteImport_ResolveRedFaults: "You must resolve all errors, marked red, before proceeding.",
    PasteImport_Warning_ManyRows: "You cannot import more than {0} rows at a time.",
    PAYLATEST: "Payment due",
    Payment_CloseInkassogramInvoiceInfo: "If the customer has paid money directly to your account, the money needs to be paid to the account specified on the invoice for the account to be correct. You can either pay back any payments to the customer and ask them to use the correct account or pay the invoice directly. Note that any fees incurred (eg interest, reminder and debt collection fees) also need to be paid, otherwise claims will continue to be sent to the customer.",
    Payment_CloseInkassogramInvoiceInfoImportant: "Any fees incurred will be deducted from the payments made for automatic billing.",
    Payment_CloseInvoiceCurrencyLoss: "Close the invoice and record as rounded off currency",
    Payment_CloseInvoiceCurrencyLoss_SupplierInvoice: "Close the invoice and record as exchange loss",
    Payment_CloseInvoiceCurrencyWin: "Close the invoice and record as rounded off currency",
    Payment_CloseInvoiceCurrencyWin_SupplierInvoice: "Close the invoice and record as exchange gain",
    Payment_CloseInvoiceRounding: "Close the invoice and record difference as bank charge",
    Payment_Difference: "Difference",
    Payment_label: "Payment",
    Payment_LeaveInvoiceOpen: "Leave the invoice open for more payments",
    Payment_LeaveInvoiceOpen_SupplierInvoice: "Leave the invoice open for more payments",
    Payment_PaidSoFar: "Paid so far",
    PAYMENT_REGISTERED: "Payment has been registered",
    Payment_SetTo: "Set to",
    Payment_SumOnInvoice: "Invoice amount",
    Payment_WhatToDoWithTheDifference: "What do you want to do with the difference?",
    PAYMENTCONFIRM: "Confirm payment",
    PaymentConfirmation: "Payment confirmation",
    PaymentCreated: "Payment created",
    PAYMENTDATE: "Payment date",
    PAYMENTHISTORY: "Payment history",
    PaymentHistory_AlienInvoices_BookkeptOutside_Message: "This invoice has been recorded outside of Bokio and was imported using opening balances",
    PaymentHistory_Claim: "Rot/Rut deduction claim from {0} published",
    PaymentHistory_Credit: "Credit note for invoice {0} was published",
    PaymentHistory_Stripe: "The invoice portal offering card payments (powered by Stripe) has been created. Use the link below to {preview} and share with your customer to get paid.",
    PaymentHistory_WasClaimed: "Rot/Rut deduction was claimed from customer by {0}",
    PaymentHistory_WasCredited: "Invoice was credited by {0} for {1}",
    PaymentInformationValidation_CannotUseBokioBankgiro_error: "Could not update payment information. Bankgiro from Bokio business account cannot be used as a payment option in the Free price plan.",
    PaymentLink_ActivePaymentLinks_label: "Active payment links",
    PaymentMessage: "Payment message",
    PaymentMethod_AccountNumber: "Account number",
    PaymentMethod_AccountNumber_Template: "{0} number",
    PaymentMethod_AtLeastOne: "At least one of payment methods must be filled in",
    PaymentMethod_Bankaccount: "Direct bank transfer",
    PaymentMethod_Bankgiro: "Bankgiro",
    PaymentMethod_BankName: "Bank account name",
    PaymentMethod_BankTransfer: "Bank transfer",
    PaymentMethod_BIC: "BIC",
    PaymentMethod_BokioBankgiro: "Bankgiro (Bokio Business Account)",
    PaymentMethod_BokioIBAN: "IBAN (Bokio)",
    PaymentMethod_Custom: "Custom",
    PaymentMethod_Domestic: "Domestic payments",
    PaymentMethod_IBAN: "IBAN",
    PaymentMethod_International: "International payments",
    PaymentMethod_NotSet: "Not set",
    PaymentMethod_PaymentDetails: "Payment details",
    PaymentMethod_Paypal: "PayPal",
    PaymentMethod_Plusgiro: "PlusGiro",
    PaymentMethod_Postgiro: "PlusGiro",
    PaymentMethod_PrimaryKey: "Payment method",
    PaymentMethod_PrimaryValue: "Payment number",
    PaymentMethod_ShouldUse_BankgiroBBA: "Please select [Bankgiro (Bokio Business Account)] to use the specified Bankgiro number.",
    PaymentMethod_SortCode: "Sort code",
    PaymentMethod_Swish: "Swish",
    PaymentMethodEmpty: "&lt;Choose method of payment&gt;",
    PaymentMethods: "Payment methods",
    PaymentMethods_Add: "Add payment method",
    PaymentMethods_CustomerMissing: "Insert the customer information to able to see the payment methods.",
    PaymentMethods_Domestic: "domestic",
    PaymentMethods_Edit: "Edit payment methods",
    PaymentMethods_International: "international",
    PaymentMethods_ListOfMethods: "Choose the payment methods you would like to offer your customers. You can offer additional payment methods by adding a \"New text row\" under \"Add items/service\".",
    PaymentMethods_NotSet_Error: "You are missing an {0} payment method, click Edit to add it.",
    PaymentMethods_OnlinePayment_Onboarded: "Card payments via Stripe available. More info on {Stripe integration‘s page}.",
    PaymentMethods_OnlinePayments_Card: "Offer card payments by Stripe",
    PaymentMethods_OnlinePayments_Onboard: "Use our beta Stripe integration to get paid faster.",
    PaymentNow: "Payment now (Usually within 24 hours)",
    PaymentOptions: "Payment options",
    PaymentPoweredBy: "Powered by",
    PaymentReference: "Payment Reference",
    PAYMENTREGISTERED: "Payment registered",
    PaymentTerms: "Payment terms",
    PaymentTermsEmpty: "&lt;Payment terms missing&gt;",
    PaymentWillBeDisconnected_InfoBar: "The connected payment and transaction will be disconnected from this supplier invoice. You will find it in Transactions to record where you can bookkeep it separately.",
    PDFAttachmentRecommendation: "&lt;strong&gt;Recommended to use attached PDF option&lt;/strong&gt;&lt;br&gt;\nYour customer is most likely using a system to import invoices automatically.\nWe suggest using the &lt;strong&gt;Invoice as link and an attached PDF&lt;/strong&gt; as the delivery option\nto ensure they receive the invoice.",
    PDFV3_PromotionalBanner_Benefits_Line1: "QR code and OCR slip for easy scanning of bankgiro",
    PDFV3_PromotionalBanner_Benefits_Line2: "Summary box of payment information in the top right corner of the invoice.",
    PDFV3_PromotionalBanner_Benefits_Title: "From now on your new invoices and quotes will have the new, improved design. Here are some advantages:",
    PDFV3_PromotionalBanner_Heading: "New Invoices and Quotes designs",
    PDFV3_PromotionalBanner_Note: "If you want to send a reminder or credit note in the old layout you can temporarily change back under Beta features.",
    PeriodicReport: "EC sales list",
    PeriodicReport_CountryCode_label: "Country code",
    PeriodicReport_Description: "&lt;p&gt;If you sell goods or services to VAT-registered customers in another EU country you are required to submit an \"EC Sales List\" to HMRC. This report gathers all of your eligible invoices for a specified period. It shows you how much have you have invoiced minus how much you have credited for both goods and services.&lt;/p&gt;&lt;p&gt;More information regarding submitting an EC Sales List &lt;a href=\"https://www.gov.uk/guidance/vat-how-to-report-your-eu-sales\" target=\"_blank\"&gt;can be found here&lt;/a&gt;&lt;/p&gt;",
    PeriodicReport_Goods: "Total goods (2) GBP",
    PeriodicReport_NoChangesInPeriod: "There were no changes in the selected period",
    PeriodicReport_Services: "Total services (3) GBP",
    PeriodicReport_Total: "Total GBP",
    PERSONAL_NUMBER: "Personal number",
    PersonalMessage: "Personal message",
    PHONE: "Phone",
    PhoneEmpty: "&lt;Telephone number missing&gt;",
    Placeholder_TenDigits: "10 digit",
    platform_selection_mutiple_shops: "Import orders from another ecommerce platform and bookkeep easily from one place",
    PLEASEFOLLOWTHEINSTRUCTIONS: "Please follow the instructions in the email",
    POSTALCODE: "Postal code",
    PreviewInvoice: "Preview",
    PreviousPDFVersions: "Previous versions",
    PRIVATECUSTOMER: "Individual",
    PRODUCTCODE: "Product code",
    Publish_label: "Publish",
    PublishCashInvoice: "Publish cash invoice",
    PublishCreditInvoice: "Publish credit note",
    PublishInvoice: "Publish invoice",
    PublishInvoice_DownloadingPDF: "Downloading PDF",
    PublishInvoice_NotInYourPlan: "{Upgrade your plan} to be able to publish {0}.",
    PublishInvoice_NotInYourPlan_EInvoice: "E-invoices",
    PublishInvoice_NotInYourPlan_InvoiceRotRut: "invoices with Rot/Rut",
    PublishInvoice_NotInYourPlan_InvoiceStripe: "invoices with Stripe payments",
    PublishInvoice_NotInYourPlan_RecurringInvoice: "recurring invoice",
    PublishInvoice_PublishingInvoice: "Publishing invoice",
    PublishQuote_NotInYourPlan: "{Upgrade your plan} to be able to publish quotes{0}.",
    Quarterly: "Quarterly",
    QuotaUsageLimitReached: "Usage limit reached",
    Quote_AddRowTo: "Add a row to your quote",
    Quote_AddTo: "Add to quote",
    Quote_AmountIncVat: "Quote amount (inc VAT)",
    Quote_ChangeState: "Change state",
    Quote_ChangeState_Description: "Here you can change the state of your quote. A quote can only be changed to \"Invoiced\" from the \"Approved\" state.",
    Quote_ConvertToInvoice: "Create invoice from quote",
    Quote_Copy: "Copy quote",
    Quote_Create: "Create a quote",
    Quote_CreatedInBokio: "Quote created in Bokio",
    Quote_Date: "Quote date",
    Quote_Delete_Description: "Are you sure you want to delete this quote?",
    Quote_Delete_Title: "Delete quote",
    Quote_delivery_options: "quote",
    Quote_delivery_options_desc: "quote",
    Quote_DeliveryHistory_SentAs: "Quote sent as",
    Quote_DeliveryStatus_Click: "Quote opened",
    Quote_DownloadAsPdf: "Download the quote as a PDF to send it by yourself.",
    Quote_Draft: "Draft",
    Quote_Edit: "Edit quote",
    Quote_EmailDeliveryMethod_Description: "Send your quote via email and keep better track of your quotes.",
    Quote_EmailDeliveryMethod_Options: "Quote delivery options",
    Quote_EmailDeliveryMethod_PreviewMessage: "{0} sent you {1} (#{2}) for {3}, valid until {4}",
    Quote_EmailDeliveryMethod_SendWithLink: "Quote as a link to the PDF (Recommended for better tracking)",
    Quote_EmailDeliveryMethod_SendWithLinkAndPdf: "Quote as a link and an attached PDF (We can’t track the open status for attached PDF)",
    Quote_EmailQuote: "Email quote",
    Quote_EmptySearch: "We didn't find any quotes. Try entering a different search.",
    Quote_EmptyState_Active: "When you have active quotes they'll show up here.",
    Quote_EmptyState_All: "When quotes are published they will show up here.",
    Quote_GeneralTerms: "General terms",
    Quote_Header: "Quote",
    Quote_No: "Quote No.",
    Quote_Number: "Quote number",
    Quote_PreviewBeforePublish: "You can preview your quote before publishing it.",
    Quote_Publish: "Publish quote",
    Quote_SeeSpecFollowingPages: "See quote specification on the following pages",
    Quote_StartingNumber_Hint: "Which quote number do you want to start with?",
    Quote_Sum: "Quoted amount",
    Quote_Terms: "Quote terms",
    Quote_UndoEdit_Description: "Do you want to cancel editting this quote?",
    Quote_UndoEdit_Title: "Undo edit",
    Quote_UpdateTitle: "Update quote",
    Quote_ValidDays: "Valid days",
    Quote_ValidFrom: "Valid from",
    Quote_ValidTo: "Valid to",
    QuoteChargeOnboard_AddedQuote: "Added a quote",
    QuoteChargeOnboard_Billing: "A charge will be added to your next monthly billing. You can see your billing under {settings and billing}",
    QuoteChargeOnboard_Understand: "Ok, I understand",
    QuoteChargeOnboard_YouAddQuote: "You have added a quote!",
    QuoteDelivery_EmailMessagePreview_message: "{0} sent you {1} (#{2}) for {3}, valid until {4}",
    QuoteEmailSubject: "Quote #{0} from {1} for {2}",
    QuoteFailureEmailHeader: "Your quote for {0} cannot be delivered",
    QuoteFailureEmailSubject: "Quote #{0} cannot be delivered",
    QuoteHistory_DisconnectInvoice: "Quote was disconnected from an invoice",
    QuoteHistory_ToInvoice: "Quote was converted to an invoice",
    QuoteHistory_ToInvoice_NoConnection: "Quote was invoiced",
    QuoteHistory_ToInvoice_WithConnection: "Quote was converted to the invoice",
    QuoteHistory_WasApproved: "Quote was approved",
    QuoteHistory_WasDeclined: "Quote was declined",
    QuoteHistory_WasEdited: "Quote was edited",
    QuoteHistory_WasPublished: "Quote was published",
    QuoteLimitReached_Cost: "Costs will be invoiced to you at the end of the month. Details can be found under billing in settings.",
    QuoteLimitReached_Description: "You have reached the limit of 5 free quotes this month.",
    QuoteLimitReached_PayExtra: "Pay 25 kr for an extra quote",
    QuoteLimitReached_UpgradePlan: "Upgrade to Balance plan to get unlimited quotes every month. Go to {price plans page} to read more and upgrade",
    QuoteNumber_Max: "The maximum quote number allowed is",
    QuoteNumber_Min: "The minimum quote number allowed is",
    QuotePreview: "Quote preview",
    QuoteRows: "Quote rows",
    QuoteRows_HaveToFixBeforePublish: "{0} quote rows have issues to fix before you can publish your quote",
    QuoteRows_HaveVatWhenUnregistered: "One or more quote rows have a VAT amount but your company is not registered for VAT.",
    Quotes: "Quotes",
    Quotes_Active: "Active quotes",
    Quotes_All: "All quotes",
    Quotes_EmailQuote_action: "Email quote",
    Quotes_EmailQuote_heading: "Email quote",
    Quotes_EmptyState_Active_Header: "No active quotes to show",
    Quotes_EmptyState_Active_Text: "When you have active quotes that await approval or handling, they'll show up here.",
    Quotes_EmptyState_All_Header: "No quotes to show",
    Quotes_EmptyState_All_Text: "When you have published quotes, they’ll show up here.",
    Quotes_EmptyState_PrimaryAction: "Create quote",
    Quotes_EmptyState_SecondaryAction: "Learn more about creating quotes",
    Quotes_EmptyState_SecondaryAction_Route: "https://www.bokio.co.uk/help/invoices/quotes/how-to-create-a-quote/",
    Quotes_Validation_Inline_DoNow_message_P: "There are {0} issues to fix before you can publish your quote.",
    Quotes_Validation_Inline_DoNow_message_S: "There is {0} issue to fix before you can publish your quote.",
    QuoteState_Approved: "Approved",
    QuoteState_Declined: "Declined",
    QuoteState_Expired: "Expired",
    QuoteState_Invoiced: "Invoiced",
    QuoteState_Published: "Published",
    QuoteSummary_Approved: "Approved quotes",
    QuoteSummary_Drafts: "Drafts",
    QuoteSummary_Published: "Published quotes",
    QuoteTo: "Quote to",
    ReadAll: "Read all",
    READY: "Ready",
    ReadyToSign: "Ready to sign",
    RealEstateNumber: "Real estate number",
    ReceivingDetails: "Receiving details",
    ReceivingEInvoices: "Receiving e-Invoices",
    RECIPIENT: "Recipient",
    Recorded_Label: "Recorded",
    RecordInvoice: "Record invoice",
    Recurring_badge: "Recurring",
    recurring_invoice_is_inactive_notice_banner: "This recurring invoice is inactive because of a technical issue which is preventing draft invoices from being generated. Inactive recurring invoices cannot be edited and no future drafts will be generated. To resolve this issue, please copy the recurring invoice, making sure to check the date before publishing. You may delete the inactive recurring invoice at any time by selecting delete in the dropdown menu. This will remove the inactive recurring invoice from the list. If you have any further issues or questions, please contact support@bokio.se",
    recurring_invoice_is_inactive_notice_banner_title: "Recurring invoice is inactive",
    Recurring_Invoice_Was_Archived: "Recurring invoice {0} was stopped",
    Recurring_Invoice_was_Created: "Recurring invoice {0} was setup to generate invoices",
    Recurring_Invoice_was_Edited: "Recurring invoice {0} was edited",
    recurring_invoices_found_inactive_warning_banner: "{0} recurring invoices are now in an inactive state due to technical issue. No new draft invoices will be created from these recurring invoices. Please open each invoice and follow the provided steps to resolve the issue.",
    recurring_invoices_found_inactive_warning_banner_title: "Inactive recurring invoices alert",
    RecurringInvoice: "Recurring invoice",
    RecurringInvoice_ActivateOldVersion_Message: "You are attempting to activate a recurring invoice that has been archived for an extended period. **This recurring invoice is not compatible with the current version of Bokio.** \n\nFor the best experience, we recommend:\n* **Copy recurring invoice:** Use the current recurring invoice information to create a new one. Keep in mind that the original will be permanently removed.\n* **Create new:** Start with a fresh recurring invoice.",
    RecurringInvoice_Create: "Create recurring invoice",
    RecurringInvoice_Dropdown_StopRecurringInvoice: "Stop recurring invoice",
    RecurringInvoice_EmptyState_PrimaryAction: "Create recurring invoice",
    RecurringInvoice_InfoMessage_onFeb29: "Selecting the 29th of February will automatically adjust to the 28th of February during non-leap years. For all other months the invoice will generate on the 29th",
    RecurringInvoice_InfoMessage_onLstDateOfMonth: "If the month does not have the selected date, then the invoice will be generated on the last possible date of the month",
    RecurringInvoice_NoInvoiceToShow_Details: "You don't have any recurring invoices yet. When you have recurring invoices they'll show up here.",
    RecurringInvoice_NoInvoiceToShow_Details_Active: "You don't have any active recurring invoices yet. When you have recurring invoices they'll show up here.",
    RecurringInvoice_NoInvoiceToShowHeader: "No recurring invoices to show",
    RecurringInvoice_NoInvoiceToShowHeader_Active: "No active recurring invoices to show",
    RecurringInvoice_PaymentTerms: "Payment terms (days)",
    RecurringInvoice_Publishing: "Publishing a recurring invoice template",
    RecurringInvoice_Quarterly: "Quarterly on {0}",
    RecurringInvoice_Set_Frequency: "Frequency",
    RecurringInvoice_SetFrequencyMessage: "A draft invoice will be created {0}. You have to review and publish drafts manually.",
    RecurringInvoice_ToolTip: "This invoice was generated from a recurring invoice",
    RecurringInvoiceFrequency: "Frequency",
    RecurringInvoiceIsBetaNotice: "This feature will be free until 30 September 2022. After this date, our Business plan will be required to use recurring invoices.",
    RecurringInvoices: "Recurring Invoices",
    RecurringInvoices_EmptyState_SecondaryAction: "Learn more about creating recurring invoices",
    RecurringInvoices_EmptyState_SecondaryAction_Route: "https://www.bokio.se/hjalp/fakturera-kunder/aterkommande-faktura-avtalsfakturering/hur-skapar-jag-aterkommande-faktura/",
    RecurringInvoices_EmptyState_SecondaryAction_Route_UK: "https://www.bokio.co.uk/help/invoices/recurring-invoices/how-to-create-a-recurring-invoice/",
    RecurringInvoiceSetting: "Recurring invoice settings",
    RecurringInvoiceStatus: "Status",
    RecurringNumber: "Recurring number",
    ReferenceUniqueNumber: "OCR Number",
    RefreshExchangeRate: "Refresh exchange rate",
    REGISTER_PAYMENT: "Register payment",
    Register_Payment_description: "Once your customer has paid the invoice, you should register a payment. Read this {0} to find out how to register the payment.",
    RegisterPayment_BokioPaymentMethod: "Because you have activated BankGiro and OCR on this invoice, you no longer need to manually register a payment. You will only need to register a payment manually if the matching has failed, for example, your customer has paid with a message or OCR number which didn't match.",
    RegisterPayment_BokioPaymentMethod_title: "This payment will be automatically registered.",
    RegisterPayment_DateInTheFuture: "Recording of payment is not possible while payment is in the future.",
    Reload_page: "reload the page",
    REMAINING: "Remaining",
    RemainingSum_label: "Remaining amount",
    REMEMBERME: "Remember me",
    Reminder_SendWIthoutFee_message: "Send without reminder fee",
    Reminder_Sent: "Reminder Sent",
    Reminder_SentTo: "The reminder is sent successfully to {0}",
    ReminderInvoice: "Reminder invoice",
    ReminderInvoice_VerificationLabel: "Reminder: Invoice",
    ReminderInvoiceHeader: "Reminder invoice",
    ReminderSentTo_Label: "Reminder sent to",
    RESEND: "Resend",
    ResultsFound: "results found",
    ReuploadFile: "Re-upload file",
    ReversedVAT: "Reverse charge",
    ReversedVAT_Construction_Activate: "Activate reverse charge for construction",
    ReversedVAT_Construction_Activate_Tooltip: "Activate this option if you would like to make reversed charge for the construction sector possible on invoices to this customer. Once activated, you can add reverse charge on an invoice row. &lt;b&gt;This can not be activated on foreign invoices.&lt;/b&gt;",
    ReversedVAT_Heading: "Reverse charge",
    ReversedVAT_Row_Opt_In: "Reversed charge for construction",
    ReversedVAT_Row_Tooltip: "By adding reverse charge for construction your VAT will be tagged as as Reverse charge on the invoice.",
    Review_Invoice: "Review invoice",
    RIGHT: "Right",
    Rot: "Rot",
    Rot_Overview_Hint: "Specify what should be Rot deductible by editing an invoice row",
    RotDeduction: "ROT deduction",
    RotMaterial_Construction: "Construction",
    RotMaterial_El: "Electricity",
    RotMaterial_GlassPlate: "Glass and plate",
    RotMaterial_Painting: "Painting and wallpapering",
    RotMaterial_Plumbing: "Plumbing - heating, ventilation, sanitation",
    RotMaterial_SoilWork: "Excavation and soil work",
    RotMaterial_Sweeping: "Bricklaying and sweeping",
    RotRut_Activate: "Activate Rot and Rut",
    RotRut_ActivateRotRutHint: "Before you activate Rot and Rut for this invoice make sure you’ve added and categorised your invoice rows first. This will make it easier to complete this step.",
    RotRut_ActivateRowHint: "Rot/Rut will be accounted for if this box is checked AND if you have activated Rot/Rut on the invoice. Learn how to create an invoice or a quote with Rot and Rut {here}.",
    RotRut_ActivationIntroductionText: "Rot and Rut is a tax deduction that could be applied for private persons. Learn how to create an invoice or a quote with Rot and Rut {here}.",
    RotRut_AddDeductions: "Add deductions",
    RotRut_AwatingPaymentInformation: "Before a Rot/Rut case will be ready to be submitted it needs to be fully paid from the customer. Read more about how it works {here}",
    RotRut_CannotMoveToStatusReadyToSubmit: "Cannot move case to status Ready to submit due to insufficient payment",
    RotRut_Claim_Amount: "Amount (SEK) to claim from customer",
    RotRut_Claim_Description: "If you want to change who is paying Rot/Rut deductible rows from Skatteverket to the customer you do that here. Select the amount you want to claim from the customer and we assist you in creating an invoice based on that.",
    RotRut_Claim_Heading: "Claim Rot/Rut payment from customer",
    RotRut_Claim_MaxAmount: "The amount can not be higher than {0}",
    RotRut_ClaimAmount_Heading: "Claimed amount (SEK)",
    RotRut_ClaimedRow: "Obetalt Rot/Rut-avdrag från skatteverket tillhörande faktura #{0}",
    RotRut_CompletedErrandsInformation: "The cases below has been marked as approved by Skatteverket",
    RotRut_CreateDeduction: "Create deduction",
    RotRut_CreateDeduction_Heading: "Activate Rot and Rut",
    RotRut_DeductionSum: "Deduction amount",
    RotRut_DescriptionHint: "Describe the deductible service being performed and between which dates it took place.",
    RotRut_DescriptionService: "Description of {0} service",
    RotRut_EditDeduction: "Edit deduction",
    RotRut_EditDeduction_Heading: "Edit Rot/Rut deduction",
    RotRut_EligibleDeduction: "Eligible for Rot/Rut deduction",
    RotRut_EligibleMaterial: "Eligible as a Rot/Rut material",
    RotRut_EligibleService: "Eligible as a Rot/Rut service",
    RotRut_EmptyState_Active_Header: "No active Rot/Rut cases to show",
    RotRut_EmptyState_Active_Text: "When you have active Rot/Rut cases, they’ll show up here.",
    RotRut_EmptyState_Completed_Header: "No completed Rot/Rut cases to show",
    RotRut_EmptyState_Completed_Text: "When you have completed Rot/Rut cases, they’ll show up here.",
    RotRut_EmptyState_Downloaded_Header: "No downloaded Rot/Rut cases to show",
    RotRut_EmptyState_Downloaded_Text: "When you have downloaded Rot/Rut cases, they’ll show up here.",
    RotRut_EmptyState_SecondaryAction: "Learn more about Rot/Rut cases",
    RotRut_EmptyState_SecondaryAction_Route: "https://www.bokio.se/hjalp/fakturera-kunder/rot-och-rut/sa-fungerar-rot-och-rutarenden/",
    RotRut_Errand: "case",
    RotRut_Errands_ActiveErrands: "Active cases",
    RotRut_Errands_ClaimAmount: "Claim amount",
    RotRut_Errands_CompletedErrands: "Completed cases",
    RotRut_Errands_DownloadHusFile: "Download HUS-file",
    RotRut_Errands_MarkAsCompleted: "Mark as \"Completed\"",
    RotRut_Errands_MoveTo_ActiveCases: "Move to \"Active cases\"",
    RotRut_Errands_MoveTo_Downloaded: "Move to \"Downloaded\"",
    RotRut_Errands_SubmittedErrands: "Downloaded cases",
    RotRut_FixUnit_WhenKindIs_Services: "hour or [-] when having Rot/Rut invoice rows with kind set to services",
    RotRut_GoToErrands: "Go to Rot/Rut cases",
    RotRut_HousingDesignation: "Housing designation",
    RotRut_InformationProperty: "Information about the property",
    RotRut_ListEmptyState: "When you have active rot/rut cases they'll show up here.",
    RotRut_Material: "Material",
    RotRut_MaximumDeduction: "Maximum {0} deduction",
    RotRut_NotSetValidation: "Rot/Rut is not set",
    RotRut_Other: "Other",
    RotRut_PropertyType: "Property",
    RotRut_ReadyToSubmitInformation: "Download cases as HUS files and upload them to Skatteverket to claim deductions. When Skatteverket makes the payment you record the income in the normal bookkeeping flow. Read more on how cases works {here}. You can submit it on Skatteverkets {webpage}",
    RotRut_RealEstate: "Real estate",
    RotRut_RemoveDeduction: "Remove deduction",
    RotRut_RemoveModal_Description: "Are you sure you want to remove this Rot/Rut deduction?",
    RotRut_SameTypeValidation: "Must be the same type in both invoice rows and Rot/Rut settings",
    RotRut_SpecifyDetails: "Specify details",
    RotRut_SpecifyDetails_Hint: "You need to check with your client how much Rot deduction they are eligible for. Read more about this at Skatteverket.",
    RotRut_Status_AwaitingPayment: "Awaiting payment",
    RotRut_Status_Completed: "Completed",
    RotRut_Status_ReadyToSubmit: "Ready to submit",
    RotRut_Status_RotRutErrandRemoved: "Removed",
    RotRut_Status_Submitted: "Downloaded",
    RotRut_StatusInvalidChangedCommandValidation: "The Rot/Rut status is changed to a invalid status",
    RotRut_SubmittedErrandsInformation: "Rot/Rut cases will be moved to Downloaded cases once they are downloaded. Upload the HUS-file to Skatteverket to claim the deduction. When Skatteverket makes the payment you record it in the normal bookkeeping flow. Read more on how it works {here}. You can submit it on Skatteverkets {webpage}",
    RotRut_Summary_Heading: "Rot/Rut",
    RotRut_TotalDeduction: "Total {0} deduction: {1}",
    RotRut_Type: "Type",
    RotRut_TypeDeduction: "Type of deduction.",
    RotRut_TypeDeductionAndWhat: "Type of deduction. {What is Rot/Rut?}",
    RotRut_TypeDeductionSum: "{0} deduction amount",
    RotRut_TypeOfMaterial: "Type of material",
    RotRut_TypeOfService: "Type of service",
    RotRut_Validation_VatForServices: "25 % for Rot/Rut services",
    RotRutErrand_RowTypeNotYetSupported: "One of the invoice's RUT categories is not yet supported by Skatteverket. You will be able to upload your file for tax deductions when Skatteverket supports it",
    RotRutInvoiceCantHaveNegativeRows_error_message: "An invoice with Rot/Rut can not have negative invoice rows.",
    RotRutQuoteCantHaveNegativeRows: "A quote with Rot/Rut can not have negative quote rows.",
    RoundedSum: "Rounded amount",
    ROUNDING: "Rounding",
    RoundInvoice_RoundingDisabled: "Rounding disabled",
    RoundOffAmount: "Round off amount",
    RowKind_Unspecified: "unspecified",
    RowsWithVAT: "Rows with VAT",
    RowVat_MustBeZeroPercentage: "Must be 0%. Your business is not registered for VAT.",
    Rut: "Rut",
    Rut_Overview_Hint: "Specify what should be Rut deductible by editing an invoice row",
    RutDeduction: "RUT deduction",
    RutMaterial_ChildServices: "Child Services",
    RutMaterial_Cleaning: "Cleaning",
    RutMaterial_ClothingCare: "Clothing and textile care",
    RutMaterial_DataITServices: "Data and IT services",
    RutMaterial_Furnishing: "Furnishing",
    RutMaterial_Gardening: "Gardening",
    RutMaterial_HousingSupervision: "Supervision",
    RutMaterial_PersonalHelpCare: "Personal help and care",
    RutMaterial_RemovalServices: "Removal services",
    RutMaterial_Repair: "Repair of appliances",
    RutMaterial_Snow: "Snow shoveling",
    RutMaterial_TransportOfHouseholdGoods: "Transport of second hand goods",
    RutMaterial_WaterWashingOfTextiles: "Laundry",
    SalaryApplication: "Salary",
    SAME_COUNTRY: "Same country",
    SAVE: "Save",
    SAVEANDPUBLISH: "Save and publish",
    SAVEANDRETURN: "Save and return",
    SAVEASARTICLE: "Save as item",
    SaveAsArticle_Tooltip: "This will save this item to 'Items' in the side menu for future use",
    SAVEASDRAFT: "Save as draft",
    SaveAsTemplate: "Save as template",
    Saving: "Content",
    ScheduledPayment_Canceled_HistoryTitle: "Payment cancelled",
    ScheduledPayment_CanceledBy_HistoryDescription: "Payment cancelled by {username} {time}",
    ScheduledPayment_CancelPayment_HistoryItemAction: "Cancel payment",
    ScheduledToBePaid: "Scheduled to be paid {time}",
    Search_ColHeading: "Search",
    searchCustomer: "Search customer",
    SearchForInvoice_label: "Search for invoice",
    searchSupplier: "Search supplier",
    SeeInvoiceSpecFollowingPages: "See invoice specification on the following pages",
    SELECTARTICLE: "Select item",
    SelectCurrency_Label: "Select currency",
    SelectCustomer: "Select customer",
    SelectCustomers_label: "Select customers",
    SEND: "Send",
    SEND_CreditNote: "Send credit note",
    SEND_INVOICE: "Send invoice",
    Send_multiple_recipients_title: "Send to multiple recipients?",
    SendAndPublish: "Send and publish",
    SendByEmail: "Email to customer",
    SendByEmailText: "We send the invoice by email to your client. Please enter recipient in the next step.",
    SENDERINFORMATION: "Your company details",
    SENDING: "Sending",
    SendingEInvoices: "Sending e-Invoices",
    SendReminder_Action: "Send reminder",
    SendTo: "Send to",
    Sent_ColHeading: "Sent",
    Set_Company_Details: "Set company details",
    Set_Company_Reference_description: "Start by adding all of the necessary information for your company and decide how you want to get paid by your customers.",
    Set_Company_Reference_heading: "Set your reference and how to be paid",
    SetInvoiceNumber_Description: "All invoices are required to have an invoice number. If it's your first invoice you can set this to be 1, however, if you have sent invoices previously you should continue to follow that invoice number sequence.",
    SetInvoiceNumber_Heading: "Set your starting invoice number",
    SetQuoteNumber_Description: "All quotes are required to have an quote number. If it's your first quote you can set this to be 1, however, if you have sent quotes previously you should continue to follow that quote number sequence. &lt;a href=\"https://www.bokio.se/hjalp/fakturera-kunder/skapa-faktura/hur-fungerar-fakturanummer/\" target=\"_blank\"&gt;Learn more&lt;/a&gt;",
    SetQuoteNumber_Heading: "Set your starting quote number",
    SETTINGS: "Settings",
    SettingsForm_ContactDetails_heading: "Company contact person",
    Settlement_AddNewSettlement: "Add new settlement",
    Settlement_BadDebt: "Bad debt",
    Settlement_BankFee: "Bank fee",
    Settlement_Bookkeep: "Record settlement",
    Settlement_ChooseReasonToSettle: "Choose reason to settle",
    Settlement_CreditCardCharge: "Credit card fee",
    Settlement_CurrencyGain: "Currency gain",
    Settlement_CurrencyLoss: "Currency loss",
    Settlement_Delete: "Delete settlement",
    Settlement_DeleteSettlement_Description: "The settlement {0} for this invoice will be deleted",
    Settlement_HowItWork: "How it works",
    Settlement_NegativeAmount_Error: "You cannot settle a negative amount.",
    Settlement_Registered_Description: "Settlement has been registered",
    Settlement_Registered_Title: "Settlement registered",
    Settlement_RoundedCurrency: "Rounded off currency",
    Settlement_SettleInvoice: "Settle invoice",
    Settlement_SettleInvoice_Description: "Settle the whole or a part of an invoice by other than a payment.",
    Settlement_SettlementType_Tooltip: "&lt;b&gt;Bad debt&lt;/b&gt;&lt;br/&gt;\nUse this when it's confirmed that you will not be paid for the invoice. This can be because you are not willing to take further action to collect the debt.\n&lt;br/&gt;&lt;br/&gt;\n&lt;b&gt;Bank fee&lt;/b&gt;&lt;br/&gt;\nUse this when a bank fee is deducted from the payment received. This is common in payments received through paypal or similar methods.\n&lt;br/&gt;&lt;br/&gt;\n&lt;b&gt;Currency gain or loss&lt;/b&gt;&lt;br/&gt;\nUse this if you have received a different amount than you invoiced due to the movement in the currency exchange rate between the invoice date and the payment date. This is only applied when you raise an invoice in a different currency.",
    Settlement_SettleWith: "Settled with",
    SettlementInvoice_CanOnlyRemoveLatestRegisteredSettlement: "Can only remove the last registered settlement for an invoice",
    SettlementInvoice_VerificationLabel: "Settlement: Invoice",
    SHOW: "Show",
    ShowEmailPreview: "Show email preview",
    SignPayment: "Sign payment",
    STARTED: "Started",
    StartSendingInvoices_ChartEmptyState_heading: "No invoices in this period.",
    StartSendingInvoices_ChartEmptyState_message: "This chart will show a summary of your sent invoices. Right now there are no invoices for the selected period of time.",
    State_P_All: "All invoices",
    State_P_AwaitingPayment: "Unpaid",
    State_P_Cancelled: "Cancelled",
    State_P_Credit: "Credit notes",
    State_P_Credited: "Credited",
    State_P_Current: "Current invoices",
    State_P_Delayed: "Overdue",
    State_P_Draft: "Drafts",
    State_P_Edit: "Edit",
    State_P_Edited: "Edited",
    State_P_Error: "Error",
    State_P_Expired: "Expired",
    State_P_Overpaid: "Overpaid",
    State_P_Paid: "Paid",
    State_P_PlannedPayment: "Scheduled for payment",
    State_P_Published: "Published",
    State_P_Received: "Received",
    State_P_Refunded: "Refunded",
    State_P_Underpaid: "Underpaid",
    State_P_Unpaid: "Unpaid",
    State_S_Active: "Active",
    State_S_Archived: "Archived",
    State_S_AwaitingPayment: "Unpaid",
    State_S_Completed: "Completed",
    State_S_Credit: "Credit note",
    State_S_Credited: "Credited",
    State_S_Delayed: "Overdue",
    State_S_Deleted: "Deleted",
    State_S_Draft: "Draft",
    State_S_Edit: "Edit",
    State_S_Edited: "Edited",
    State_S_NotBookkept: "Not Recorded",
    State_S_Overpaid: "Overpaid",
    State_S_Paid: "Paid",
    State_S_Partiallypaid: "Partially paid",
    State_S_PlannedPayment: "Payment scheduled",
    State_S_Published: "Published",
    State_S_Received: "Received",
    State_S_Stopped: "Stopped",
    State_S_Underpaid: "Underpaid",
    State_S_Unpaid: "Unpaid",
    Status_ColHeading: "Status",
    StopRecurringInvoice_Modal_Content: "By stopping this recurring invoice it will not generate any further invoices. You can reactivate it by visiting the stopped recurring invoice.",
    StopRecurringInvoice_Modal_Tittle: "Stop recurring invoice",
    Stripe_EnablePayments_Checkbox: "Accept online card payments via Stripe",
    Stripe_EnablePayments_Info: "The payment method currently selected in your Company Settings will be displayed on the Invoice PDF. To use Stripe as sole payment, please select “Custom” from that dropdown.",
    Stripe_EnablePayments_NotAvailableInPlan: "Stripe payments are not available in your current plan.",
    Stripe_EnablePayments_TotalAmountLow: "Stripe payments are not available as the total invoice amount is too low.",
    Stripe_Help_Link: "https://www.bokio.co.uk/help/invoices/stripe-payments/",
    Stripe_StripeIntegration: "Stripe Integration",
    StripeOnboarded_Able_To_Select_As_PaymentMethod: "When you’re creating an invoice you’ll be able to select Stripe as your payment method.",
    StripeOnboarded_Heading: "You can now offer card payments to your customers",
    StripeOnboarded_Link: "https://www.stripe.com",
    StripeOnboarded_ServiceFeesHeading: "As you’re creating an invoice you’ll have the options to select Stripe as your payment method. Service fees (for total volume):",
    StripeOnboarded_SubHeading: "To access Stripe’s dashboard, please visit {www.stripe.com}.",
    StripeOnboarding_ComprehensiveSecurityAndRigorousCompliance: "Comprehensive security and rigorous compliance",
    StripeOnboarding_ConnectWithStripeButton: "Connect with Stripe",
    StripeOnboarding_ContinueWhereYouLeftButton: "Continue where you left",
    StripeOnboarding_Disconnect: "Disconnect",
    StripeOnboarding_DisconnectFromStripe: "Disconnect from Stripe",
    StripeOnboarding_Disconnecting_In_Progress: "Disconnecting in progress...",
    StripeOnboarding_DisconnectingMessage: "It might take a couple of minutes to disconnect from Stripe. You’ll be notified once it’s done.",
    StripeOnboarding_FastPredictablePayoutsToYourBankAccount: "Fast, predictable payouts to your bank account",
    StripeOnboarding_FinancialReconciliationAndReporting: "Financial reconciliation and reporting",
    StripeOnboarding_GetPaidQuicklyAndGetBetterCashFlow: "Get paid quickly and get a better cash flow",
    StripeOnboarding_GlobalPaymentsWithASingleIntegration: "Global payments with a single integration",
    StripeOnboarding_Heading: "Accept card payments on invoices and get paid faster",
    StripeOnboarding_InvoicePortalWithCheckoutPaymentFlow: "Invoice portal with checkout payment flow",
    StripeOnboarding_MakeItEasyForYourCustomersToPay: "Make it easy for your customers to pay",
    StripeOnboarding_MessageAfterDisconnect: "You successfully disconnected from Stripe.",
    StripeOnboarding_RedirectToStripe: "You’ll be re-directed to Stripe",
    StripeOnboarding_SecurePayments: "Benefit from secure payments",
    StripeOnboarding_ServiceFeesEuropeanCards: "1,4% + £0,20 for European issued cards",
    StripeOnboarding_ServiceFeesHeading: "As you’re creating an invoice you’ll have the options to select Stripe as your payment method. Service fees per transaction:",
    StripeOnboarding_ServiceFeesNonEuropeanCards: "2,9% + £0,20 for non-European issued cards",
    StripeOnboarding_SubHeading: "Offer online card payments to your customers and:",
    StripeScene_Title: "Card payments by Stripe",
    StripeScene_TitleBeta: "Card payments by Stripe BETA",
    StripeServiceFee_For_Bokio: "Bokio does not charge any additional fees on any transactions.",
    StripeServiceFees_Per_Transaction: "Stripe service fees per transaction:",
    SUM: "Amount",
    SUMEXVAT: "Amount ex VAT",
    SUMIncVAT: "Amount incl VAT",
    SumLeftToPay: "Amount left to pay",
    SUMMARY: "Summary",
    SumOfDebt_Local_Label: "Sum of Debt (£)",
    SUMTOPAY: "Amount to pay",
    Supplier_Edit_action: "Edit supplier",
    SupplierInvoice: "Supplier invoice",
    SupplierInvoice_Amount_Label: "Invoice amount (inc VAT)",
    SupplierInvoice_CreateSupplier_EmptyState: "Register a supplier",
    SupplierInvoice_DueAmount_colhead: "Amount due",
    SupplierInvoice_InvoiceNumber_tooltip: "Adding an invoice number helps you to keep track of individual supplier invoices. &lt;br/&gt; This can usually be found in the top of the invoice.",
    SupplierInvoice_MarkAsScheduledForPayment: "Mark as scheduled for payment",
    SupplierInvoice_MarkedAsFullyPaid: "Invoice marked as fully paid",
    SupplierInvoice_Name_colhead: "Invoice name",
    SupplierInvoice_NoSuppliers_EmptyState: "You currently have no registered suppliers!",
    SupplierInvoice_NumberOfInvoices_ColHeading: "Number of invoices",
    SupplierInvoice_QuickPay_QR_Description: "Scan this QR code with your Bank app to quickly pay the invoice",
    SupplierInvoice_QuickPay_QR_Title: "Pay with QR code",
    SupplierInvoice_RefNo_colhead: "Invoice ref.",
    SupplierInvoice_RefundedAmountLabel: "Refunded amount",
    SupplierInvoice_SumOfDebt_ColHeading: "Sum of debt",
    SupplierInvoice_Supplier_ColHeading: "Supplier",
    SupplierInvoice_UndoMarkAsScheduledForPayment: "Undo schedule for payment",
    SupplierInvoiceHistoryPaymentRecording_RecordInvoice: "In order for Bokio to record the payment you first have to record the invoice.",
    SupplierInvoiceHistoryPaymentRequest_AddedBy: "Added by {user} {date}",
    SupplierInvoiceHistoryPaymentSchedule_InvoiceNotRecorded: "The invoice needs to be recorded, you can either do it now or after the payment is done.",
    SupplierInvoiceHistoryPaymentSchedule_InvoiceRecorded: "When the payment is done and handled by Bokio it will automatically be recorded.",
    SupplierInvoiceHistoryPaymentSignature_SignedBy: "Signed by {user} {date}",
    SupplierInvoices_AddSupplier: "New supplier",
    SupplierInvoices_heading: "Supplier invoices",
    SupplierInvoices_HistoryFilter: "History",
    SupplierInvoices_HistoryFilter_Empty: "There are no supplier balances due on this date",
    SupplierInvoices_NewPaymentAndInvoice: "New payment and invoice",
    SupplierInvoices_NoInvoicesFound_SearchFilter: "We didn't find any invoices. Try entering a different search.",
    SupplierInvoices_NoneCompleted_EmptyState: "When supplier invoices are published they will show up here.",
    SupplierInvoices_NoOpenInvoices_EmptyState: "When you have active supplier invoices they'll show up here.",
    SupplierInvoices_Plural: "Supplier invoices",
    SupplierInvoices_Suppliers_Title: "Suppliers",
    Suppliers_DateHistory_Input: "Payable as of",
    Suppliers_Total_Due: "Total Payable",
    SUPPORT: "support",
    SyncingInProgress: "Syncing in progress",
    Tag_email_already_added: "The email {0} already exists in recipient list",
    Tag_email_limit_reached: "Recipient limit reached. Remove an email to add another",
    TemplateName: "Template name",
    TemplateSavedSuccessfully_Toaster: "Your template has been saved successfully and is ready to use",
    TERMS: "Terms",
    TESTACCOUNTWARNING: "Please set your email so we can save your work.",
    TestCompanyQuickInvoiceNotice_Completed_CTA: "Go to Invoices",
    TestCompanyQuickInvoiceNotice_Completed_Text: "We have now added a customer and created an invoice for you to bookkeep. You will find the test invoice under Active invoices.",
    TestCompanyQuickInvoiceNotice_Completed_Title: "Test invoice generated",
    TestCompanyQuickInvoiceNotice_CTA: "Generate test invoice",
    TestCompanyQuickInvoiceNotice_Error: "Failed to create test invoice",
    TestCompanyQuickInvoiceNotice_MissingCompanyInfoWarning: "Missing required company information",
    TestCompanyQuickInvoiceNotice_Text: "Get started and test how you can manage invoices in Bokio without having to add any information of your own. We automatically create a customer and an invoice for you to bookkeep.",
    TestCompanyQuickInvoiceNotice_Title: "Explore invoice management easily with one click",
    TestCompanyQuickInvoiceNotice_UpdateCompanyInformation: "Update information",
    TextImport_text: "text template",
    TextImport_texts: "text templates",
    TextInput: "Text input",
    TEXTROWTEXT: "Text",
    TextTemplateDeleteMessage: "Are you sure you want to delete this text template?",
    TextTemplateDescription: "The template name will only be used to identify your text template when you reuse the template.",
    TextTemplates: "Text templates",
    Timeline_InvoiceIssued: "Invoice was issued",
    Timeline_Payment: "Payment of",
    Timeline_Reimbursement: "Reimbursement of",
    TITLE: "Title",
    TO: "To",
    TOBILL: "To invoice",
    Toggl_Account: "Toggl Account",
    Toggl_Entries: "Toggl entries",
    Toggl_Helper: "How to integrate with Toggl",
    Toggl_NoData: "There are no Toggl rows in the period above or you haven't linked those items with Toggl's Client and Toggl's Project.",
    TOGGLHELP1H: "1. Go to your profile",
    TOGGLHELP1P: "Go to your Toggl profile.",
    TOGGLHELP2H: "2. Copy token",
    TOGGLHELP2P: "Scroll down and copy the token field value.",
    TOGGLHELP3H: "3. Paste token",
    TOGGLHELP3P: "Paste the token field value to our form.",
    TOKEN: "Token",
    Tooltip_change_contact_title: "Change contact?",
    Total_amount_billed: "Total amount billed (Incl VAT)",
    total_tax: "Total tax",
    TotalAmount: "Total amount",
    TotalCreditAmount: "Total credit amount",
    TotalCustomers_label: "Total customers",
    TotalDeductible: "total deductible",
    TotalExcludingVat: "Total Excluding VAT",
    TotalExTax_heading: "Total (ex VAT)",
    TotalIncludingVat: "Total Including VAT",
    TotalIncTax_heading: "Total (Incl VAT)",
    TotalInvoices_label: "Total invoices",
    TotalPaidAmount: "Total paid amount",
    TotalPerRow: "Amount per row",
    TotalSumExVAT_label: "Total amount ex VAT",
    TotalSumGreaterThanZero: "Total amount must be greater than zero",
    TotalSumIncVAT_label: "Total amount inc VAT",
    TotalSumLeftToPay: "Total amount left to pay",
    TotalSumToCredit: "Total amount to credit",
    TotalSumToPay: "Total amount to pay",
    TRY: "Try",
    TT: "Time Tracking",
    TTACCOUNT: "Account",
    TTCLIENT: "Client",
    TTEMPTY: "No time entries found.",
    TTENDDATE: "End date",
    TTHOURS: "Hours",
    TTPRICE: "Price (hourly)",
    TTPROJECT: "Project",
    TTREMOVEACCOUNT: "Remove account",
    TTSELECTACCOUNT: "Select account",
    TTSELECTDATE: "Select date range",
    TTSELECTWORKSPACE: "Select workspace",
    TTSTARTDATE: "Start date",
    TTWORKSPACE: "Workspace",
    Type: "Type",
    UK_AdditionalFields_InfoText_text: "When you add additional information, it will be included in the invoice or quote.",
    UNDERANDOVERPAIDINVOICES: "Under/Overpaid invoices",
    UndoCreditConfirm: "Do you want to undo the credit note?",
    UndoCreditConfirmText: "The credit note will be deleted, and the original invoice will be restored.",
    UndoCrediting: "Undo credit",
    UndoCreditingText: "The credit note can be undone if it is the latest invoice and has not yet been recorded.",
    UndoCreditSuccess: "The crediting has been reverted!",
    UndoEdit_button: "Undo edit",
    UndoEditInvoice_heading: "Undo edit invoice",
    UndoEditInvoice_message: "Do you want to cancel editing this invoice?",
    UndoInvoiceError_InvoiceNotLatest_message: "Invoice cannot be undone as it is not your latest invoice. This error can occur if you have changed your invoice number sequence after publishing",
    UndoPublishConfirm: "Undo publish?",
    UNIT: "Unit",
    UNITPRICE: "Unit price",
    UNITPRICEEXVAT: "Price / unit ex VAT",
    update_button: "Update",
    updateArticle: "Update item",
    UpdateInvoice: "Update invoice",
    UPGRADEFROMTRIALEXPLANATION: "You are about to make your account permanent. We just need your email you want to use to log in.",
    Using_x_account: "Using {0} account?",
    Validation_AtLeastOneContactDetails_MustFill: "At least one of the contact details must be filled in for each contact, or remove any empty contacts",
    Validation_DateGreaterThanOrEqual_Must_ToCreateRecurringInvoice: "Pick a date no earlier than {0} to create a recurring invoice",
    ValidationFaild_PaymentInformationCannotUseBokioBankgiro: "Bankgiro from Bokio business account cannot be used as a payment option in the Free price plan. Please change your company payment option to be able to publish.",
    ValidationFaild_PaymentInformationCannotUseBokioIBAN: "IBAN from Bokio business account can no longer be used as a payment option. Please change your company payment option to be able to publish.",
    ValidationFailed_CompanyAddressMissingInformation: "The invoice failed to publish because the company address in missing information",
    ValidationFailed_CompanyDetails_Missing: "Please fill in your company details",
    ValidationFailed_CompanyMissingCountrySubdivision: "The invoice failed to publish because the company is missing country. You can set this under {company settings}.",
    ValidationFailed_CreditsNotAllowedInvoiceState: "Something went wrong when trying to connect to the invoice. Please **refresh the page** and try connecting again.",
    ValidationFailed_CustomerAddressMissingInformation: "The invoice failed to publish because the customer address in missing information",
    ValidationFailed_ExchangeRateError_Message: "Please try refreshing the exchange rate or enter it manually.",
    ValidationFailed_FaultyRow: "Row #{0} - {1} was not valid",
    ValidationFailed_ForeignCustomerAndReversedVATRow: "The invoice failed to publish because it contains both reverse charge on invoice rows and a foreign customer.",
    ValidationFailed_ForeignCustomerWithReversedVat: "You cannot activate reverse charge on a foreign customer",
    ValidationFailed_ForeignPaymentMethodNotSet: "The invoice failed to publish because international payment method is not set. You can set this under edit company or click {here}.",
    ValidationFailed_ForeignPaymentMethodNotSet_EditPayment: "The invoice failed to publish because international payment method is not set. You can set this under edit payment or click {here}.",
    ValidationFailed_InvoiceCurrencyMustBeIn: "Invoice currency must be in {0}",
    ValidationFailed_InvoiceDeadlineIsBeforeInvoiceDate: "The invoice failed to publish because the due date cannot be before the invoice date.",
    ValidationFailed_InvoiceNumber_AlreadySet: "The invoice number has already been set. Please {0} to solve this.",
    ValidationFailed_InvoiceNumber_Invalid: "Please set a valid invoice number. The invoice number should at least start with 1",
    ValidationFailed_NoFiscalYearForInvoiceDate: "The invoice failed to publish because its date is outside of the fiscal year. You can change fiscal year under {Settings}.",
    ValidationFailed_PaymentMethodNotSet: "The invoice failed to publish because domestic payment method is not set. You can set this under edit company or click {here}.",
    ValidationFailed_PaymentMethodNotSet_EditPayment: "The invoice failed to publish because domestic payment method is not set. You can set this under edit payment or click {here}.",
    ValidationFailed_Publish: "The invoice failed to publish because of validation errors",
    ValidationFailed_ReverseChargeError_Message: "Customer does not have reverse charge activated. Update before publishing.",
    ValidationFailed_ReverseChargeError_Title: "Remove reverse charge from items:",
    ValidationFailed_RotRutInvoiceDeductionAmountExceedsMaximum: "{0} deduction amount: Enter an amount which is less than or equal to {1}",
    ValidationFailed_StripeAmountTooLow: "The invoice failed to publish because the total amount is too low to enable Stripe payments.",
    ValidationFailed_ZeroSumInvoiceShouldHaveRowGreaterThanZero: "The invoice must contain at least one invoice row with a value greater than 0",
    ValidationWarning_HeaderTitle: "Before you continue:",
    ValidationWarning_InvoiceOldDated: "The invoice date on this invoice has passed. Are you sure you want to publish your invoice?",
    ValidationWarning_InvoiceRowTextLong: "Text might be too long. Please look at the invoice preview to make sure it fits well on the PDF. If it does not fit well we recommend you to split the text into multiple text rows.",
    ValidationWarning_VatInvoiceForForeignCustomer: "You have added VAT on this invoice to a foreign customer. Publish only if you are sure this is correct.",
    ValidToCannotBeforeValidFrom: "Valid to cannot be before Valid from",
    VAT: "VAT",
    VAT_NO: "VAT no.",
    VAT_NUMBER: "VAT number",
    VATNUMBER: "VAT number",
    VERIFYEMAILEXPLANATION: "To make it possible for you to login and access your data again we need your email",
    ViewCustomer_ActiveInvoices: "Active invoices",
    ViewInvoice: "View invoice",
    ViewInvoicePDF: "View Invoice PDF",
    ViewInvoices: "View Invoices",
    WANTTOCREATEINVOICE: "Do you want to create your first invoice?",
    WasPublished_label: "was published",
    Weekly: "Weekly on {0}",
    Whats_Next: "What's next?",
    WITHOUT_BOOKKEEP: "Continue without accounting",
    WithRotRut: "with Rot/Rut",
    Yearly: "Annually on the {0} of {1}",
    YesDelete_button_action: "Yes, delete",
    YouHaveNoItemsToBookkeep: "There is nothing left to record for this invoice right now",
    YouHaveXItemsToBookkeep_P: "You have {0} invoice events to record",
    YouHaveXItemsToBookkeep_S: "You have {0} invoice event to record.",
    YourCustomer_Heading: "Your customer",
    YOURREFERENCE: "Your contact",
    YOURVATNBR: "Your VAT no.",
    Zettle_Onboarding_FreePlan_Error: "{Upgrade your plan} to enable automatic recording of Zettle events.",
  };
}