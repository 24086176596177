/* eslint-disable */

import type { AccountingLang } from "../types/AccountingLang";
import { translateTempImpl } from "../TranslatableLanguage";

export function AccountingLangDef(): AccountingLang {
  return {
    translateTemp: (key: string, neutral: string, svSE?: string, enGB?: string) => translateTempImpl(key, neutral, svSE, enGB),
    
    AccountBalance: "Account balance  {0}: {1}",
    Accounted: "Journals",
    Accounted_NoBookkeepingEmptyState_heading: "You haven't recorded anything for this period yet.",
    Accounted_NoBookkeepingEmptyState_message: "All recorded items for {0} will appear here",
    Accounted_NoPeriodsFound_Error: "Could not find the requested period. If the issue persists, please contact support.",
    Accounted_NoValidPeriod_Error: "The requested period does not belong in any of the fiscal years. Please contact support.",
    Accounted_yourCompany_inTextmessage: "your company",
    AccountingType: "Accounting method",
    AccountingType_Cash: "Cash basis",
    AccountingType_Invoice: "Accrual basis",
    AccountingType_InvoiceWithCashVAT: "Cash VAT",
    AccountingType_SoleTraderNotVatRegistered_Cash: "Cash basis",
    AccountingType_SoleTraderNotVatRegistered_Invoice: "Accrual basis",
    AccountingType_SoleTraderVatRegistered_Cash: "Cash basis with cash VAT",
    AccountingType_SoleTraderVatRegistered_Invoice: "Accrual basis with accrual VAT",
    AccountingType_SoleTraderVatRegistered_InvoiceWithCashVAT: "Accrual basis with cash VAT",
    AccrualFundsNotice_Heading: "Change account name",
    AccrualFundsNotice_Text: "Do you know that you can change name on the accrual funds accounts in the account plan to better synchronise account numbers with calendar years? {Read more about changing accrual funds account names.}",
    AdditionalFiles: "Additional files",
    AdditionalFilesEmptyState_Content: "Upload all files that are not specifically related to a journal entry or invoice here to store them in Bokio. This is where you should upload your bank statements, customer statements and other relevant files.",
    AdditionalFilesEmptyState_Title: "No additional files to show",
    AdditionalFileToBigNotice: "One or more files were to big and will not be uploaded. Maximum allowed file size is {0} MB.",
    AdditionalFileUpload_FileTypeNotSupported: "One or more selected files are not supported. Supported files are .pdf, .png, .jpeg, .txt, .csv and .xls.",
    AddPage: "Add page",
    AddReceiptsModal_AddReceipt: "Attach Receipt to transaction",
    AddReceiptsModal_DetachReceipt: "Detach Receipt from transaction",
    AddSupplier: "Add supplier",
    AdvancedCustomTemplate_ExpressionInfo_SubHeading: "You can use formulas to customize your debit and credit fields. See examples below.",
    AdvancedCustomTemplate_ExpressionInfo_Title: "Possible variables and mathematical functions",
    AllAccounts: "All accounts",
    AllDirections: "All directions",
    AreYouAnAccountant: "Are you an accountant?",
    AreYouAnAccountant_No: "No, I’m not an accountant",
    AreYouAnAccountant_ThankYou: "Thank you for your feedback!",
    AreYouAnAccountant_TogetherWeMakeBokio: "Together we make Bokio great!",
    AreYouAnAccountant_WeAppreciate: "We appreciate you took the time to answer this question.",
    AreYouAnAccountant_WeAreCurrentlyLooking: "By letting us know if you're an accountant you're helping us make Bokio even better for professional users.",
    AreYouAnAccountant_Yes: "Yes, I am an accountant",
    Asset_AccumulatedAmount: "Accumulated depreciation",
    Asset_AlsoDeleteVerification: "Would you also like to delete the associated journal entry for this asset?",
    Asset_Archive_Button: "Archive",
    Asset_ArchiveModal_Text: "If you no longer own the asset (for example because it is sold, disposed or broken), you should remove it from the asset register. The event needs to be recorded manually. \n \nAre you sure you want to remove the asset from the register?",
    Asset_ArchiveModal_Title: "Archive",
    Asset_AssetDetailsModalTitle: "Asset details",
    Asset_AssetDetailsSaved: "Asset details have been saved.",
    Asset_AssetJournalEntry: "Asset journal entry",
    Asset_AssetLifespan: "Lifespan",
    Asset_AssetLifetime_Years: "{0} years",
    Asset_Category: "Category",
    Asset_ConnectedJournalEntries: "Connected journal entries",
    Asset_CreateAssetForJournalEntry: "Create asset",
    Asset_Delete: "Delete fixed asset",
    Asset_DeleteConfirmation: "Are you sure you want to delete this fixed asset?",
    Asset_Depreciate_Action: "Depreciate",
    Asset_DepreciationJournalEntry: "Depreciation journal entries",
    Asset_DepreciationMethod_InputLabel: "Depreciation method",
    Asset_DepreciationMethod_Linear: "Straight line",
    Asset_DepreciationPeriod_InputLabel: "Depreciation frequency",
    Asset_DepreciationPeriod_Yearly: "Yearly",
    Asset_Description: "Description",
    Asset_EmptyState_Description: "You have not added any fixed assets yet. When you have purchased and recorded fixed assets you will see them here.",
    Asset_EmptyState_Title: "No fixed assets to show",
    Asset_ItemDetails: "Item details",
    Asset_LearnMoreAboutAssets: "Learn more about fixed assets",
    Asset_LearnMoreAboutAssets_Link: "https://www.bokio.co.uk/help/accounting/fixed-assets/fixed-asset-faqs/",
    Asset_LeftToDepreciate: "Current value",
    Asset_Lifetime_Tooltip: "The lifespan of your asset is the time it’s considered to be useful in your business. The lifespan depends on the type of asset.",
    Asset_LifetimeCustom_InputLabel: "Custom lifetime",
    Asset_LifetimeCustom_Label: "Custom",
    Asset_Method_Tooltip: "With the linear method your asset will be depreciated with the same amount for every year, except it is proportional to how long you have had the asset in the fiscal year. If you bought it when half of the fiscal year is left, you will depreciate half of the regular yearly depreciation. E.g:\n\nAsset value: £10,000\nPurchase date: 6 months before end of fiscal year\nLifespan: 5 years (60 months)\nYearly depreciations: 6/60 * 10,000 = £1,000, 12/60 * 10,000 = £2,000, £2,000, £2,000, £2,000, £1,000",
    Asset_MoreDetailsNeeded_FormDescription: "To be able to depreciate the asset some more information is needed.",
    Asset_Name_Tooltip: "Give your asset a name that describes it in detail and makes it easy for you to identify later. E.g. Computer-Type of model, Car brand-ABC 123",
    Asset_Period_Tooltip: "Your asset will be depreciated once a year, on the last day of your fiscal year.",
    Asset_PurchaseAmount: "Purchase Amount",
    Asset_PurchaseDate: "Purchase Date",
    Asset_RecordAsAsset_Label: "Record as fixed asset",
    Asset_RemoveDepreciation: "Remove depreciation",
    Asset_RemoveDepreciation_ModalText: "This will remove the depreciation and annul the verification. Are you sure you want to remove this?",
    Asset_SaveDetails_Action: "Save asset",
    Asset_SearchField_Placeholder: "Search assets",
    Asset_Status_Active: "Active",
    Asset_Status_All: "All",
    Asset_Status_Archived: "Archived",
    Asset_Unarchive_Button: "Unarchive",
    Asset_UpToDateWithDepreciations: "There are no depreciations to make right now. You will be able to depreciate this asset at the end of the current fiscal year.",
    Asset_YouCanNowRegisterDepreciations: "You can now register a depreciation for this asset. Make sure to do this before completing your closure.",
    Assets_AddFixedAsset: "Import fixed asset",
    Assets_AddFixedAssetManually: "Import fixed asset",
    Assets_ControlVerificationBalance_Error: "We were unable to the create the fixed asset because the entered purchase amount and accumulated depreciation amount don't match what is in the bookkeeping (account {0} of {1} and account {2} of {3})",
    Assets_SearchForJournalEntries_Action: "Search journal entries",
    Assets_SearchJournalEntries_Description: "Search for journal entries which match the information you've entered above. Connecting a journal entry for this purchase can help you better keep track of your assets.",
    AssetTemplate_ThresholdInfo: "**Is this a fixed asset?** \nWhen the purchase value excluding VAT is above {0} and the time it’s considered to be useful in your business is at least 3 years, this should probably be recorded as a fixed asset. [Learn more about fixed assets.](https://www.bokio.co.uk/help/accounting/fixed-assets/fixed-asset-faqs/)",
    Attest: "Approve",
    Attest_Approve_Action: "Approve",
    Attest_DeleteReceipt_Action: "Delete receipt",
    Attest_DeleteReceipt_Text: "The receipt will be permanently deleted.",
    Attest_DeleteReceipt_Title: "Are you sure you want to delete the receipt?",
    Attest_Deny_Action: "Deny",
    Attest_Deny_Text: "If you deny the receipt because of poor quality, you may ask the employee to upload the receipt again afterwards.",
    Attest_Deny_Title: "Are you sure you want to deny the receipt?",
    AutmationRulePreview_MatchInfo_DidNotMatch: "Does not match",
    AutmationRulePreview_MatchInfo_MatchedRule: "Matches",
    AutmationRulePreview_MatchInfo_MatchedRuleButOtherHadPriority: "Other rule matched first",
    AutomationRule_CreateBankRule: "Create bank rule",
    AutomationRule_Preview_Debug: "Select any row to see why the rule matched or not",
    AutomationRule_RuleNameTooltipText: "Pick a name that helps you remember what the rule is about",
    AutomationRuleError_FeatureNotAvailable: "Bank rules are not available for your current plan",
    AutomationRuleError_InvalidConstraint: "Invalid condition defined, please check the field and condition type",
    AutomationRuleError_InvalidEditOfEditedRule: "The rule has already been edited after it was shown here, please reload the rules list and try again",
    AutomationRuleError_InvalidRecipeAction: "Invalid template selected, please choose another recipe for the rule",
    AutomationRuleError_InvalidStatusChange: "Invalid status change for the rule, please reload the rules list and try again",
    AutomationRuleError_MaxConstraintCountExceeded: "Rule exceeds the maximum number of conditions allowed. Remove some conditions and try again",
    AutomationRuleError_MaxRuleCountExceeded: "Maximum rules count exceeded. To add a rule another one must first be removed",
    AutomationRulePreview_ContainsType: "contains",
    AutomationRulePreview_EndsWithType: "ends with",
    AutomationRulePreview_EqualToType: "is equal to",
    AutomationRulePreview_StartsWithType: "starts with",
    AutomationRules: "Bank rules",
    AutomationRules_ActionHeading: "2. Action",
    AutomationRules_AddConstraint: "Add another condition",
    AutomationRules_AddConstraintGroup: "Add condition group",
    AutomationRules_AddConstraintToGroup: "Add condition to the group",
    AutomationRules_BankTransactionsTemplateSelectionBasedOnRule: "Template(s) selected based on rule:",
    AutomationRules_ChangePriority: "Change priority",
    AutomationRules_ConstraintConnector_AND: "And",
    AutomationRules_ConstraintConnector_AND_Label: "All conditions match",
    AutomationRules_ConstraintConnector_OR: "Or",
    AutomationRules_ConstraintConnector_OR_Label: "Any condition match",
    AutomationRules_ConstraintField_AccountNumber: "Payment account",
    AutomationRules_ConstraintField_Amount: "Amount",
    AutomationRules_ConstraintField_Message: "Description",
    AutomationRules_ConstraintHeading: "1. Conditions",
    AutomationRules_ConstraintType_Contains: "contains",
    AutomationRules_ConstraintType_EndWith: "ends with",
    AutomationRules_ConstraintType_EqualTo: "equals",
    AutomationRules_ConstraintType_GreaterThan: "greater than",
    AutomationRules_ConstraintType_LessThan: "less than",
    AutomationRules_ConstraintType_NoConstraint: "Choose type of constraint",
    AutomationRules_ConstraintType_StartsWith: "starts with",
    AutomationRules_CreateNewRule: "Create new rule",
    AutomationRules_DeleteConfirmation: "The bank rule will be deleted.",
    AutomationRules_DoesNotMatch: "Doesn't match",
    AutomationRules_EditModalTitle: "Bank rules",
    AutomationRules_Explanation: "If you have many similar recurring bank transactions it is possible to create rules for which template that should be used. That way you do not have to search for the correct template everytime and can record many transactions in one go.",
    AutomationRules_FromTaxAccount: "This rule is created by the tax account integration and cannot be edited",
    AutomationRules_Matches: "Matches",
    AutomationRules_MissingPermissionToSave: "You do not have the appropriate permissions to save bank rules",
    AutomationRules_NoPreviewMessage: "Fill in all mandatory fields to preview matches",
    AutomationRules_OperatorLabelGroup: "Group match if",
    AutomationRules_PreviewLabel: "Preview",
    AutomationRules_RootOperatorLabel: "Rule match if",
    AutomationRules_RuleDirection_Cost: "Money out",
    AutomationRules_RuleDirection_Income: "Money in",
    AutomationRules_RuleDirectionTitle: "Show template for",
    AutomationRules_RuleMatch_DoesntMatch: "The rule doesn't match the row",
    AutomationRules_RuleMatch_Matches: "The rule matched the row",
    AutomationRules_RuleMatch_WrongDirection: "The selected template has the wrong direction for this row",
    AutomationRules_RuleNameLabel: "Rule name",
    AutomationRulesList_EmptyState_LearnMore: "Learn more about creating bank rules",
    AutomationRulesList_EmptyState_Message: "Save time bookkeeping by creating rules for your recurring bank transactions.",
    AutomationRulesList_EmptyState_Title: "No bank rules to show",
    AutoVerification_InputAmounts_FormLabel: "How should the amount be split up?",
    AutoVerificationRow_InputRowCount_FormLabel: "How many rows should the original be split into? (Max: {0})",
    AutoVerificationRow_Split: "Split bank row",
    AutoVerificationRow_UndoSplit: "Undo split transaction",
    AutoVerificationRow_UndoSplit_Description_Invalid: "The above transaction is recorded. The recording has to be deleted or nullified in order to continue.",
    AutoVerificationRow_UndoSplit_Description_Valid: "The above transactions will be deleted and replaced with the following transaction:",
    AutoVerificationRow_UndoSplit_DetachReceipt_Warning: "When undoing the split transaction, {0} attached receipts will be detached from the transaction. These receipts can be found in Uploads.",
    Balance: "Balance",
    BalanceReport: "Balance sheet",
    BalanceReport_NoRecordedTransactions_message: "Account {0} has no recorded transactions for this period",
    Bank_ConfirmDeleteImport_CTA: "Delete import",
    Bank_ConfirmDeleteImport_Prompt: "Are you sure you want to delete this import?",
    Bank_ConfirmDeleteImport_Title: "Delete import",
    Bank_CountTransactions: "{x} transactions",
    Bank_Date: "Date",
    Bank_DisconnectModal_Action: "Disconnect",
    Bank_EditPreviousImports_CTA: "Manage imports",
    Bank_ImportedOn: "Imported on {date}",
    Bank_ImportFromBank: "Import from bank",
    Bank_ImportManually_CTA: "Import manually",
    Bank_ImportsEmpty: "When you have done your first bank import it will appear here.",
    Bank_LastImportedRow: "Date of last row",
    Bank_Sum: "Sum",
    Bank_Title: "Message",
    BankAccount_Action_Sync: "Sync",
    BankAccount_FetchingTransactions: "Fetching your transactions",
    BankAccount_WhenProblemsThenImportManually: "If you experience any issues with the automatic bank sync, you can also {import transactions manually}",
    BankDemo_Header: "Example",
    BankDemo_Heading: "Demo Bank",
    BankDemo_Menu_AccountsAndCards: "Accounts and Cards",
    BankDemo_Menu_Funds: "Funds",
    BankDemo_Menu_Loans: "Loans",
    BankDemo_Menu_Start: "Home",
    BankDemo_Step1_Instruction: "Start by clicking anywhere in the transaction table",
    BankDemo_Step2_Instruction: "Press",
    BankDemo_Step3_Instruction: "When all text is selected, press",
    BankDemo_Step4_Instructions_Text: "When you have done this in your bank, go back to Bokio and paste with",
    BankFeed_ConnectBank_CTA: "Connect bank",
    BankFeed_ConnectionsNotConnected: "You have a bank feed that hasn't been connected to a bookkeeping/payment account yet. {Select payment account now}",
    BankFeed_ConnectToBank_CTA: "Connect to bank",
    BankFeed_ConnectToBank_PoweredBy: "Powered by",
    BankFeed_ConnectToBank_Preamble: "With automatic bank connection, your transactions from your external bank are synced directly to Bokio. You can easily manage your bank entries from To Do or Transactions to Record. Save time and reduce the risk of errors by eliminating manual entry.",
    BankFeed_ConnectToBank_Recommended: "Recommended",
    BankFeed_ConnectToBank_Steps_1: "Start by selecting your bank and confirm through authentication.",
    BankFeed_ConnectToBank_Steps_2: "Select the bank accounts you want to connect to Bokio.",
    BankFeed_ConnectToBank_Steps_3: "Set up the Bookkeeping / Payment account each bank account should be connected to and specify the date from which you want to retrieve your transactions.",
    BankFeed_ConnectToBank_Title: "Connect your bank to Bokio",
    BankFeed_DisconnectBankModal_Description: "What do you want to do with the transactions imported from this bank?",
    BankFeed_DisconnectBankModal_KeepUnrecordedTransactions: "Keep transactions",
    BankFeed_DisconnectBankModal_RemoveUnrecordedTransactions: "Remove transactions",
    BankFeed_DisconnectBankModal_Title: "Disconnect {0}",
    BankFeed_FetchingTransactions_Information: "We are fetching your transactions in the background and will notify you when it is done.",
    BankFeed_Manage_AccountLabel: "Bookkeeping/payment account",
    BankFeed_Manage_AccountPlaceholder: "Select bookkeeping/payment account",
    BankFeed_Manage_AddAccountButton: "Add payment account",
    BankFeed_Manage_AddBank: "Add bank",
    BankFeed_Manage_BankAccountNumber: "Bank account number",
    BankFeed_Manage_ConnectToPaymentAccount: "Choose bookkeeping account for your accounts",
    BankFeed_Manage_ConnectToPaymentAccountDescription: "All your future transactions will be automatically connected to this bookkeeping/payment account.",
    BankFeed_Manage_ConnectToPaymentAccountHelpText: "How to choose bookkeeping account",
    BankFeed_Manage_DisconnectBank: "Disconnect bank",
    BankFeed_Manage_GetStatementsFrom: "Get transactions from",
    BankFeed_Manage_InvalidCombination: "Both payment account and get transaction from date must be set",
    BankFeed_Manage_LinkToDomesticPaymentMethodOnCreatedInvoicePrompt_Title: "Do you want to use Bokio Business Account on your sales invoices?",
    BankFeed_Manage_LinkToDomesticPaymentMethodOnCreatedInvoiceToggle_Description: "Invoices sent through Bokio in the future will get {0} as the selected payment option, which will make automatic payments possible. OCR number will also be activated on the invoice. These settings can be changed later under Invoice settings.",
    BankFeed_Manage_LinkToDomesticPaymentMethodOnCreatedInvoiceToggle_Title: "Do you want to use your new {0} on your sales invoices?",
    BankFeed_Manage_NavigationText: "Manage",
    BankFeed_Manage_NavigationText_Extended: "Manage connected banks",
    BankFeed_Manage_Reconnected: "Reconnected bank {0}",
    BankFeed_Manage_SameBookkeepingAccountWarning: "Importing multiple accounts to the same payment account is not possible. Please remove one or choose a different account.",
    BankFeed_Manage_Title: "Manage bank feeds",
    BankFeed_ManageConfirm_Text: "We will import your transactions from the date you entered into the payment account that you entered. If you want to change the from date after you have confirmed, you will have to disconnect and then connect your bank again.",
    BankFeed_ManageConfirm_Title: "Confirm import",
    BankFeed_SyncMessage: "Your bank transactions have been fetched and are ready to be recorded. {View in Todo}",
    BankImport_Action_DisconnectJournalEntry: "Disconnect from journal entry",
    BankImport_Status_NewItem: "New Item",
    BankImport_Status_Processing: "Processing by bank",
    BankImport_Status_Removed: "Removed manually",
    BankImport_StatusLink_UndoRemove: "undo",
    BankImport_Step1: "Import",
    BankImport_Step2: "Import options",
    BankImport_Step3: "Confirmation",
    BankImportUpload_Body: "We could not read the text that was pasted.\n\nYou can help us fixing this problem by submitting the content to our services.",
    BankImportUpload_DoNotSend: "Do not upload",
    BankImportUpload_DontAskAgain: "Remember my choice",
    BankImportUpload_DoSend: "Upload",
    BankImportUpload_Title: "Ooops!",
    BankParser_AddedRows_Message: "The added rows can now be recorded from the todo list",
    BankParser_AmountPaid: "Paid",
    BankParser_AmountReceived: "Received",
    BankParser_AutoDetect: "Let Bokio guess",
    BankParser_BankName: "The name of your bank",
    BankParser_Duplicate_ColHeading: "Has duplicate",
    BankParser_Duplicate_Deleted: "Deleted",
    BankParser_Duplicate_InQueueOrBookkept: "Recorded or in queue",
    BankParser_Duplicate_Tooltip: "This rows has already been imported earlier",
    BankParser_Error_FoundNoTableInPaste: "We could not find a table in the pasted text",
    BankParser_Error_MustBeOneToOneMapping_Date: "Exactly one column should have the Date heading.",
    BankParser_Error_MustBeOneToOneMapping_Message: "Exactly one column should have the Message heading.",
    BankParser_Error_MustBeOneToOneMapping_Sum: "Exactly one column should have the Amount heading.",
    BankParser_Error_RowsOfDifferentLength: "The rows in the table have different lengths",
    BankParser_FailedToParseExcel: "Unable to parse Excel file",
    BankParser_FromWhichBank: "Which bank are you importing from?",
    BankParser_Heading: "Get data from bank",
    BankParser_ImportXRowsWhereofYDuplicates: "Add {0} rows, whereof {1} already was imported",
    BankParser_Include_ColHeading: "Add",
    BankParser_NextStep: "Continue",
    BankParser_NoChangeColumns: "No, change columns",
    BankParser_OpenTodo: "Open todo list",
    BankParser_OtherBank: "Other bank",
    BankParser_PasteBankStatementInfo: "Paste a copy of the bank statement from your online bank. Feel unsure of how to do? {View sample statement}",
    BankParser_PleaseFixMappingErrors: "Please fix these {0} mapping errors",
    BankParser_ReportProblem: "Report problem",
    BankParser_ReportProblem_Comment: "Leave a comment (optional)",
    BankParser_UseNewColumns: "Use new columns",
    BankParser_ViewAccount: "View account",
    BankParser_XRowsImported: "{0} rows have been added",
    BankParser_YesImport: "Yes, Import",
    BankReconciliation_AmountLeftToRecord: "Amount left to record",
    BankReconciliation_AmountLeftToRecord_Tooltip: "This is the total amount of the transactions imported from your bank that haven't been recorded yet.",
    BankReconciliation_BalanceInBank: "Balance in bank",
    BankReconciliation_BalanceInBank_Tooltip: "This is the balance fetched from your bank account at the last updated date above. This will agree to your bank statement.",
    BankReconciliation_BalanceInBokio: "Balance in Bokio",
    BankReconciliation_BalanceInBokio_Tooltip: "This is the balance in Bokio from the recorded transactions in this account. This will be the same balance as shown in the General ledger and Balance sheet report on today's date.",
    BankReconciliation_BankStatement: "Bank statement",
    BankReconciliation_ConnectAccount_Header: "This payment account is not connected to a bank feed!",
    BankReconciliation_ConnectedToBank: "Connected to bank",
    BankReconciliation_ItemsToReconcile: "Journal entries to reconcile",
    BankReconciliation_ItemsToReconcile_Tooltip: "This is the number of journal entries recorded in the account {0} that are not yet linked to an imported bank transaction.",
    BankReconciliation_NotConnected: "Not connected",
    BankReconciliation_NotConnectedToBank: "Not connected to bank",
    BankReconciliation_NotRecorded: "Not recorded",
    BankReconciliation_Reconcile: "Reconcile",
    BankReconciliation_Reconciled: "Reconciled",
    BankReconciliation_RunningBalance: "Running balance",
    BankReconciliation_ShowDeletedTransactions: "Show deleted transactions ({0})",
    BankReconciliation_State_BalacesAreCorrect: "Well done! You have nothing left to record and your balance in Bokio agrees with your balance in your bank!",
    BankReconciliation_State_BookkeepingAndBankNotConnected: "You have manually recorded journal entries to this account but have not connected your bank to Bokio. Do so below and then make sure to reconcile those journal entries with the imported transactions.",
    BankReconciliation_State_DoesNotBalance: "Your bank statement does not agrees with the balance in Bokio. Make sure you have added your opening balances and look for any missing transactions in your bank as well as any wrongly recorded bank transactions in the general ledger.",
    BankReconciliation_State_ItemsToReconcile: "You have recorded some journal entries to this account manually that are not yet linked to any imported bank transactions. You should reconcile them with the imported transactions from your bank.",
    BankReconciliation_State_NotSynced: "If you would like to reconcile it with your bank account, connect it first to your bank under Bank feeds.",
    BankReconciliation_State_OpeningBalanceUncertainForPeriod: "You need to close older fiscal years in order for the bank reconciliation to determine the correct balances for the bank accounts.",
    BankReconciliation_State_TransactionsToRecordAndItemsToReconcile: "You have imported bank transactions to be recorded and you have also manually recorded journal entries to this account that are not yet linked to any imported bank transactions. We suggest that you first record all transactions and then review the journal entries left to reconcile.",
    BankReconciliation_State_TransactionsToRecordWillAgree: "You have transactions to record, once these are recorded your balance in Bokio will agree to the balance in your bank.",
    BankReconciliation_State_TransactionsToRecordWontAgree: "You have transactions to record, once these are recorded your balance in Bokio will not agree to your bank balance. Look for any missing transactions in your bank statement or wrongly recorded ones in the general ledger.",
    BankReconciliation_ThingsToResolve: "Things to resolve",
    BankTransactions_Confirmation_Description_MatchInvoice: "Your bank transaction has been matched with an invoice payment in Bokio. You can see details of your recorded journal entry below.",
    BankTransactions_Confirmation_Description_MatchJournalEntry: "The journal entry below was already recorded and we have now linked this to the selected bank transaction.",
    BankTransactions_Confirmation_Description_MatchSupplierInvoice: "Your bank transaction have been matched with a supplier invoice payment in Bokio. You can see details of your recorded journal entry below.",
    BankTransactions_Confirmation_Title_MatchInvoice: "Payment matched and registered",
    BankTransactions_Confirmation_Title_MatchJournalEntry: "Matched with journal entry",
    BankTransactions_Empty_Import: "You have no bank transactions to record. You can fix this by importing bank statements from your bank.",
    BankTransactions_Empty_Sync: "You have no bank transactions to record. You can fix this by connecting you bank to Bokio.",
    BankTransactions_EverythingRecorded: "You have recorded all of your bank transactions. You will be notified once you have more transactions to record.",
    BankTransactions_HowDoesBankImportWork: "How does bank import work?",
    BankTransactions_HowToSetupBankFeeds: "How to setup bank feeds",
    BankTransactions_ManualBulk: "You can’t manually record items when recording multiple transactions at once",
    BankTransactions_MatchInvoice: "Match and register payment",
    BankTransactions_MatchItems: "Match {0} items",
    BankTransactions_MatchJournalEntry: "Match with journal entry",
    BankTransactions_MatchSalaryPeriod: "Connect and record taxes",
    BankTransactions_MatchVatPeriod: "Connect and record taxes",
    BankTransactions_MultipleTemplatesNotSupported: "Multiple templates are not supported when selecting more than one transaction.",
    BankTransactions_MultipleTemplatesNotSupported_Description: "At this moment you can only use multiple templates when recording one transaction at a time. By selecting more than one transaction you will lose your progress.",
    BankTransactions_TemplateHasNoBulkSupport: "You currently can't use this template when recording multiple transactions.",
    Bookkeep: "Record",
    Bookkeep_AllTemplates_TabTitle: "All templates",
    Bookkeep_Manual_TabTitle: "Manual",
    Bookkeep_Search_TabTitle: "Search",
    BookkeepCategory_NonMoney_Description: "Journal entries that do not involve the movement of money e.g. Fuel scale charge, corporation tax accrual and postponed VAT accounting",
    BookkeepCategory_NonMoney_Header: "Non-Money",
    BookkeepCategoryDesc_Income_text: "Income received after having sold a product or service, either a receipt or a customer invoice.",
    BookkeepCategoryDesc_Other_text: "All that do not fall under the above categories, e.g. payments to tax account, bank transfers, deposits, etc.",
    BookkeepCategoryDesc_Receipt_text: "Costs and expenses for direct purchase, i.e. you pay directly on site and receive a receipt.",
    BookkeepCategoryDesc_Supplier_text: "Invoices received after purchasing a product or service,  generally with credit period of 1 month before payment.",
    BookkeepCategoryStep_NextButton: "Next",
    BookkeepIncome: "Record income",
    Bookkeeping_AddCategory_action: "Add template",
    Bookkeeping_Amount: "Amount",
    Bookkeeping_CategoryStep_Billing: "Bokio services",
    Bookkeeping_CategoryStep_Billing_Description: "Payment of Bokio services",
    Bookkeeping_CategoryStep_Income: "Money in",
    Bookkeeping_CategoryStep_Other: "Other",
    Bookkeeping_CategoryStep_ReceiptOrExpense: "Money out",
    Bookkeeping_CategoryStep_SaveAndDoLaterButton: "Save and do later",
    Bookkeeping_CategoryStep_SupplierInvoice: "Supplier invoice",
    Bookkeeping_CategoryStep_WhatIsYourUploadedMaterial: "What type of transaction do you want to record?",
    Bookkeeping_CompanyInfo_NeedMoreInfo: "We need some more information about your company to start accounting.",
    Bookkeeping_CompanyInfo_UpdateAndContinue: "Update and continue",
    Bookkeeping_CompanyInfo_WhatTypeOfCompany: "What type of company do you have?",
    Bookkeeping_CompanyInfo_WhichMethod: "Which accounting method are you using?",
    Bookkeeping_Completed: "Completed",
    Bookkeeping_CreateSupplierInvoice_Header: "Create supplier invoice",
    Bookkeeping_Due: "Due",
    Bookkeeping_Issued: "Issued",
    Bookkeeping_SumCategoryMissing: "Unfortunately this accounting template is not currently usable with bank imports or expenses. In order to record this item you can use the manual accounting option.",
    Bookkeeping_SupplierInvoiceCreateAndExit: "Create and exit",
    Bookkeeping_TotalAmount: "Total amount: {0}",
    Bookkeeping_TotalAmountMustAddUpTo: "Total amount must add up to: {0}",
    BookkeepingCashSupplierInvoice_BookkeepingPrepared: "Bookkeeping prepared!",
    BookkeepingCompleted_FeedbackText: "We want you to be able to bookkeep as smoothly as possible. If you have any feedback we would love to hear it. Were you able to find the correct template, could you easily do a manual recording or did you experience any issues that you would like us to improve?",
    BookkeepingMenu_CategoryBookkeep: "Record",
    BookkeepingMenu_CategoryCreate: "Create",
    BookkeepingMenu_CategoryImport: "Import",
    BookkeepingMenu_ImportFromBank: "Bank feeds",
    BookkeepingMenu_ImportFromFile: "Import from file",
    BookkeepingMenu_Invoice: "Invoice",
    BookkeepingMenu_Receipt: "Receipt",
    BookkeepingMenu_Salary: "Salary",
    BookkeepingMenu_UploadMaterial: "Upload receipt or invoice",
    BookkeepingMenu_WithoutReceipt: "Without receipt",
    BookkeepingSchool_Instructions_Title: "Instructions",
    BookkeepingSchool_LearningOutcome: "In the Bookkeeping school you will learn to record uploaded materials that you may find under Things to do. The materials are selected to give you as much understanding of the various types of bookkeeping there are.",
    BookkeepingSchool_LookForGreen: "Find green markers to navigate through the exercises.",
    BookkeepingSchool_Start_DeclineButton: "Decline",
    BookkeepingSchool_Start_Description: "With our Bookkeeping school you will become ready to start off with your real company as you get familiarized with the fundamentals.",
    BookkeepingSchool_Start_Description_2: "You can always find the guide again under Help in the menu.",
    BookkeepingSchool_Start_StartButton: "Start the bookkeeping school",
    BookkeepingSchool_Start_Title: "Welcome to Bokio, {name}",
    BookkeepingSchool_Title: "Bookkeeping school",
    BookkeepingSuccess_JournalEntryRecorded: "Journal entry recorded!",
    BookkeepingSupplierInvoice_Ocr: "OCR number",
    BookkeepingSupplierInvoice_PaymentMessage_Tooltip: "The payment message used by the recipient to identify the source of the payment.",
    BookkeepingSupplierInvoice_PaymentReference: "Payment reference",
    BookkeepingSupplierInvoiceSum: "Sum including VAT",
    BookkeepManual_PreviewAndBookkeep_Action: "Preview and record",
    BookkeepManually: "Record manually",
    BookkeepReceipt: "Record receipt",
    BookkeepSupplierInvoice: "Record supplier invoice",
    BookkeepSupplierInvoiceStep_CreateAndContinue_Action: "Create and continue",
    BookkeepSupplierInvoiceStep_SaveAndExit_Action: "Save and exit",
    Bookkept_CreatedBy: "Created by",
    Bookkept_SourceFeature: "with the {0}",
    Brexit_BrexitChanges: "Brexit changes",
    Brexit_ChangesDescription: "We need to know which country in the United Kingdom your business is located in so we can make sure your bookkeeping is correct following Brexit.\n\nThere will be new rules for importing and exporting goods to and from EU countries for English, Scottish and Welsh businesses. Northern Irish businesses will continue to apply the rules of the EU when selling and purchasing goods from the EU.\n\nOnce you have answered this question, we will handle the bookkeeping automatically based on the date selected and whether it is before or after Brexit for both invoicing and bookkeeping.",
    Brexit_DueToBrexit_Description: "Due to Brexit, we need to know which country your company is located in. This will let us apply new rules so your accounting is correct.",
    Brexit_LearnMore: "Learn more",
    Brexit_SaveAndContinue_Action: "Save and continue",
    Brexit_SelectLocation_Header: "Select company location",
    Brexit_ThisInformationAlsoInCompanySettings: "This information can also be found in company settings",
    ChartOfAccounts_VatMapping_Header: "VAT mapping",
    CheckVerifications_Title: "Check verification",
    ChooseSupplier: "Choose supplier",
    ChooseUploadedReceipt: "Choose uploaded receipt",
    ClosingBalance: "Closing balance",
    Closure_NothingToRecord: "You do not have an ingoing balance to record since this fiscal years result is zero.",
    ConfirmEditImportedNotice_Body: "If you delete or edit an imported journal entry you will no longer be able to undo the import of entries. If you want to undo the import, go to {imported journal entries}. You can then import an adjusted file to Bokio.",
    ConfirmEditImportedNotice_Edit_Checkbox_Label: "I will only edit this journal entry",
    ConfirmEditImportedNotice_Remove_Checkbox_Label: "I will only remove this journal entry",
    ConfirmEditImportedNotice_Title: "Editing/removing imported journal entry",
    Continue_ButtonText: "Continue",
    ConvertReceiptToExpense: "Move to expenses",
    CopyVerification_CopyAction: "Copy",
    CopyVerification_ModalTitle: "Copy journal entry",
    CopyVerification_VerificationCopied: "Journal entry copied!",
    CreatedByXOnY: "Created by {0} on {1}",
    CreateYear: "Create year",
    Credit: "Credit",
    CreditNoteTemplateNotAvailable: "Credit note templates are currently not available. Please record it manually.",
    CustomBookkeepingTemplates: "Custom bookkeeping templates",
    CustomTemplate_CreateNew_action: "Create new template",
    CustomTemplate_Rating_Description: "Any thoughts that could help us improve your experience with creating custom bookkeeping templates?",
    CustomTemplate_Rating_Title: "How was your experience creating a custom bookkeeping template?",
    CustomTemplateCategory_Cost: "Money Out",
    CustomTemplateCategory_Income: "Money In",
    CustomTemplateCategory_Transfer: "Non-Money",
    CustomTemplates_AreaChoice_Domestic: "Domestic",
    CustomTemplates_AreaChoice_OutsideEU: "From non-EU country",
    CustomTemplates_AreaChoice_WithinEU: "From within the EU",
    CustomTemplates_CreateCustomTemplateModal_heading: "Create custom accounting template",
    CustomTemplates_CreateNewTemplate_description: "Below you can generate a preview of how an example journal entry will look when using the template. Note that these values will not be saved but instead will be filled in when recording. ",
    CustomTemplates_DeleteTemplate_heading: "Delete custom template",
    CustomTemplates_DeleteTemplate_message: "Are you sure you want to delete template \"{0}\"?",
    CustomTemplates_ExampleAreaChoice_label: "Purchase area",
    CustomTemplates_GetStarted_heading: "Get started with custom bookkeeping templates",
    CustomTemplates_GetStarted_message: "Customise Bokio to your needs and speed up repetitive accounting tasks by creating your own accounting templates.",
    CustomTemplates_HelpArticleUrl: "https://www.bokio.co.uk/help/accounting/bookkeeping-in-bokio/create-your-own-bookkeeping-templates/",
    CustomTemplates_PreviewJournalEntry_heading: "Preview journal entry",
    CustomTemplates_PreviewVerification_Button: "Preview",
    CustomTemplates_SaveAnyway: "Save anyway",
    CustomTemplates_SaveTemplate: "Save template",
    CustomTemplates_ShareCustomTemplate_Checkbox: "Shared",
    CustomTemplates_ShareCustomTemplate_CopyMessage: "The shareable link to your custom template is now copied to the clipboard.",
    CustomTemplates_ShareCustomTemplate_Description: "You can share this custom template with other users in Bokio by checking the checkbox and sharing the URL to those you want to share it with. Those users can then create a copy of this template by pasting the shared URL in their browser. If you don't want further copies to be made with the link, you can uncheck the checkbox.",
    CustomTemplates_ShareCustomTemplate_ModalTitle: "Share custom template",
    CustomTemplates_TabOption_Advanced: "Advanced mode",
    CustomTemplates_TransactionDetails_EmptyState_Notice: "You need to fill in recipe type before you can enter transaction details",
    CustomTemplates_TransactionDetails_label: "Transaction details",
    CustomTemplates_TransactionPlaceholder: "e.g. Total * 0.8",
    CustomTemplates_TypeOfTemplate_label: "Type of template",
    CustomTemplates_UpdateCustomTemplateModal_Heading: "Update custom template",
    CustomTemplates_VATRate_label: "VAT rate",
    CustomTemplates_VATRate_tooltip: "This specifies the VAT rate used for the cost or income",
    CustomTemplates_VATUnitType_label: "VAT type",
    CustomTemplates_VATUnitType_tooltip: "This specifies the kind of VAT that will be applied to the template",
    CustomTemplates_VerificationAccount_tooltip: "This specifies the account the cost or income will be allocated to",
    Dashboard_Account_Title: "Accounting balances",
    Dashboard_MissingRequirements_ConfigureNow: "Configure now",
    Dashboard_MissingRequirements_Info: "We need some more information about your company to show the overview",
    Dashboard_Profit_Title: "This year's profit",
    Dashboard_Taxes_Dividend: "Plan your dividend",
    Dashboard_Taxes_Title: "This year's taxes for the company",
    DateCreated: "Date created",
    DateCreatedNewestToOldest: "Date created (newest to oldest)",
    DateCreatedOldestToNewest: "Date created (oldest to newest)",
    Debit: "Debit",
    DeleteEntireReceipt: "Delete entire receipt",
    DeleteMaterial: "Delete receipt or invoice",
    DeleteMaterials: "Delete receipts or invoices",
    DeleteThisPage: "Delete this page",
    DeleteVerificationReason_RecordedTwice: "It’s been recorded twice",
    DeleteVerificationReason_WrongBookkeeping: "The bookkeeping is wrong",
    DeleteVerificationReason_WrongReceipt: "The attached receipt is wrong",
    DownloadPDF: "Download PDF",
    DraftCompleted_DraftCreated: "Journal entry scheduled",
    DraftCompleted_Information: "You can find your scheduled item in your To do. When it’s due, everything is prepared for you to record it with just a click of a button.",
    DraftCompleted_WhenCanYouRecord: "When can I record it?",
    DraftVerification_CreateDraft_Action: "Schedule",
    DraftVerification_DeleteDescription: "Are you sure you want to remove the scheduled item?",
    DraftVerification_ItWillBeScheduled: "This will be scheduled to be recorded on the specified date. Once scheduled the verification will be visible in the {To-Do}.",
    DraftVerification_PreviewAndSchedule: "Preview and schedule",
    DraftVerification_PreviewDraft: "Preview",
    DraftVerification_PublishDescription: "When you record this draft the following journal entry will be added to your journal",
    DraftVerification_UpcommingTodoModal_Description: "You will be able to record the following journal entry on or after the date stated below",
    DraftVerification_YouCanSchedule: "You can now schedule journal entries by selecting a future date below",
    EditVerification_AlreadyChanged: "{0} was already edited or canceled",
    EditVerification_ConfirmChanges: "Confirm changes",
    EditVerification_CorrectionType_Asset: "View fixed assets",
    EditVerification_CorrectionType_Closure: "Edit Closure",
    EditVerification_CorrectionType_Disbursement: "Manage Expense payment",
    EditVerification_CorrectionType_Expense: "Manage Expense",
    EditVerification_CorrectionType_Invoice: "Edit Invoice",
    EditVerification_CorrectionType_Salary: "Edit Salary",
    EditVerification_CorrectionType_SupplierInvoice: "Edit Supplier invoice",
    EditVerification_CorrectionType_VatReport: "Edit VAT return",
    EditVerification_PageTitle: "Edit journal entry",
    EditVerification_Preview_Label_Original: "{0} is the existing journal entry",
    EditVerification_Preview_Label_XWillBeTheNewVerification: "{0} will be the new journal entry",
    EditVerification_Preview_Label_XWillCancelY: "{0} will reverse the effect of {1}",
    EditVerification_RemoveVerification: "Remove journal entry",
    EditVerification_RemoveVerification_Permanently_Description: "You can permanently remove this journal entry as it is the latest journal entry in its series and it is created within less than a month.",
    EditVerification_RemoveVerification_Permanently_Label: "Remove permanently",
    EditVerification_RemoveVerification_Title: "Remove {0}",
    EditVerification_RemoveVerificationAnyway: "Remove journal entry anyway",
    Error_AccountBalanceLimitExceeded: "There is a limit on the balance on one of the accounts involved in the verification that has been exceeded",
    Error_AccountMustBeSelected: "Account must be selected",
    Error_AccountNotInChartOfAccounts: "Account ({0}) doesn't exist in our chart of accounts.",
    Error_AccountNotValid: "The input is not a valid account",
    Error_BookkeepingAmountsCannotBeNegative: "Debit and credit amounts cannot be negative.",
    Error_DuplicateDetected: "A duplicate of this journal entry was already saved. Do you still want to save it?",
    Error_EmptyVerification: "The journal entry is empty!",
    Error_EnterCorrectFormat: "Please upload a file with the format of .jpg, .jpeg or .png instead",
    Error_FiscalYearDoesntExist: "The date is not in any existing fiscal year, create this year &lt;a href=\"/{0}/settings-r/fiscals\" target=\"_self\"&gt;Settings -&gt; Fiscal years&lt;/a&gt;",
    Error_FutureDate: "The date must not be in the future",
    Error_HasLaterConversionBalances: "",
    Error_InvalidAccount: "One or more rows had an invalid account",
    Error_InvalidAmount: "One or more rows had an invalid amount",
    Error_InvalidDateForConnectedEntity: "The date does not match the required value",
    Error_InvalidFile: "This is not a valid file format",
    Error_InvalidTransactionsForConnectedEntity: "Invalid values on required accounts",
    Error_IsAutoVerificationRowsPendingOrInconsistent: "The bank transaction(s) are still pending or the dates of bank transaction(s) and journal entry do not match.",
    Error_NoFiscalYear: "There is no fiscal year!",
    Error_PaymentBeforeInvoiceDate: "The date of the payment is before the date of the purchase",
    Error_ReachedMaximumVerificationLimitForTestCompanies: "The maximum number of verifications for a test company has been reached",
    Error_ReceiptAlreadyBookkept: "Receipt already recorded!",
    Error_ReceiptDeleted: "Receipt has been deleted!",
    Error_RegisterReceiptAlreadyBookkept: "Receipt already recorded!",
    Error_SumDoesNotAddUp: "Debit and credit must add up to the same amount",
    Error_UnableToRegisterPaymentOnJournalEntry: "You are not able to register a payment on this Journal entry",
    Error_VatPeriodClosed: "The date of this journal entry is for a closed VAT period. Consider recording this journal entry at the start of the next open VAT period.",
    Error_VatPeriodClosed_BankTransaction: "The VAT period for the date is closed and the journal entry could affect the VAT. Consider recording this at the start of the next open VAT period. This would need to be done manually, and then matched to the bank line, as you cannot change the date when recording from Bank feeds.",
    Error_VatPeriodClosed_SupplierInvoice: "If you delete this supplier invoice, you will also delete a related journal entry that was recorded in a now closed VAT period. Consider recording this at the start of the next open VAT period.",
    Error_VatPeriodStarted: "The VAT period for the date is reconciled but not closed. Make sure you are on the right period.",
    Error_VerificationWasNotFinished: "There must be at least two rows with debit or credit larger than 0",
    Error_YearClosed: "The fiscal year is closed",
    Error_YearDoesntExist: "The date was outside any existing fiscal year, create this year &lt;a href=\"/{0}/settings-r/fiscals\" target=\"_self\"&gt;Settings -&gt; Fiscal years&lt;/a&gt;",
    ETR_AutomationRule_DontUseCTA: "Don't use rule",
    ETR_AutomationRule_Heading: "Bookkeeping rule",
    ETR_AutomationRule_UseCTA: "Record with rule",
    ETR_Completed_Heading: "Recorded as",
    ETR_ItemAlreadyRecorded: "Already recorded",
    ETR_LockedVatPeriodWarning: "The VAT period for the date is locked. You can only record this if doesn't affect the VAT. Otherwise you have to open the period first.",
    ETR_Matches_DontMatch: "Don't use match",
    ETR_Matches_Match: "Match",
    ETR_Matches_SIMatch_CurrencyWinAmount: "{0} currency win will be recorded",
    ETR_Matches_SIMatch_RecordAsCurrencyWin: "Record the difference as currency win/loss",
    ETR_Matches_SIMatch_RemainingAmount: "Remaining sum will be {0}",
    ETR_Matches_SIMatch_WillClose: "The supplier invoice will be closed",
    ETR_Matches_SIMatch_WillNotClose: "The supplier invoice will be left open",
    ETR_Matches_SIMatch_WillRegisterPayment: "We will register a payment ({0}/{1}) and record it",
    ETR_Matches_VerMatch_Description: "You will connect this bank row to {0}. This will complete the bank row without recording anything new.",
    ETR_MatchingReceipts: "Matching receipts",
    ETR_NoBookkeepingAccount: "Bookkeeping account not set for this bank account",
    ETR_NoMatchingReceipts: "No matching documents found",
    ETR_Title: "Events to record",
    ETR_XMatchingReceipts: "{0} matching document(s) found",
    ETR_XMatchingReceipts_CTA: "Choose document",
    FaultyVerifications: "Faulty journal entries",
    FillInTheDetails: "Enter details",
    FiscalYear: "Fiscal year",
    FiscalYear_CouldNotDelete_VatReportExists: "Fiscal year cannot be deleted because it has a connected VAT report.",
    FiscalYear_CouldNotDelete_VerificationExists: "Fiscal year cannot be deleted because it was not last date or first with zero verifications and zero incoming balances.",
    FiscalYear_QuickCloseError_EndDateWithinTwoBankingDays: "Can not close a fiscal year until two bank days after the end date has passed. This is to ensure any bank related event has been recorded.",
    FiscalYear_Status_CalculatedResultNotZero: "Calculated result is not zero",
    FiscalYear_Status_OutgoingIngoingBalanceDiff: "Outgoing balance issue",
    FiscalYear_Status_ShouldBeClosed: "Should be closed",
    FiscalYearListScene_ActionsDisabledWarning: "You don't have access to perform any actions from this page. Please contact the administrator of this company.",
    FiscalYearListScene_YearHasImbalancedOutoingBalances: "Your assets and liabilities do not balance. This is most likely due to some of your fiscal years having a saved \"Beräknat resultat\" that is not equal to zero. Learn more about {how to fix the problem in our guide}.",
    FiscalYearListScene_YearHasOutgoingIngoingBalanceDiff: "Your calculated balance does not match the saved one. You fix this easily by opening and closing the specified fiscal year.",
    FiscalYears: "Fiscal years",
    FiscalYears_SaveAndApprove_Button: "Save and approve",
    FiscalYears_SaveAndApprove_Tooltip: "This fiscal year has been generated in connection to automatic recordings. The settings are taken from the previous year. Please review and correct the settings if needed. Once it is correct, click save and approve.",
    FiscalYearsIssueNotice_ImbalancedOutgoingBalances: "Your assets and liabilities do not balance. This is most likely due to some of your fiscal years having a saved \"Beräknat resultat\" that is not equal to zero. {Review your fiscal years} or learn more about {how to fix the problem in our guide}.",
    FiscalYearsIssueNotice_OutgoingIngoingBalanceDiff: "Your calculated balance does not match the saved one. You fix this easily by opening and closing the {previous fiscal years}.",
    FiscalYearStatus_NeedsReview: "Needs review",
    HintAboutFullImport: "Here you can import journal entries from another system continuously. If you want to import earlier bookkeeping when you start using Bokio you should go to {0}.",
    Hmrc_AuthError_Connect: "Connect",
    Hmrc_AuthError_ConnectText: "You are not currently connected to HMRC. In order to retrieve or update the company's VAT information you will first have to connect to HMRC.",
    Hmrc_AuthError_ConnectTitle: "Connect to HMRC",
    Hmrc_AuthError_Reconnect: "Reconnect",
    Hmrc_AuthError_ReconnectText: "We are sorry but we were unable to access HMRC. You need to reconnect to HMRC to grant Bokio authority to access the company's VAT information.",
    Hmrc_AuthError_ReconnectTitle: "Reconnect to HMRC",
    Hmrc_BookkeepPayment_AlreadyBookkept: "I have already recorded this",
    Hmrc_BookkeepPayment_BookkeepAndComplete: "Record and complete",
    Hmrc_BookkeepPayment_BookkeptAs: "Recorded as",
    Hmrc_BookkeepPayment_Content: "When your payment is complete you have to record the amount in order to finish the VAT return process.",
    Hmrc_BookkeepPayment_Header: "Record VAT payment",
    Hmrc_Cancel: "Cancel",
    Hmrc_EmptyState_Text: "There are no VAT returns to show or the company haven’t connected to HMRC yet. You can update the VAT list at any time to get the latest updates.",
    Hmrc_EmptyState_UpdateList: "Update VAT list",
    Hmrc_ExternalSubmitContent: "We are unable to show additional information for this VAT report. This is likely due to it being recorded outside of Bokio. For more detailed information of this period please visit [HMRC](https://www.gov.uk/log-in-register-hmrc-online-services) or the tool you submitted it from.",
    Hmrc_ExternalSubmitHeader: "VAT return information",
    Hmrc_ExternalSubmitJournalEntryHeading: "VAT journal entry",
    Hmrc_ExternalSubmitManualJournalEntry: "VAT journal entry needs to be recorded manually for this report",
    Hmrc_ExternalSubmitManualJournalEntryRecorded: "VAT journal entry manually recorded",
    Hmrc_LastSynced: "Last synced with HMRC: {0}",
    Hmrc_List_DueDate: "Due date",
    Hmrc_List_SubmittedOn: "Submitted on",
    Hmrc_List_UpdateVatList: "Update VAT list",
    Hmrc_PayOrReclaim_Content: "Before you can record the VAT return you will either have to pay or reclaim money from HMRC.",
    Hmrc_PayOrReclaim_Header: "Pay or reclaim money",
    Hmrc_ReportSummary_AmountToGet: "Amount to receive",
    Hmrc_ReportSummary_AmountToPay: "Amount to pay",
    Hmrc_ReportSummary_PaymentDue: "Payment due date",
    Hmrc_ReportSummary_SubmitDue: "Submission due date",
    Hmrc_ReviewReport_BookkeptAs: "VAT period recorded as",
    Hmrc_ReviewReport_Description: "Review your VAT return before submitting it to HMRC and make sure:\n  *   All your customer and supplier invoices have been recorded for the period\n  *   All other income and expenses have been recorded for the period\n  *   Your bank statements matches the balance in account 1200\n  *   All balance sheet accounts are correct\n  *   Run the VAT checker to ensure that everything is posted correctly. [Check report](bokio:{0})",
    Hmrc_ReviewReport_ReviewAndSubmit: "Review and Submit",
    Hmrc_ReviewReport_Title: "Review and submit VAT",
    Hmrc_ReviewReport_ZeroVatNotice: "Your VAT for this period is zero but you still have to submit your VAT return to HMRC.",
    Hmrc_Status: "Status",
    Hmrc_StatusLabel_Completed: "Completed",
    Hmrc_StatusLabel_Current: "Current",
    Hmrc_StatusLabel_Incomplete: "Incomplete",
    Hmrc_StatusLabel_Overdue: "Overdue",
    Hmrc_StatusLabel_Submitted: "Submitted",
    Hmrc_Submitted: "Submitted",
    Hmrc_SubmittedOn: "Submitted on {0}",
    Hmrc_SumbitReportModal_ConfirmAndSubmit: "Confirm and submit",
    Hmrc_SumbitReportModal_ConfirmText: "I confirm that the information I am submitting is true and complete",
    Hmrc_SumbitReportModal_Legal: "When you submit this VAT information you are making a legal declaration that the information is true and complete. A false declaration can result in prosecution.",
    Hmrc_SumbitReportModal_NoVatContent: "You are about to submit your VAT return to HMRC. Bokio will also close the period for you and you should not record anything else for this period.",
    Hmrc_SumbitReportModal_Title: "Are you sure you want to submit and lock the period?",
    Hmrc_SumbitReportModal_WithVatContent: "You are about to submit your VAT return to HMRC. Bokio will also close the period for you and you should not record anything else for this period. Your journal entry will look like this:",
    Hmrc_UnableToSubmit_PeriodOngoing: "You cannot submit your VAT return to HMRC until your VAT period has finished",
    Hmrc_UnlockConfirmation_WithoutVerification: "Are you sure you want to unlock this step? The VAT return will then be marked as incomplete.",
    Hmrc_UnlockConfirmation_WithVerification: "Are you sure you want to unlock this step? The connected journal entry will be deleted and the VAT return will be marked as incomplete.",
    Hmrc_UnlockStep: "Unlock step",
    Hmrc_VrnError_IncorrectText: "The VAT registration number you have entered is incorrect",
    Hmrc_VrnError_IncorrectTitle: "VAT registration number is incorrect",
    Hmrc_VrnError_MissingText: "You need to enter your VAT registration number to get information from HRMC",
    Hmrc_VrnError_MissingTitle: "VAT registration number is missing",
    Hmrc_VrnError_Submit: "Submit",
    Hmrc_ZeroVat_CompleteButton: "Complete VAT return",
    Hmrc_ZeroVat_Description: "Your VAT return for this period was zero. This means that you have no payment to record. Complete the VAT return to mark it as done.",
    ImportAccountReceivables: "Trade debtors",
    ImportAsset_InfoBar: "Import fixed assets that are not yet being tracked in Bokio. These can be already recorded assets which are being tracked in another tool or spreadsheet outside of Bokio.",
    ImportBookkeeping: "Import accounting",
    ImportedVerification_Notice_Body: "If you want to undo import of journal entries, go to {imported journal entries}. You can then import an adjusted file to Bokio.",
    ImportSi_Errors_Title: "Errors found validating your journal entries",
    ImportSi_ErrorsFound: "Errors found in your files",
    ImportSi_Header: "Import journal entries",
    ImportSi_ParseErrors_Title: "Errors found reading your files",
    ImportSuppliers: "Import suppliers",
    ImportSuppliers_NumberOfImportedArticle: "{ok} of {total} suppliers was successfully imported",
    ImportSuppliers_NumberOfImportedArticle_Description: "You can find them in your supplier list",
    ImportValidationFatal_ImportYearIsBeforeClosedYears: "The imported fiscal year starting on {0} is before years that have already been closed. Reopen those years before importing.",
    ImportValidationFatal_ImportYearMismatchesSetting: "overlaps existing fiscal year",
    ImportValidationFatalFiscalYearClosedHelpDetailsText: "Some existing fiscal year is closed and importing could affect the balances of that year. Go to the settings for the listed fiscal years and ensure they are marked as open before importing.",
    ImportValidationFatalHelpDetailsText: "This import would create a gap between the fiscal years below. Usually this is caused by a change in the start month for a fiscal year. If that is the case you can create or edit the fiscal years that the company has had under Settings so they match up with the ones in the import and then try the import again.",
    ImportValidationFatalHelpText: "These errors make it impossible to import your accounting. Please contact support and we will help you",
    ImportValidationFatalSettingMismatchHelpDetailsText: "Imported year overlaps fiscal year already created in Bokio. To be able to import the file the start and end date of the fiscal years need to match up. The dates for fiscal years can be changed under Settings.",
    ImportValidationFatalTitle: "Important errors found",
    ImportValidationWarningHelpText: "Some of these errors might cause your accounting to be faulty. Please review the errors to make sure it's correct. Contact support if you need help with this.",
    ImportValidationWarningTitle: "Possible errors found",
    IncomingBalanceShort: "OB",
    IngoingPayments: "Ingoing payments",
    Journal_Annulled: "Annulled",
    Ledger: "General ledger",
    ManageBankRules: "Manage bank rules",
    ManualTab_CustomTemplates_Notice_heading: "Often recording manual transactions?",
    ManualTab_CustomTemplates_Notice_Message: "{Create custom templates} and make the bookkeeping easier.",
    MapCustomAccounts: "Map custom accounts",
    Matches: "Matches",
    MathematicalExpressionTip_Desc: "Simply input your desired mathematical expression and we will do the calculation for you!",
    MathematicalExpressionTip_Example: "For example, if you want to calculate 25% VAT on 100, you can simply enter 0.25*100.",
    MathematicalExpressionTip_Heading: "Use our built-in calculator",
    MathematicalExpressionTip_Tip: "Tips",
    MergeReceipts: "Merge receipts",
    MissingVerifications: "Missing journal entries",
    MoneyAccount_Account_Column: "Payment account",
    MoneyAccount_AddAndManagePaymentAccounts: "Add and manage payment accounts",
    MoneyAccount_Bookkeeping: "Bookkeeping",
    MoneyAccount_Cancel: "Cancel",
    MoneyAccount_EditAccounts: "Edit payment account",
    MoneyAccount_Invoices: "Invoices",
    MoneyAccount_ManageAccountsDescription: "Setup the accounts that you want to have available when doing your bookkeeping. You can only add accounts that are part of your chart of accounts.",
    MoneyAccount_Name_Column: "Name",
    MoneyAccount_NewAccount_Button: "New payment account",
    MoneyAccount_NewAccount_ModalTitle: "New payment account",
    MoneyAccount_PaymentAccounts: "Payment accounts",
    MoneyAccount_Preselect_ModalDescription: "Select if you want this account to be preselected in any of the areas below when bookkeeping. You can only have one account selected in each area.",
    MoneyAccount_Preselect_ModalHeader: "Preselect",
    MoneyAccount_PreselectedIn_Column: "Preselected in",
    MoneyAccount_RemoveAccount: "Remove payment account",
    MoneyAccount_Salary: "Salary",
    MoneyAccount_Save: "Save",
    MoneyAccount_YourPaymentAccounts: "Your payment accounts",
    MoneyAccounts_AccountType: "Account type",
    MoneyAccounts_CurrentBalance: "Current balance",
    MoneyAccounts_PaymentAccount: "Payment Account",
    MoneyGoingIn: "Money going in",
    MoneyGoingOut: "Money going out",
    MostUsedTemplates: "Your most common templates",
    MultipleVerifications: "Multiple verifications",
    NewBalance_Heading: "New balance",
    Next: "Next",
    NMaterialsWillBeDeleted: "{0} receipts or invoices will be deleted",
    NoSupplierFound: "No supplier found",
    NrOfMaterialsSelected: "{0} receipts or invoices selected",
    OkConfirm_ButtonText: "Ok, confirm",
    Onboarding_ChangeBG_Step4: "Use new Bankgiro number",
    Onboarding_DontChangeBG_Step4: "Don't change",
    OpeningBalance: "Opening balance",
    OpeningBalances: "Opening balances",
    Or: "Or",
    OtherIncomeAnnually: "Other income ({units} per year)",
    OutgoingBalanceShort: "CB",
    OutgoingPayments: "Outgoing payments",
    PaymentAccounts: "Payment accounts",
    PaymentDate: "Payment date",
    PaymentDateNewestToOldest: "Payment date (newest to oldest)",
    PaymentDateOldestToNewest: "Payment date (oldest to newest)",
    PaymentDirection: "Payment direction",
    PlanYourSalary: "Plan your salary",
    PostReceiptUpload_InfoTooltip: "Skatteverket require you to save the original copy of your accounting record for three years.",
    PreBookkeep_ChooseCategory: "Choose category",
    Prediction_ShowMostUsed: "Show your most common templates instead of suggestions",
    Prediction_Supplier_Income_Title: "Income from {0} are usually recorded as:",
    Prediction_Supplier_Purchase_Title: "Purchases from {0} are usually recorded as:",
    Prediction_TextMatch_Title: "{0} is usually recorded as:",
    PrepareBookkeeping_SupplierInvoice: "Prepare accounting",
    PreviewAndEditTransaction: "Preview and edit journal entry",
    PreviewTransactions: "Preview journal entries",
    PreviewTransactionsModa_RecordTransactions_Plural: "Record transactions",
    PreviewTransactionsModa_RecordTransactions_Singular: "Record transaction",
    PreviousBalance_Heading: "Previous balance",
    ProprietorshipSettings: "Other income and tax",
    RBMM_Balances_Empty: "There was no opening balances for this box",
    RBMM_Balances_Heading: "Opening balances for box",
    RBMM_Heading: "Mappings for the box",
    RBMM_MagicMappingsInfo: "This box allow automatic mappings for some accounts beside the ones mentioned above. That means we look at the rest of the journal entry to decide if it should be in this box or in another box.",
    RBMM_MagicMappingTooltip: "This was automapped",
    RBMM_Mappings_Heading: "Mapped accounts",
    RBMM_SumCellInfo: "This box is calculated based on the totals from other boxes. Formula: {0}",
    RBMM_Table_Account: "Account",
    RBMM_Verification_Empty: "There was no content in this box for this period",
    RBMM_Verification_Heading: "Journal entries",
    Receipt: "Receipt",
    Receipt_GoToReport_Salary: "Go to report",
    Receipt_PreviewNotAvailable_Salary: "Preview not available for salary receipts.",
    Receipt_PreviewNotAvailable_SalaryTax: "Preview not available for salary tax reports.",
    Receipts: "Receipts",
    ReceiptSupportTicketModal_Title_ExistingTicket: "Bookkeeping support",
    ReceiptSupportTicketModal_Title_NewTicket: "Send to review",
    RecordNTransactions: "Record {0} transactions",
    RecordTransactions_AddReceipt_Label: "Add receipt",
    RecordTransactions_AllTemplates_Tab: "All templates",
    RecordTransactions_Amount_Label: "Amount",
    RecordTransactions_BrowseMatch_Label: "Browse matches",
    RecordTransactions_BrowseMatch_Title: "Browse matches",
    RecordTransactions_Date_Label: "Date",
    RecordTransactions_DeleteTransaction_Label: "Delete transaction",
    RecordTransactions_DisableMatching_Label: "Disable matching",
    RecordTransactions_EmptyState_Description: "To record or match a bank transaction select one from the list on the left.",
    RecordTransactions_Manual_Tab: "Manual",
    RecordTransactions_MatchItem_CTA: "Match item",
    RecordTransactions_MatchTransaction_Title: "Match transaction",
    RecordTransactions_Message_Label: "Message",
    RecordTransactions_MultipleTransactionsMatchedToOneVerification_Desc: "The journal entry below was already recorded and we have now linked this to the selected bank transactions.",
    RecordTransactions_NoTransactionSelected_Header: "No bank transaction selected",
    RecordTransactions_NrOfHits: "Showing {0} of {1} transactions",
    RecordTransactions_NrOfMatches_M: "{0} suggested matches found for the selected transaction",
    RecordTransactions_NrOfMatches_S: "One suggested match found for the selected transaction",
    RecordTransactions_PageTitle: "Transactions to record",
    RecordTransactions_Record_CTA: "Record",
    RecordTransactions_RecordAndComplete_CTA: "Record transaction",
    RecordTransactions_RecordAndCompleteAnyways_CTA: "Record anyway",
    RecordTransactions_SearchTemplates_Placeholder: "Search templates",
    RecordTransactions_SearchTransactions_Placeholder: "Search transactions",
    RecordTransactions_SplitTransaction_Label: "Split transaction",
    RecordTransactions_Suggestions_Tab: "Suggestions",
    RecordTransactions_TransactionDate_Label: "Transaction date",
    RecordTransactions_TransactionMatchedPlural_Header: "Matched {0} transactions",
    RecordTransactions_TransactionMatchedPlural_Text: "Your bank transactions have been matched with an invoice payment in Bokio or an existing transaction. You can see details of your recorded journal entries below or the existing journal entries matched with bank transactions.",
    RecordTransactions_TransactionRecorded_Header: "Transaction recorded",
    RecordTransactions_TransactionRecorded_Text: "Your bank transaction has been recorded. You can see details of your recorded journal entry below.",
    RecordTransactions_TransactionRecordedPlural_Header: "Transactions recorded",
    RecordTransactions_TransactionRecordedPlural_Text: "Your bank transactions have been recorded. You can see details of your recorded journal entries below.",
    RecordTransactions_UndoSplitTransaction_Label: "Undo split transaction",
    RecordTransactions_WhatDidYouGetPaidFor_Title: "What did you get paid for?",
    RecordTransactions_WhatDidYouPayFor_Title: "What did you pay for?",
    RecordWithoutMatch: "Record without match",
    RegisterAndRecordPayment: "Register and record payment",
    RegisterBookkeepAnother: "Record another",
    RegisterBookkeepAnyway: "Record anyway",
    RegisterBookkeepAsInvoice: "Record as invoice",
    RegisterBookkeepingAccount: "Accounting account",
    RegisterBookkeepNext: "Record next",
    RegisterEditVerification: "Edit journal entry",
    RegisterNewRow: "New row",
    RegisterReceipt_ConfirmSave_CashMethodWarning: "A journal entry will be created when saving the payment of the invoice.",
    RegisterSearchEmptyResult: "The search for {0} gave no results. Search again or browse through",
    RemoveImport_ConfirmModal_Body_Balances: "If the fiscal year has been opened and there were incoming balances in the import, those balances were removed when the fiscal year was opened. Make sure that the balances are correct before you continue to record for this fiscal year in Bokio.",
    RemoveImportedVerification_RemoveSe: ".se import",
    RemoveImportedVerification_RemoveSi: ".si import",
    ReportWarnings_OpeningBalanceUncertainForPeriod_Instructions: "The report for the period uses opening balances from a fiscal year from too far in the past. To ensure the values are displayed correctly please close the indicated fiscal years in the list of fiscal years.",
    RescueMoneyAccount: "To which payment account are you importing?",
    RescuePastePlaceholder: "Paste your transactions from your online bank.",
    RescueStep2: "Did we understand your bank correctly?",
    ResetAccountDefinition_AccountProperties: "Bokios account properties",
    ResetAccountDefinition_BaseAccountDetails: "Bokios account properties",
    ResetAccountDefinition_Change: "Change",
    ResetAccountDefinition_CustomAccountDetails: "Current account properties",
    ResetAccountDefinition_Message: "Changing to Bokios definition for account {0} would mean that Bokios name and mappings will be used for the account. The change will apply to all your verifications, both existing and new.",
    ResetAccountDefinition_ResetAccount: "Account properties - {0}, {1}",
    ResetEquityInfo: "This verification is for recording the transfer/reset of equity. {What is this?}",
    ResultCurrentYear: "Movement",
    ResultReport: "Profit and loss report",
    Review: "Review",
    SelectReceipt: "Choose uploaded receipt",
    SelectTemplate: "Select template",
    ShowBankRules: "Show bank rules",
    ShowRecordedMaterials: "Show recorded uploads",
    ShowVerification: "View journal entry",
    SourceFeature_Closure: "closure feature",
    SourceFeature_Salary: "salary feature",
    SourceFeature_Vacation: "vacation feature",
    Supplier: "Supplier",
    Supplier_AddInvoicePayment_Action: "Add payment",
    Supplier_PaymentCreator_MarkAsFullyPaid_CashMethod: "Mark invoice as fully paid",
    Supplier_PaymentCreator_MarkAsFullyPaid_InvoiceMethod: "Mark invoice as fully paid and register the difference as currency loss/gain",
    Supplier_PaymentCreator_PaymentType: "Payment type",
    SupplierDeleteInvoice_CreateNewFromReceipt_label: "Create new supplier invoice based on receipt",
    SupplierDeleteInvoiceConfirmation: "Do you want to delete the supplier invoice? Related journal entries will also be deleted.",
    SupplierDeleteMessage: "Are you sure you want to delete this supplier?",
    SupplierDeleteSupplierInvoice: "Delete supplier invoice",
    SupplierDeleteTitle: "Delete supplier",
    SupplierInvoice: "Supplier invoice",
    SupplierInvoice_NeedPaymentInformation: "In order to add a payment for this invoice, you must first add the payment details for the supplier",
    SupplierInvoice_VerificationDescription_PreviewCashMethod: "This will only prepare the recording of this invoice. A journal entry will be created only when you register a payment. It will then look like this if you select {0} as the payment account.",
    SupplierInvoiceCurrencyInput_Description: "The currency is connected to your supplier and the amount will be converted to {0} in the upcoming steps.",
    SupplierInvoiceCurrencyInput_Header: "Enter invoice amount in {0}",
    SupplierInvoiceDate: "Invoice date",
    SupplierInvoiceDueDate: "Due date",
    SupplierInvoiceHereYouCanEnterManual: "Here you can record manually, and you can read more about how debit and credit works {here}.",
    SupplierInvoiceHereYouCanEnterManual_Link: "https://www.bokio.co.uk/help/getting-started/bookkeeping/what-are-debit-and-credit/",
    SupplierInvoicePayment: "Supplier invoice payment",
    SupplierInvoiceRemainingSum: "Remaining sum",
    SupplierInvoices_ManagePayments: "Manage Payments",
    SupplierInvoiceSum: "Sum",
    SupplierPayment_Payment_Label: "Payment to supplier",
    SupplierPayment_Repayment_Label: "Refund from supplier",
    SupportTicket_Comment_Label: "Additional comment",
    SupportTicket_PrefilledComment_NoResponse: "Hej!\n\nDå vi inte har mottagit någon återkoppling från dig kommer vi att stänga ärendet. Om du önskar att vi öppnar det på nytt är det bara att återkoppla.\n\nÖnskar dig en fin dag!",
    SupportTicket_PrefilledComment_SupportQuestion: "Hej,\n\nTack för att du har skickat in en fråga till vår Bokföringssupport.\n\nDen här frågan lämpar sig dock bättre till vår vanliga support då detta handlar mer om hur du ska navigera och vad du ska göra i själva systemet - inte hur själva bokföringen ska konteras.\n\nDu når oss på support@bokio.se\n\nDu kan läsa mer om bokföringssupporten och vad den innebär [här](https://www.bokio.se/hjalp/bokio-medlemskap/tillaggstjanster/bokforingssupport/)\n\nÖnskar dig en fin dag!",
    SupportTicket_PrefilledComment_TooComplex: "Hej,\n\nTack för att du har skickat in ett ärende till vår bokföringssupport.\n\nVi har undersökt ert ärende och kommer dessvärre behöva neka denna förfrågan då vi behöver sätta oss in i din bokföring för att kunna svara, eller för att den är av en mer komplex karaktär. Mer om bokföringssupporten och hur den fungerar kan du läsa [här](https://www.bokio.se/hjalp/bokio-medlemskap/tillaggstjanster/bokforingssupport/)\n\nI dessa fall kan du få hjälp av våra samarbetspartners via **Tilläggstjänster &gt;Anlita en konsult** inne i Bokio istället.\n\nAllt gott!",
    SupportTicket_Response_Label: "Response",
    SupportTicket_Status_CloseTicket: "Close",
    SupportTicket_Status_Label_FoundSolution: "Have you found a solution by yourself?",
    SupportTicket_Status_ReopenCheckbox: "Reopen issue",
    SupportTicket_Status_ReopenCheckbox_Label: "Do you need more help with this bookkeeping?",
    SupportTicket_StatusField_SelectLabel: "Status",
    SupportTicket_SubmitComment_Placeholder: "Any additional information about what is being recorded?",
    SupportTicket_SubmitComment_TooltipHelpText: "Please be as exhaustive as possible about what is being recorded and include the purpose of the thing the recording is about",
    SupportTicket_SubmitCommentButton: "Add comment",
    SupportTicketButton_GetRecordingHelp_AskBokioToReview: "Request bookkeeping support",
    SupportTicketButton_GetRecordingHelp_FollowYourTicket: "Follow your issue",
    SupportTicketButton_GetRecordingHelp_Tooltip: "If you have a receipt you can ask Bokio to help with the bookkeeping",
    SupportTicketButton_GetRecordingHelp_UnsureHowToRecord: "Unsure how to record?",
    SupportTicketInfo_Status_Closed: "Closed",
    SupportTicketInfo_Status_InProgress: "In Progress",
    SupportTicketInfo_Status_Invalid: "Invalid",
    SupportTicketInfo_Status_MarkedForBilling: "Will be invoiced",
    SupportTicketInfo_Status_Pending: "Pending",
    SupportTicketInfo_Status_Resolved: "Resolved",
    SupportTicketResponse_SubmitComment_Placeholder: "This should be recorded using the template...",
    SupportTicketsCloseReason: "Reason",
    SupportTicketsCloseReasonNoResponse: "No response",
    SupportTicketsCloseReasonNotSet: "Not set",
    SupportTicketsCloseReasonSupportQuestion: "Support question",
    SupportTicketsCloseReasonTooComplex: "Too complex",
    SupportTicketSubmissionForm_Comment_Placeholder: "Please describe your document. If you made a purchase, please describe how this will be used in your business.",
    SupportTicketSubmissionForm_Info: "Bokio can help you review your invoice, receipt or other document that you are about to record or have previously recorded. You will recieve an answer describing what template to use or how to do a manual recording. {Read more about bookkeeping support here.}",
    SupportTicketSubmissionForm_Info_ContactSupport: "Was this not the type of help you were looking for? Our regular support answers product related questions and bug reports, {write to them here}.",
    SupportTicketSubmissionForm_Info_HireAnAccountant: "Or do you need help with a more complex accounting question? {Hire an accounting consultant}.",
    SupportTicketSubmissionForm_PurchaseInfo: "The amount will be charged from your existing payment method once the review is done. We will only charge you if we are able to help you.",
    SupportTicketSubmissionForm_SubmitButton: "Send to review{0}",
    SupportTicketSubmissionForm_SubmitComment_TooltipHelpText: "Please be as exhaustive as possible about what is being recorded and include the purpose of the thing the recording is about",
    SupportTicketSubmissionForm_SuccessInfo: "A new bookkeeping support ticket has been created. You will receive a notification when we have responded.",
    SupportTicketSubmissionForm_SuccessInfo_DoneButton: "Done",
    SupportTicketSubmissionForm_SuccessInfo_Heading: "Bookkeeping support ticket created!",
    SupportTicketSubmissionForm_SuccessInfo_ShowTicketButton: "Show ticket",
    TaxAccount_ErrorMessageWithReason: "Skatteverket responsed with the error code: {0}",
    TaxAccount_GeneralErrorMessage: "Something failed while syncing. Please contact support.",
    TaxAccount_NoAccessMessage: "You do not have permission to read the tax account at Skatteverket for this company.",
    TaxDeadline: "Taxes due",
    TaxDeadlineWithoutEUTrade: "Taxes due without EU trade",
    Taxes_IncomeTax: "Income tax",
    Taxes_LocalTaxRate: "Local tax rate",
    Taxes_SocialFees: "Social fees",
    Taxes_TaxProfitAdjustment: "Taxable result adjustment",
    Taxes_TaxReduction: "Tax reduction",
    Taxes_YearProfit: "Profit",
    TaxOnIncomeAsPercentage: "Income tax (%)",
    TaxPeriod_DeclareHere: "Open declaration (Skatteverket)",
    ThereAreNErrors: "There are {n} errors",
    ThereAreNWarnings: "There are {n} warnings",
    ThereIsOneError: "There is one error",
    ThereIsOneWarning: "There is one warning",
    ThisIsHowYourVerificationWillLook: "Your journal entry will look like this:",
    Todo_ConfirmDelete_Title: "Are you sure you want to delete this?",
    Todo_FirstCustomRating_Description: "Any thoughts that could help us improve your experience with creating your own tasks?",
    Todo_FirstCustomRating_Title: "How was your experience creating your own tasks?",
    TransactionStepper_Header: "Add a receipt per transaction",
    TransactionStepper_Of: "of",
    TransactionStepper_transaction: "Transaction",
    TransferEquity: "Reset Equity",
    TTR_Salary_PeriodIsDone: "This salary period was already marked as done. We suggest you undo the last step of the salary flow for this period and then come back here and connect the row.",
    TTR_Salary_PeriodIsNotMarkedAsReady: "You need to complete some steps for this tax period before you can record this.",
    TTR_Salary_SumIsIncorrect: "The sum does not match the taxes Bokio calculated for this month. If you manually changed something in the AGI file or uploaded more than one file for the same period you will need to record this manually. Choose Record without a match and choose the template Avdragen skatt or Arbetsgivaravgift. Then return to the salary flow and mark the final step as manually recorded.",
    TTR_Vat_PeriodIsDone: "This salary period was already marked as done. We suggest you undo the last step of the vat flow for this period and then come back here and connect the row.",
    TTR_Vat_PeriodIsNotMarkedAsReady: "You need to complete some steps for this tax period before you can record this.",
    TTR_Vat_SumIsIncorrect: "The sum does not match the taxes Bokio calculated for this month.",
    Type: "Type",
    Type_Cooperative: "Cooperative",
    Type_LimitedCompany: "Limited company",
    Type_LimitedPartnership: "Limited liability partnership",
    Type_Partnership: "Partnership",
    Type_Proprietorship: "Sole trader",
    UpdatedAccountBalances_Heading: "Updated account balance",
    Upload_ChooseAnUploadedReceipt_button: "Choose an uploaded receipt",
    UploadAdditionalFiles: "Upload additional files",
    UploadImage: "Take/upload a photo",
    UploadItem: "Upload receipt or invoice",
    UploadMaterials: "Upload receipts or invoices",
    UploadNewReceipt: "Upload new material",
    UploadSelectReceipt: "Upload or choose an accounting record",
    UploadSelectReceipt_Description: "such as an invoice or receipt",
    UploadsEmptyState_Content: "Upload all your receipts and invoices here. You can also use the {receipt inbox} or our dedicated {mobile app} to upload all your receipts and invoices to Bokio.",
    UploadsEmptyState_Title: "No uploads to show",
    UploadsPage_ShowMaterials_label: "Showing {0} of {1} receipts or invoices",
    ValueRemaining: "{0} left",
    Vat_Debug_ControlCellError_Info: "This could be because the vat-account didn't match the sales/expense account. It could also be because the sum was registered wrong. Please double check the accounting and if everything looks ok you can ignore this warning.",
    Vat_Debug_Header: "Check VAT return",
    Vat_Debug_HideDeletedAndVat: "Hide canceled journal entries",
    Vat_Debug_HideOk: "Hide ok journal entries",
    Vat_Debug_List_Header: "Review {0} of {1} journal entries",
    Vat_Debug_Status_BookkeeptAfterClose: "Missing in report",
    Vat_Debug_Status_BookkeeptAfterClose_Description: "This journal entry was recorded after the report was closed and contains transactions that should be in the VAT return. If this wasn't on purpose you should correct this.",
    Vat_Debug_Status_FailedToRemap: "Failed to map",
    Vat_Debug_Status_FailedToRemap_Description: "This journal entry contains a sales account that can be used for both VAT sales and VAT free sales. We could not automatically map it to A05 or E42. You can either change how it is recorded or you can add this amount to A05 or E42 when you hand in the report.",
    Vat_Debug_Status_PossibleError: "Possible error",
    Vat_Debug_Status_Remapped: "Auto-mapped",
    Vat_Debug_Status_Remapped_Description: "This journal entry contains a sales account that can be used for both VAT sales and VAT free sales. We automatically mapped this one to A05 or E42 based on if the VAT-accounts.  Please verify that this looks OK.",
    VAT_Debug_Tooltip: "This period might have an error. Click the message to go to the check VAT report page.",
    VAT_DeclarationBlockTitle: "Declare VAT to Skatteverket",
    VAT_DeclarationInfo: "As registered for VAT you need to declare VAT for each VAT-period. Even if the period has no events.",
    Vat_Details_UnconnectedAccounts_InfoBox: "We automatically check accounts in accounting groups 2, 3 and 4. If you have custom accounts in other accounting groups, you need to map them as well.",
    Vat_Details_UnconnectedAccounts_ListTitle: "The following unmapped accounts were found",
    Vat_Details_UnconnectedAccounts_Title: "There are accounts that need to be mapped before showing the report!",
    Vat_MapAccountsAction: "Map accounts",
    VAT_MustCompletePreviousStep: "The previous step has to be completed first",
    Vat_Report_QuickCloseNotice_Text: "If you need to report this period through Bokio you can open it again through the three dot icon",
    Vat_Report_QuickCloseNotice_Title: "The period is closed and marked as manually handled",
    Vat_Report_SaveBlocker_NotYetClosable: "The report can not yet be saved. Please wait until the last day of the period.",
    Vat_Report_SaveBlocker_PeriodClosed: "A VAT return can not be created, since the closure for this fiscal year has already been completed. You can close the VAT period through the three dots menu on the top right if you have already handled the VAT report manually.",
    Vat_UnlockReport_Action: "Unlock report",
    VAT_VatInfo: "The easiest way to declare is to upload the file that we generated for this period to Skatteverket.",
    VatDeclaration_MarkAsDeclared_Action: "Mark as declared",
    VatDeclaration_MarkAsNotDeclared_Action: "Mark as not declared",
    VatDeclaration_MarkAsPaid_Action: "Mark tax payment as done",
    VatFlow_FeedbackTitle: "Do you have any feedback on the VAT flow?",
    VatListingError_FiscalYears: "We cannot list your VAT returns because your fiscal year settings are incorrect. Please review and adjust the settings in the [Fiscal years](bokio:/companyid/settings-r/fiscals) settings page and then return to this page",
    VatListingError_Generic: "Something went wrong when loading your VAT data; please contact Bokio support at [support@bokio.co.uk](mailto:support@bokio.co.uk)",
    VatPeriod: "VAT status",
    VATReport: "VAT return",
    VatReport_Check_Info: "This will show you all transactions recorded in the period of the VAT return and which boxes in the VAT return each transaction effects. This allows you to ensure that no transactions have been omitted or duplicated. In the column on the check report that is labelled \"mapping ID\", you can see where each transactions has been recorded within the VAT Return e.g. GB1 goes into Box 1 on the VAT Return and so on.",
    VatReport_NotRegistered: "**You have specified that you are not registered for VAT for the current fiscal year**\nOnce you have a turnover (revenue) of £85,000 or above over a period of 12 months you will have to register for VAT at HMRC. When you have registered and changed your settings in Bokio, you can view and manage all your VAT returns here.\n\nTo find out more about how VAT works and how to register, read more on [www.gov.uk](https://www.gov.uk/vat-registration)\nYou can review and modify your company VAT settings in [Fiscal years](bokio:/companyid/settings-r/fiscals)",
    VatReport_PeriodSettings: "Settings for VAT periods",
    VatReport_TaxPayoutInfoText: "You will get {0} return on VAT deposited on your tax account. If that is then paid out from Skattekonto to your company you need to record this.",
    VatReport_TaxPayoutInfoText_WithoutTaxAccount: "You can record this from Transactions to record. Use the template 'Uttag från skattekonto'",
    VatReport_TaxPayoutInfoText_WithTaxAccount: "Because you have the tax account sync this will be recorded easily from Transactions to record",
    VATReports: "VAT returns",
    VatReportSE_AmountExplanation_ToolTip: "The amount is based on the difference between ingoing and outgoing VAT. If you have more ingoing VAT, you will get money back. If you have more outgoing VAT, you need to pay money back. Calculation:",
    VatReportSE_BalanceReport_Checklist: "Ensure that you have recorded the VAT for the previous period.",
    VatReportSE_BookkeepAndLock_Action: "Record and lock",
    VatReportSE_Cancel_Action: "Cancel",
    VatReportSE_CheckListDescription: "The VAT return is filled in continuously when you record items. It is therefore important that you keep the following in mind:",
    VatReportSE_CheckListHelpLink: "Read more about the VAT flow.",
    VatReportSE_Close_Action: "Close",
    VatReportSE_ClosePeriod_Description: "Your VAT period is closed and now you will have to declare the VAT to Skatteverket. You can see what you have left to do in declare VAT.",
    VatReportSE_CloseThePeriod_StepLabel: "Close the period",
    VatReportSE_CompletePeriod_Action: "Complete period",
    VatReportSE_ConfirmAndContinue_Action: "Confirm and continue",
    VatReportSE_ConfirmAndUnlock_Action: "Confirm and unlock",
    VatReportSE_CostAndIncomesBookkept_Checklist: "All expenses and incomes are recorded for the period.",
    VatReportSE_CurrentPeriod_Status: "Current period",
    VatReportSE_Declare_Deadline: "VAT-declaration deadline",
    VatReportSE_Declare_StepHeader: "Declare VAT to Skatteverket",
    VatReportSE_DeclareVat_StepLabel: "Declare VAT",
    VatReportSE_DownloadDisabled_NoSavedReport: "Once you have completed step 2 you will be able to download and submit your VAT file to Skatteverket",
    VatReportSE_DownloadFile_Action: "Download",
    VatReportSE_DownloadOrEnterVat_Info: "Here is the VAT declaration file we have generated for you. You can now upload it at {Skatteverket} when declaring your VAT for this period.",
    VatReportSE_DownloadReport_StepHeader: "Download VAT report",
    VatReportSE_EventsAreMappedCorrectly_Checklist: "Make sure all accounting events are included in the correct boxes in the VAT return. {Check report here.}",
    VatReportSE_FullyDeleteReport: "Fully delete report",
    VatReportSE_GreatJob: "Great job, this is what you have left!",
    VatReportSE_HowToSubmit: "When it’s time to submit the VAT return, you can use the file provided below. You can also manually enter the amounts shown in the previous step. You can always come back later and download the file.",
    VatReportSE_IncomingVatToDeduct: "Incoming VAT to deduct",
    VatReportSE_InvoicesBookkept_Checklist: "All invoices created in Bokio are recorded.",
    VatReportSE_List_Description: "When a VAT period has expired you need to close the period to be able to declare the VAT to Skatteverket.\nYou are reporting VAT {period} for your current fiscal year.\nIf this does not align with what is registered at Skatteverket adjust the period through {fiscal years}.",
    VatReportSE_List_Period_Monthly: "Each month",
    VatReportSE_List_Period_Quarterly: "Each quarter",
    VatReportSE_List_Period_Yearly: "Yearly",
    VatReportSE_LockAndContinue_Action: "Close period and continue",
    VatReportSE_LockPeriod_Action: "Lock period",
    VatReportSE_LockPeriod_ModalDescription: "You are about to lock this VAT period, which won’t allow you to record anything else with VAT on this period. This is how the journal entry will look:",
    VatReportSE_MarkAsComplete: "Mark period as closed",
    VatReportSE_MarkAsComplete_Undo: "Mark period as open",
    VatReportSE_MarkAsComplete_WarningInfo: "Keep in mind that when you close the VAT period, you should not add anything more on the period that includes VAT.\n\n  **When should you mark the period as closed?**\n\n  - When you have imported accounting with completed VAT periods\n  - Completed and posted the VAT report manually",
    VatReportSE_Pay_StepHeader: "Pay VAT",
    VatReportSE_Pay_StepHeader_GetPaid: "Handle return on VAT",
    VatReportSE_PaymentAccountsMatchBank_Checklist: "The payment accounts in the bookkeeping match the bank statements for each day.",
    VatReportSE_PeriodClosed_StepLabel: "Period closed",
    VatReportSE_Periodisations_Checklist: "Periodisations are done, if applicable.",
    VatReportSE_Reconciliation_StepHeader: "Reconciliation",
    VatReportSE_Record_StepHeader: "Record VAT on the tax account",
    VatReportSE_ReviewReturn: "Before continuing and closing the period, make sure the VAT return looks correct by reviewing it below.",
    VatReportSE_SubmitAndComplete_StepHeader: "Review and close period",
    VatReportSE_ToDeclareVat_Action: "To declare VAT",
    VatReportSE_TotalOutgoingVAT: "Total outgoing VAT",
    VatReportSE_UnlockConfirmation_Singular: "Are you sure you want to unlock this step? By doing so Bokio will unlock this step and remove the journal entry below.",
    VatReportSE_UnlockStep: "Unlock Step",
    VatReportSE_VatFile: "VAT file",
    VatReportSE_VatToDeduct: "VAT to deduct",
    VatReportSE_VatToPay: "VAT to pay",
    VatReportSE_VatToReceive: "VAT to receive",
    VatReportSE_ZeroReport_Info: "Your VAT for this period is zero but you still have to submit and report this to Skatteverket.",
    VerificaitonCommentField_Label: "Comment",
    VerificaitonCommentField_Placeholder: "Add comment that will be added to the journal entry",
    Verification: "Journal entry",
    Verification_DeleteEntireReceipt: "Delete entire receipt",
    Verification_DeleteEntireReceipt_Warning: "Are you sure you want to delete the entire receipt? Make sure you download a copy if you wish to use it in the future.",
    Verification_DeleteSinglePageFromReceipt: "Delete page",
    Verification_DeleteSinglePageFromReceipt_ModalTitle: "Delete page from the receipt",
    Verification_DeleteSinglePageFromReceipt_Warning: "Are you sure you want to delete this page from the receipt? The selected page will be deleted, so make sure you download a copy if you wish to use it in the future.",
    Verification_DetachReceiptFromTransaction: "Detach receipt",
    Verification_DetachReceiptFromTransaction_Warning: "Are you sure you want to detach the entire receipt from the connected transaction? You can still find the receipt in {0} after it is detached.",
    Verification_Remove: "Remove",
    VerificationAccount: "Account",
    VerificationCompleted_GreatJob: "Great job and keep up the good work. You can see details of your recorded transaction below.",
    VerificationDate: "Date",
    VerificationDuplicate_Description: "We found similar transactions to the one you are about to record. Make sure this is not a duplicate.",
    VerificationDuplicate_ModalDescription: "These are the transactions similar to the one you are trying to record:",
    VerificationDuplicate_ModalTitle: "Review transactions",
    VerificationDuplicate_Review_CTA: "Review similar transactions",
    VerificationEditor_Date_Label: "Date",
    VerificationEditor_DifferenceInBalance: "Rows are unbalanced",
    VerificationEditor_NoDifferenceInBalance: "Debit and credit are balanced",
    VerificationEditor_ShowAccountBalance: "Show account balance",
    VerificationEditor_Title_Label: "Title",
    WhatDidYouPayFor: "What did you pay for?",
    WhatDidYouSell: "What did you sell?",
    WhichVatPeriodDoYouUse: "Is the company registered for VAT?",
    WhyAreYouRemovingJournalEntry: "What’s the reason you are removing this journal entry?",
    WithMatches: "With matches",
    WithoutMatches: "Without matches",
  };
}