/* eslint-disable */

import type { SettingsLang } from "../types/SettingsLang";
import { translateTempImpl } from "../TranslatableLanguage";

export function SettingsLangDef(): SettingsLang {
  return {
    translateTemp: (key: string, neutral: string, svSE?: string, enGB?: string) => translateTempImpl(key, neutral, svSE, enGB),
    
    AccontorPartnerDescription: "If you would like to focus more on your core business and feel that accounting is neither fun nor easy. Do what hundreds of Bokio users have done and outsource your bookkeeping to us and become an Accountor Partner.",
    AccountingFormChangeConfirmationTitle: "Accounting form change confirmation",
    AccountorPartnerFeatures: "Bookkeeping of all purchases and sales\nVAT returns\nPAYE tax returns\nYour own contact person\nCurrent recording of transaction done by accountant\nBookkeeping of salaries\nYear-end accounts, incl. bookkeeping of annual tax and results\nFinancial statement (K1 eller K2) and income tax return (NE appendix or INK2)",
    AccountPlan_CustomAccounts_Instructions: "You have accounts Bokio doesn't support. Map the custom accounts to an existing account in Bokio in order for your bookkeeping to be correct. Map to an account that ends up in a similar place in the balance or profit and loss sheet. To easier find these accounts you can choose to show only custom accounts instead of showing all accounts.",
    AccountPlan_NonExistingAccounts_Action: "Create and map these accounts",
    AccountPlan_NonExistingAccounts_Title: "You have imported {0} account number(s) that do(es) not exist in Bokio's chart of accounts",
    AccountPlan_Title: "Chart of Accounts",
    AddFiscalYearModal_CreateUpcomming_Description: "Your fiscal year will end soon. Please check that the settings below are correct for next year too. If they're not, you can correct them here.",
    AddFiscalYearModal_CreateUpcomming_Header: "Confirm your settings",
    AddFiscalYearModal_RedirectText: "In order to continue using Bokio you must have a fiscal year for the current date.",
    AddFiscalYearModal_SwitchCompanies: "Switch to another company",
    AddOns_Accounting_ChangeLegal_Desc: "Advisory and change of legal structure including registration at Bolags- and Skatteverket.",
    AddOns_Accounting_ChangeLegal_Price: "From 5 000 kr {excl. VAT}",
    AddOns_Accounting_ChangeLegal_ReadMore: "Read more about Ombildning",
    AddOns_Accounting_ChangeLegal_Title: "Change of legal structure",
    AddOns_Accounting_Closure_Desc: "Want help with year end closure? We support various packages based on your company type.",
    AddOns_Accounting_Closure_Price: "From 1 380 kr {excl. VAT}",
    AddOns_Accounting_Closure_ReadMore: "Read more about closure",
    AddOns_Accounting_Closure_Title: "Closure",
    AddOns_Accounting_Consul_Desc: "Want help with something specific? Get help from our accounting expert.",
    AddOns_Accounting_Consul_Price: "From 1 500 kr/h {excl. VAT}",
    AddOns_Accounting_Consul_PriceForPartner: "From 1 000 kr/h {excl. VAT}",
    AddOns_Accounting_Consul_ReadMore: "Read more about consultation",
    AddOns_Accounting_Consul_Title: "Consultation",
    AddOns_Accounting_K10_Desc: "We can help you prepare K10 forms and calculation of dividend allowance \u0026 establishment of associated form.",
    AddOns_Accounting_K10_Price: "From 3 500 kr {excl. VAT}",
    AddOns_Accounting_K10_ReadMore: "Read more about K10",
    AddOns_Accounting_K10_Title: "K10",
    AddOns_Accounting_PageTitle: "Hire an accountant",
    AddOns_Accounting_Partner_Price_EF: "Fr. 1099 kr/month {excl. VAT}",
    AddOns_Business_PageDesc: "As a small business owner it can be difficult to get the best deals from big corporations. But we're on your side. We ensure that you get a competitive price for the services that we have carefully selected. Take part of our carefully selected services and streamline your business today.",
    AddOns_Business_PageTitle: "Offers",
    AddOns_Feedback_Button: "Write to us",
    AddOns_Feedback_Desc: "Any thoughts about our offerings?\nAre there any other services you need?",
    AddOns_Feedback_Modal_Message_Label: "Your feedback",
    AddOns_Feedback_Modal_Message_Placeholder: "Write your feedback here",
    AddOns_Feedback_Modal_Title: "Give us feedback",
    AddOns_Feedback_Title: "Give us feedback",
    AddOns_Offer_Ativo_Desc: "Invoice your customers and get paid immediately. With Factoring, you increase your company's liquidity quickly.",
    AddOns_Offer_Ativo_Price: "2.75% of the amount financed",
    AddOns_Offer_Ativo_Title: "Factoring – {Factoringgruppen}",
    AddOns_Offer_CircleK_Description: "Lower your transportation costs with Circle K. Sweden’s biggest fuel network with safe card solutions",
    AddOns_Offer_CircleK_Price: "Discount on fuel and much more",
    AddOns_Offer_CircleK_Title: "Fuel – {Circle K}",
    AddOns_Offer_FINQR_Description: "Easily register your unpaid invoices at FINQR to get help with reminders and debt collection. Change or cancel whenever you like.",
    AddOns_Offer_FINQR_Price: "0 kr per send and no subscription fee",
    AddOns_Offer_FINQR_Title: "Reminders and Debt Collection - FINQR",
    AddOns_Offer_Froda_Description: "Don’t choose between flexible and affordable. Select Froda. Sweden's most satisfied customers according to Trustpilot",
    AddOns_Offer_Froda_Price: "10% discount",
    AddOns_Offer_Froda_Title: "Business loans – {Froda}",
    AddOns_Offer_Hertz_Description: "Sweden's largest car rental company. With a rental car form Hertz you travel safely and comfortably",
    AddOns_Offer_Hertz_Price: "10% discount",
    AddOns_Offer_Hertz_Title: "Car rental – {Hertz Sverige}",
    AddOns_Offer_Lexly_Desc: "Do you have full control of your agreements? Get advice and help with the agreements your company needs.",
    AddOns_Offer_Lexly_Link: "https://lexly.se/bokio?utm_source=bokio\u0026utm_medium=referral\u0026utm_campaign=foretag",
    AddOns_Offer_Lexly_Price: "Free initial advice and 15% off all agreements",
    AddOns_Offer_Lexly_Title: "Legal advice – {lexly}",
    AddOns_Offer_Lyreco_Description: "Get your office supplies and much more with up to 40% discount at Lyreco",
    AddOns_Offer_Lyreco_Price: "Up to 40% discount",
    AddOns_Offer_Lyreco_Title: "Office supplies – {Lyreco}",
    AddOns_Offer_OKQ8_Description: "Get good discounts on fuel, charging and washing, as well as access to over 700 stations.",
    AddOns_Offer_OKQ8_Price: "Discount on Neste MY (HVO100)",
    AddOns_Offer_OKQ8_Title: "Fuel – {OKQ8}",
    AddOns_Offer_Pensure_Desc: "Get more pension for your money with a digital, simple and sustainable occupational pension. Free advice and extra favorable pension solutions for you as a Bokio customer.",
    AddOns_Offer_Pensure_Price: "25% off your first year fee",
    AddOns_Offer_Pensure_Title: "Occupational pension and health insurance - {Pensure}",
    AddOns_Offer_PocketLaw_Description: "Access to 50+ template agreements, digital support, e-signing and secure storage.",
    AddOns_Offer_PocketLaw_Price: "20% discount",
    AddOns_Offer_PocketLaw_Title: "Your digital in-house lawyer – {PocketLaw}",
    AddOns_Offer_Tag_Active: "Active",
    AddOns_Offer_Wndy_Description: "The best digital HR-department on the market. From 0kr/month",
    AddOns_Offer_Wndy_Price: "20% discount on the Pro and Premium packages",
    AddOns_Offer_Wndy_Title: "HR - {Wndy}",
    AddOns_Partner_Button: "Submit interest",
    Agency: "Practice",
    AgencyBox_Accountor: "Accountor has been a trusted partner with Bokio since 2019 and has already helped hundreds of Bokio customers. We can help you with all of your accounting or support you in specific matters like taxation, company matters, salary or HR. We’ve got experts in small businesses and 300 licensed accountants and tax roll managers available for you.",
    AgencyBox_Accountor_Long: "&lt;p&gt;&lt;b&gt;How can we help you?&lt;/b&gt;&lt;/p&gt; In the &lt;b&gt;Accountor Partner&lt;/b&gt; service, we can help you with your entire bookkeeping, you can also get help with closure or if you have other advisory questions. &lt;br /&gt;&lt;b&gt;Accountor partner&lt;/b&gt;, this includes: &lt;ul&gt;&lt;li&gt;Accounting of all purchases and sales&lt;/li&gt;&lt;li&gt;VAT declaration&lt;/li&gt;&lt;li&gt;Employer declaration&lt;/li&gt;&lt;li&gt;Your own accounting accountant that handles all ongoing accounting&lt;/li&gt;&lt;li&gt;Accounting of salary&lt;/li&gt;&lt;li&gt;Closure incl. bookkeeping of the year's tax and profit&lt;/li&gt;&lt;li&gt;Annual report (K1 or K2) and income declaration (NE-appendix or INK2)&lt;/li&gt;&lt;/ul&gt;&lt;h3&gt;Closure:&lt;/h3&gt;&lt;p&gt;Limited company or sole proprietorship does not matter, we will help you with your financial statements, annual report and income tax return.&lt;/p&gt;&lt;h3&gt;Advisory Services:&lt;/h3&gt;If you are instead looking for help with certain selected parts, below you have examples of what we can help your company with. Just write in the box what you want help with.\n&lt;ul&gt;&lt;li&gt;Investigation and reconciliation of your bank account or tax account&lt;/li&gt;&lt;li&gt;Advice on specific management of your business&lt;/li&gt;&lt;li&gt;Tax advice&lt;/li&gt;&lt;li&gt;Company advice&lt;/li&gt;&lt;li&gt;Conversion from a sole proprietorship to a limited company&lt;/li&gt;&lt;li&gt;Performance planning&lt;/li&gt;&lt;li&gt;K10&lt;/li&gt;&lt;/ul&gt;",
    AgencyBox_ButtonText: "Request an offer",
    AgencyBox_DeskJockeys: "Deskjockeys are experts in solving your problems related to taxes, bookkeeping and everything else that keep you up at night! Prior to offering you a fitting solution, a chartered accountant, tax lawyer, and general people person will do a thorough assessment of the problems you face. If you choose us you will receive a fixed price!",
    AgencyBox_DeskJockeys_Long: "Deskjockeys is a modern and digitized accounting agency and we are located in several places in Sweden. We want to be a support for you, not only in terms of finance and accounting but also on the administrative side. Together we identify your challenges and create a plan to solve them!\n For those of you who run a business without employees, we have an introductory offer that suits you who want to do something yourself, get help moving forward when you've driven quickly and get help with more advanced questions. We only work with fixed prices.\nWhat you do yourself: &lt;ul&gt;&lt;li&gt;Invoicing your customers&lt;/li&gt;&lt;li&gt;Take photos of receipts and send to the system &lt;/li&gt;&lt;li&gt;Responsible for paying the company's supplier invoices and other things that must be paid from the company's bank account.&lt;/li&gt;&lt;/ul&gt; What Deskjockeys do:\n&lt;ul&gt;&lt;li&gt;Assists with accounting and reporting&lt;/li&gt;&lt;li&gt;VAT accounting&lt;/li&gt;&lt;li&gt;Salary&lt;/li&gt;&lt;li&gt;Income declaration&lt;/li&gt;&lt;li&gt;Annual accounts &lt;/li&gt;&lt;li&gt;Annual report&lt;/li&gt;&lt;li&gt;Advice regarding profit distribution and distribution&lt;/li&gt;&lt;/ul&gt;Examples of other advice we offer:&lt;ul&gt;&lt;li&gt;Retirement planning&lt;/li&gt;&lt;li&gt;Tax optimization&lt;/li&gt;&lt;/ul&gt;Welcome with your request.",
    AgencyBox_EasyCount_Long: "Easycount is a proactive digital accounting and auditing agency that works with fixed price solutions to make it even easier to be an entrepreneur. With us, you can get help with more or less everything from starting your company to liquidation. You can easily choose between a partial or complete solution, regardless of accounting, payroll, financial statements or support. Of course, we also offer relevant and value-creating advice and if your company is growing we can help you with an audit.\n\nWe distinguish ourselves by being quick-footed, personal, committed and want to be a supplier that makes a difference. You are welcome to try Easycount - at a fixed price!",
    AgencyBox_EasyCount_Short: "Easycount is a proactive digital accounting and auditing agency that works with fixed price solutions to make it even easier to be an entrepreneur. With us, you can get help with more or less everything from starting your company to liquidation. You can easily choose between a partial or complete solution, regardless of accounting, payroll, financial statements or support. Of course, we also offer relevant and value-creating advice and if your company is growing we can help you with an audit.",
    AgencyBox_OneEconomy_Long: "It should be fun to run your own business! That's why we give you the support you need, both ongoing and one-off questions, so you can focus on your core business.\n\n We at UCS One Business help large and small companies with everything regarding finance, payroll, HR and business systems. Since 2021, we have had a close collaboration with Bokio.Together with you as a customer, we ensure that the work is as simple and efficient as possible.This means that we are flexible and tailor solutions specifically for your business, so that you can do what you like most.\n\n",
    AgencyBox_OneEconomy_Short: "UCS One Business offers all the support your business needs with flexible services and packages, at great prices. With 100s of helped customers and close collaboration with Bokio since 2021, we have become Bokio experts, so you can feel safe with our experience. We value personal contact and simplicity. The most important thing is that it should be fun to run your own business!",
    AgencyBox_Studac_Long: "Save time and money with Studac and get accounting assistance from a skilled economics student at a fraction of the average cost of a traditional accounting firm.\n\nStudac offers advisory services, customized accounting courses, and comprehensive solutions that help you focus on your business while we assist with accounting. We tailor our services to your needs and provide a customized solution that fits your business.\n\nAt Studac, we are proud to offer a unique opportunity for students to gain practical experience and guidance while helping other businesses. We believe that our model is a win-win situation for everyone: students gain valuable experience, and businesses receive professional assistance at cost-effective prices.",
    AgencyBox_Studac_Short: "Studac offers cost-effective accounting assistance from skilled business students for small and medium-sized companies. We tailor our services entirely to your needs and create a win-win situation where students gain valuable experience and companies receive professional help at great prices.",
    AgencyBox_WinWin_Long: "We believe in you as an entrepreneur and that you can handle large parts of the accounting yourself in Bokio, but when things go wrong, you should have someone to hold your hand. We are primarily aimed at those who run limited companies with consulting activities and want to keep track of the finances of your company. We offer everything you need from tax advice, accounting and declaration assistance to simpler support. We always run fixed prices and digital processes together with personal commitment. Contact us if you want to work with a future-oriented agency.",
    AgencyBox_WinWin_Short: "We believe in you as an entrepreneur and that you can handle large parts of the accounting yourself in Bokio, but when things go wrong, you should have someone to hold your hand. We are primarily aimed at those who run limited companies with consulting activities and want to keep track of the finances of your company. We offer everything you need from tax advice, accounting and declaration assistance to simpler support. We always run fixed prices and digital processes together with personal commitment. Contact us if you want to work with a future-oriented agency.",
    AgencyInvite_NoInviteToShow_heading: "No invitation to show",
    AgencyInvite_NoInviteToShow_message: "Enter your accountants email to view and manage the invitation.",
    AgencyInvite_SuggestedPermissions_description: "Select which type of permissions the practice should have. You can always change this later.",
    AgencyInvite_SuggestedPermissions_heading: "Suggested permissions",
    AreYouSure: "Are you sure?",
    AuditHistory_Download_ItemsInDownload: "Items {0} - {1}",
    AuditHistory_Download_ModalExplanation: "Here you can download your audit history. Each file will contains a maximum of {0} items of the history.",
    AuditHistory_Download_ModalTitle: "Download audit history",
    AuditHistory_Download_TableHeading: "Included items in audit history",
    AuditHistory_EventType: "Action",
    AuditHistory_Ip: "IP address",
    AuditHistory_ItemName: "Affected item",
    AuditHistory_Title: "Audit history",
    AuditHistory_User: "User",
    AutomaticChecking_Description: "Automatic recording of events in invoice",
    AutomaticCheckingSettings_Description: "Activate automatic recording of events in invoices. This will affect creation of invoices, register payments and settlements.",
    AutomaticCheckingSettings_Heading: "Automatic recording",
    BackofficeNotification_Description: "When you enable a category you will start getting notifications when you login to Bokio.&lt;br/&gt;In addition to that, you can also opt-in for our email notifications.",
    BankId_Activate: "Activate BankID",
    BankId_Activate_ConfirmKnowsEffect: "I am aware that once I have activated BankID, I will only be able to login using BankID",
    BankId_Activate_ConfirmOnlyOneWithAccess: "It is only I who have access to {0}",
    BankId_Activate_ConfirmOwnerOfAccount: "I am the owner of the user account {0}",
    BankId_Activate_CTA: "Activate",
    BankId_Activate_IncorrectIfOtherAccount: "Please note that you must only activate BankID with your personal number on your own personal account, as you will be the only one who can log in!",
    BankId_Activate_Title: "Activate BankID authentication",
    BankId_Activate_With2FA_Text: "To activate BankID authentication, fill in your password and verification code (from your authenticator app).",
    BankId_Activate_WithPasword_Text: "To activate BankID authentication, fill in your password.",
    BankId_RequiredMessage: "This company has a Bokio Business Account and therefore all users must login with BankID. This is to ensure that the company's bank information is handled securely. Please activate BankID to be able to access this company.",
    BankId_Text: "Adding BankID authentication keeps your account much safer. When you login you will use BankID instead of your previous credentials.",
    BankId_Title: "BankID authentication",
    BankId_Title_Activated: "Your account is protected with BankID",
    Billing: "Manage subscription",
    Billing_CardPaymentTermsRefresher: "The subscription fee is paid in advance, while usage fees are charged in arrears and are always charged monthly. We will automatically renew the subscription.",
    Billing_FailedPayments_DidNotRetryReason: "Retry this payment again",
    Billing_FailedPayments_DidNotTry: "Did not try",
    Billing_FailedPayments_InfoText: "[Verify that you have sufficient funds](bokio:/companyid/bank/bankinformation) on your payment account and ensure that you have [activated your Bokio card](bokio:/companyid/bank/cards) if you are intending to use your Bokio Business Account as your payment method.",
    Billing_FailedPayments_InfoText_NonBBA: "Make sure you have sufficient funds on your payment account.",
    Billing_FailedPayments_Require3DS: "Requires 3DSecure authentication",
    Billing_FailedPayments_RetryNow: "Retry payments now",
    Billing_FailedPayments_SlowLoad: "Retrying payments might take up to a minute. Thank you for your patience.",
    Billing_FailedPayments_Title: "These payments have failed:",
    Billing_FailedPayments_TooSoonText: "We could not retry the payments right now. Wait at least 30 seconds between retries.",
    Billing_MyPlan: "My plan",
    Billing_PageTitle: "Subscription and add-on costs",
    Billing_SeeReason: "See reason",
    Billing_Settings_UpdatePaymentCard: "Update payment card",
    Billing_SubText_Text: "View and manage the company's subscription, payment method, receipts and costs for optional add-on services",
    BillingFailedPayments_RetryNow: "Retry payments",
    Campaign_Banner_GenericYearly50Percent_Badge: "Don’t miss out",
    Campaign_Banner_GenericYearly50Percent_CTA: "Get your discount",
    Campaign_Banner_GenericYearly50Percent_Heading: "Flash Deal! Upgrade within {0}",
    Campaign_Banner_GenericYearly50Percent_Text: "Keep smart accounting templates, customised professional invoices and much more. Upgrade by {0} and get a 50% discount on your annual plan.",
    Campaign_Banner_TrialConversion_Badge: "Don’t miss out",
    Campaign_Banner_TrialConversion_CTA: "Choose your plan",
    Campaign_Banner_TrialConversion_Heading: "Flash Deal! Upgrade within {0}",
    Campaign_Banner_TrialConversion_Text: "Keep access to all of our best features after your trial period ends.\nUpgrade no later than **{0}** and get up to 50% off all plans! ",
    Campaign_Banner_TrialConversion2_Badge: "Don’t miss out",
    Campaign_Banner_TrialConversion2_CTA: "Upgrade now",
    Campaign_Banner_TrialConversion2_Heading: "Upgrade before {0} for a 40% discount",
    Campaign_Banner_TrialConversion2_Text: "Your trial is ending soon. Upgrade now and get up to 40% off your subscription on Balance or Business.",
    Campaign_Banner_TrialConversionYearly50_Badge: "Don’t miss out",
    Campaign_Banner_TrialConversionYearly50_CTA: "Choose your plan",
    Campaign_Banner_TrialConversionYearly50_Heading: "Flash Deal! Upgrade within {0}",
    Campaign_Banner_TrialConversionYearly50_Text: "Keep access to all of our best features after your trial period ends.\nUpgrade no later than {0} and get 50% on a yearly plan. ",
    ChangeCompanySystem_TestToReal_Confirm_Label: "I have read and understood that my test company will be converted to a real company",
    ChangeCompanySystem_TestToReal_Menu_Subtext: "Convert your test company to a real company to be able to use all available features",
    ChangeCompanySystem_TestToReal_Title: "Convert to real company",
    ChangeCompanySystem_TestToReal_Warning: "Please be aware that **all** data will be included in the real company. If a lot of test data needs to be removed from the real company we recommend that a new one is created instead.",
    ChangeCompanySystem_TestToReal_Warning_Title: "You are about to convert this test company to a real company.",
    ChangePricePlanError_Text: "Please try again or contact [support@bokio.co.uk](mailto:support@bokio.co.uk)",
    ChangePricePlanError_Title: "Something went wrong when trying to change the price plan",
    ClientRequests_CancelRequest: "Cancel request",
    ClientRequests_PendingRequest: "Pending request",
    COA_ConnectedTo: "Copy properties from account",
    COA_CopyAccount: "Copy account",
    COA_CreateNewAccount: "Create new account",
    coa_CustomAccountOverlappingBasePlan: "The account exists in Bokios account definition",
    coa_CustomAccountsOverlappingBasePlan: "You have created custom account properties for an account that exists in Bokios charts of accounts. Review the properties by clicking on Bokios account properties below.",
    coa_CustomAccountUnmapped: "The account does not have a mapping to Bokios account definition",
    COA_filter_All: "Show all accounts",
    COA_filter_Custom: "Show only custom accounts",
    COA_NewModal_AccountDetails: "Account details",
    COA_NewModal_AccountLabel: "New account number",
    COA_NewModal_AccountProperties: "Account properties",
    COA_NewModal_ConnectLabel: "Connect this account to",
    COA_NewModal_InvalidAccount: "There are no possible custom accounts for the selected account",
    COA_NewModal_NameLabel: "Title",
    COA_NewModal_Title: "Create new account",
    COA_RenameAccount: "Rename",
    COA_ViewModal_AccountNumber: "Account number",
    Company_PageTitle: "Contact information and company type",
    CompanyColor_ChangeColor_action: "Change colour",
    CompanyColor_Heading_SubText: "This color is used to personalise invoice, quote and payslip PDFs with your company branding",
    CompanyDataStorage_Option_No: "No, delete data",
    CompanyDataStorage_Option_Yes: "Yes, store data",
    CompanyDataStorage_Title: "Store your data in Bokio",
    CompanyInformation_heading: "Company information",
    CompanyOwner_Error_NonOwnerTryingToAssignOwnership: "Only the owner of the Bokio company can transfer the ownership.",
    CompanyOwnerInvite_SuggestedPermissions_description: "Select what type of permissions the company owner should start with.",
    CompanyVacationSettings_BalanceTracking: "Vacation balance tracking",
    CompanyVacationSettings_BalanceTracking_Description: "By turning on vacation balance tracking, we'll automatically keep track of your employees vacation balances. We'll also automatically record vacation debt so that your company can have a better view of how much you owe your employees. [Read more about vacation balances on our help pages](https://www.bokio.se/hjalp/lon/semester-sjukfranvaro/sla-pa-automatiska-semesterbalanser/)",
    CompanyVacationSettings_BalanceTrackingOff: "Vacation balance tracking off",
    CompanyVacationSettings_BalanceTrackingOn: "Vacation balance tracking on",
    CompanyVacationSettings_Title: "Vacation settings",
    CompanyVacationSettings_VacationYearStartDate: "Vacation year starting date",
    CompanyVacationSettings_VacationYearStartDate_Description: "The vacation year starting date is when we set the balance for the upcoming vacation year for every employee. The most common start of the vacation year is 1st of April - 31st of March. Some companies have the vacation year starting at 1st of January, but it's not as common.",
    ConfigureEInvoice: "Configure e-Invoice",
    ConfirmCompanyDetailsModal_ConfirmButton: "Confirm company details",
    ConfirmCompanyDetailsModal_Preamble_NoOrgNumber: "You have not entered a valid organisation number. You need to confirm your organisation number in order to continue.",
    ConfirmCompanyDetailsModal_Preamble_NoSearchResults: "We where unable to collect your company details. You need to confirm your organisation number in order to continue.",
    ConfirmCompanyDetailsModal_Title: "Confirm your company information",
    CurrentSubscription_ChangePlan_Info_Title: "When changing price plan",
    CustomAccounts_Header_ConnectedTo: "Connected to",
    CustomAccounts_MapAccounts_Info: "When you import previous bookkeeping your files might contain account numbers that Bokio does not support. You need to map these outstanding accounts to accounts Bokio supports. [Read more.]({0})",
    CustomAccounts_MustConnectToBasPlanAccount: "{0}: \"Connected to\" must refer to an existing Bas15 account",
    CustomAccounts_NoCustomAccounts_Info: "You have no accounts to map!",
    DeleteCompany_AdditionalFeedback_label: "Feedback to Bokio",
    DeleteCompany_AdditionalFeedback_placeholder: "Please take a moment to give us feedback on how we can improve.",
    DeleteCompany_BeforeYouDelete_Error: "Before you can delete your company you need to solve the issues below.",
    DeleteCompany_Checkbox_IAgreeToLetBokioDelete: "I agree to let Bokio delete the company  {0} ({1})",
    DeleteCompany_Checkbox_IUnderstandIWillNotAccess: "I understand that I will not be able to access the company  {0} ({1}) in Bokio again",
    DeleteCompany_ConfirmModal_Action: "Delete {0}",
    DeleteCompany_ConfirmModal_Description1: "You are about to delete {0}, are you sure you want to continue?",
    DeleteCompany_ConfirmModal_Description2: "This action cannot be undone!",
    DeleteCompany_DeleteWarning: "Please note that you cannot undo this once you’ve deleted your company!",
    DeleteCompany_FeedbackDelete_Heading: "What is the reason for you to delete this company?",
    DeleteCompany_FeedbackDelete_Paragraph: "We are sorry to see you go and would love to understand why you’re deleting your company so we can create even better services for any of your future companies. Please provide us with some feedback.",
    DeleteCompany_heading: "Delete company",
    DeleteCompany_Section1_Heading1: "What does it mean to delete the company in Bokio?",
    DeleteCompany_Section1_Paragraph1: "When you delete the company {0}, no one will be able to access it again. All data in the company will be lost.\n\nYour personal account {1} will not be deleted, so you can still access other companies in Bokio.",
    DeleteCompanyRestriction_ActiveSubscriptions_Header: "Your company has active connections",
    DeleteCompanyRestriction_ActiveSubscriptions_Message: "Before we can delete this company, you need to disconnect from {0}. If you need to, you can contact support for help.",
    DeleteCompanyRestriction_BokioBusinessAccountActive_Header: "Your company has Bokio Business Account",
    DeleteCompanyRestriction_BokioBusinessAccountActive_Message: "Before deleting your company you need to terminate your Bokio Business Account",
    DeleteCompanyRestriction_BokioBusinessAccountActive_OtherUser_Message: "Before deleting your company the user who onboarded Bokio Businsess Account needs to terminate Bokio Business Account",
    DeleteCompanyRestriction_BokioBusinessAccountActiveOnboarding_Header: "You have an ongoing Bokio Business Account activation",
    DeleteCompanyRestriction_BokioBusinessAccountActiveOnboarding_Message: "Before deleting your company you need to cancel the activation of Bokio Business Account",
    DeleteCompanyRestriction_BokioPlan_Header: "Your company has a paid plan or unpaid costs",
    DeleteCompanyRestriction_BokioPlan_Message: "If you have a paid plan for the company, you must cancel that plan. You are also not allowed to have any unpaid invoices when deleting the company.\nIf you have usage costs that haven't been invoiced yet you must wait until they have been invoiced before the company can be deleted.",
    DeleteCompanyRestriction_MoreUsersConnected_Header: "Your company has more users connected to it",
    DeleteCompanyRestriction_MoreUsersConnected_Message: "Since there’s more users connected to this company, you need to contact support in order to delete this company.",
    DeleteCompanyRestriction_Services_EInvoices: "E-invoices",
    DeleteCompanyRestriction_Services_Factoring: "Invoice financing",
    DeleteCompanyRestriction_Services_Plaid: "Plaid",
    DeleteCompanyRestriction_Services_PrioSupport: "Prio support",
    DeleteCompanyRestriction_Services_Stripe: "Stripe",
    DeleteCompanyRestriction_UnpaidSentInvoices_Header: "Your company has unpaid sent invoices",
    DeleteCompanyRestriction_UnpaidSentInvoices_Message: "We noticed that you have unpaid invoices where the payment method is set to Bokio Business Account. You need to either cancel these invoices or wait for them to be paid until you can delete your company.",
    DeleteFiscalYearInfo_HasNonZeroIncomingBalance: "This fiscal year has a non zero incoming balance",
    DeleteFiscalYearInfo_HasVerifications: "There are verification in this fiscal year",
    DeleteFiscalYearInfo_IsCurrentFiscalYear: "This is the current fiscal year",
    DeleteFiscalYearInfo_IsNotFirstFiscalYear: "This is not your first fiscal year",
    DeleteFiscalYearInfo_IsOnlyYear: "You have only one fiscal year",
    DeleteFiscalYearInfo_SubTitle: "You can't delete this fiscal years due to following reasons(s)",
    DeleteFiscalYearInfo_Title: "The fiscal year cannot be deleted.",
    DeleteUserAccount_Checkbox_IUnderstandIWillNotAccess: "I understand that I will not be able to access my user account",
    DeleteUserAccount_ConfirmModal_Description1: "You are about to delete your personal account, are you sure you want to continue?",
    DeleteUserAccount_ConfirmModal_Description2: "This action cannot be undone!",
    DeleteUserAccount_DeleteAction: "Delete my personal account",
    DeleteUserAccount_DeleteWarning: "Please note that we do not remove any of your personal data connected to e.g. previous payslips or any connected service that you have entered a separate agreement with.",
    DeleteUserAccount_heading: "Delete my user account",
    DeleteUserAccount_Section_Heading: "What does it mean to delete your personal account?",
    DeleteUserAccount_Section_Paragraph: "When you delete your personal account we will remove all personal data that is connected with your user account. This includes name, email address, phone number and personal number.",
    DeleteUserAccount_UnableToDelete_ConnectedPartner: "You must leave your partner companies before the user account can be deleted.",
    DeleteUserAccount_UnableToDelete_MemberOfCompany: "You must leave or delete your companies before the user account can be deleted.",
    DisconnectCompany_FeedbackOption_AccountantTakeOver: "An accountant will take over my records",
    DisconnectCompany_FeedbackOption_DoesNotSupportNeeds: "Bokio is missing functionality that my company needs",
    DisconnectCompany_FeedbackOption_IClosedMyCompany: "The company has ceased to exist",
    DisconnectCompany_FeedbackOption_NoLongerActive: "I'm no longer active in the company",
    DisconnectCompany_FeedbackOption_OtherReason: "Other reason",
    DisconnectCompany_FeedbackOption_TestCompany: "This was only a test company",
    EInvoiceSettings_Description: "Activate and configure e-Invoice. This enables you to send e-Invoices to companies that accepts e-Invoices.",
    Export_FailedPaymentCTA: "See payments",
    Export_FailedPaymentError: "Export is unavailable while your company has failed payments.",
    ExportCompany_Action_VerificationsTsv: "Journal entries (.tsv)",
    ExportCompany_Filename_Receipts: "Receipts",
    ExportCompany_Filename_ReceiptsByDates: "Receipts by dates",
    ExportCompany_UnbookkeptRecipts: "Export unrecorded receipts",
    FeatureToggles: "Preview features",
    FeatureToggles_EmptyText: "Currently there are no preview features for your company",
    FeedbackModal_AdditionalFeedback_Label: "Feedback to Bokio",
    FeedbackModal_AdditionalFeedback_Placeholder: "Please take a moment to give us feedback on how we can improve.",
    FileUploader_Accepted_Multiple: "Accepted file types:",
    FileUploader_Accepted_Single: "Accepted file type:",
    FileUploader_MainText_Desktop: "Drag and drop your file here, or click to pick one from your device",
    FileUploader_MainText_DropOnly: "Drag and drop your receipts or invoices here to upload",
    FileUploader_MainText_Mobile: "Tap here to pick a file from your device",
    FileUploader_WrongFileType: "Wrong file type",
    FiscalYear_CloseYearAction: "Close year",
    FiscalYear_CloseYearText: "Are you sure you want to close the fiscal year of {start – end}? If you close it, you won’t be able to record anything else in this period.",
    FiscalYear_CloseYearTitle: "Close fiscal year",
    FiscalYear_ConfirmOpenModal_FollowingYears_Text: "If you open this fiscal year, the following closed fiscal years will be opened also",
    FiscalYear_OpenYearAction: "Open year",
    FiscalYear_OpenYearTitle: "Open fiscal year",
    FiscalYears_AccountingFormChangeConfirmationText: "Are you sure you want to change the accounting method?",
    FiscalYears_AccountingMethod: "Accounting method",
    FiscalYears_AccountingMethod_Description: "",
    FiscalYears_Create_Title: "Create new fiscal year",
    FiscalYears_CreateFiscalYear_InvalidPermissions: "Your fiscal year has ended and unfortunately you do not have the required permissions to create a new one. To continue please contact someone with the required permissions.",
    FiscalYears_DeleteYearAction: "Delete year",
    FiscalYears_DeleteYearText: "Do you really want to delete this fiscal year?",
    FiscalYears_DeleteYearTitle: "Delete fiscal year",
    FiscalYears_IncomingBalancesWarning: "Warning: If you change the start date, the opening balances for this year will also be moved.",
    FiscalYears_LockedDates_Explanation: "These dates can't be changed, they are generated automatically based on your previous fiscal year.",
    FiscalYears_MoveWarning: "A number of journal entries need to be moved out of or into this fiscal year. To make this change you need to {contact support}",
    FiscalYears_NoActiveSettings_Message: "There is no fiscal year for the current date. In order to continue using Bokio you need to create a new one below.",
    FiscalYears_OpeningBalancesExist_CannotCreatePreviousFiscalYear: "You have opening balances for the company's first fiscal year, so you cannot create a fiscal year starting on {0}. If you would like to create the fiscal year, you will need to remove all your opening balances.",
    FiscalYears_Turnover: "Projected turnover",
    FiscalYears_Turnover_Description: "",
    FiscalYears_VatPeriod: "What is your VAT registration status?",
    FiscalYears_VatPeriod_Description: "For more information about VAT, please visit [www.gov.uk](https://www.gov.uk/vat-registration)",
    FiscalYears_VatPeriod_ToolTipMessage: "If the reporting frequency is changed, all periods not marked as reconciled will be replaced. If you want to change an existing period, the reconciliation step in the VAT report must be unlocked.",
    GoBackHome: "Take me back home",
    GoToFiscalYears: "Go to fiscal years",
    GoToInvoices: "Go to invoices",
    GoToPaymentMethods: "Go to payment methods",
    HireAnAccountant_Description: "Are you stuck or need advice?\nThrough our partners, you can get help with everything from ongoing bookkeeping to financial statements, tax return advice and tax issues from a professional consultant. Contact the agency that suits you and get help from an accounting consultant who knows Bokio.\nBokio does not take responsibility for the cooperation you enter into with your consultant, we only act as an intermediary. Therefore, we encourage you as a customer to check that you find an agency that best suits your company's needs.\n[**On our website**](https://www.bokio.se/anlita-en-konsult/), we have compiled common questions and tips for a successful collaboration with an accounting consultant.",
    Import_AccountantStep_Description: "The best option is to get SIE file for the years that needs to be transferred to Bokio. ",
    Import_AccountantStep_Description2: "If you don’t have access to such a file you can can set opening balances instead. In that case the balance sheet of the last year is the best option.",
    Import_AccountantStep_Title: "How did you previously do your bookkeeping?",
    Import_BestImportSolution_Ibs_Label: "Set opening balances for the first year in Bokio",
    Import_BestImportSolution_Ibs_Text: "This is the least amount of work if you have many years or many things to record but it is more difficult.",
    Import_BestImportSolution_Manual_Label: "Record everything in Bokio",
    Import_BestImportSolution_Manual_Text: "This is straight forward but can be slow if you have many events to record. You also get the full history in Bokio which is good.",
    Import_HowToImport_BestImportSolution_Label: "Pick the best way to import for you",
    Import_HowToImport_ImportSIE_Title: "Import SIE files (best option)",
    Import_HowToImport_ImportSIE_WithoutPrevious_Description: "This is the best option if you have access to your SIE files. [Read more about SIE files](https://www.bokio.se/hjalp/komma-igang/importera-bokforing/vad-ar-en-sie-fil/)",
    Import_HowToImport_ImportSIE_WithPrevious_Description: "This is the best option for {tool} if you have access to your SIE files. [Read more about SIE files](https://www.bokio.se/hjalp/komma-igang/importera-bokforing/vad-ar-en-sie-fil/)",
    Import_ImportPreviousBookkeepingBlock_CancelBtn: "I have no previous bookkeeping",
    Import_ImportPreviousBookkeepingBlock_Description: "The import of previous bookkeeping will be a necessary step for the closure of the current fiscal year, because the balances need to match. We highly recommend that you do it the earliest of your convenience to prevent unnecessary complex closure processes. Know that you can start recording for this year regardless.",
    Import_ImportPreviousBookkeepingBlock_Heading: "How did you previously do your bookkeeping?",
    Import_ImportSieFileInstead_LinkText: "Import previous bookkeeping instead",
    Import_PreviousBookkeeping_Label: "How has the bookkeeping been done previously in your company?",
    Import_PreviousSolution_Accountant: "Accountant",
    Import_PreviousSolution_Excel: "Excel or similar",
    Import_PreviousSolution_Label: "What accounting software did you use?",
    Import_PreviousSolution_NoPreviousBookkeeping: "There has not been any bookkeeping done previously",
    Import_PreviousSolution_PenAndPaper: "Pen and paper",
    Import_PreviousSolution_Software: "Other software",
    Import_PreviousSolutionOtherName_Label: "Name of the software",
    Import_SetOpeningBalances_LinkText: "Set opening balances instead",
    ImportCompany_FAQ_Header: "FAQ on importing earlier bookkeeping",
    ImportCompany_FAQ_Link1_href: "https://www.bokio.se/hjalp/komma-igang/importera-bokforing/vad-ar-en-sie-fil/",
    ImportCompany_FAQ_Link1_inner: "What's an SIE-file?",
    ImportCompany_FAQ_Link2_href: "https://www.bokio.se/hjalp/komma-igang/importera-bokforing/importera-bokforing-steg-for-steg/",
    ImportCompany_FAQ_Link2_inner: "Import bookkeeping: Step by step",
    ImportCompany_FAQ_Link3_href: "https://www.bokio.se/hjalp/komma-igang/importera-bokforing/hur-exporterar-jag-sie-filer-fran-tidigare-bokforingsprogram/",
    ImportCompany_FAQ_Link3_inner: "How do I export SIE-files from previous bookkeeping apps?",
    ImportCompany_FAQ_Link4_href: "https://www.bokio.se/hjalp/komma-igang/importera-bokforing/min-sie-fil-gar-inte-att-importera/",
    ImportCompany_FAQ_Link4_inner: "I can't import my SIE file",
    ImportCompany_FilesSent_Details: "We'll look through the files and get back to you.",
    ImportCompany_FilesSent_Header: "Your files were sent to support",
    ImportCompany_NoFiscalYearsFound: "No fiscal years was found in some of the files",
    ImportCompany_PageHeader: "Import previous bookkeeping",
    ImportCompany_Review_CTA_Force: "Force import",
    ImportCompany_Review_CTA_Import: "Import",
    ImportCompany_Review_CustomAccounts: "Custom accounts",
    ImportCompany_Review_DuplicateVerifications_Multiple: "{0} duplicate journal entries won’t be imported",
    ImportCompany_Review_DuplicateVerifications_Single: "{0} duplicate journal entry won’t be imported",
    ImportCompany_Review_Error: "Error",
    ImportCompany_Review_Error_FiscalYearClosed: "Existing fiscal year is closed",
    ImportCompany_Review_Error_FiscalYearGap: "Fiscal year gap",
    ImportCompany_Review_Error_FiscalYearOverlap: "Fiscal year overlap",
    ImportCompany_Review_FiscalYear: "Fiscal year",
    ImportCompany_Review_Header: "Review files",
    ImportCompany_Review_NewVerifications_Multiple: "{0} new journal entries",
    ImportCompany_Review_NewVerifications_None: "No",
    ImportCompany_Review_NewVerifications_Single: "{0} new journal entries",
    ImportCompany_Review_Table_Date: "Date",
    ImportCompany_Review_Table_Nr: "No.",
    ImportCompany_Review_Table_Sum: "Sum",
    ImportCompany_Review_Table_Title: "Title",
    ImportCompany_ReviewSettings_AccountingMethodWarning: "'The accounting method in Bokio is \"{0}\" but seems to be \"{1}\" in the files'",
    ImportCompany_ReviewSettings_Confirm: "I have verified that it's the correct files and want to continue",
    ImportCompany_ReviewSettings_Description: "These warnings are here to prevent you from importing the wrong files to your company because that can be hard to undo. What you should do now is double check that the files are the correct ones. And if they are you can just continue. If you want to change the company details you can later do that under the settings.",
    ImportCompany_ReviewSettings_Header: "We found some settings worth reviewing",
    ImportCompany_ReviewSettings_MixedOrgNumbers: "The files you imported might be from different companies, please review this.",
    ImportCompany_ReviewSettings_NameWarning: "'The company name in Bokio is \"{0}\" but was \"{1}\" in the files'",
    ImportCompany_ReviewSettings_OrgNumberWarning: "'The organisation number in Bokio is \"{0}\" but was \"{1}\" in the files'",
    ImportCompany_ReviewSettings_VatSettingWarning: "'The VAT period in Bokio is \"{0}\" but seems to be \"{1}\" in the files'",
    ImportCompany_Success_Header: "Your files have been imported successfully!",
    ImportCompany_Success_Instructions: "Please go to the fiscal year page and close any previous open years that you have already completed the closure for.",
    ImportCompany_Upload_Crash_Details: "Something went wrong when trying to read your file.",
    ImportCompany_Upload_Crash_Header: "File could not be read",
    ImportCompany_Upload_CTA_CheckFiles: "Check files",
    ImportCompany_Upload_ErrorsFound: "We found {0} error(s).",
    ImportCompany_Upload_Header: "Upload files",
    ImportCompany_Upload_Table_Name: "File name",
    ImportCompany_Upload_Table_Size: "File size",
    ImportedJournalEntries: "Imported journal entries",
    ImportPreviousBookkeeping: "Import previous bookkeeping",
    Inbox_AddAllowedEmail: "Add allowed email",
    Inbox_AllowUnsafeEmails: "Allow unsafe emails",
    Inbox_Body: "Body",
    Inbox_EditInboxAddress_Preamble: "Set a new email address for you inbox.",
    Inbox_EditInboxAddress_Title: "Inbox address",
    Inbox_From: "From",
    Inbox_History_AttachmentStatus_Accepted: "Imported",
    Inbox_History_AttachmentStatus_Pending: "Not imported",
    Inbox_History_AttachmentStatus_RefusedMd5Checksum: "Already imported",
    Inbox_History_AttachmentStatus_RefusedMimetype: "Not a PDF",
    Inbox_History_MessageStatus_BlockedByInboxAccess: "Queued",
    Inbox_History_MessageStatus_BlockedByInboxAccess_Legend: "The email is queued until you activate the Balance or Business plan",
    Inbox_History_MessageStatus_Imported: "Imported",
    Inbox_History_MessageStatus_Rejected: "Rejected",
    Inbox_History_MessageStatus_Sandboxed: "Sandboxed",
    Inbox_Manage: "Manage inbox",
    Inbox_Modal_Approve: "Approve and import PDFs",
    Inbox_Modal_Attachments: "Attachments",
    Inbox_Modal_FraudChecks: "Fraud checks",
    Inbox_Modal_MessageDetails: "Email data",
    Inbox_Modal_Reject: "Reject",
    Inbox_Modal_Status: "Status",
    Inbox_RemovedUser: "(Deleted user)",
    Inbox_Sender: "Sender",
    Inbox_Subject: "Subject",
    Inbox_UnconnectedUser: "(Not connected to a user)",
    InboxAddAllowedEmailModal_Preamble: "Add an allowed sender email for {0}",
    InboxAllowedSenders_Preamble: "Choose your allowed senders and email addresses",
    InboxAllowedSenders_Title: "Allowed senders/emails",
    InboxGettingStartedInstructions: "Send your invoices or receipts to the email address above to get it directly into your {0}. Currently we only support PDF, max 4 MB per file.",
    InboxHistory: "Downloading files from an unknow source is dangerous!",
    InboxHistory_AttachmentStatus_Accepted_Legend: "The attachment was imported",
    InboxHistory_AttachmentStatus_Pending_Legend: "The attachment was not imported but will be if you approve the message",
    InboxHistory_InboundEmailResultStatus_Label_Accepted: "Accepted",
    InboxHistory_InboundEmailResultStatus_Label_RefusedNoFiles: "No files",
    InboxHistory_InboundEmailResultStatus_Label_RefusedWhitelist: "Invalid sender",
    InboxHistory_InboxFraudCheckResult_Label_Fail: "Fail",
    InboxHistory_InboxFraudCheckResult_Label_Suspicious: "Suspicious",
    InboxHistory_InboxFraudCheckResult_Trusted_Fail: "Trusted",
    InboxHistory_InitialAction_Label: "Bokio intially set this to",
    InboxHistory_Label_CurrentStatus: "Status",
    InboxHistory_Label_FraudCheck: "Fraud check",
    InboxHistory_Label_ResultStatus: "Result",
    InboxHistory_Label_Sender: "Sender",
    InboxHistory_Label_TimeStamp: "Received",
    InboxHistory_Modal_Title: "Message details",
    InboxHistory_Status_Accepted_Imported_Legend: "The email and the attachments was imported",
    InboxHistory_Status_Accepted_Rejected_Legend: "This email was rejected and no files where imported",
    InboxHistory_Status_Accepted_Sandboxed_Legend: "The email was put in quarantine. Nothing was imported.",
    InboxHistory_Title: "Inbox message history",
    InReviewByAgency_NoticeTitle: "In review by {0}",
    Integrations_heading: "Integrations",
    Integrations_iZettle_Description: "Connect your Bokio account with Zettle and automatically record journal entries for your Zettle events.",
    Integrations_MainScene_SubText: "Here you will find all of the integrations currently available in Bokio.",
    Integrations_OpenAPI_AreYouInterested_heading: "Are you interested in a Bokio open API?",
    Integrations_OpenApi_Enquiry: "Enquiry regarding Bokio open API",
    Integrations_OpenApi_RegisterInterest: "Register interest",
    Integrations_OpenApi_WeArePlanning_message: "We are gathering interest and requests to learn how you would like to use an open API from Bokio.",
    InterestSubmittedSuccessfully: "Interest submitted successfully",
    IntervalChangeModal_text: "Starting from {0} you will pay {1}{2} excl VAT.\n \n\n[Learn more about changing billing cycle]({3})",
    IntervalChangeModal_title: "Change to {0} billing",
    IntervalChangeModal_to_Premium_yearly_text: "For just {0} ({1} incl. VAT), upgrade your plan for the period {2} to {3}. This {4} reduction allows you to avoid paying for any unused Premium time.",
    InvitationType_Accountant: "Accountant",
    InvitationType_General: "General",
    Invite: "Invite",
    InviteAccountant_BothAgenciesNotice: "This email address is also associated with an agency on Hire an accountant page. Select an option to continue.",
    InviteAccountant_PartnerAgency_Description: "To send a request to {0}, you must send it from Hire an accountant page.",
    InviteAccountant_SuccessMsg_Content: "You will receive a notification when your accountant responds to your invitation",
    InviteAccountant_SuccessMsg_Title: "Your invitation has been sent",
    InviteAccountant_VisitHAAPage: "Visit Hire an accountant page",
    InviteAgency_description: "The invitation will be sent to the practice the accountant belongs to below. Once the invitation is accepted you can start collaborating in Bokio.",
    InviteAgency_LoadingMessage: "Setting up permissions and preparing invitation...",
    InviteAgency_NewAccountantDescription: "The invitation will be sent to the practice the accountant belongs to below. Once the invitation is accepted you can start collaborating in Bokio.",
    InviteAgency_NewAccountantTitle: "Your accountant isn't using Bokio",
    InviteNewAccountant_PersonalMessage_Label: "Personal message to your accountant (max 200 characters) (optional)",
    InvoiceSettings_AddInvoiceNumberPrefix: "Add prefix",
    InvoiceSettings_ConfirmChanges: "Confirm changes",
    InvoiceSettings_ConfirmNumberChanges: "Are you sure you want to change this? It will affect your next invoice and customer number?",
    InvoiceSettings_CurrencyRounding_Description: "Round invoices and quotes to the closest whole krona.",
    InvoiceSettings_CurrencyRounding_Heading: "Total amount rounding",
    InvoiceSettings_CustomerNumber: "Which customer number do you want to start with?",
    InvoiceSettings_Description: "Set default values for invoice terms (days, interest on overdue payments, delivery terms), currency rounding, customer and invoice numbers.",
    InvoiceSettings_Heading: "Invoice and quote settings",
    InvoiceSettings_InvoiceNumber: "Invoice number",
    InvoiceSettings_InvoiceNumberPrefix: "Prefix",
    InvoiceSettings_InvoiceTerms_Heading: "Invoice terms",
    InvoiceSettings_RemoveInvoiceNumberPrefix: "Remove prefix",
    InvoiceSettings_SaveChanges: "Save changes",
    KickbackCalculator_CalculateCasjback_Header: "Estimate your cashback here",
    KickbackCalculator_Description_BBA: "This is based on your current business account. Adjust the value to recalculate the saving.",
    KickbackCalculator_Description_Bookkeeping: "This is based on your previous bookkeeping. Adjust the value to recalculate the saving. You need an active Bokio business account to get cashback.",
    KickbackCalculator_Heading: "Cashback estimation",
    KickbackCalculator_Label_AccountBalance: "Account balance",
    KickbackCalculator_Label_CardSpend: "Card spend/month",
    KickbackCalculator_Label_DepositedCapital: "Deposited capital",
    KickbackCalculator_Label_TotalCardPurchases: "Total card purchases per month, excl. cash withdrawals",
    KickbackCalculator_learn_more_about_bokio_cashback: "Learn more about Bokios cashback",
    KickbackCalculator_LI_CashbackCardSpend: "{0} cashback on card purchases",
    KickbackCalculator_LI_CashbackDeposited: "{0} cashback on account balance (per year)",
    KickbackCalculator_Recalculate: "Recalculate",
    KickbackCalculator_Result_Header: "Total cashback",
    KickbackCalculator_ResultPrefix_Cashback: "Cashback",
    KickbackCalculator_ResultSuffix_PerMonth: "/month",
    KickbackCalculator_Table_CashbackCardSpend: "Cashback on card spend ({0})",
    KickbackCalculator_Table_CashbackDeposited: "Cashback on deposited capital ({0} per year)",
    KickbackCalculator_Table_Header: "The calculation is based on:",
    KickbackCalculator_Table_Total: "Total:",
    KickbackCalculator_Table_TotalValue: "{0}/month",
    Layout_Heading_SubText: "Position your company address to the left or right of documents depending on the window location on your envelopes",
    Layout_PageTitle: "Layout, logo and colour",
    LayoutColor_Subtext_description: "These settings allow you to customise how your invoices, quotes and payslips are formatted",
    LeaveCompany_ConfirmModal_Action: "Leave {0}",
    LeaveCompany_ConfirmModal_Description1: "You are about to leave {0}, are you sure you want to continue?",
    LeaveCompany_ConfirmModal_Description2: "This action cannot be undone!",
    LeaveCompany_Feedback_Heading: "What is the reason for you to leave this company?",
    LeaveCompany_Feedback_Paragraph: "We are sorry to see you go and would love to understand why you’re leaving this company so we can create even better services. We would really appreciate some feedback.",
    LeaveCompany_heading: "Leave company",
    LeaveCompany_LeaveWarning: "Please note that you cannot undo this once you’ve left this company!",
    LeaveCompany_Section1_Heading1: "What does it mean to leave the company in Bokio?",
    LeaveCompany_Section1_Paragraph1: "When you leave the company {0}, you will not be able to access this company unless you get invited again.\n\nYour personal account {1} will not be deleted, so you can still access other companies in Bokio.",
    LeaveCompany_UnableToLeave_UserIsBbaOwner: "You cannot leave this company since you have initiated or have an active Bokio Business Account in your account. To leave the company you must terminate the Bokio Business Account first.",
    LeaveCompany_UnableToLeave_UserIsOwner: "You are not able to leave this company since you are the owner of the company in Bokio. To leave the company you must either delete the company or first transfer ownership to another user.",
    LoginMethod_Token: "Token",
    ManageNotifications: "Manage notifications",
    ManagePlans: "Manage plans",
    MarketingSettings: "Marketing settings",
    MobileAppPromotion_AppStoreLink: "https://apps.apple.com/se/app/bokio/id1477107219",
    MobileAppPromotion_PlayStoreLink: "https://play.google.com/store/apps/details?id=com.bokio.app",
    Notification_Description: "",
    Notification_EmailDescription: "{Email} : Sent to the email address in your Bokio account",
    Notification_PushDescription: "{Push} : Sent to the mobile device or tablet that has the Bokio app",
    NotificationRedirect_CouldNotBeFound: "The notification item couldn't be found",
    OffboardAgency_FeedbackHeading: "Offboard {0}",
    OffboardAgency_FeedbackMessage: "Why are you offboarding this practice?",
    OffboardAgency_FeedbackOption_AgreementEnded: "Our agreement has ended",
    OffboardAgency_FeedbackOption_ClientPreferredAnotherSystem: "My accountant preferred another system",
    OffboardAgency_FeedbackOption_UnhappyWithProvidedService: "I was unhappy with the provided service",
    OffboardAgency_SubmitAndOffboard_Button: "Submit and offboard",
    OpeningBalances_AccountsDoNotExistInAccountPlan: "One or more accounts do not exist in our account plan.",
    OpeningBalances_Amount: "Amount",
    OpeningBalances_Assets_InstructionModal_Explanation: "Opening balances for a new fiscal year are always the same as the closing balances for the previous fiscal year. If you change systems {0}-01-01 you will need the closing balances for {1}-12-31. In other words, you will need a balance report from your previous accounting tool that applies for the previous financial year.\n\nStart by looking at the closing balances for the assets. All asset accounts starts with 1XXX, for example 1510-Kundfodringar. You can find the closing balances on the right side of the balance report.",
    OpeningBalances_Assets_Instructions_Step_FillSums: "In Bokio, Asset accounts are in the format of xx and 1xxx. It’s important to note that this won’t necessarily be the same in your previous software.",
    OpeningBalances_Assets_Instructions_Step_ReadBalanceReport: "Start by taking the assets from your balance sheet or Trial Balance report.",
    OpeningBalances_BalanceInfo: "Balance is achieved when the total for assets, equity and dept is 0,00 kr",
    OpeningBalances_BalanceIsCorrect: "The total sum for assets, equity and dept is 0,00 kr",
    OpeningBalances_BalanceIsWrong: "Balance has not been achieved. Make sure that you have entered all amounts correctly and that assets are balanced against equity and liabilities. If you don’t have a completed yearly financial satement where the result is accounted for you should transfer the result to an appropriate account.",
    OpeningBalances_BalanceIsWrong_Title: "The total sum for assets, equity and dept is not 0,00 kr",
    OpeningBalances_CantSave: "Are you unable to save? Learn how with {our guide on opening balances}",
    OpeningBalances_EditBalances: "Edit balances",
    OpeningBalances_EquityAndLiabilities_InstructionModal_Explanation: "Opening balances for a new fiscal year are always the same as the closing balances for the previous fiscal year. If you change systems {0}-01-01 you will need the closing balances for {1}-12-31. In other words, you will need a balance report from your previous accounting tool that applies for the previous financial year.\n\nStart by looking at the closing balances for liabilities and equity. All asset accounts starts with 2XXX, for example 2440-Leverantörsskulder. You can find the closing balances on the right side of the balance report.",
    OpeningBalances_EquityAndLiabilities_Instructions_Step_FillSums: "In Bokio, liability and equity accounts are 2xxx and 3xxx.",
    OpeningBalances_EquityAndLiabilities_Instructions_Step_FinalSum: "Credits from your Trial Balance should be entered here as positive and if you have any liability or equity accounts that are in Debit you should enter them as negative here",
    OpeningBalances_EquityAndLiabilities_Instructions_Step_ReadBalanceReport: "Take your equity and liability accounts from your balance sheet or Trial Balance report and enter each account balance at the end of the previous fiscal year.",
    OpeningBalances_Error_ErrorNoticeTitle: "We have detected problems with your opening balances.",
    OpeningBalances_HowDoIReadBalanceReport: "How do I read the balance report?",
    OpeningBalances_Instruction: "Here is where you can set the opening balances for your first fiscal year at Bokio starting at **{start}**. You can [control and change your fiscal years](bokio:/companyid/settings-r/fiscal-years) before you set opening balances. Having a balance sheet report accessible for the previous fiscal year that reaches to the latest fiscal years last day is the easiest way to do this accurately.",
    OpeningBalances_InstructionModal_Example: "Enter the exact values shown in the balance report ​​into Bokio. Below is an example of how it looks.",
    OpeningBalances_InvalidAccountNumber: "Invalid account number",
    OpeningBalances_NewBalance: "New account",
    OpeningBalances_NoBalancesFor: "No balances has been set for {0}",
    OpeningBalances_NotFirstFiscalYear: "You are viewing the fiscal year that starts on {0}, which is not the company's first year, and that's why you can't modify it.",
    OpeningBalances_SaveButtonTooltip: "You can only save your opening balances if the total balance is zero, if the page is unlocked and if there are no invalid account numbers.",
    OpeningBalances_SetOpeningBalancesHeader: "Set opening balances – {0}",
    OpeningBalances_Success: "Opening balances have been saved! You can see details of your [opening balances in the general ledger](bokio:/companyid/reports/ledger)",
    OpeningBalances_Title: "Opening balances",
    OpeningBalances_TotalBalance: "Total balance",
    Overview_AutomationRules_SubText_text: "Define custom rules for which templates to use when recording bank transactions.",
    Overview_ChangePassword: "Change password",
    Overview_CompanySettings_SubText_text: "Your company settings are required for invoicing and salaries, including contact information, logo and layout, payment etc.",
    Overview_CustomBookkeepingTemplates_SubText_text: "Create and manage custom bookkeeping templates to simplify and streamline your accounting",
    Overview_ExportBookkeeping: "Export accounting and receipts",
    Overview_ExportCompanyData: "Export data",
    Overview_FiscalYears_SubText_text: "Contains fiscal year dates, VAT settings, selected accounting method and projected turnover details.",
    Overview_Import_Bookkeeping_Description: "Import previous bookkeeping or set opening balances",
    Overview_Import_TradeCreditors: "Trade creditors",
    Overview_Import_TradeCreditors_Description: "Import unpaid supplier invoices to get an overview in Bokio of who you owe money to.",
    Overview_Import_TradeDebtors: "Trade debtors",
    Overview_Import_TradeDebtors_Description: "Import unpaid customer invoices to get an overview in Bokio of who owes you money.",
    Overview_Inbox_SubText_text: "Set up senders who can directly e-mail invoices to be recorded",
    Overview_MoneyAccounts_SubText_text: "Here you will find a listing of the names of accounts that are available for recording transactions in your general ledger.",
    Overview_OpeningBalances: "Move your opening balances into Bokio using your balance sheet report from your previous accounting software.",
    Overview_Salary_ContactPerson: "Contact person",
    Overview_TerminateBokioBusinessAccount_Description: "Discontinue Bokio Business Account and all its services.",
    Partner: "Partner",
    Partner_Application_DescribeWhatYouWantHelpWith: "Describe what you would like help with",
    PartnerOnboarding_FirstStep_Title: "1. Confirm company information",
    PartnerOnboarding_SecondStep_Title: "2. Fiscal year information",
    PartnerOnboarding_Submit_Acknowledgment: "I acknowledge that:",
    PartnerOnboarding_SubmitNotice_WhatsNext: "Thank you so much for submitting interest. Our accountants will check the information and get back to you at {email}",
    PartnerOnboarding_Submitted_UnderReview: "Our partner's onboarding team will review your application and respond to {email}",
    PartnerOnboarding_ThirdStep_Employees: "How many employees are working in your company?",
    PartnerOnboarding_ThirdStep_ExpectedTurnover: "What is the expected turnover per year?",
    PartnerOnboarding_ThirdStep_MainActivity: "What is your company's main activity?",
    PartnerOnboarding_ThirdStep_MainActivity_Placeholder: "Tell us about what your company does and anything specific that we need to know.",
    PartnerOnboarding_ThirdStep_PartnerStartMonth: "Which month do you want to start the partner service?",
    PartnerOnboarding_ThirdStep_Title: "3.  Other information",
    PartnerOnboarding_ThirdStep_Verifications: "Verifications per month",
    PaymentMethods_invoice: "Invoice",
    PaymentMethodSettings_Description: "Set payment methods for domestic and foreign invoices.",
    PaymentMethodSettings_Heading: "Payment methods",
    PersonalSettings_CouldNotUpdateDetails_errorMessage: "We couldn't update your details, please try again",
    PersonalSettings_UserResearchGroup_Description: "Help us to improve the product by participating in our resarch group. You will get the chance to join interviews, selftests and more. (You can leave the group whenever you want)",
    PersonalSettings_UserResearchGroup_Label: "I want to be part of Bokio's research group",
    PlaceOfEmployment: "Place of Employment",
    Plan_Ends: "Ends {0}",
    Plan_Renews: "Renews {0}",
    Plan_Starts: "Starts {0}",
    Plans_6_month_free_promo_text: "As a newly founded company you can get up to 6 additional months when you upgrade. [Read more about Bokio Start here]({0}). **The offer is valid until {1}**",
    Plans_6_month_free_promo_title: "Upgrade your price plan and receive up to 6 extra months for free",
    Plans_BanksSupportingAutomaticImport: "Banks that support automatic bank connection",
    Plans_BBABanner_Activate_Header: "Activate Bokio Business Account",
    Plans_BBABanner_Activate_Paragraph: "Invoices as well as salaries can get recorded automatically with and it gets easier to get paid and pay with Bokio Business Account. With an active Business account you will be able to get cashback from the Premium Plus plan.",
    Plans_BBABanner_CTA: "Learn more",
    Plans_BilledMonthly: "Billed {0} monthly",
    Plans_BilledQuarterly: "Billed {0} quarterly",
    Plans_BilledYearly: "Billed {0} yearly",
    Plans_BokioStartCampaign_Content: "As a newly started company, you get up to 6 additional months when you subscribe to a plan. Bokio Business Account included at no extra cost!",
    Plans_BokioStartCampaign_Get6FreeMonths: "Get a head start with 6 extra months",
    Plans_BokioStartCampaign_GetStarted: "Get started",
    Plans_BokioStartCampaign_OfferEnds: "Offer ends {0}",
    Plans_BuyModal_ExVat: "excl. VAT",
    Plans_BuyModal_ExVatAndCashback: "excl. VAT and cashback",
    Plans_BuyModal_Free_Description: "Manual bookkeeping and invoicing for smaller companies.",
    Plans_BuyModal_Heading: "Almost there!",
    Plans_BuyModal_MissingInfo: "You need to specify your country and company name before you can continue",
    Plans_BuyModal_Monthly: "monthly",
    Plans_BuyModal_MonthlyBilling: "Monthly billing",
    Plans_BuyModal_PerMonth: "/ month",
    Plans_BuyModal_PerQuarter: "/ quarter",
    Plans_BuyModal_PerYear: "/ year",
    Plans_BuyModal_Premium_Description: "All features are included in our all-in-one solution with Business Account, Invoicing, Salary and Bookkeeping",
    Plans_BuyModal_Pro_Description: "Designed to deliver more – refunds, personal support and accounting assistance",
    Plans_BuyModal_Quarterly: "quarterly",
    Plans_BuyModal_QuarterlyBilling: "Quarterly billing",
    Plans_BuyModal_Sub1_Heading: "Choose plan",
    Plans_BuyModal_Sub2_Heading: "Choose billing cycle",
    Plans_BuyModal_Sub3_Heading: "Subscription details",
    Plans_BuyModal_Yearly: "annual",
    Plans_BuyModal_YearlyBilling: "Annual billing",
    Plans_Campaign_AppliedIntervalDiscount_Description: "You have {0}% discount. This discount is active until {1}.",
    Plans_Campaign_IntervalDiscount_Description: "Get {0}% discount when you select {1} with {2} billing. This offer is active for {3}.",
    Plans_Campaign_IntervalDiscount_FirstBillingPeriod: "the first billing period",
    Plans_Campaign_IntervalDiscount_FirstMonths: "the first {0} months",
    Plans_Campaign_IntervalDiscount_Label: "Discount {0}%",
    Plans_CancelPlan_button: "Cancel plan",
    Plans_Checkout_AutomaticallyRenews: "Your plan automatically renews until cancelled.",
    Plans_Checkout_Conditions_Heading: "Conditions",
    Plans_Checkout_SubscriptionCostMonthly: "Monthly cost",
    Plans_Checkout_SubscriptionCostQuarterly: "Quarterly cost",
    Plans_Checkout_SubscriptionCostYearly: "Annual cost",
    Plans_Checkout_ToPay: "To pay",
    Plans_Checkout_TrialNotice: "The first payment is withdrawn by {0}, when the trial period has ended.",
    Plans_Checkout_TrialText: "You will not be charged until your trial ends on {0}. ",
    Plans_Checkout_VAT25: "VAT 25 %",
    Plans_ConfirmUpgrade_CashbackNotice: "Your expected monthly cashback of {0} is calculated to be higher than your {1}/month cost of Premium Plus.",
    Plans_ConfirmUpgrade_LearnMore: "Learn more about changing price plans here",
    Plans_ConfirmUpgrade_PayButton: "Pay {0}",
    Plans_ConfirmUpgrade_Text: "The cost to upgrade your plan for the period {0} to {1} is {2}.",
    Plans_ConfirmUpgrade_Text3_NoDiscount: "After {0} you will pay {1} {2}.",
    Plans_ConfirmUpgrade_Text3_WithDiscount: "Your discount is valid until {0}, afterwards you will pay {1} {2}.",
    Plans_ConfirmUpgrade_Title: "Confirm upgrade",
    Plans_ConfirmUpgrade_ToPremiumPLus_AndOnwards: "**{0} and onwards:** {1}",
    Plans_ConfirmUpgrade_ToPremiumPLus_ReadMore: "Learn more about reduced price when upgrading",
    Plans_ConfirmUpgrade_ToPremiumPLus_Text: "The Premium Plus price is reduced for the remaining time of your current subscription. {0} is distributed monthly and reduces your future quarterly payments until the full amount is used.",
    Plans_ConfirmUpgrade_ToPremiumPLus_TodayYouPay: "{0} {1} {2} ({3} incl. VAT)",
    Plans_ConfirmUpgrade_ToPremiumPLus_TodayYouPay_start: "Today you pay:",
    Plans_ConfirmUpgrade_TrialUpgrade: "By proceeding, your scheduled payment for a {0} subscription with {1} will be cancelled, and you will switch to a {2} subscription with {3}. The first payment will occur on {4} for {5} excl. VAT if you don't cancel before hand.",
    Plans_CurrentPlanBox_MonthlyPayments: "Monthly payments of {0}.",
    Plans_CurrentPlanBox_NextPayment: "The next payment will be on {0}.",
    Plans_CurrentPlanBox_PlanIsEnding: "The plan will end {0}.",
    Plans_CurrentPlanBox_QuarterlyPayments: "Quarterly payments of {0}.",
    Plans_CurrentPlanBox_YearlyPayments: "Yearly payments of {0}.",
    Plans_DisabledMessage: "Currently the price plan page is disabled. We are working on enabling it as soon as possible",
    Plans_DowngradeToPremium_okButton: "Confirm Downgrade",
    Plans_DowngradeToPremium_Quarter: "quarter",
    Plans_DowngradeToPremium_Quarterly: "quarterly",
    Plans_DowngradeToPremium_StartingFrom: "Starting from {0} you will pay {1}/{2} excl VAT.",
    Plans_DowngradeToPremium_Text: "By downgrading, you will no longer have access to monthly cashback or prioritised support. Your scheduled payment will be cancelled and replaced by a payment for Premium with a {0} billing cycle.",
    Plans_DowngradeToPremium_Title: "Downgrade to Premium",
    Plans_DowngradeToPremium_Year: "year",
    Plans_DowngradeToPremium_Yearly: "yearly",
    Plans_EPB_Text_Monthly: "Monthly payments",
    Plans_EPB_Text_Quarterly: "Quarterly payments",
    Plans_EPB_Text_Yearly: "Yearly payments",
    Plans_F_ArsredovisningOnline: "Integration to Årsredovisning Online",
    Plans_F_AssetManagement: "Asset management",
    Plans_F_AutomaticReports: "Automated reports",
    Plans_F_Bank: "Bank",
    Plans_F_BankAccount: "Bank account",
    Plans_F_BankRules: "Bank rules",
    Plans_F_BBA: "Bokio Business Account",
    Plans_F_BBankSync: "Bank feeds",
    Plans_F_BBGNumber: "Bankgiro number with company name",
    Plans_F_BCard: "Business card",
    Plans_F_BFreeTransactions: "Free bank transactions per month",
    Plans_F_BManualImports: "Manual bank import",
    Plans_F_Bookkeeping: "Accounting and taxes",
    Plans_F_bookkeepingTemplates: "Bookkeeping templates",
    Plans_F_BOther: "Other",
    Plans_F_BSupport: "Support",
    Plans_F_BSupport_Free: "Bug reports and community support",
    Plans_F_BSupport_Premium: "Priority support",
    Plans_F_BSupport_Pro: "Priority support and live chat",
    Plans_F_BulkRecording: "Record multiple transactions at once",
    Plans_F_CashbackCapital: "Cashback on account balance",
    Plans_F_CashbackCard: "Cashback on card purchases",
    Plans_F_Closure: "Manual closure",
    Plans_F_CurrencyExchangeSurcharge: "Currency exchange surcharge",
    Plans_F_CurrencyExchangeSurcharge_text: "Acc to bank exchange rate data",
    Plans_F_CurrencyExchangeSurchargeInternationalPayments: "Currency exchange surcharge international payments",
    Plans_F_CustomTemplates: "Custom bookkeeping templates",
    Plans_F_Discount: "{0} {1} during year {3}",
    Plans_F_ECommerce: "Ecommerce",
    Plans_F_EInvoice: "E-invoice",
    Plans_F_EmployerTax: "Employer declaration (AGI)",
    Plans_F_ExchangeSurchargeFee: "Currency exchange surcharge card (purchases and withdrawals in a foreign currency)",
    Plans_F_ExchangeSurchargeFeeApplciableFrom: "(from 1 October 2024)",
    Plans_F_IBranding: "Custom colour, layout and logotype on invoices and quotes",
    Plans_F_IChecking: "Automated checking",
    Plans_F_Inbox: "Receipt inbox",
    Plans_F_Integrations: "Integrations",
    Plans_F_InternationalPaymentFee: "International payment below {3000}",
    Plans_F_InternationalPayments: "International payments",
    Plans_F_InternationalPaymentsContactSupport: "International payments (contact support for activation)",
    Plans_F_InvoicePurchase: "Factoring",
    Plans_F_InvoiceReports: "Invoice reports",
    Plans_F_Invoicing: "Invoicing",
    Plans_F_InvoicingBasic: "Create, send and record invoices",
    Plans_F_ISecondaryFeatures: "Quotes",
    Plans_F_IZettle: "Zettle integration",
    Plans_F_ManualBookkeeping: "Manual Bookkeeping",
    Plans_F_MultipleAccounts: "Multiple bank accounts",
    Plans_F_NeAttachment: "NE-bilaga for proprietorships",
    Plans_F_NrOfUsers: "Number of users",
    Plans_F_OMobileApp: "Mobile app",
    Plans_F_PaymentsOutsideEU: "Payments outside EU",
    Plans_F_PayrollManagement: "Payroll management",
    Plans_F_QR_AVI: "QR code and AVI-slip on invoices",
    Plans_F_Quotes: "Quotes",
    Plans_F_ReceiptSupport: "Receipt support",
    Plans_F_RecurringInvoices: "Recurring invoices*",
    Plans_F_RotRut: "Support for rot and rut",
    Plans_F_Salary: "Payroll and expenses",
    Plans_F_Salary_DirectorsPayroll: "Directors payroll",
    Plans_F_SBasic: "Create, record and report salary",
    Plans_F_SEmployeeCost: "Cost per additional employee",
    Plans_F_SExpenses: "Manage expenses",
    Plans_F_SFreeEmployees: "Employees included per month",
    Plans_F_SmartTemplates: "Smart bookkeeping templates",
    Plans_F_Stripe: "Accept card payments with Stripe",
    Plans_F_SwishMonthlyFee: "Monthly fee Swish Business",
    Plans_F_SwishTransactionCost: "Swish transaction fee",
    Plans_F_Tags: "Tags",
    Plans_F_TaxAccount: "Tax account",
    Plans_F_Unlimited: "Unlimited",
    Plans_F_VacationSalary: "Vacation tracking",
    Plans_F_VATReturns: "VAT return",
    Plans_F_Verification: "verification",
    Plans_F_WithdrawalFee: "Withdrawal fee Sweden and abroad",
    Plans_FailedPaymentBlock_Heading: "Your payment failed",
    Plans_FailedPaymentBlock_Message: "To keep using your plan you need to update your payment details",
    Plans_FreeCompany_SelectPlan: "Select a plan to resume Bokio services",
    Plans_LimitedTimeOffer_PremiumPlus1: "Get Premium Plus for the cost of Premium for the first month (Save 300 SEK). The repayment is made to your Bokio business account",
    Plans_LimitedTimeOffer_PremiumPlus2: "Double your cashback on card spend (2%) for the first month",
    Plans_LimitedTimeOffer_PremiumPlusHeading: "Limited Time Offer for Premium Plus",
    Plans_LimitedTimeOffer_PremiumPlusOfferEnds: "Offer ends after {0}.",
    Plans_Monthly: "monthly",
    Plans_NPT_Current: "Current plan",
    Plans_NPT_Quarterly: "Quarter",
    Plans_NPT_Recommended: "Recommended",
    Plans_NPT_Yearly: "Year",
    Plans_Premium_ChangeTo_Quarterly: "quarterly payments",
    Plans_Premium_ChangeTo_Yearly: "yearly payments",
    Plans_PremiumPlus_to_Premium: "Premium",
    Plans_Quaterly: "quarterly",
    Plans_RequiresBBA: "Requires Bokio Business Account",
    Plans_RPU_AppliedBokioStartOffer_Description: "As a newly started company, you got {0} additional months when you subscribed to a plan.",
    Plans_RPU_BokioStartOffer: "{0} months added",
    Plans_RPU_BokioStartOffer_Description: "As a newly started company, you get {0} additional months when you subscribe to a plan.\n\n**Offer ends {1}.**",
    Plans_RPU_campaigns_header: "Included campaigns:",
    Plans_RPU_CancelCancellation: "Undo planned cancel",
    Plans_RPU_CancelCurrentPlan: "Cancel {0}",
    Plans_RPU_ChangeTo: "Change to {0}",
    Plans_RPU_ConfirmCBLabel: "I confirm that my selected company {0} is correct",
    Plans_RPU_DowngradeTo: "Downgrade to {0}",
    Plans_RPU_EndOfYear_CampaignHeading: "Santa's sleigh, on its way, with Bokio's 30% off to save the day!",
    Plans_RPU_EndOfYear_CampaignMessage: "Take the chance to sign up for a plan before the end of the year - get 30% off on Premium or 20% on Premium Plus! The offer is targeted and applies to companies that do not already have a price plan and are not already connected to a user who has a price plan. The discount is valid between 15 December - 31 December 2023 and applies only to the first subscription and payment period.",
    Plans_RPU_EndOfYear_InfoHeading: "{0}% on first period",
    Plans_RPU_EndOfYear_InfoMessage: "Offer valid until 31st December",
    Plans_RPU_GetStarted: "Get started",
    Plans_RPU_Heading_Single_Plan: "Upgrade to {0}",
    Plans_RPU_Perks_Premium_BankServices: "Bank services",
    Plans_RPU_Perks_Premium_BokioBusinessAccount: "Bokio Business account",
    Plans_RPU_Perks_Premium_Heading: "Includes:",
    Plans_RPU_Perks_Premium_MoreProfessionalInvoicing: "More professional invoicing",
    Plans_RPU_Perks_Premium_SalaryServices: "Directors payroll",
    Plans_RPU_Perks_Premium_SmarterBookkeeping: "Smarter bookkeeping",
    Plans_RPU_Perks_PremiumPlus_CashbackCapital: "{0} cashback on account balances",
    Plans_RPU_Perks_PremiumPlus_CashbackCardSpend: "{0} cashback on card purchases",
    Plans_RPU_Perks_PremiumPlus_FreeAccountingAssistance: "Free accounting assistance",
    Plans_RPU_Perks_PremiumPlus_Heading: "Everything in Premium and:",
    Plans_RPU_Perks_PremiumPlus_PrioSupport: "Prioritised support with live chat",
    Plans_RPU_Text: "**Secure payment with Stripe:** We only accept card payment.",
    Plans_RPU_UpgradeTo: "Upgrade to {0}",
    Plans_SelectPlan: "Select plan",
    Plans_TestCompany: "Test companies have access to features that require {0} plan. You cannot change plan for test companies.",
    Plans_TrialPeriod: "Trial period",
    Plans_UpcomingPayments: "Upcoming payments:",
    Plans_UpdatePaymentDetails_Heading: "Enter card details",
    Plans_UpgradeModal_Premium_Features: "### Included in Premium:\n- Smart bookkeeping templates\n- Custom bookkeeping templates\n- Payroll for unlimited directors\n- Recurring invoices\n- Priority support\n- Invoice payments with Stripe\n- Payroll for one director\n- Invoicing with your logo and colour\n- … and much more!",
    Plans_UpgradeModal_Pro_Features: "### Included in Premium Plus:\nEverything in Premium and:\n- Cashback on card purchases\n- Cashback on account balances\n- Free accounting assistance\n- Prioritised support with live chat",
    Plans_UpgradeModal_YouNeedToUpgrade: "You need to upgrade your plan in order to access this feature.",
    Plans_VerifyOrgNumber_Notice: "We were unable to collect your company details. Please update your organisation number before you can view personalised offers and start a subscription.",
    Plans_Yearly: "yearly",
    PleaseUpdateTheseDetails: "Please update these details before you continue",
    PricePlan_Purchase_Premium_Plus_Success_bba: "You now have access to cashback and bookkeeping support.",
    PricePlan_Purchase_Premium_Plus_Success_no_bba: "You now have access to bookkeeping support. To get cashback you need to get Bokio Business Account.",
    PricePlan_Purchase_Success: "You have successfully purchased {0}.",
    PricePlan_Purchase_Success_Free: "You have successfully activated {0}.",
    PricePlan_Purchase_Success_Get_BBA: "Get Business Account",
    PricePlan_Purchase_Success_OnTrial: "You have successfully purchased {0}. Your new plan will be activated after your trial period ends.",
    PricePlan_Purchase_Success_Undo_button_text: "Maybe later",
    ReadMoreAboutBokioPartner: "Read more about Bokio Partner",
    Reason: "Reason",
    RemoveImport_ContactSupport: "Please contact {support@bokio.se} if you have any questions.",
    RemoveImport_Feedback_Title: "Do you have any feedback on the flow for removing imported journal entries?",
    RemoveImport_Menu: "Remove imported journal entries",
    RemoveImport_SubHeaderText: "Imported journal entries, added from external sources, can be managed here for the relevant fiscal years. To correct or update your records, you have the option to delete any unwanted entries. This ensures your financial records remain accurate and up-to-date.",
    RemoveImportedVerifications_SuccessMessage: "{0} imported journal entries for fiscal year {1} has been removed.",
    RemoveImportedVerifications_SuccessTitle: "Removed imported journal entries",
    RemoveImportModal_Text: "Are you sure you want to delete **imported journal entries for {0}**? Please be aware that this action cannot be undone. If you want to have a backup, then first do an export of the bookkeeping for this fiscal year.",
    RemoveImportModal_Title: "Remove imported journal entries",
    RequestAQoute_HireAnAccountant: "Hire an accountant",
    Result: "Result",
    RPU_PaymentConditions: "Read more about payment conditions here",
    RPU_PeriodWarning_Cancellation: "**Cancellation:** Subscriptions cannot be cancelled prematurely during the payment period. The price plan and your access will continue until the end of the period. Cancellation must be made no later than the day before the start of a new payment period. No refunds are provided.",
    RPU_PeriodWarning_RefundsAndAutomaticRenewal: "**Automatic renewal:** The subscription is automatically renewed at the end of the payment period (quarter or year) unless it’s cancelled beforehand.",
    RPU_PeriodWarning_UsageFees: "",
    RPU_ReadMoreAboutPremiumPlus: "Learn more about the Premium Plus",
    Security_LogOut_Everywhere_Description: "This logs you out of Bokio everywhere you're logged in, including the mobile app.",
    Security_LogOut_Everywhere_Title: "Log out everywhere",
    Security_RecentLogin_Method: "Login method",
    Security_RecentLogin_Time: "Time",
    Security_RecentLogin_Title: "Recent logins",
    SelectCountry_Placeholder: "Select country",
    September2024_1000Campaign_Badge: "Special deal",
    September2024_1000Campaign_Content: "With integrated banking services, Bokio becomes more convenient and automated than ever. Bank account, bankgiro and business card are included in your subscription. Sign up for our business account and get a 1000 kr start-up bonus!",
    September2024_1000Campaign_Heading: "More balance with banking and bookkeeping in one",
    September2024_1000Campaign_OfferValidTo: "Offer is valid to {0}",
    Settings_CompanyColor_heading: "Company colour",
    SIEExport_DidYouKow_InviteAccountantNotice_Message: "Did you know that you can invite your accountant to your Bokio account?",
    SIEExport_DidYouKow_YearEndNotice_Message: "Did you know that Bokio now provides year end services?",
    SIEExport_FeedbackHeading: "Export SIE files",
    SIEExport_FeedbackMessage: "Why are you exporting your SIE files?",
    SIEExport_FeedbackOption_AccountantUsingOtherSoftware: "My accountant is using other software",
    SIEExport_FeedbackOption_BokioIsMissingFunctionality: "Bokio is missing functionality that my company needs",
    SIEExport_FeedbackOption_DownloadBackup: "For backup",
    SIEExport_FeedbackOption_Other: "Other",
    SIEExport_FeedbackOption_ToCreateYearEndInOtherSoftware: "To create year end in other software",
    SIEExport_SubmitAndExport_Button: "Submit and export",
    StartBonus_text: "start bonus",
    StepXOfY: "Step {0} of {1}",
    SubmitInterest_Acknowledgment_AllowPartnerAgency: "By submitting this request, you allow the agency to look at your company's accounts to produce the best possible quote for you.",
    SubmitInterest_Acknowledgment_BokioIsIntermediary: "Bokio only acts as an intermediary between you and the agency without any additional responsibility.",
    SubmitInterest_Acknowledgment_InfoIsCorrect: "All the provided information is correct.",
    SubmitInterest_Info_IntegratedWithAO: "Integration with Årsredovisning Online is free, but any service cost is separate from Bokio.",
    SubmitInterest_Info_NEBilaga: "NE-bilaga is included if you upgrade to the {premium yearly plan}",
    SubmitInterest_Info_SubmitIsFree: "Submitting this request is free of charge.",
    SubmitInterestAgency: "Submit interest",
    Subscription_FAQ_Answer_1: "The price plan you choose depends on the benefits you want for your business. All features such as bookkeeping, banking, invoicing, payroll and reporting are included whether you choose Premium or Premium Plus.\n\nThe difference between the plans is that Premium Plus allows you to earn money on your business account. Premium Plus gives you a cashback (currently **[{0}%]**) on your account balance and {1}% cashback on all card purchases. The cashback on your account balance works and is calculated in the same way as interest. Unlike the majority of banks, you receive a favourable return without any limits or the requirement to lock in your money. You have access to free withdrawals and the cashback is paid out monthly to your Bokio Business Account.\n\nDepending on your account balance, you can earn money directly in your accounting service! Use the calculator further up on the page to calculate your cashback. With Premium Plus you also get free bookkeeping support where you can get help with bookkeeping questions directly in Bokio.\n\n[Compare our plans in more detail here](https://www.bokio.se/priser/#prisplaner)",
    Subscription_FAQ_Answer_2: "No, in Bokio there is no commitment or termination period beyond the payment period itself, i.e. the period you pay for when you sign up for a plan. However, the subscription is automatically renewed if it is not cancelled no later than the day before a new payment period begins.\n\nIf you choose to pay annually, you pay for 12 months immediately. If you choose a quarterly subscription, you pay for 3 months. Each payment covers the entire period during which you have access to Bokio's services. It is not possible to end a subscription prematurely, but you can cancel it before the next payment period. If you do not cancel your subscription no later than the day before a new payment period starts, it will automatically be renewed by the same period as before.\n\n[More information on payment conditions can be found here](https://www.bokio.se/priser/#villkor).",
    Subscription_FAQ_Answer_3: "To open a Bokio Business Account, you must first subscribe to a plan. Then, you can easily apply for the business account under Bank in the left menu.  When you apply, you have the option to include a bankgiro in the service and also the chance to transfer an existing bankgiro to the Bokio Business Account, if you already have one. When everything is ready, you will be able to manage your payments and order the business card!\n\nYou can apply for a Bokio Business Account if you have a Limited Company (AB), a Proprietorship (EF) or a Partnership (HB). [Read more questions and answers about Bokio Business Account here](https://www.bokio.se/hjalp/bokio-foretagskonto-bankkoppling/bokio-foretagskonto/vanliga-fragor-om-bokio-foretagskonto/).",
    Subscription_FAQ_Answer_4: "To use Bokio, you must have an active subscription. If you no longer want to use Bokio and cancel your subscription, it is important that you retrieve all data from Bokio that you may need in the future.",
    Subscription_FAQ_Answer_5: "Premium is available as both a quarterly and annual subscription. If you want to change from annual to quarterly payment, this can be done up to the day before the next payment period begins. The change will only take effect after your annual subscription has ended. However, if you have a quarterly subscription and change to annual payment, payment and activation of the new subscription will go through immediately. The remaining amount for your payment period will be deducted from the price of the annual subscription. Please note that Premium Plus is only available as a quarterly subscription.",
    Subscription_FAQ_Answer_6: "Yes, you can upgrade to Premium Plus immediately regardless of whether you have Premium with quarterly or annual payments. Upon payment, the remaining amount left for your subscription period (per month) will be deducted from your Premium Plus price (per quarter).\n\n[Read more in detail here](https://www.bokio.se/hjalp/bokio-medlemskap/prisplaner/vanliga-fragor-om-prisplaner/#uppgradera)",
    Subscription_FAQ_Answer_7: "To ensure that your subscription is cancelled, you should cancel your subscription when you are logged in and not by email at the latest the day before a new payment period starts. You can cancel your subscription at any time. The price plan and your access will continue until the end of the payment period. Paid subscriptions are non-refundable.   Please note that cancelling a Bokio Business Account and cancelling a price plan subscription are separate processes. If you have a Bokio Business Account and want to cancel your subscription, you need to cancel your business account first.",
    Subscription_FAQ_Answer_8: "Yes, the cashback (in %) is guaranteed throughout the subscription period (i.e. the payment period), except in case of an increase. If the cashback is reduced, it will only affect you in the next period.  As with variable interest rates at other Swedish banks, the cashback (%) is adjusted a maximum of once every three months (with the exception of an increase). The current cashback in % or adjustment reflects the current market situation and our banking partner Svea's prospects for future interest rate adjustments in the market. Bokio together with Svea will always strive to offer the best refund Swedish small businesses can get on their transaction account.\n\n[Read more about the cashback here](https://www.bokio.se/hjalp/bokio-medlemskap/prisplaner/vanliga-fragor-om-premium-plus/)",
    Subscription_FAQ_Answer_9: "Yes, when switching from Premium Plus to Premium, the change is made after the end of the payment period. The cashback on the Bokio Business Account is valid throughout the remaining period of your Premium Plus subscription.",
    Subscription_FAQ_externalLink: "Read answers to more questions here",
    Subscription_FAQ_heading: "Frequently asked questions about our subscriptions",
    Subscription_FAQ_Question_1: "How do I know which price plan to choose?",
    Subscription_FAQ_Question_2: "Are there any commitment and termination periods for Bokio price plans?",
    Subscription_FAQ_Question_3: "How do I open a business account in Bokio?",
    Subscription_FAQ_Question_4: "How can I access my bookkeeping?",
    Subscription_FAQ_Question_5: "Can I change my payment period?",
    Subscription_FAQ_Question_6: "Will I be refunded for my remaining Premium payment period if I upgrade to Premium Plus?",
    Subscription_FAQ_Question_7: "How can I cancel my subscription?",
    Subscription_FAQ_Question_8: "Is the cashback (%) on account balance fixed throughout the subscription period?",
    Subscription_FAQ_Question_9: "Can I downgrade from Premium Plus to Premium?",
    TagAdmin_AddOrEditAction: "Add or edit tags",
    TagTypes_AddNewTagType: "Add new tag group",
    TagTypes_AddTagsToCostPool: "Add cost pools",
    TagTypes_AddTagsToGroup: "Add tags to this group",
    TagTypes_AddTagsToProjects: "Add projects",
    TagTypes_AllowedValueLabel: "Tag name",
    TagTypes_Badge: "{0} tag(s)",
    TagTypes_Badge_Plural: "{0} tags",
    TagTypes_CreateCustomTagType: "Create custom tag group",
    TagTypes_CreateGroup: "Create a new group",
    TagTypes_CreateTagType: "Create tag group",
    TagTypes_DeleteTagTypeInfo: "Deleting the tag type will also delete all of the related allowed values.",
    TagTypes_DeleteUsedAllowedValueError: "Failed to delete tag value as it is in use. If you need to do this you can contact support.",
    TagTypes_DeleteUsedTagTypeError: "Failed to delete tag type as it is in use. If you need to do this you can contact support.",
    TagTypes_DuplicateAllowedValue: "Could not save. This value is already in use.",
    TagTypes_DuplicateDimension: "Could not save. This predefined group is already in use.",
    TagTypes_DuplicateQuickAlias: "Could not save. This prefix is already in use.",
    TagTypes_EditTagType: "Edit Tag group",
    TagTypes_Example_Header: "Projects",
    TagTypes_Example_Item: "Project {0}",
    TagTypes_HideExample: "Hide example",
    TagTypes_Name: "Name of the group",
    TagTypes_PlaceHolder: "E.g. Projects",
    TagTypes_PredefinedPicker_CostPool: "Cost pool",
    TagTypes_PredefinedPicker_CostPool_Detail: "Project tracking is for you who want to follow up costs on a organizational level, e.g. teams or departments.",
    TagTypes_PredefinedPicker_Custom: "Custom",
    TagTypes_PredefinedPicker_Custom_Detail: "Freely set up your tags to get any dimension you want to analyze. See examples.",
    TagTypes_PredefinedPicker_Projects: "Projects",
    TagTypes_PredefinedPicker_Projects_Detail: "Project tracking is for you who have multiple projects that you need to compare and follow up individually.",
    TagTypes_QuickAlias: "Prefix",
    TagTypes_QuickAlias_Extrainfo: "Pick a character that you can use to quicktag items of this group. E.g '$' for projects and '@' for persons",
    TagTypes_ShowExample: "Show example",
    TagTypes_SubTitle: "Tags can be used to analyse projects, cost pools and other things in more detail",
    TagTypes_Title: "Tag settings",
    TOTP_ActivateText: "The authenticator app should now give you a 6-digit code for Bokio. Fill it in here to verify and complete the setup process!",
    TOTP_BackupText: "If you change your phone or lose your 2FA connection you need the backup key to keep access to your account. Download and store your recovery document somewhere secure.",
    TOTP_BackupTitle: "Dont forget to save your backup code!",
    TOTP_ConfirmBackupLabel: "I understand that if I lose the 2FA connection, I’ll be locked out if I didn't downloaded and save the recovery document/backup key!",
    TOTP_Continue: "Continue",
    TOTP_DeactivateIntro: "To deactivate 2-factor authentication, fill in your password and verification code (from your authenticator app).",
    TOTP_DeactivateTitle: "Disable 2-factor authentication",
    TOTP_Disable: "Deactivate",
    TOTP_DisableAction: "Disable 2-factor authentication",
    TOTP_DownloadAndContinue: "Download and continue",
    TOTP_DownloadApp: "Download the authentication app",
    TOTP_DownloadApp_Text: "You’ll need to download an app to your phone that will generate unique codes for 2-factor verification. We suggest one of these:\n    ",
    TOTP_Enable: "Activate",
    TOTP_EnableTwoFactor: "Enable two factor authentication",
    TOTP_OrManuallyEnter: "Or manually enter this code:",
    TOTP_ReadMore: "&lt;a target=\"_blank\" rel=\"noopener noreferrer\" href=\"https://www.bokio.se/hjalp/bokio-medlemskap/ditt-bokio-konto/tvafaktor-autentisering-med-bokio/\"\n &gt;Read more about 2FA&lt;/a&gt;",
    TOTP_ScanCode: "Scan the QR-code",
    TOTP_ScanCode_Text: "Open the app you just downloaded and use it to scan the QR-code below to connect the app with Bokio.",
    TOTP_SuggestedAppsTitle: "We suggest one of these:",
    TOTP_Text: "Adding 2-factor authentication keeps your account much safer. When you login we will require a code generated by your phone in addition to you password.",
    TOTP_Title: "2-factor authentication",
    TOTP_Title_Activated: "Your account is protected with 2-factor authentication",
    TOTP_YourBackupCode: "Your backup code",
    Turnover_0To1: "Up to 1 million",
    Turnover_1To40: "1 to 40 million",
    Turnover_40ToX: "Over 40 million",
    UpdateUserName: "Update your email",
    UpdateUsername_ConfirmInstructions: "Check your inbox (including the spam) for an email from us and either click the link in the email or input the code below.",
    UpdateUserName_ConfirmNewEmail: "Confirm new email",
    UpdateUsername_ConfirmTitle: "Confirm your new Email",
    UpdateUserName_CurrentEmail: "Current email",
    UpdateUsername_Error_Completed: "Already verified!",
    UpdateUsername_Error_EmailFailed: "Sending verification email failed!",
    UpdateUsername_Error_EmailNotAvailable: "A Bokio account already exists with the selected email address",
    UpdateUsername_Error_InvalidEmail: "The selected email is not in valid format!",
    UpdateUsername_Error_NoLongerValid: "This code has expired and can't be used!",
    UpdateUsername_Error_NoLongerValid_Action: "Go back and retry",
    UpdateUsername_Error_WrongCodeOrRequest: "The code or the link was incorrect!",
    UpdateUsername_Error_WrongUser: "The logged in user did not create this change request",
    UpdateUserName_Has2FA_Warning: "You currently have two factor authentication enabled. It will continue to work if you change your email, but the authenticator app will list the old email. If you want to fix this you will have to disable 2FA and then enable it again after you changed your email.",
    UpdateUserName_NewEmail: "New email",
    UpdateUserName_NewEmailHelp: "The new email will be the one you have to use to sign in with and contact support with in the future. We will also send all emails to the new email address.",
    User_Accountant: "Practice and accountants",
    User_AccountantDescrription: "Invite your accountant to collaborate in Bokio",
    User_Admin: "Admin",
    User_AdminDescription: "Give full access to a business partner or family member",
    User_Administration: "Administration",
    User_InviteAccountant: "Invite accountant",
    User_InviteAdmin: "Invite admin",
    User_InviteNewUser: "Invite a new user",
    User_InvitePayrollManager: "Invite payroll manager",
    User_InviteUser: "Invite user",
    User_Other: "Other",
    User_OtherDescription: "Select custom permissions for a new user",
    User_PayrollManager: "Payroll manager",
    User_PayrollManagerDescription: "Access to set up and run payrolls, manage employees salaries and expenses",
    User_SalariesAndExpenses: "Salaries and Expenses",
    UserRole_Error_TryingToTransferRoleUserDoesntHave: "You must currently have the role that you want to transfer to someone else",
    Users_ActiveUsers: "Active users ",
    Users_AddAccountant: "Add Accountant",
    Users_AddUser: "Add user ",
    Users_AgencyPermissions_Header: "Agency permissions",
    Users_AgencyPermissions_Tooltip: "Any accountant from the agency will get these default permissions when added. These permissions can be changed at any given time.",
    Users_AreYouSureYouWantToCancelTheInvite: "Are you sure you want to cancel the invite to ",
    Users_AreYouSureYouWantToDeleteYourUser: "Are you sure you want to delete the user ",
    Users_CancelInvite: "Cancel invite",
    Users_DeleteUser: "Delete user",
    Users_EditPermissionsFor: "Edit permissions for",
    Users_InvitationPending: "Invitation pending",
    Users_NoPermission: "You don't have access to view this page. Please contact the administrator of this company. If you need help, contact support.",
    Users_OffboardAgency: "Disconnect practice",
    Users_SendInvite: "Send invitation",
    Users_UnableToDeleteBbaOwner: "This user has an active Bokio Business Account. Please contact support to get help with removing this user.",
    Users_UnableToDeleteOwner: "This user is the company owner. They must transfer the ownership to someone else before being removed.",
    Users_UnableToDeleteSelf: "You can't delete yourself from the users admin page, please disconnect yourself from the company instead.",
    UsersScene_NoAssignedAccountants: "No accountants are currently assigned to your company",
    YourInboxAddressIs: "Your inbox address is",
  };
}