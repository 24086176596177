/* eslint-disable */

import type { RecipesLang } from "../types/RecipesLang";
import { translateTempImpl } from "../TranslatableLanguage";

export function RecipesLangDef(): RecipesLang {
  return {
    translateTemp: (key: string, neutral: string, svSE?: string, enGB?: string) => translateTempImpl(key, neutral, svSE, enGB),
    
    CarLeasing_Administrative: "Related to Administrative fees (excl. vat)",
    CarLeasing_Administrative_Descr: "Administrative fees excl. VAT",
    CarLeasing_Administrative_Description: "Administrative fees excl. VAT",
    CarLeasing_AdministrativeVAT: "Related to Vat administrative fees",
    CarLeasing_AdministrativeVAT_Descr: "TRANSLATE: Moms Administrativa avgifter",
    CarLeasing_AdministrativeVAT_Description: "VAT Administrative Fees",
    CarLeasing_High: "Fee increase (incl. vat)",
    CarLeasing_High_Description: "Fees increased",
    CarLeasing_Insurance: "Related to Insurance premium &amp; taxes",
    CarLeasing_Insurance_Description: "Insurance Premiums &amp; Taxes",
    CarLeasing_Leasing: "Related to leasing cost (excl. vat)",
    CarLeasing_Leasing_Description: "VAT on leasing",
    CarLeasing_LeasingVAT: "Related to Vat leasing cost",
    CarLeasing_LeasingVAT_Description: "VAT on leasing cost",
    CarLeasing_Total: "Invoice total amount (incl. vat)",
    CarLeasing_Total_Description: "Total invoice amount (incl. vat)",
    CarLeasing_TotalRule_FailMessage: "Value in amount must be a total of all other values",
    Common_Account: "Account",
    Common_Account_Description: "The bank account that has been affected",
    Common_AmountWithNoVAT: "Amount",
    Common_AmountWithVAT: "Amount (Incl. VAT)",
    Common_AmountWithVAT_Description: "Amount (Incl. VAT)",
    Common_DueDate: "Due date",
    Common_DueDate_Description: "Invoice expiration date or date when the invoice was paid, if it is already have been paid on another date",
    Common_InvoiceDate: "Invoice date",
    Common_InvoiceDate_Description: "Date of expense / income. Date on invoice date or date on receipt.",
    Common_PaymentDate: "Payment date",
    Common_PaymentDate_Description: "Date of payment ",
    Common_Title: "Title",
    Common_Title_Description: "e.g.  'purchased computer'",
    Common_TotalVAT: "VAT amount",
    Common_TotalVAT_Description: "Sum of only VAT amount ",
    Common_TransactionDate: "Date",
    Common_TransactionDate_Description: "Transaction date",
    Representation_NumberOfPeople: "Number of people",
    Representation_NumberOfPeople_Description: "Number of people",
    Representation_WasItMeal: "Was it a meal?",
    Representation_WasItMeal_AnswerOptions: "Yes:0,No - refreshment:1",
    Representation_WasItMeal_Description: "If the consumption can replace a meal, only VAT is deductible",
  };
}